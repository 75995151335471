export const baseConfig = {
 "debug": false,
 "defaultRedirectUrl": "http://localhost:8082",
 "privacyPolicy": {
  "baseUrl": "/static/",
  "documentUrls": {
   "de": "PRIVACY_POLICY_DE.md",
   "en": "PRIVACY_POLICY_EN.md"
  }
 },
 "aws": {
  "cognito": {
   "userPool": {
    "userPoolIdLogin": "eu-central-1_HFR9WrvYX",
    "userPoolId": "eu-central-1",
    "clientId": "20g80mj8u46l75maclm8flu39l"
   }
  }
 },
 "backend": {
  "services": {
   "alarm": {
    "base_url": "https://alarm.staging.sw-machines.io",
    "endpoints": {
     "list": "/alarms/list",
     "top": "/alarms/top/amount"
    }
   },
   "device": {
    "base_url": "https://device.staging.sw-machines.io",
    "base_url_zip": "https://static.staging.sw-machines.io/swcp/migration/aws-migrate-iot.zip",
    "endpoints": {
     "enrollment": "/devices"
    }
   },
   "assets": {
    "base_url": "https://asset.staging.sw-machines.io",
    "endpoints": {
     "breadcrumb": "/breadcrumb",
     "companies": "/companies",
     "controllers": "/controllers",
     "controller": "/controller",
     "countries": "/countries",
     "customer_number": "/customerNumber",
     "enrollment": "/devices",
     "halls": "/halls",
     "industry_sectors": "/industrySectors",
     "industry_sector": "/industrySector",
     "lines": "/lines",
     "locations": "/locations",
     "machines": "/machines",
     "machine_types": "/machine_types",
     "machine_type": "/machineType",
     "search": {
      "base_endpoint": "/search/machines",
      "machineType": "/machineType"
     }
    }
   },
   "shopfloor": {
    "base_url": "https://content.staging.sw-machines.io",
    "endpoints": {
     "shopfloor": {
      "base_endpoint": "/shopfloor",
      "line": {
       "data": "/lines/",
       "image": "/lines/"
      },
      "hall": {
       "data": "/halls/",
       "image": "/halls/"
      }
     }
    }
   },
   "auth": {
    "base_url": "https://auth.staging.sw-machines.io",
    "endpoints": {
     "check_access_token": "/check",
     "logout": "/revoke",
     "refresh": "/refresh"
    }
   },
   "axistest": {
    "base_url": "https://axistest.staging.sw-machines.io",
    "endpoints": {
     "overview": "/overview",
     "result": "/result"
    }
   },
   "timeseries": {
    "base_url": "https://timeseries.staging.sw-machines.io",
    "endpoints": {
     "alarms": {
      "list": "/alarms/list",
      "top": "/alarms/top/amount"
     },
     "availability": "/availability",
     "availability_aggregated": "/availability/aggregated",
     "export_availability": "/availability/export",
     "export_timeline": "/generic/timeline/export",
     "cycle_time": "/generic/timeline",
     "feedrate_override": {
      "current": "/v2/override/feedrate"
     },
     "multi_timeline": {
      "timeline": "/generic/timeline",
      "collections": "/collections",
      "spindle_collections_ids": [
       "7b2b65c7-4c64-4d8b-94ba-fd02826b82ab",
       "be89cddb-72a0-481f-828d-075d32cbe19c",
       "a07fd607-bbf5-4e6c-aad1-ac65ca0a2c82",
       "81e7b55e-e756-4a8c-a5ca-556c844e514d",
       "a7a9d141-bb93-4512-93f0-a64d150b1e51",
       "77145879-c9a3-4a47-a28a-a626cfda95fa",
       "5d771339-185d-4dbe-9d81-dd5edcf865d8",
       "57fe4512-10e6-4d05-9cdc-51afdb2875ab",
       "2795ad6f-91f4-49fc-9f59-d9cb55eb6e44",
       "ff7940f9-59ea-403f-981c-c39d0cb64f78",
       "6773e806-a3af-4aa0-834b-d6bde8dbc398",
       "21a64a2b-4ae4-47c2-a450-2c9445d7442d",
       "e4e31942-8a03-4118-bf47-f03b4368b164",
       "d695be95-18d2-4e50-bf21-b0554668863b",
       "78bf98c9-4907-43c8-ae7c-7a3e2711bbf0",
       "6dbf489b-5eb3-4fde-a20e-75791c2187cd",
       "b7df76d1-d609-4a6b-9fd4-c9c5a65412c2",
       "499535a7-3976-4fbb-98bc-2cce5b609902",
       "ee9674bb-0cd4-4c69-b40d-bc762e369d0d",
       "c480acfe-6019-4fa8-b386-4637150cc0f4",
       "4accd21b-6efc-4070-b92c-6979ec4fa5bf",
       "8f4db641-beab-4489-bf84-baacc3d81958",
       "1feac422-fc75-44d3-bf03-0c6f1f872910",
       "d42961c5-6e8e-4e82-9ef9-ffcfb8f21a5e",
       "d6b6764e-1483-4518-bd0a-fe35985904d8",
       "5924bfa9-8230-4101-a9fa-94e57bd8d559",
       "aa690344-72df-4fc7-abc6-34ae61e56764",
       "b787d83b-2199-4212-a6b8-e0cd297d5dd1",
       "d0e34d4b-97fd-44e6-8d7a-126f9cdeb03a",
       "0aed3b5d-fcb5-4507-a00b-494abcf3330d",
       "046d3751-60ee-442d-b9d3-2f965fd4f40a",
       "cda792ca-906a-4623-b446-36e8655570d9",
       "934f720c-2d9f-403d-9e3e-6268ae0610d3",
       "541e9d2a-a82e-4ec0-921b-7238722bd7fa",
       "5dac3c82-dfd7-4315-9800-1524035738ad",
       "8611c194-b59d-41a9-9fae-f28b4d13d1ad"
      ],
      "variables": "/variables",
      "current": "/generic/latest"
     },
     "operation_mode": {
      "timeline": "/generic/timeline",
      "current": "/generic/latest"
     },
     "runtime_monitor": {
      "timeline": "/generic/timeline",
      "current": "/generic/latest"
     },
     "uptime_controller": {
      "kpi": "/availability/distribution",
      "timeline": "/generic/timeline"
     },
     "spindle_override": {
      "current": "/v2/override/spindle"
     },
     "tiles": {
      "detail_tile": "/tiles/detail"
     },
     "workpiece_counter": "/tiles/counter",
     "maintenance": {
      "available_axis_tests": "/axisTest/availableAxisTests",
      "axis_test_result": "/axisTest/axisTestResult"
     },
     "variables": {
      "collections": "/collections",
      "variables": "/variables"
     }
    },
    "variable_ids": {
     "CONNECTION_STATE_BOX": "51cb59ce-373a-4ba0-87f4-e903594a9fda",
     "SENDING_STATE": "2bd70b18-05e3-4c20-b9f3-3bb2f4afee11",
     "RUNTIME_DATA_AND_OPERATION_MODE": [
      "aacf3da7-740a-406e-b897-070bf722913c",
      "f6f5e6a7-5053-4603-ab1a-d7ee9cb7c7be"
     ],
     "RUNTIME_DATA": [
      "aacf3da7-740a-406e-b897-070bf722913c"
     ],
     "OPERATION_MODE": [
      "f6f5e6a7-5053-4603-ab1a-d7ee9cb7c7be"
     ],
     "CYCLETIME_TABLES": [
      "3f3430fb-4820-4e9c-baad-79c3ec78ca55",
      "0c8caacc-1251-4537-95d2-7d591fda2661"
     ],
     "MACHINE_STATE": [
      "aacf3da7-740a-406e-b897-070bf722913c"
     ],
     "FEEDRATE_OVERRIDE": [
      "8af8fe41-e740-4b4c-961c-e8ae3a1faafb"
     ],
     "SPINDLE_OVERRIDE": [
      "195db9c0-59a7-462e-b04c-5159ac4d9a04"
     ],
     "NUMBER_ALARMS_AND_RUNTIME_DATA": [
      "aacf3da7-740a-406e-b897-070bf722913c",
      "6a6ecdb1-6df9-4ff5-989a-74de62cbd167"
     ],
     "ENERGY_MONITORING_AIR": [
      "f8ac107c-508b-43bd-a021-0fee9b3e051e",
      "b8e21147-a6e4-440a-837b-8c9689f2710c",
      "3d8ec2b9-de3c-4b81-9f4a-078c0045ede2",
      "910e969c-3051-451a-801e-d851d92ffaf0",
      "dffda5c0-dfb5-42c2-a81f-4b06bb4d2ec3",
      "77ab8131-d771-4985-8e9a-34aa0d2bf102"
     ],
     "ENERGY_MONITORING_CURRENT": [
      "24d76757-d672-4de8-837a-b1c3696da1cf",
      "d532eb8f-1777-420d-b4e4-9c78b1872d4d",
      "caf68ced-a27b-40b3-9146-932202ab7b5d",
      "15009529-5e49-48b6-9778-732ef3ee7d97",
      "7f288be5-0501-4cdf-89ef-b8829fd90c51",
      "1e9646c4-6801-49f9-a1d4-c2a5ab29bc9b"
     ],
     "ENERGY_MONITORING_HOUR": [
      "1d21755b-7649-44f7-a24a-d46cbdc0c353",
      "dcf8084f-387f-4e23-883c-2409defc1892"
     ],
     "DB370_MESSAGE": "2e791d7f-36ce-4df5-bc2b-940b4cd6429c"
    }
   },
   "limits": {
    "base_url": "https://limits.staging.sw-machines.io",
    "endpoints": {
     "machines": "/machines",
     "limits": "/limits",
     "updateLimits": "/update",
     "machine_types": "/machine_types",
     "table": {
      "limit": "/table",
      "alarms": "/alarms/table"
     }
    }
   },
   "service": {
    "base_url": "https://mail.staging.sw-machines.io",
    "endpoints": {
     "mail": "/mail"
    },
    "request": {
     "types": [
      {
       "id": "service"
      },
      {
       "id": "inspection"
      },
      {
       "id": "expansion"
      },
      {
       "id": "retrofit"
      },
      {
       "id": "request"
      }
     ]
    },
    "file_languages": [
     {
      "id": "cz"
     },
     {
      "id": "de"
     },
     {
      "id": "en"
     },
     {
      "id": "es"
     },
     {
      "id": "fr"
     },
     {
      "id": "hu"
     },
     {
      "id": "it"
     },
     {
      "id": "pl"
     },
     {
      "id": "zh"
     }
    ],
    "maxUploadSizeInMB": 20
   },
   "nc_state": {
    "base_url": "https://machineevents.staging.sw-machines.io",
    "endpoints": {
     "machines": "/machines",
     "suffix_nc_state": "nc_data"
    }
   },
   "manuals": {
    "base_url": "https://content.staging.sw-machines.io",
    "endpoints": {
     "manuals": "/machines"
    }
   },
   "users": {
    "base_url": "https://user.staging.sw-machines.io",
    "endpoints": {
     "new": "/users",
     "privacy_policy": "/users/me",
     "settings": "/users/me/settings",
     "userinfo": "/users/me",
     "userlist": "/users",
     "accountinfo": "/users",
     "user_edit": "/users",
     "user_delete": "/users"
    },
    "availableRoles": [
     "DEVELOPER",
     "SUPERUSER",
     "OEM",
     "OEM_QA",
     "OEM_DOCS",
     "OEM_USER_MANAGER",
     "OEM_TECHNICIAN",
     "USER",
     "VISITOR"
    ],
    "availableGenders": [
     "female",
     "diverse",
     "male"
    ]
   },
   "save_user_settings": {
    "base_url": "https://user.staging.sw-machines.io",
    "endpoints": {
     "settings": "/users/me/settings"
    }
   },
   "search": {
    "base_endpoint": "/search/machines?query=",
    "base_url": "https://search.staging.sw-machines.io",
    "endpoints": {
     "search_users": "/search/users"
    },
    "search_all": "/search/machines?query="
   },
   "release": {
    "base_url": "https://release.staging.sw-machines.io",
    "endpoints": {
     "activeRelease": "/active"
    }
   }
  }
 },
 "frontend": {
  "applications": {
   "login": {
    "base_url": "https://platform.staging.sw-machines.io/login"
   }
  }
 },
 "generic": {
  "variables": {},
  "base_window_title": "SW lifedata CloudPlatform",
  "window_title_separator": "-",
  "geo_reference_url": "https://nominatim.openstreetmap.org/search?q=%query&format=json",
  "patterns": {
   "machine_sw_identifier": {
    "pattern": "^[a-z0-9-_]{7,}$",
    "modifier": "i"
   },
   "valid_email_address": {
    "pattern": "[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}"
   }
  },
  "log": {
   "console": true,
   "server": true,
   "log_levels": {
    "log": true,
    "info": true,
    "warn": true,
    "error": true
   }
  },
  "chart": {
   "height": 400
  },
  "datepicker": {
   "enable_select_limit": true,
   "max_selectable_days": 31
  }
 },
 "i18n": {
  "language_file_url": "/_config/locales/{{language}}.json",
  "default_language": "en",
  "availableLanguages": [
   {
    "id": "cz",
    "title": "czech"
   },
   {
    "id": "en",
    "title": "english"
   },
   {
    "id": "de",
    "title": "german"
   },
   {
    "id": "zh",
    "title": "chinese"
   },
   {
    "id": "pl",
    "title": "polish"
   },
   {
    "id": "es",
    "title": "spanish"
   },
   {
    "id": "fr",
    "title": "french"
   },
   {
    "id": "it",
    "title": "italian"
   },
   {
    "id": "hu",
    "title": "hungarian"
   }
  ]
 },
 "localstorage": {
  "storage_key": "__SWCP_STAGING__",
  "token_key": "swcp-jwt",
  "equability_limits_key": "equability_limits",
  "circularity_limits_key": "circularity_limits"
 },
 "qs": {
  "collection_id": "8d29c2b2-3530-4e5e-94ce-d14bcaa6e345"
 },
 "interval": {
  "longTime": 30000,
  "shortTime": 30000
 },
 "passwordCriteria": {
  "length": {
   "title": "min. 8 characters",
   "pattern": "(?=^.{8,255}$).*"
  },
  "upperCaseLetters": {
   "title": "min. 1 uppercase letter (A-Z)",
   "pattern": "(?=.*[A-Z]).*"
  },
  "lowerCaseLetters": {
   "title": "min. 1 lowercase letter (a-z)",
   "pattern": "(?=.*[a-z]).*"
  },
  "number": {
   "title": "min. 1 number (0-9)",
   "pattern": "(?=.*[0-9]).*"
  },
  "specialChar": {
   "title": "min. 1 special character",
   "pattern": "(?=.*[~!@#$%^&*()_+-.?,;:}{\\]|[=\\/,<>`'\"])"
  },
  "dontMatchOld": {
   "title": "must not match with old password",
   "pattern": "dontMatchOld"
  },
  "matchConfirmationPassword": {
   "title": "new password and re-typed password must match",
   "pattern": "matchConfirmationPassword"
  },
  "enterValidationCode": {
   "title": "Enter the validation code",
   "pattern": "none"
  }
 },
 "allowedBrowsers": [
  "chrome",
  "crios",
  "chromium",
  "edge",
  "safari",
  "firefox",
  "opera"
 ]
};