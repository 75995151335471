export const swLocales = {
  cz: {
    translation: {
      generic: {
        logout: 'Odhlásit',
        release: 'Dne ‚%replaceValue1‘ bude v době od‘%replaceValue2‘ do ‚%replaceValue3‘ probíhat údržba platformy. Dostupnost platformy bude v této době omezena.',
        pageTitle: 'SW CloudPlatform',
        closeLabel: 'Zavřít',
        acceptLabel: 'Přijmout',
        userProfile: 'Uživatelský profil',
        declineLabel: 'Odmítnout',
        backToDashboard: 'Zpět na panel',
        footerLinks: {
          gtct: {
            url: 'https://sw-machines.com/en/gtc/',
            title: 'GTCT / Standardy',
          },
          contact: {
            url: 'mailto:lifedata@sw-machines.com',
            title: 'Kontakt',
          },
          imprint: {
            url: 'https://sw-machines.com/en/imprint/',
            title: 'Tiráž',
          },
          privacy: {
            title: 'Respektování soukromí',
            header: 'Zásady ochrany osobních údajů',
          },
          swHomepage: {
            url: 'https://www.sw-machines.com/',
            title: 'Domovské stránky fy SW',
          },
        },
        noInternetConnection: 'Zdá se, že Vám nefunguje internetové připojení. Správně se připojte a zkuste to znovu.',
      },
      modules: {
        delete: {
          text: 'Krok nepůjde vrátit zpět!',
        },
        error: {
          upload: 'Neúspěšné uploady',
          message: {
            enrollment_code_409: 'Toto zařízení jste si do aws již přihlásili.',
          },
        },
        qs: {
          tabs: {
            list: 'Měřené časy',
          },
          list: {
            report: {
              headline: 'Výběr proměnných',
            },
          },
          grid: {
            columns: {
              datetime: 'Datum',
            },
          },
        },
        frameTitle: {
          qs: 'Zajištění kvality',
          users: 'Správa uživatelů',
          assets: 'Správa zařízení',
          settings: 'Nastavení',
          dashboard: 'Panel',
          userProfile: 'Uživatelský profil',
        },
        kpi: {
          tileTitle: 'KPI',
        },
        sidebar: {
          users: 'Správa uživatelů',
          assets: 'Správa zařízení',
          dashboard: 'Panel',
          userProfile: 'Uživatelský profil',
        },
        generic: {
          noData: 'Žádná data',
          dateFormat: 'DD.MM.YYYY',
          noDuration: 'Doba není k dispozici',
          timeFormat: 'HH:mm:ss',
          alarmPending: 'Nevyřízený alarm',
          buttons: {
            back: 'Zpět',
            save: 'Uložit',
            view: 'Zobrazit',
            cancel: 'Zrušit',
            export: 'Download mezních hodnot',
            import: 'Spustit',
            submit: 'Převzít',
            selectAllCheckboxes: 'Vybrat vše',
            unselectAllCheckboxes: 'Zrušit výběr',
          },
          notAvailable: 'Nedostupné',
          notConnected: 'Ještě nepřipojeno',
          dateTimeFormat: 'DD.MM.RRRR HH:mm:ss',
          timeFormats: {
            day: 'den',
            days: 'dny',
            hour: 'hodina',
            year: 'rok',
            hours: 'hodiny',
            month: 'měsíc',
            years: 'roky',
            minute: 'minuta',
            months: 'měsíce',
            second: 'vteřina',
            minutes: 'minuty',
            seconds: 'vteřiny',
          },
          notAvailableShort: 'n/a',
          timeFormatDuration: 'HH:mm:ss',
          timeFormatWithoutSeconds: 'HH:mm',
          sweetalert: {
            buttons: {
              understood: 'Rozumím',
            },
          },
        },
        success: {
          upload: 'Úspěšné uploady',
          statusCodes: {
            s_0: {
              message: 'Vyskytla se neočekávaná chyba. Zkuste si stránku načíst znovu.',
            },
            s_1: {
              message: 'Změny byly uloženy.',
            },
            s_930: {
              message: 'Objekt ‚%replaceValue‘ byl odstraněn.',
            },
            s_931: {
              message: 'Podnik ‚%replaceValue‘ byl odstraněn.',
            },
            s_932: {
              message: 'Linka ‚%replaceValue‘ byla odstraněna.',
            },
            s_933: {
              message: 'Hala ‚%replaceValue‘ byla odstraněna.',
            },
            s_934: {
              message: 'Lokalita ‚%replaceValue‘ byla odstraněna.',
            },
            s_935: {
              message: 'Soubor ‚%replaceValue‘ byl úspěšně vymazán.',
            },
          },
          message: {
            duplication_deleted: 'Duplicitní karty byly vymazány!',
            add_line_to_dashboard: 'Linka byla přidána do panelu',
          },
        },
        limit: {
          tabs: {
            list: 'Mezní hodnoty',
            type: 'Vyberte podle typu',
            machine: 'Vyberte podle ID stroje',
            listImport: 'Import mezních hodnot',
            listDownload: 'Download mezních hodnot',
          },
          text: {
            text: 'Mezní hodnoty si stanovíte pro proměnné, které budete potřebovat sledovat. Pokud sledovaná proměnná nadefinovaný interval mezních hodnot opustí, obdržíte příslušné oznámení. Mezní hodnoty si můžete nechat zobrazit také formou diagramu, který si vygenerujete v záložce „Časové řady“.',
            title: 'Mezní hodnoty',
          },
          export: {
            text: 'Aktuálně nastavené mezní hodnoty všech proměnných z platformy Cloud si můžete kdykoliv vyexportovat. Tato sestava Vám poslouží jako reference i jako nástroj pro jednorázový import všech mezních hodnot pomocí funkce „Importovat mezní hodnoty do CSV“',
            title: 'Exportovat mezní hodnoty do CSV',
          },
          import: {
            text: 'Dříve stažený soubor CSV si můžete naimportovat spolu s informacemi týkajícími se mezních hodnot a abonentů, a tím si aktuálně uložené hodnoty příslušně aktualizovat. Na který typ zařízení nebo stroje budete chtít naimportované mezní hodnoty aplikovat, si můžete určit později',
            title: 'Importovat mezní hodnoty z CSV',
            machines: {
              title: 'Vyberte stroj nebo typ stroje, na který chcete mezní hodnoty aplikovat.',
            },
          },
          upload: {
            fields: {
              file: 'Vyberte si soubor CSV pro import',
            },
          },
        },
        manual: {
          line: {
            one: 'Ve správě objektů si otevřete potřebnou linku.',
            two: 'V řádce s nadpisem najdete kontextové menu.',
            four: 'Linkový kachlík bude přidán do panelu.',
            three: 'Klikněte na kontextové menu a otevřete možnost „Přidat do panelu”.',
            header: 'Přidávání linkových kachlíků',
          },
          individual: {
            one: 'Klikněte na symbol „ozubeného kolečka“ po levé straně.',
            two: 'Použijte symbol „plus“.',
            four: 'Potvrďte pomocí „Uložit“. Kachlík bude přidán do panelu.',
            three: 'Kachlík pojmenujte a přidejte potřebný stroj.',
            header: 'Přidávání jednotlivých kachlíků',
          },
        },
        users: {
          fab_title: 'Založení nového uživatele',
          new: {
            title: 'Založit uživatele',
            buttons: {
              add: 'Přidat',
              back: 'Zpět na seznam',
              save: 'Uložit',
              cancel: 'Zrušit',
              delete: 'Vymazat uživatele',
            },
            fields: {
              email: 'E-mail',
              roles: 'Skupina uživatelů',
              gender: 'Pohlaví',
              created: 'Vytvořeno',
              lastName: 'Příjmení',
              firstName: 'Jméno',
              breadcrumb: 'Zobrazit navigaci',
              main_group: 'Uživatelská skupina',
              phoneNumber: 'Telefonní číslo [nepovinný údaj]',
              customer_org: 'Oddělení',
              last_modified: 'Naposledy upraveno',
              email_validation: 'E-mail (pro ověření)',
            },
            error_messages: {
              email_not_match: 'E-mailové adresy nesouhlasí.',
              email_not_valid: 'Zadejte platnou e-mailovou adresu.',
              roles_not_specified: 'Vyberte skupinu uživatelů.',
              gender_not_specified: 'Zadejte pohlaví.',
              last_name_not_specified: 'Zadejte příjmení.',
              first_name_not_specified: 'Zadejte jméno.',
              main_group_not_specified: 'Vyberte skupinu uživatelů.',
              family_name_not_specified: 'Zadejte příjmení.',
              customer_organization_not_specified: 'Vyberte oddělení.',
            },
          },
          update: {
            title: 'Aktualizovat uživatele',
          },
          success: {
            title: 'Povedlo se!',
            button: 'Zpět na seznam',
            message: 'Uživatel byl úspěšně založen. Na zadanou e-mailovou adresu byl zaslán e-mail s automaticky vygenerovaným heslem.',
            message_for_edit: 'Uživatel byl úspěšně aktualizován.',
            button_back_to_last_page: 'Zpět na poslední stránku',
          },
          delete: {
            success: 'Uživatel byl úspěšně vymazán.',
            confirmation: {
              title: 'Chcete tohoto uživatele opravdu vymazat?',
              confirm: 'Ano, tohoto uživatele chci vymazat',
              dismiss: 'Ne, zrušit',
            },
          },
          groups: {
            visitor: 'Návštěvník',
            customer: 'Zákazník',
          },
          list: {
            grid: {
              user: 'Uživatel',
              email: 'E-mail',
              company: 'Firma',
              enabled: 'Stav',
              lastName: 'Příjmení',
              sw_admin: 'Administrátor fy SW',
              firstName: 'Jméno',
              userRights: 'Právní stav',
              phoneNumber: 'Telefonní číslo',
            },
          },
          fields: {
            gender: {
              male: 'mužské',
              female: 'ženské',
              diverse: 'diverzní',
            },
          },
        },
        search: {
          noResults: 'Nenalezeny žádné výsledky',
          results: {
            of: 'z',
            lines: 'Linky',
            machines: 'Stroje',
          },
          callToAction: 'Vyhledání strojů',
        },
        spistat: {
          tileTitle: 'SpiStat',
        },
        worldmap: {
          tileTitle: 'Mapa světa',
        },
        shopfloor: {
          tileTitle: 'Provozovna',
          upload: {
            text1: 'Obrázek schématu provozovny zašlete na adresu lifedata@sw-machines.com (JPG, PNG, PDF).',
            text2: 'Jestliže jste si stroje přihlásili do platformy SW CloudPlatform, pak se Vám objeví po levé straně spolu s živými resp. aktuálními daty.',
            text3: 'Stavy z výkresu si pak jednoduše přetáhnete k příslušným strojům.',
            mainText: 'V tomto náhledu si vytvoříte schéma provozovny s informacemi o strojích v různé míře detailnosti. Postup je následující.',
            tileTitle: 'Nahrát provozovny',
          },
        },
        asset: {
          delete: {
            success: 'Objekt byl odstraněn.',
            confirmation: {
              title: 'Chcete ‚%replaceValue‘ opravdu odstranit?',
              confirm: 'Odstranit',
              dismiss: 'Zrušit',
            },
          },
          new: {
            buttons: {
              delete: 'Odstranit objekt',
            },
          },
          updateLimits: {
            confirmation: {
              intro: 'Chcete převzít mezní hodnoty stroje tohoto typu?',
              title: 'Změnili jste typ stroje',
              accept: 'Ano, převzít nové hodnoty',
              decline: 'Ne, ponechat aktuální hodnoty',
              dismiss: 'Zrušit',
              info_accept: 'Aktuální mezní hodnoty změníte na mezní hodnoty stroje nového typu výběrem možnosti „Ano“.',
              info_decline: 'Pokud budete chtít aktuální mezní hodnoty ponechat, vyberete možnost „Ne“.',
              info_accept_warning: 'Aktuální mezní hodnoty je po přepisu možno vymazat.',
            },
          },
        },
        assets: {
          line: {
            tabs: {
              kpi: 'KPI',
              list: 'Stroje',
              shopfloor: 'Provozovna',
            },
            fab_title: 'Přidat novou linku',
            grid: {
              hall: 'Hala',
              line: 'Linka',
              type: 'Typ',
              contractEnd: 'Konec smlouvy [volitelný údaj]',
              cellPosition: 'Buňka / pozice [volitelný údaj]',
              operationStep: 'Krok',
              machineIdentifierSw: 'Č. stroje (SW)',
              machineIdentifierCustomer: 'Název stroje',
            },
            success: {
              title: 'Povedlo se!',
              button: 'Zpět na seznam',
              message: 'Linka byla úspěšně založena.',
            },
            new_form_title: 'Nová linka',
            edit_form_title: 'Upravit linku',
            new: {
              fields: {
                name: 'Název linky',
                name_short: 'Název linky (zkrácený) [nepovinný údaj]',
                description: 'Popis [nepovinný údaj]',
              },
              error_messages: {
                name_not_specified: 'Zadejte název linky.',
              },
            },
            editSuccess: {
              title: 'Povedlo se!',
              button: 'Zpět na seznam',
              message: 'Linka byla úspěšně aktualizována.',
            },
          },
          hall: {
            fab_title: 'Přidat novou halu',
            grid: {
              part: 'Typ dílu',
              lineName: 'Linka',
              typeOfLoading: 'Způsob nakládání',
              lineResponsible: 'Osoba zodpovědná za linku',
              plannedQuantity: 'Plánované množství',
              numberOfMachines: '# strojů',
            },
            tabs: {
              list: 'Linky',
              shopfloor: 'Provozovna',
            },
            success: {
              title: 'Povedlo se!',
              button: 'Zpět na seznam',
              message: 'Hala byla úspěšně založena.',
            },
            new_form_title: 'Nová hala',
            shopfloor: {
              edit: 'Editační režim',
              configure: {
                save: {
                  fab_title: 'Uložit konfiguraci',
                },
                discard: {
                  fab_title: 'Zamítnout konfiguraci',
                },
              },
            },
            edit_form_title: 'Upravit halu',
            new: {
              fields: {
                name: 'Název haly',
                name_short: 'Název haly (zkrácený) [nepovinný údaj]',
                description: 'Popis [nepovinný údaj]',
                description_external: 'Popis (externí) [nepovinný údaj]',
                description_internal: 'Popis (interní) [nepovinný údaj]',
              },
              error_messages: {
                name_not_specified: 'Zadejte název haly.',
              },
            },
            editSuccess: {
              title: 'Povedlo se!',
              button: 'Zpět na seznam',
              message: 'Hala byla úspěšně aktualizována.',
            },
          },
          grid: {
            description: 'Popis [nepovinný údaj]',
          },
          company: {
            fab_title: 'Přidat nový podnik',
            grid: {
              city: 'Lokalita',
              email: 'E-mail',
              street: 'Adresa',
              country: 'Stát',
              website: 'Webové stránky',
              postalCode: 'PSČ',
              locationName: 'Lokalita',
            },
            tabs: {
              list: 'Lokality',
              worldmap: 'Mapa světa',
            },
            success: {
              title: 'Povedlo se!',
              button: 'Zpět na seznam',
              message: 'Podnik byl úspěšně založen.',
            },
            toggle_switch: 'Vytvořit novou lokalitu se stejnými údaji',
            new_form_title: 'Nový podnik',
            edit_form_title: 'Upravit podnik',
            new: {
              fields: {
                city: 'Město',
                name: 'Název podniku',
                email: 'E-mail [nepovinný údaj]',
                state: 'Spolková země [nepovinný údaj]',
                street: 'Adresa',
                country: 'Stát',
                website: 'Webové stránky [nepovinný údaj]',
                zipcode: 'PSČ',
                legal_form: 'Právní forma [nepovinný údaj]',
                name_short: 'Název podniku (krátký) [nepovinný údaj]',
                description: 'Popis [nepovinný údaj]',
                company_logo: 'Logo podniku (URL) [nepovinný údaj]',
                customer_number: 'Zákaznické číslo',
                industry_sector: 'Průmyslové odvětví',
                description_external: 'Popis (externí) [nepovinný údaj]',
                description_internal: 'Popis (interní) [nepovinný údaj]',
              },
              error_messages: {
                url: 'Zadejte platné URL.',
                email: 'Zadejte platnou e-mailovou adresu.',
                city_not_specified: 'Zadejte město.',
                name_not_specified: 'Zadejte název podniku.',
                street_not_specified: 'Zadejte adresu.',
                country_not_specified: 'Zadejte stát.',
                zipcode_not_specified: 'Zadejte PSČ.',
                customer_number_not_specified: 'Zadejte zákaznické číslo.',
                industry_sector_not_specified: 'Zadejte průmyslové odvětví.',
              },
            },
            editSuccess: {
              title: 'Povedlo se!',
              button: 'Zpět na seznam',
              message: 'Podnik byl úspěšně aktualizován.',
            },
            header: {
              labels: {
                email: 'E-mail',
                address: 'Adresa',
                country: 'Stát',
                website: 'Webové stránky',
              },
            },
            coordinateselement: {
              intro_text: 'Podnik vyhledaný podle zadané zeměpisné šířky a délky se v rámci platformy SW CloudPlatform zobrazí na mapě.',
              intro_heading: 'Informace o místě',
              location_name: 'Název místa:',
              label_latitude: 'Zeměpisná šířka',
              label_longitude: 'Zeměpisná délka',
              text_fallback_info: 'V ostatních případech se použijí standardní souřadnice.',
              text_location_found: 'Bylo nalezeno místo.',
              text_location_entered: 'Místo bylo úspěšně zadáno.',
              text_success_location: 'Automaticky byla doplněna tato data:',
              button_define_coordinates: 'Zadání souřadnic',
              text_no_coordinates_found: 'K zadaným datům nebyly nalezeny žádné souřadnice.',
              text_coordinates_success_set: 'Podle automaticky doplněných souřadnic.',
              button_set_custom_coordinates: 'Automatické doplnění souřadnic',
              text_specify_coordinates_yourself: 'Souřadnice můžete také zadat sami.',
            },
          },
          machine: {
            fab_title: 'Založit nový objekt',
            tabs: {
              info: 'Časové průběhy',
              alarms: 'Alarmy',
              gateway: 'Gateway',
              service: 'Servis',
              spistat: 'SpiStat',
              swimlane: 'Disponibilita linky',
              'axis-test': 'Zkouška os',
              collection: 'Kolekce',
              maintenance: 'Údržba',
              performance: 'Výkon',
              spindledata: 'Data vřeten',
              administration: 'Správa',
              energymonitoring: 'Energetický monitoring',
            },
            move: {
              message: "Objekt '%replaceValue' je možno přesunout do jiného podniku, lokality, haly a/nebo linky.",
              select_hall_label: 'Vybrat novou halu',
              select_line_label: 'Vybrat novou linku',
              select_company_label: 'Vybrat nový podnik',
              select_location_label: 'Vybrat novou lokalitu',
            },
            new: {
              fields: {
                s3: 'S3',
                name: 'Číslo stroje (SW)',
                iotcore: 'IoT Core',
                startup: 'Dodávka [nepovinný údaj]',
                shipping: 'Expedice [nepovinný údaj]',
                controller: 'Řídicí systém',
                machine_type: 'Typ stroje',
                warranty_end: 'Konec záruky [nepovinný údaj]',
                iotgreengrass: 'IoT Greengrass',
                systemsManager: 'Správce systémů',
                warranty_start: 'Začátek záruky [nepovinný údaj]',
                machine_swmp_api: 'Ukončení SWMP API [volitelné]',
                machine_swsp_trace: 'Ukončení SWSP Trace [volitelné]',
                machine_swcp_portal: 'Ukončení portálu SWCP [volitelné]',
                start_of_production: 'Začátek výroby [nepovinný údaj]',
                machine_swcp_support: 'Ukončení suportu pro SWCP [volitelné]',
                machine_cell_position: 'Buňka / pozice stroje',
                machine_swsp_line_mon: 'Ukončení SWSP LineMon [volitelné]',
                machine_identifier_customer: 'ID stroje (zákaznické)',
              },
              error_messages: {
                name_not_specified: 'Zadejte platné číslo stroje fy SW (min. 7 znaků: A-Z, 0-9, -).',
                controller_not_specified: 'Vyberte řídicí systém.',
                machine_type_not_specified: 'Vyberte typ stroje.',
                machine_identifier_customer_not_specified: 'Zadejte ID stroje.',
              },
            },
            success: {
              title: 'Povedlo se!',
              button: 'Zpět na seznam',
              message: 'Objekt byl úspěšně založen.',
            },
            new_form_title: 'Přidat objekt',
            edit_form_title: 'Upravit objekt',
            move_form_title: 'Přesunout objekt',
            editSuccess: {
              title: 'Povedlo se!',
              button: 'Zpět na seznam',
              message: 'Objekt byl úspěšně aktualizován.',
            },
            header: {
              labels: {
                api: 'SWMP API',
                trace: 'SWSP Trace',
                portal: 'Portál SWCP',
                lineMon: 'SWSP LineMon',
                support: 'Suport pro SWCP',
                shipping: 'Datum expedice',
                controller: 'Řídicí systém',
                contractEnd: 'Konec smlouvy',
                machineType: 'Typ stroje',
                startupDate: 'Datum uvedení do provozu',
                warrantyEnd: 'Konec záruky',
                cellPosition: 'Buňka / pozice',
                warrantyStart: 'Začátek záruky',
                customerNumber: 'Zákaznické číslo',
                enrollmentConfig: 'Přihlašovací konfigurace',
                startOfProduction: 'Začátek výroby',
                machineIdentifierSw: 'Č. stroje SW',
                enrollmentConfigDownload: 'Stáhnout',
                machineIdentifierCustomer: 'Zákaznický název stroje',
              },
            },
            add_line_to_dashboard: 'Přidání linky do panelu',
            elements: {
              'axis-test': {
                kpi: 'KPI',
                unit: 'Jednotky',
                value: 'Hodnota',
                kpiUnit: 'Jednotky',
                headline: 'Zkouška os',
                kpiValue: 'Hodnota',
                kpiHeader: 'KPI',
                chartTitle: 'Zkouška os',
                limitsLoad: 'Načíst z paměti prohlížeče',
                limitsSave: 'Uložit do paměti prohlížeče',
                selectAxis: 'Vybrat osu',
                limitsExport: 'Exportovat mezní hodnoty do dočasné paměti',
                openAxistest: 'Otevřít zkoušku os',
                listbox: {
                  label: 'Typ / osa(y)',
                  noContentMessage: 'Výběr typu zkoušky osy',
                  noAxisTestAvailable: 'Tento typ zkoušky osy neexistuje',
                },
                axisTestPlural: 'Zkouška os',
                noChartMessage: 'Z levého sloupečku vyberte kolekci.',
                adjustments: {
                  add: 'Přidat lokální úpravu',
                  range: 'Dosah',
                  header: 'Lokální úprava',
                  remove: 'Odstranit',
                  position: 'Pozice',
                  amplitude: 'Amplituda',
                  spreadMultiplier: 'Multiplikátor mezních hodnot',
                },
                chart: {
                  Equability: {
                    x: 'Pozice',
                    y: 'Proud',
                    up: 'nahoru',
                    down: 'dolů',
                    limitsUp: 'omezí v kladném směru',
                    limitsDown: 'omezí v záporném směru',
                    sliderSlopeCenter: 'Počátek stoupání',
                    sliderSlopeFactor: 'Koeficient stoupání',
                    sliderVerticalOffset: 'Vyosení Y',
                    sliderVerticalSpread: 'Omezit šířku',
                    sliderQuadraticCenter: 'Počátek kvadratického úseku',
                    sliderQuadraticFactor: 'Koeficient kvadratického úseku',
                  },
                  Circularity: {
                    clockwise: 'Po směru hodinových ručiček',
                    limitsClockwise: 'Meze po směru hodinových ručiček',
                    counterClockwise: 'Proti směru hodinových ručiček',
                    sliderSineOffset: 'Vyosení vlny',
                    sliderSineAmplitude: 'Amplituda vlny',
                    sliderSineFrequency: 'Kmitočet vlny',
                    sliderVerticalOffset: 'Vyosení Y',
                    sliderVerticalSpread: 'Omezit šířku',
                    limitsCounterClockwise: 'Meze proti směru hodinových ručiček',
                  },
                },
                kpis: {
                  pulseVelocity: 'Rychlost',
                  pulseMaxRampUp: 'Rampa ->',
                  pulseMaxRampDown: 'Rampa <-',
                  pulseMaxStiffness: 'Tuhost maximální',
                  pulseMinStiffness: 'Tuhost minimální',
                  pulseMeanStiffness: 'Tuhost Ø',
                  circularReversalRange: 'Vratné rozpětí',
                  pulseMaxUpPathCurrent: 'Proud maximální ->',
                  pulseMinUpPathCurrent: 'Proud minimální >',
                  pulseMeanUpPathCurrent: 'Proud Ø ->',
                  pulseMaxDownPathCurrent: 'Proud maximální <-',
                  pulseMinDownPathCurrent: 'Proud minimální <-',
                  pulseMeanDownPathCurrent: 'Proud Ø <-',
                  circularShapeDeviationClockwise: 'Odchylka od kruhovitosti při pravotočivé interpolaci',
                  pulseStiffnessStandardDeviation: 'Tuhost σ',
                  pulseStandardDeviationUpPathCurrent: 'Proud σ ->',
                  pulseStandardDeviationDownPathCurrent: 'Proud σ <-',
                  circularShapeDeviationCounterClockwise: 'Odchylka od kruhovitosti při levotočivé interpolaci',
                },
                axisLabels: {
                  CIRCLE: {
                    x: 'Pozice',
                    y: 'Pozice',
                  },
                  EQUABILITY: {
                    x: 'Pozice',
                    y: 'Proud',
                  },
                },
                axisTestTypes: {
                  CIRCLE: 'Zkouška kruhové interpolace',
                  EQUABILITY: 'Zkouška synchronnosti',
                  CIRCULARITY: 'Zkouška kruhové interpolace',
                },
              },
              gateway: {
                title: 'Gateway',
                contact: 'V případě výskytu chyby se obraťte na',
                headline: 'Gateway',
                netcfg: {
                  cta: 'Prostřednictvím formuláře Netcfg nám, prosím, sdělte odpovídající data.',
                  intro: 'Informace o konfiguraci sítě jsou nezbytné pro správné připojení Vašeho stroje.',
                  title: 'Konfigurace sítě',
                  goToNetconfig: 'Otevřít konfiguraci sítě',
                },
                device: {
                  title: 'Detaily zařízení',
                },
                enrollment: {
                  line1: 'Soubor pro enrollment si stáhnete kliknutím na tlačítko na dolním okraji kachlíku.',
                  line2: 'Soubor se začne stahovat automaticky.',
                  line3: 'Soubor s příponou .json si uložte do kmenového adresáře paměťového zařízení USB. Zkontrolujte, zda soubor nese tento název:',
                  line4: 'enrollment.json.',
                  line5: 'Zkontrolujte, zda kmenový adresář paměťového zařízení USB obsahuje tyto soubory:',
                  line6: 'Paměťové zařízení USB zasuňte do příslušného gateway pro IoT.',
                  line8: 'Po úspěšném přihlášení se rozsvítí zelená LED, která zhasne až po vysunutí paměťového úložiště USB.',
                  title: 'Enrollment',
                  button: 'Soubor pro enrollment',
                },
                deviceMigration: {
                  line1: 'Stáhněte si soubor s názvem „migrate-iot.zip“ a obsah extrahujte na prázdné paměťové zařízení USB.',
                  line2: 'aws.json',
                  line3: 'Stáhněte si též ',
                  line4: ' a uložte rovněž na paměťové zařízení USB. Zkontrolujte, zda soubor nese tento název:',
                  line5: 'Zkontrolujte, zda kmenový adresář paměťového zařízení USB obsahuje tyto soubory:',
                  line6: 'install.json',
                  line7: 'migration.tar.gz',
                  line8: 'migration.tar.gz.sig',
                  line9: 'migration.json',
                  title: 'Migrace platformy',
                  line10: 'Paměťové zařízení USB zasuňte do příslušného gateway pro IoT.',
                  line11: 'Poté se ihned spustí automatická instalace softwaru, kterou bude provázet blikající žlutá LED.',
                  line12: 'Po úspěšném dokončení migrace se rozsvítí zelená LED, která zhasne až po vysunutí paměťového zařízení USB.',
                  line13: 'Po úspěšném dokončení migrace se rozsvítí zelená LED, která zhasne až po vysunutí paměťového zařízení USB.',
                  device: {
                    button: 'migrate-iot.zip',
                  },
                  generic: {
                    button: 'aws.json',
                  },
                },
              },
              info: {
                generate: 'Vygenerovat',
                headline: 'Časové průběhy',
                noChartMessage: 'Vyberte alespoň jednu proměnnou a kliknutím vygenerujte potřebné údaje',
              },
              alarms: {
                headline: 'Alarmy',
              },
              service: {
                headline: 'Servis',
                update: {
                  title: 'Aktualizovat soubor',
                },
                upload: {
                  title: 'Nahrát soubor',
                  fields: {
                    file: 'Soubor',
                    language: 'Jazyk souboru',
                    file_name: 'Název souboru',
                    list_of_languages: {
                      cz: 'Čeština',
                      de: 'Němčina',
                      en: 'Angličtina',
                      es: 'Španělština',
                      fr: 'Francouzština',
                      hu: 'Maďarština',
                      it: 'Italština',
                      pl: 'Polština',
                      zh: 'Čínština',
                    },
                  },
                  buttons: {
                    send: 'Nahrát',
                    cancel: 'Zrušit',
                  },
                  success: {
                    title: 'Povedlo se',
                    button: 'Zpět na přehled servisu',
                    message: 'Soubor byl úspěšně nahrán.',
                  },
                  error_messages: {
                    file_name: 'Zadejte název souboru.',
                    file_not_valid: 'Zadejte soubor / vyberte menší soubor.',
                    language_not_specified: 'Zadejte jazyk souboru.',
                  },
                },
                manuals: {
                  title: 'Příručky',
                },
                version: {
                  title: 'Soubory verzí',
                },
                contracts: {
                  title: 'Smlouvy',
                },
                components: {
                  title: 'Hardwarové a softwarové komponenty',
                },
                spare_parts: {
                  title: 'Seznam náhradních dílů',
                },
                consumeables: {
                  title: 'Provozní látky',
                },
                Update: {
                  buttons: {
                    send: 'Aktualizovat',
                  },
                },
                software_edge: {
                  title: 'Software IoT Gateway',
                  values: {
                    box_id: 'Box-ID',
                    machine_connect: 'Machine Connect',
                    operating_system: 'Operační systém',
                  },
                },
                contact_service: {
                  line1: 'Potřebujete se strojem pomoci?',
                  line2: 'Kontaktujte nás!',
                  title: 'Zpráva pro servis SW',
                  button: 'Kontaktovat servis',
                  fields: {
                    name: 'Číslo stroje (SW)',
                    type: 'Typ dotazu',
                    email: 'E-mail',
                    message: 'Zpráva',
                    phone_no: 'Telefonní číslo',
                    last_name: 'Příjmení',
                    first_name: 'Jméno',
                    contact_person: 'Kontaktní osoba (SW) [nepovinný údaj]',
                    list_of_types: {
                      request: 'Obecný dotaz',
                      service: 'Servis',
                      retrofit: 'Retrofit',
                      expansion: 'Rozšíření',
                      inspection: 'Inspekce',
                    },
                    machine_identifier_customer: 'ID stroje (zákaznické) [nepovinný údaj]',
                  },
                  buttons: {
                    send: 'Odeslat dotaz',
                    cancel: 'Zrušit',
                  },
                  success: {
                    title: 'Povedlo se!',
                    button: 'Zpět na přehled servisu',
                    message: 'Dotaz byl úspěšně odeslán do servisního oddělení SW.',
                  },
                  error_messages: {
                    email_not_valid: 'Zadejte platnou e-mailovou adresu.',
                    name_not_specified: 'Zadejte platné číslo stroje fy SW (min. 7 znaků: A-Z, 0-9, - _).',
                    phone_not_specified: 'Zadejte platné telefonní číslo.',
                    message_not_specified: 'Zadejte text zprávy.',
                    last_name_not_specified: 'Zadejte příjmení.',
                    first_name_not_specified: 'Zadejte jméno.',
                    family_name_not_specified: 'Zadejte příjmení.',
                    contact_type_not_specified: 'Zadejte typ dotazu.',
                  },
                },
                software_machine: {
                  title: 'Software stroje',
                  values: {
                    nc_version: 'Verze NC',
                    hmi_version: 'Verze HMI',
                    plc_version: 'Verze PLC',
                    compile_cycles: 'Kompilovací cykly',
                    operating_system: 'Operační systém',
                    pcu_base_version: 'Verze PCU-Base',
                    plc_toolbox_version: 'PLC-Toolbox',
                    plc_user_program_version: 'Uživatelský program PLC',
                  },
                },
                changed: {
                  success: {
                    message: 'Název souboru byl úspěšně změněn',
                  },
                },
                maintenance_assistance: {
                  intro: 'Problém Vám pomůže vyřešit asistent.',
                  title: 'Asistent',
                  sub_title: 'Máte potíže s nějakým z Vašich objektů?',
                  open_assistant: 'Otevřít asistenta',
                  request_account: 'Vyžádat si účet',
                },
              },
              spistat: {
                headline: 'Statistiky vřeten SpiStat',
              },
              onlineState: {
                title: 'IoT Gateway',
                labelOnline: 'zap',
                labelOffline: 'vyp',
              },
              swimlane: {
                headline: 'Disponibilita linky',
              },
              sendingState: {
                title: 'Stav odesílání',
                aborted: 'zrušen',
                sending: 'odesílá',
                interrupted: 'přerušen',
              },
              alarms_map: {
                headline: 'Mapa alarmů',
              },
              collection: {
                headline: 'Kolekce',
                noChartMessage: 'Z levého sloupečku vyberte kolekci.',
              },
              performance: {
                kpi: {
                  sum: 'Součet',
                  header: 'Performance Kpi',
                  target: 'Plán',
                },
                alarms: {
                  title: 'Alarmy',
                  category: [
                    'Neklasifikováno',
                    'Chybové hlášení',
                    'Provozní hlášení',
                    'Stavové hlášení',
                  ],
                  icons: {
                    trend: 'Trend',
                    duration: 'Doba do vyřízení',
                    flatlist: 'Prostý seznam',
                    frequency: 'Hojnost výskytu',
                  },
                  tableHeadlines: {
                    time: 'Čas',
                    trend: 'Trend',
                    duration: 'Doba do vyřízení',
                    leftTime: 'Alarm zrušen',
                    alarmCount: 'Počet',
                    alarmNumber: 'Č. alarmu',
                    description: 'Popis',
                    alarmOccured: 'Alarm nahlášen',
                    alarmClassification: 'Klasifikace alarmů',
                    alarmCountSecondRange: 'Počet T-1',
                  },
                  description: {
                    trend: {
                      up: 'vzestupný',
                      down: 'sestupný',
                      flat: 'konstantní',
                    },
                  },
                },
                details: {
                  title: 'Detaily',
                  ncProgram: 'NC program',
                  lastModified: 'Poslední záznam hodnot',
                  machineState: 'Stav stroje',
                  numberAlarms: 'Nevyřízené alarmy',
                  stopCondition: 'Podmínky zastavení',
                  ncProgramState: 'Stav NC programu',
                  protectionLevel: 'Ochranná úroveň',
                  ncProgramStates: [
                    'zrušen',
                    'zastaven',
                    'běží',
                    'čeká',
                    'přerušen',
                  ],
                  protectionLevels: [
                    'Systém',
                    'Výrobce',
                    'Servis',
                    'Uživatel',
                    'Klíčový spínač v poloze 3',
                    'Klíčový spínač v poloze 2',
                    'Klíčový spínač v poloze 1',
                    'Klíčový spínač v poloze 0',
                  ],
                },
                cycleTime: {
                  title: 'Doba trvání cyklu',
                  chart: {
                    yAxis: {
                      title: 'Doba trvání cyklu v s',
                    },
                  },
                },
                piececounter: {
                  ok: 'OK',
                  nok: 'NE OK',
                  sum: 'Součet',
                  title: 'Kusové počítadlo',
                  titleDayCounter: 'Denní počítadlo',
                  titleShiftCounter: 'Směnové počítadlo',
                  titleTotalCounter: 'Celkové počítadlo',
                  titleTotalCounterSmall: 'Celkem',
                },
                availability: {
                  title: 'Disponibilita',
                  kpi: {
                    title: 'Disponibilita KPI',
                  },
                  legend: {
                    auto: 'AUTO',
                    JOG_with_error: 'JOG s chybou',
                    MDA_with_error: 'MDA s chybou',
                    JOG_without_error: 'JOG bez chyby',
                    MDA_without_error: 'MDA bez chyby',
                    not_defined_condition: 'Nedefinovaný stav',
                    auto_no_program_active: 'AUTO žádný aktivní program',
                    auto_waiting_for_parts: 'AUTO čeká na díly',
                    auto_with_error_program_stopped: 'AUTO s chybou - zastavený program',
                    auto_waiting_for_parts_stopping_error: 'AUTO čeká na díly + chybné zastavení',
                  },
                  aggregated: {
                    title: 'Agregovaná dostupnost',
                  },
                  chart: {
                    yAxis: {
                      title: 'Disponibilita',
                    },
                  },
                },
                inProduction: {
                  title: 'Stav',
                },
                operationMode: {
                  title: 'Provozní stav',
                  chart: {
                    yAxis: {
                      title: 'Provozní stav',
                    },
                  },
                },
                runtimeMonitor: {
                  title: 'Monitor provozní doby',
                  chart: {
                    yAxis: {
                      title: 'Program',
                      labels: {
                        on: 'zap',
                        off: 'vyp',
                      },
                    },
                  },
                },
                spindleOverride: {
                  title: 'Override vřetena',
                },
                feedrateOverride: {
                  title: 'Override posuvu',
                },
                uptimeDistribution: {
                  title: 'Uptime Distribution',
                },
              },
              administration: {
                title: 'Správa',
                headline: 'Správa',
                aws: {
                  title: 'Odkazy na AWS',
                },
                references: {
                  values: {
                    s3: 'S3',
                    iot: 'IoT Core',
                    ssm: 'Správce systémů',
                    greengrass: 'IoT Greengrass',
                  },
                },
              },
              condition: {
                bill: {
                  title: 'Kusovník',
                },
                component: {
                  title: 'Komponenty',
                },
              },
              maintenance: {
                headline: 'Údržba',
              },
              spindleData: {
                headline: 'Data vřeten',
              },
              point8: {
                chart: {
                  xAxis: {
                    title: 'Čas před alarmem (s)',
                  },
                  yAxis: {
                    title: 'Čas po alarmu (s)',
                  },
                },
              },
              energymonitoring: {
                headline: 'Energetický monitoring',
                chartTitles: {
                  air: 'Spotřeba vzduchu',
                  current: 'Spotřeba elektřiny',
                  consumption: 'Průměrná hodinová spotřeba',
                },
              },
              conditionMonitoring: {
                headline: 'Hlídání stavu',
              },
            },
          },
          location: {
            fab_title: 'Přidat novou lokalitu',
            tabs: {
              list: 'Haly',
              shopfloor: 'Provozovna',
            },
            grid: {
              hallName: 'Hala',
            },
            success: {
              title: 'Povedlo se!',
              button: 'Zpět na seznam',
              message: 'Lokalita byla úspěšně založena.',
            },
            new_form_title: 'Nová lokalita',
            edit_form_title: 'Upravit lokalitu',
            new: {
              fields: {
                city: 'Město',
                name: 'Název lokality',
                email: 'E-mail [nepovinný údaj]',
                state: 'Spolková země [nepovinný údaj]',
                street: 'Adresa',
                country: 'Stát',
                website: 'Webové stránky [nepovinný údaj]',
                zipcode: 'PSČ',
                language: 'Jazyk [nepovinný údaj]',
                name_short: 'Název lokality (zkrácený) [nepovinný údaj]',
                description: 'Popis [nepovinný údaj]',
                description_internal: 'Popis (interní) [nepovinný údaj]',
              },
              error_messages: {
                email: 'Zadejte platnou e-mailovou adresu.',
                city_not_specified: 'Zadejte město.',
                name_not_specified: 'Zadejte název lokality.',
                street_not_specified: 'Zadejte adresu.',
                country_not_specified: 'Zadejte stát.',
                zipcode_not_specified: 'Zadejte PSČ.',
              },
            },
            editSuccess: {
              title: 'Povedlo se!',
              button: 'Zpět na seznam',
              message: 'Lokalita byla úspěšně aktualizována.',
            },
            header: {
              labels: {
                address: 'Adresa',
                country: 'Stát',
              },
            },
          },
          companies: {
            grid: {
              city: 'Lokalita',
              email: 'E-mail',
              street: 'Adresa',
              country: 'Stát',
              website: 'Webové stránky',
              postalCode: 'PSČ',
              companyName: 'Podnik',
            },
            tabs: {
              list: 'Podnik',
              worldmap: 'Mapa světa',
            },
            map: {
              description_not_available: 'Neexistuje žádný popis',
            },
          },
          generic: {
            new: {
              buttons: {
                add: 'Přidat',
                back: 'Zpět na seznam',
                edit: 'Aktualizovat',
                save: 'Uložit',
                email: 'Odeslat obrázek',
                cancel: 'Zrušit',
                addMultiple: 'Přidat + Nový',
              },
            },
          },
          administration: {
            edit_form_title: 'Upravit správu',
          },
        },
        settings: {
          tabs: {
            limits: 'Meze',
          },
          button: {
            import: 'Spustit import',
            dismiss: 'Zrušit',
            download: 'Spustit download',
          },
        },
        file: {
          new: {
            buttons: {
              delete: 'Vymazat soubor',
            },
          },
        },
        hall: {
          new: {
            buttons: {
              delete: 'Odstranit halu',
            },
          },
        },
        line: {
          new: {
            buttons: {
              delete: 'Odstranit linku',
            },
          },
        },
        runtimeStatus: {
          tileTitle: 'Stav',
        },
        charts: {
          tooltipDateFormat: 'DD.MM.YYYY HH:mm:ss',
          exporting: {
            datetimeformat: '%d.%m.%Y %H:%M:%S',
          },
          globalSettings: {
            lang: {
              noData: 'Nejsou k dispozici žádná data',
              loading: 'Načítají se data...',
              months: [
                'leden',
                'únor',
                'březen',
                'duben',
                'květen',
                'červen',
                'červenec',
                'srpen',
                'září',
                'říjen',
                'listopad',
                'prosinec',
              ],
              resetZoom: 'Zrušit lupu',
              weekdays: [
                'neděle',
                'pondělí',
                'úterý',
                'středa',
                'čtvrtek',
                'pátek',
                'sobota',
              ],
              shortMonths: [
                'led',
                'úno',
                'bře',
                'dub',
                'kvě',
                'čer',
                'čvc',
                'srp',
                'zář',
                'říj',
                'lis',
                'pro',
              ],
              shortWeekdays: [
                'ne',
                'po',
                'út',
                'st',
                'čt',
                'pá',
                'so',
              ],
              increaseDateRange: 'alespoň do',
            },
          },
        },
        datepicker: {
          presets: {
            title: 'Přednastavení',
            rangeTo: 'do',
            selectDateRange: 'Vyberte časové období',
            predefined: {
              today: 'dnes',
              last7days: 'za posledních 7 dní',
              yesterday: 'včera',
              last24Hours: 'za posledních 24 hodin',
              last5Minutes: 'za posledních 5 minut',
            },
          },
        },
        i18n: {
          chooseLanguagePhrase: 'Vybrat jazyk',
        },
        company: {
          new: {
            buttons: {
              delete: 'Odstranit podnik',
            },
          },
        },
        location: {
          new: {
            buttons: {
              delete: 'Odstranit lokalitu',
            },
          },
        },
        runtimeStatusList: {
          tileTitle: 'Stav',
        },
        dashboard: {
          configure: {
            fab_title: 'Nakonfigurovat panel',
            hide: {
              title: 'skrýt',
            },
            show: {
              title: 'zobrazit',
            },
            delete: {
              title: 'vymazat',
            },
            add: {
              fab_title: 'Přidat vlastní kachlíky',
            },
            save: {
              fab_title: 'Uložit konfiguraci',
            },
            move_left: {
              title: 'Přejít na předchozí pozici',
            },
            move_right: {
              title: 'Přejít na další pozici',
            },
            discard: {
              fab_title: 'Zamítnout konfiguraci',
            },
            add_custom_card: {
              save: 'uložit',
              input: 'Zadat název kachlíku',
              title: 'Přidat vlastní kachlíky',
              cancel: 'zrušit',
              missingMachine: 'Vyberte alespoň jeden stroj',
            },
          },
        },
        errors: {
          statusCodes: {
            e_0: {
              message: 'Vyskytla se neočekávaná chyba. Zkuste si stránku načíst znovu.',
            },
            e_1xx: {
              message: 'Informational Error',
            },
            e_2xx: {
              message: 'Povedlo se',
            },
            e_3xx: {
              message: 'Přesměrování',
            },
            e_400: {
              message: 'Dotaz byl chybný, takže jej nebylo možno zpracovat.',
            },
            e_401: {
              message: 'Neautentifikovaný přístup. Přihlaste se znovu.',
            },
            e_403: {
              message: 'Pro přístup k požadovanému datovému zdroji nemáte oprávnění.',
            },
            e_404: {
              message: 'Požadovaný datový zdroj není k dispozici.',
            },
            e_405: {
              message: 'Nepovolená metoda.',
            },
            e_409: {
              message: 'Tento datový zdroj již existuje.',
            },
            e_418: {
              message: "I'm a Teapot! Yaay!",
            },
            e_4xx: {
              message: 'Client error',
            },
            e_500: {
              message: 'Interní chyba serveru.',
            },
            e_5xx: {
              message: 'Server error',
            },
            e_900: {
              message: 'Objekt se nepodařilo založit. Zkuste to znovu později.',
            },
            e_901: {
              message: 'Akce byla zrušena. Nový objekt nebyl založen.',
            },
            e_902: {
              message: 'Akce byla zrušena. Dotaz nebyl odeslán do servisního oddělení.',
            },
            e_903: {
              message: 'Při pokusu o odeslání dotazu do servisního oddělení došlo k chybě. Zkuste to znovu později.',
            },
            e_904: {
              message: "Objekt '%replaceValue' byl úspěšně založen. Nyní můžete přidat další objekty.",
            },
            e_905: {
              message: 'Akce byla zrušena. Objekt nebyl aktualizován.',
            },
            e_906: {
              message: "Objekt '%replaceValue' byl úspěšně přesunut.",
            },
            e_910: {
              message: 'Při pokusu o založení uživatele došlo k chybě. Zkuste to znovu později.',
            },
            e_911: {
              message: 'Akce byla zrušena. Nový uživatel nebyl založen.',
            },
            e_912: {
              message: 'Při pokusu o aktualizaci objektu došlo k chybě. Zkuste to ještě jednou.',
            },
            e_920: {
              message: 'Akce byla zrušena. Změny nebyl uloženy.',
            },
            e_921: {
              message: 'Vyskytla se neočekávaná chyba. Zkuste to ještě jednou.',
            },
            e_922: {
              message: 'Nesprávné uživatelské jméno a/ nebo heslo. Zkuste to ještě jednou.',
            },
            e_923: {
              message: 'Zadejte uživatelské jméno a heslo.',
            },
            e_924: {
              message: 'Relace byla z důvodu nečinnosti ukončena. Přihlaste se znovu.',
            },
            e_925: {
              message: 'Všechny uživatele SW CloudPlatform žádáme, aby nejprve potvrdili, že souhlasí s našimi zásadami ochrany osobních údajů.',
            },
            e_926: {
              message: 'Váš účet je dočasně zablokován. Zkuste to znovu za pár minut.',
            },
            e_927: {
              message: 'Heslo bylo úspěšně změněno.',
            },
            e_928: {
              message: 'Akce byla zrušena. Soubor nebyl nahrán.',
            },
            e_929: {
              message: 'Při pokusu o nahrání souboru došlo k chybě. Zkuste to znovu později.',
            },
            e_930: {
              message: 'Při pokusu o výmaz souboru došlo k chybě. Zkuste to znovu později.',
            },
            e_10500: {
              message: 'Zdá se, že Vám nefunguje internetové připojení. Zkontrolujte si, zda jste správně připojeni.',
            },
            e_10501: {
              message: 'Používáte nepodporovaný internetový prohlížeč. Doporučujeme používat tyto prohlížeče: Google Chrome, Microsoft Edge, Apple Safari.',
            },
            e_409_asset: {
              message: 'Tento objekt (číslo stroje SW %replaceValue) již existuje.',
            },
            e_410_asset: {
              message: 'Soubor s názvem %replaceValue již existuje',
            },
          },
        },
        list: {
          sweetalert: {
            maxAmountReached: {
              html: 'Současně je možno vybrat jen %s prvků',
              title: 'Dosaženo max. počtu',
            },
          },
        },
      },
      module: {
        assets: {
          machine: {
            alarms: {
              no_alarms_text: 'Neexistují žádné alarmy',
            },
            nc_state_file_cardTitle: 'Stavové soubory NC',
            nc_state_files: {
              no_files_text: 'Neexistují žádné soubory.',
              column_title_trigger: 'Spouštěč',
              column_title_filename: 'Název souboru',
              column_title_creation_date: 'Datum vytvoření',
              modules: {
                assets: {
                  nc_state_download: 'Stáhnout NC data',
                },
              },
            },
          },
        },
      },
    },
  },
  de: {
    translation: {
      generic: {
        logout: 'Abmelden',
        release: "Am '%replaceValue1' von'%replaceValue2' bis '%replaceValue3' werden Wartungsarbeiten an der Plattform durchgeführt. Die Plattform wird in diesem Zeitraum teilweise nicht verfügbar sein.",
        pageTitle: 'SW CloudPlatform',
        closeLabel: 'Schließen',
        acceptLabel: 'Akzeptieren',
        userProfile: 'Benutzerprofil',
        button: {
          clear: 'Diagramm entfernen',
          timeline: 'Grenzwertanpassung',
          separateCharts: 'Separate Diagramme',
        },
        declineLabel: 'Ablehnen',
        backToDashboard: 'Zurück zum Dashboard',
        footerLinks: {
          gtct: {
            url: 'https://sw-machines.com/agb/',
            title: 'GTCT / Standards',
          },
          contact: {
            url: 'mailto:lifedata@sw-machines.com',
            title: 'Kontakt',
          },
          imprint: {
            url: 'https://sw-machines.com/impressum/',
            title: 'Impressum',
          },
          privacy: {
            title: 'Privatsphäre',
            header: 'Datenschutz-Bestimmungen',
          },
          swHomepage: {
            url: 'https://www.sw-machines.com/',
            title: 'SW Homepage',
          },
        },
        noInternetConnection: 'Sie scheinen nicht mit dem Internet verbunden zu sein. Bitte verbinden Sie sich und versuchen es erneut.',
      },
      modules: {
        generic: {
          of: 'von',
          noData: 'Keine Daten',
          dateFormat: 'DD.MM.YYYY',
          downloaded: 'downloaded',
          noDuration: 'keine Dauer verfügbar',
          timeFormat: 'HH:mm:ss',
          alarmPending: 'Alarm anstehend',
          buttons: {
            back: 'Zurück',
            save: 'Speichern',
            view: 'Anzeigen',
            cancel: 'Abbrechen',
            submit: 'Übernehmen',
            export: {
              limit: 'Download Limits',
              alarms: 'Download Alarme',
            },
            import: {
              limit: 'Start',
              alarms: 'Start',
            },
            selectAllCheckboxes: 'Alles auswählen',
            unselectAllCheckboxes: 'Auswahl aufheben',
          },
          notAvailable: 'Nicht verfügbar',
          notConnected: 'Noch nicht verbunden',
          download: {
            file: 'File Downloads',
          },
          dateTimeFormat: 'DD.MM.YYYY HH:mm:ss',
          timeFormats: {
            day: 'Tag',
            days: 'Tage',
            hour: 'Stunde',
            year: 'Jahr',
            hours: 'Stunden',
            month: 'Monat',
            years: 'Jahre',
            minute: 'Minute',
            months: 'Monate',
            second: 'Sekunde',
            minutes: 'Minuten',
            seconds: 'Sekunden',
          },
          notAvailableShort: 'n/a',
          timeFormatDuration: 'HH:mm:ss',
          timeFormatWithoutSeconds: 'HH:mm',
          sweetalert: {
            buttons: {
              understood: 'Verstanden',
            },
          },
        },
        delete: {
          text: 'Sie können dies nicht rückgängig machen!',
        },
        error: {
          upload: 'Fehlgeschlagene Uploads',
          download: 'Fehlgeschlagene Downloads',
          message: {
            enrollment_code_409: 'Das Gerät ist bereits in aws registriert.',
            limit_change_failed: 'Die Grenzwerte konnten nicht geändert werden. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut oder schreiben Sie eine E-Mail an lifedata@sw-machines.com.',
          },
        },
        qs: {
          tabs: {
            list: 'Zeitaufnahmen',
          },
          list: {
            report: {
              headline: 'Variablen auswählen',
            },
          },
          grid: {
            columns: {
              datetime: 'Datum',
            },
          },
        },
        frameTitle: {
          qs: 'Qualitätssicherung',
          users: 'Benutzerverwaltung',
          assets: 'Asset-Verwaltung',
          settings: 'Einstellungen',
          dashboard: 'Dashboard',
          userProfile: 'Benutzerprofil',
        },
        kpi: {
          tileTitle: 'KPI',
        },
        sidebar: {
          users: 'Benutzerverwaltung',
          assets: 'Asset-Verwaltung',
          dashboard: 'Dashboard',
          userProfile: 'Benutzerprofil',
        },
        success: {
          upload: 'Erfolgreiche Uploads',
          downloads: 'Erfolgreiche Downloads',
          statusCodes: {
            s_0: {
              message: 'Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie, die Seite neu zu laden.',
            },
            s_1: {
              message: 'Die Änderungen wurden gespeichert.',
            },
            s_930: {
              message: "Asset '%replaceValue' wurde erfolgreich gelöscht.",
            },
            s_931: {
              message: "Unternehmen '%replaceValue' wurde erfolgreich gelöscht.",
            },
            s_932: {
              message: "Linie '%replaceValue' wurde erfolgreich gelöscht.",
            },
            s_933: {
              message: "Halle '%replaceValue' wurde erfolgreich gelöscht.",
            },
            s_934: {
              message: "Standort '%replaceValue' wurde erfolgreich gelöscht.",
            },
            s_935: {
              message: "Datei '%replaceValue' wurde erfolgreich gelöscht.",
            },
          },
          message: {
            duplication_deleted: 'Doppelte Karten wurden gelöscht!',
            add_line_to_dashboard: 'Linie wurde zum Dashboard hinzugefügt',
          },
        },
        limit: {
          tabs: {
            list: 'Limits',
            type: 'Wählen Sie nach Typ aus',
            alarms: 'Alarme',
            machine: 'Wählen Sie nach Maschinen-ID aus',
            listImport: 'Limits Import',
            listDownload: 'Limits Download',
          },
          text: {
            text: 'Grenzwerte sind Werte, die Sie für Variablen festlegen, die Sie interessieren. Wenn eine Variable nicht innerhalb der definierten Grenzwerte liegt, erhalten Sie eine Benachrichtigung. Grenzwerte werden auch in Diagrammen angezeigt, die auf der Registerkarte „Zeitreihen“ generiert werden.',
            title: 'Limits',
          },
          export: {
            text: 'Sie können jederzeit die aktuell eingestellten Grenzwerte für alle in der Cloud Platform vorhandenen Variablen exportieren. Sie können diese Liste als Referenz verwenden oder sie verwenden, um alle Grenzwerte auf einmal im Abschnitt „Grenzwerte als CSV importieren“ zu importieren.',
            title: 'Export Limits als CSV',
          },
          import: {
            text: 'Sie können eine zuvor heruntergeladene CSV-Datei mit Informationen zu Limits und Abonnenten importieren, um die aktuell gespeicherten Werte zu aktualisieren. Sie können später auswählen, auf welche Anlagen- oder Maschinentypen Sie die importierten Grenzwerte anwenden möchten',
            title: 'Grenzwerte aus CSV importieren',
            machines: {
              title: 'Wählen Sie die Maschinen oder den Maschinentyp aus, auf die Sie die Grenzwerte anwenden möchten.',
            },
          },
          upload: {
            fields: {
              file: 'Wählen Sie die CSV-Datei aus, die Sie importieren möchten',
            },
          },
        },
        manual: {
          line: {
            one: 'Gehen Sie zur gewünschten Linie in der Asset-Verwaltung.',
            two: 'In der Überschriftenzeile finden Sie ein Kontextmenü.',
            four: 'Die Kachel der Line wird zum Dashboard hinzugefügt.',
            three: 'Klicken Sie auf das Kontextmenü und wählen Sie „Zum Dashboard hinzufügen”.',
            header: 'Hinzufügen von Linienkacheln',
          },
          individual: {
            one: 'Klicken Sie auf das „Zahnrad“-Symbol, auf der linken Seite.',
            two: 'Wählen Sie das „Plus“-Symbol.',
            four: 'Bestätigen Sie mit „Speichern“. Die einzelne Kachel wird zum Dashboard hinzugefügt.',
            three: 'Benennen Sie Ihre Kachel und fügen Sie Ihre gewünschten Maschinen hinzu.',
            header: 'Hinzufügen von individuellen Kacheln',
          },
        },
        users: {
          fab_title: 'Neuen Benutzer anlegen',
          new: {
            title: 'Nutzer anlegen',
            buttons: {
              add: 'Hinzufügen',
              back: 'Zurück zur Liste',
              save: 'Speichern',
              cancel: 'Abbrechen',
              delete: 'Nutzer löschen',
            },
            fields: {
              email: 'E-Mail',
              roles: 'Nutzergruppe',
              gender: 'Geschlecht',
              created: 'Erstellt',
              lastName: 'Nachname',
              firstName: 'Vorname',
              breadcrumb: 'Zeige Navigation',
              main_group: 'Main group',
              phoneNumber: 'Telefonnummer [optional]',
              customer_org: 'Kundenorganisation',
              last_modified: 'Zuletzt bearbeitet',
              email_validation: 'E-Mail (Validierung)',
            },
            error_messages: {
              email_not_match: 'E-Mail-Adressen stimmen nicht überein.',
              email_not_valid: 'Bitte geben Sie eine gültige E-Mail-Adresse an.',
              roles_not_specified: 'Bitte wählen Sie eine Nutzergruppe aus.',
              gender_not_specified: 'Bitte geben Sie ein Geschlecht an.',
              last_name_not_specified: 'Bitte geben Sie einen Namen an.',
              first_name_not_specified: 'Bitte geben Sie einen Vornamen an.',
              main_group_not_specified: 'Bitte wählen Sie eine Benutzergruppe aus.',
              family_name_not_specified: 'Please specify a last name',
              customer_organization_not_specified: 'Bitte wählen Sie eine Kundenorganisation aus.',
            },
          },
          update: {
            title: 'Nutzer aktualisieren',
          },
          success: {
            title: 'Erfolgreich!',
            button: 'Zurück zur Liste',
            message: 'Der Benutzer wurde erfolgreich angelegt. Eine E-Mail mit einem automatisch generierten Passwort wurde an die angegebene E-Mail-Adresse versandt.',
            message_for_edit: 'Der Benutzer wurde erfolgreich aktualisiert.',
            button_back_to_last_page: 'Zurück zur letzten Seite',
          },
          delete: {
            success: 'Nutzer erfolgreich gelöscht.',
            confirmation: {
              title: 'Soll dieser Nutzer wirklich gelöscht werden?',
              confirm: 'Ja, diesen Nutzer löschen',
              dismiss: 'Nein, abbrechen',
            },
          },
          groups: {
            visitor: 'Besucher',
            customer: 'Kunde',
          },
          list: {
            grid: {
              user: 'Benutzer',
              email: 'E-Mail',
              company: 'Firma',
              enabled: 'Status',
              lastName: 'Nachname',
              sw_admin: 'SW Admin',
              firstName: 'Vorname',
              userRights: 'Rechtestatus',
              phoneNumber: 'Telefonnummer',
            },
          },
          fields: {
            gender: {
              male: 'männlich',
              female: 'weiblich',
              diverse: 'divers',
            },
          },
        },
        alarms: {
          tabs: {
            list: 'Alarms',
            type: 'Wählen Sie nach Typ aus',
            machine: 'Wählen Sie nach Maschinen-ID aus',
            listImport: 'Alarme Import',
            listDownload: 'Alarm File Downloads',
          },
          text: {
            text: 'Alarm-Benachrichtigungen können für die Alarmnummern festgelegt werden, die Sie interessieren. Wenn an einer definierten Maschine der eingestellte Alarm auftritt, erhalten Sie eine Benachrichtigung.',
            title: 'Alarm-Benachrichtigungen',
          },
          export: {
            text: 'Sie können jederzeit die aktuell eingestellten Alarm-Benachrichtigungen aller Maschinen in der SW CloudPlatform exportieren. Sie können diese Liste als Referenz verwenden oder sie verwenden, um alle Alarm-Benachrichtigungen auf einmal im Abschnitt „Alarme als CSV importieren“ zu importieren.',
            title: 'Export der Alarme als CSV',
          },
          import: {
            text: 'Sie können eine zuvor heruntergeladene CSV-Datei mit Informationen zu Alarmen und Abonnenten importieren, um die aktuell gespeicherten Werte zu aktualisieren. Sie können später auswählen, auf welche Anlagen- oder Maschinentypen Sie die importierten Alarm-Benachrichtigungen anwenden möchten.',
            title: 'Alarme aus CSV importieren',
            machines: {
              title: 'Wählen Sie die Maschinen oder den Maschinentyp aus, auf die Sie die Alarm-Benachrichtigungen anwenden möchten.',
            },
          },
          upload: {
            fields: {
              file: 'Wählen Sie die CSV-Datei aus, die Sie importieren möchten',
            },
          },
        },
        search: {
          noResults: 'Keine Ergebnisse gefunden',
          results: {
            of: 'von',
            lines: 'Linien',
            machines: 'Maschinen',
          },
          callToAction: 'Suche nach Maschinen',
        },
        spistat: {
          tileTitle: 'SpiStat',
        },
        worldmap: {
          tileTitle: 'Weltkarte',
        },
        shopfloor: {
          tileTitle: 'Shopfloor',
          upload: {
            text1: 'Senden Sie ein Bild Ihres Shopfloor-Layout an lifedata@sw-machines.com (JPG, PNG, PDF)',
            text2: 'Wenn Ihre Maschinen bei der SW CloudPlatform registriert und angemeldet sind, werden sie auf der linken Seite angezeigt und senden Echtzeitdaten',
            text3: 'Ziehen Sie den Status dann einfach per Drag & Drop auf Ihren Plan an der richtigen Maschine.',
            mainText: 'In dieser Ansicht können Sie Ihr individuelles Shopfloor-Layout einrichten und Ihre Maschinen durch verschiedene Informationsebenen betrachten. Folgen Sie einfach diesen Schritten.',
            tileTitle: 'Shopfloor upload',
          },
        },
        asset: {
          delete: {
            success: 'Asset erfolgreich gelöscht.',
            confirmation: {
              title: "Soll '%replaceValue' wirklich gelöscht werden?",
              confirm: 'löschen',
              dismiss: 'abbrechen',
              company: {
                text: 'Wenn Sie das Unternehmen löschen, werden außerdem alle dazugehörigen Nutzer gelöscht.',
              },
            },
          },
          new: {
            buttons: {
              delete: 'Asset löschen',
            },
          },
          updateLimits: {
            confirmation: {
              intro: 'Möchten Sie die Grenzwerte dieses neuen Maschinentyps übernehmen?',
              title: 'Sie haben den Maschinentyp geändert',
              accept: 'Ja, neue Grenzwerte übernehmen',
              decline: 'Nein, aktuelle Grenzwerte behalten',
              dismiss: 'Abbrechen',
              info_accept: "Um die aktuellen Grenzwerte mit den Grenzwerten des neuen Maschinentyps zu überschreiben, wählen Sie 'Ja'.",
              info_decline: "Um die aktuellen Grenzwerte beizubehalten, wählen Sie 'Nein'.",
              info_accept_warning: 'Alle aktuellen Grenzwerte werden überschrieben oder gelöscht.',
            },
          },
        },
        assets: {
          line: {
            tabs: {
              kpi: 'KPIs',
              list: 'Maschinen',
              shopfloor: 'Shopfloor',
            },
            fab_title: 'Neue Linie hinzufügen',
            grid: {
              hall: 'Halle',
              line: 'Linie',
              type: 'Typ',
              contractEnd: 'Vertragsende [optional]',
              cellPosition: 'Zelle / Position [optional]',
              operationStep: 'Bearbeitungsschritt',
              machineIdentifierSw: 'Maschinennr. (SW)',
              machineIdentifierCustomer: 'Maschinenname',
            },
            success: {
              title: 'Erfolgreich!',
              button: 'Zurück zur Liste',
              message: 'Die Linie wurde erfolgreich angelegt.',
            },
            new_form_title: 'Neue Linie',
            edit_form_title: 'Linie bearbeiten',
            new: {
              fields: {
                name: 'Linienname',
                name_short: 'Linienname (Kurzform) [optional]',
                description: 'Beschreibung [optional]',
              },
              error_messages: {
                name_not_specified: 'Bitte geben Sie einen Liniennamen an.',
              },
            },
            editSuccess: {
              title: 'Erfolgreich!',
              button: 'Zurück zur Liste',
              message: 'Die Linie wurde erfolgreich aktualisiert.',
            },
          },
          hall: {
            fab_title: 'Neue Halle hinzufügen',
            grid: {
              part: 'Teiletyp',
              lineName: 'Linie',
              typeOfLoading: 'Beladungstyp',
              lineResponsible: 'Linienverantwortlicher',
              plannedQuantity: 'Geplante Menge',
              numberOfMachines: '# Maschinen',
            },
            tabs: {
              list: 'Linien',
              shopfloor: 'Shopfloor',
            },
            success: {
              title: 'Erfolgreich!',
              button: 'Zurück zur Liste',
              message: 'Die Halle wurde erfolgreich angelegt.',
            },
            new_form_title: 'Neue Halle',
            shopfloor: {
              edit: 'Bearbeitungsmodus',
              configure: {
                save: {
                  fab_title: 'Konfiguration speichern',
                },
                discard: {
                  fab_title: 'Konfiguration verwerfen',
                },
              },
            },
            edit_form_title: 'Halle bearbeiten',
            new: {
              fields: {
                name: 'Hallenname',
                name_short: 'Hallenname (Kurzform) [optional]',
                description: 'Beschreibung [optional]',
                description_external: 'Beschreibung (extern) [optional]',
                description_internal: 'Beschreibung (intern) [optional]',
              },
              error_messages: {
                name_not_specified: 'Bitte geben Sie einen Hallennamen an.',
              },
            },
            editSuccess: {
              title: 'Erfolgreich!',
              button: 'Zurück zur Liste',
              message: 'Die Halle wurde erfolgreich aktualisiert.',
            },
          },
          grid: {
            description: 'Beschreibung [optional]',
          },
          company: {
            fab_title: 'Neues Unternehmen hinzufügen',
            grid: {
              city: 'Ort',
              email: 'E-Mail',
              street: 'Anschrift',
              country: 'Land',
              website: 'Webseite',
              postalCode: 'Postleitzahl',
              locationName: 'Standort',
            },
            tabs: {
              list: 'Standorte',
              worldmap: 'Weltkarte',
            },
            success: {
              title: 'Erfolgreich!',
              button: 'Zurück zur Liste',
              message: 'Das Unternehmen wurde erfolgreich angelegt.',
            },
            toggle_switch: 'Einen Standort mit denselben Daten erzeugen',
            new_form_title: 'Neues Unternehmen',
            edit_form_title: 'Unternehmen bearbeiten',
            new: {
              fields: {
                city: 'Stadt',
                name: 'Name des Unternehmens',
                email: 'E-Mail [optional]',
                state: 'Bundesland [optional]',
                street: 'Anschrift',
                country: 'Land',
                website: 'Webseite [optional]',
                zipcode: 'Postleitzahl',
                legal_form: 'Rechtsform [optional]',
                name_short: 'Name des Unternehmens (kurz) [optional]',
                description: 'Beschreibung [optional]',
                company_logo: 'Unternehmenslogo (URL) [optional]',
                customer_number: 'Kundennummer',
                industry_sector: 'Industriesektor',
                description_external: 'Beschreibung (extern) [optional]',
                description_internal: 'Beschreibung (intern) [optional]',
              },
              error_messages: {
                url: 'Bitte geben Sie eine gültige URL an.',
                email: 'Bitte geben Sie eine gültige E-Mail Adresse an.',
                city_not_specified: 'Bitte geben Sie eine Stadt an.',
                name_not_specified: 'Bitte geben Sie einen Unternehmensnamen an.',
                street_not_specified: 'Bitte geben Sie eine Anschrift an.',
                country_not_specified: 'Bitte geben Sie ein Land an.',
                zipcode_not_specified: 'Bitte geben Sie eine Postleitzahl an.',
                customer_number_not_specified: 'Bitte geben Sie eine Kundennummer an.',
                industry_sector_not_specified: 'Bitte geben Sie einen Industriesektor an.',
              },
            },
            editSuccess: {
              title: 'Erfolgreich!',
              button: 'Zurück zur Liste',
              message: 'Das Unternehmen wurde erfolgreich aktualisiert.',
            },
            header: {
              labels: {
                email: 'E-Mail',
                address: 'Anschrift',
                country: 'Land',
                website: 'Webseite',
              },
            },
            coordinateselement: {
              intro_text: 'Die SW CloudPlatform stellt dieses Unternehmen auf einer Karte dar und verwendet hierfür den Längen- und Breitengrad.',
              intro_heading: 'Informationen zum Ort',
              location_name: 'Name des Orts:',
              label_latitude: 'Breitengrad',
              label_longitude: 'Längengrad',
              text_fallback_info: 'Falls nicht, werden die Standardkoordinaten verwendet.',
              text_location_found: 'Ein Ort wurde gefunden.',
              text_location_entered: 'Der Ort wurde angegeben.',
              text_success_location: 'Folgende Daten wurden gesetzt:',
              button_define_coordinates: 'Koordinaten selbst eintragen',
              text_no_coordinates_found: 'Es wurden keine Koordinaten zu den eingetragenen Daten gefunden.',
              text_coordinates_success_set: 'Koordinaten gesetzt.',
              button_set_custom_coordinates: 'Koordinaten setzen',
              text_specify_coordinates_yourself: 'Alternativ können Sie die Koordinaten selbst eingeben.',
            },
          },
          machine: {
            fab_title: 'Neues Asset anlegen',
            tabs: {
              info: 'Zeitreihen',
              alarms: 'Alarme',
              gateway: 'Gateway',
              service: 'Service',
              spistat: 'SpiStat',
              swimlane: 'Verfügbarkeit Linie',
              'axis-test': 'Achstests',
              collection: 'Kollektionen',
              maintenance: 'Wartung',
              performance: 'Performance',
              spindledata: 'Spindeldaten',
              administration: 'Administration',
              energymonitoring: 'Energiemonitoring',
            },
            move: {
              message: "Das Asset '%replaceValue' kann in ein neues Unternehmen, Standort, Halle und/oder Linie verschoben werden.",
              select_hall_label: 'Neue Halle auswählen',
              select_line_label: 'Neue Linie auswählen',
              select_company_label: 'Neues Unternehmen auswählen',
              select_location_label: 'Neuen Standort auswählen',
            },
            new: {
              fields: {
                s3: 'S3',
                name: 'Maschinennummer (SW)',
                iotcore: 'IoT Core',
                startup: 'Lieferung [optional]',
                shipping: 'Versand [optional]',
                controller: 'Steuerung',
                machine_type: 'Maschinentyp',
                warranty_end: 'Garantie-Ende [optional]',
                iotgreengrass: 'IoT Greengrass',
                systemsManager: 'System Manager',
                warranty_start: 'Garantiebeginn [optional]',
                machine_swmp_api: 'Maschinen SWMP API Ende [optional]',
                machine_swsp_trace: 'Maschinen SWSP Trace Ende [optional]',
                machine_swcp_portal: 'Maschinen SWCP Portal Ende [optional]',
                start_of_production: 'Beginn der Produktion [optional]',
                machine_swcp_support: 'Maschinen SWCP Support Ende [optional]',
                machine_cell_position: 'Maschinen Zelle / Position',
                machine_swsp_line_mon: 'Maschinen SWSP LineMon Ende [optional]',
                machine_identifier_customer: 'Maschinen-ID (Kunde)',
              },
              error_messages: {
                name_not_specified: 'Bitte geben Sie eine gültige SW-Maschinennummer an (mind. 7 Zeichen: A-Z, 0-9, -).',
                controller_not_specified: 'Bitte wählen Sie eine Steuerung aus.',
                machine_type_not_specified: 'Bitte wählen Sie einen Maschinentyp aus.',
                machine_identifier_customer_not_specified: 'Bitte geben Sie eine Maschinen-ID an.',
              },
            },
            success: {
              title: 'Erfolgreich!',
              button: 'Zurück zur Liste',
              message: 'Das Asset wurde erfolgreich angelegt.',
            },
            new_form_title: 'Asset hinzufügen',
            edit_form_title: 'Asset bearbeiten',
            move_form_title: 'Asset verschieben',
            editSuccess: {
              title: 'Erfolgreich!',
              button: 'Zurück zur Liste',
              message: 'Das Asset wurde erfolgreich aktualisiert.',
            },
            header: {
              labels: {
                api: 'SWMP API',
                trace: 'SWSP Trace',
                portal: 'SWCP Portal',
                lineMon: 'SWSP LineMon',
                support: 'SWCP Support',
                shipping: 'Versanddatum',
                controller: 'Steuerung',
                contractEnd: 'Vertragsende',
                machineType: 'Maschinentyp',
                startupDate: 'Inbetriebnamedatum',
                warrantyEnd: 'Gewährleistungsende',
                cellPosition: 'Zelle / Position',
                warrantyStart: 'Gewährleistungsbeginn',
                customerNumber: 'Kundennummer',
                enrollmentConfig: 'Enrollment-Konfiguration',
                startOfProduction: 'Beginn der Produktion',
                machineIdentifierSw: 'Maschinennr. SW',
                enrollmentConfigDownload: 'Herunterladen',
                machineIdentifierCustomer: 'Maschinenname Kunde',
              },
            },
            add_line_to_dashboard: 'Linie zum Dashboard hinzufügen',
            elements: {
              'axis-test': {
                kpi: 'KPI',
                unit: 'Einheit',
                value: 'Wert',
                kpiUnit: 'Einheit',
                headline: 'Achstests',
                kpiValue: 'Wert',
                kpiHeader: 'KPI',
                chartTitle: 'Achstest',
                limitsLoad: 'Aus dem Browserspeicher laden',
                limitsSave: 'Im Browserspeicher speichern',
                selectAxis: 'Achse auswählen',
                limitsExport: 'Grenzwerte in die Zwischenablage exportieren',
                openAxistest: 'Achstest öffnen',
                listbox: {
                  label: 'Typ / Achse(n)',
                  noContentMessage: 'Achstesttyp auswählen',
                  noAxisTestAvailable: 'Kein Achstest dieses Typs verfügbar',
                },
                axisTestPlural: 'Achstests',
                noChartMessage: 'Bitte wählen Sie einen Achstest auf der linken Seite.',
                adjustments: {
                  add: 'Lokale Anpassung hinzufügen',
                  range: 'Reichweite',
                  header: 'Lokale Anpassung',
                  remove: 'Entfernen',
                  position: 'Position',
                  amplitude: 'Amplitude',
                  spreadMultiplier: 'Limit-Multiplikator',
                },
                chart: {
                  Equability: {
                    x: 'Position',
                    y: 'Strom',
                    up: 'Positive',
                    down: 'Negative',
                    limitsUp: 'begrenzt die positive Richtung',
                    limitsDown: 'begrenzt die negative Richtung',
                    sliderSlopeCenter: 'Kurvenursprung',
                    sliderSlopeFactor: 'Kurvenfaktor',
                    sliderVerticalOffset: 'Y-Versatz',
                    sliderVerticalSpread: 'Breite begrenzen',
                    sliderQuadraticCenter: 'Quadratischer Ursprung',
                    sliderQuadraticFactor: 'Quadratischer Faktor',
                  },
                  Circularity: {
                    clockwise: 'Uhrzeigersinn',
                    limitsClockwise: 'Grenzen im Uhrzeigersinn',
                    counterClockwise: 'Gegen d. Uhrzeigersinn',
                    sliderSineOffset: 'Wellenversatz',
                    sliderSineAmplitude: 'Wellenamplitude',
                    sliderSineFrequency: 'Wellenfrequenz',
                    sliderVerticalOffset: 'Y-Versatz',
                    sliderVerticalSpread: 'Breite begrenzen',
                    limitsCounterClockwise: 'Grenzen gegen den Uhrzeigersinn',
                  },
                },
                kpis: {
                  pulseVelocity: 'Geschwindigkeit',
                  pulseMaxRampUp: 'Rampe ->',
                  pulseMaxRampDown: 'Rampe <-',
                  pulseMaxStiffness: 'Steifigkeit maximal',
                  pulseMinStiffness: 'Steifigkeit minimal',
                  pulseMeanStiffness: 'Steifigkeit Ø',
                  circularReversalRange: 'Kreisumkehrspanne',
                  pulseMaxUpPathCurrent: 'Strom maximal ->',
                  pulseMinUpPathCurrent: 'Strom minimal ->',
                  pulseMeanUpPathCurrent: 'Strom Ø ->',
                  pulseMaxDownPathCurrent: 'Strom maximal <-',
                  pulseMinDownPathCurrent: 'Strom minimal <-',
                  pulseMeanDownPathCurrent: 'Strom Ø <-',
                  circularShapeDeviationClockwise: 'Kreisformabweichung im Uhrzeigersinn',
                  pulseStiffnessStandardDeviation: 'Steifigkeit σ',
                  pulseStandardDeviationUpPathCurrent: 'Strom σ ->',
                  pulseStandardDeviationDownPathCurrent: 'Strom σ <-',
                  circularShapeDeviationCounterClockwise: 'Kreisformabweichung gegen d. Uhrzeigersinn',
                },
                axisLabels: {
                  CIRCLE: {
                    x: 'Position',
                    y: 'Position',
                  },
                  EQUABILITY: {
                    x: 'Position',
                    y: 'Strom',
                  },
                },
                axisTestTypes: {
                  CIRCLE: 'Kreisformtest',
                  EQUABILITY: 'Gleichlauftest',
                  CIRCULARITY: 'Kreisformtest',
                },
              },
              gateway: {
                title: 'Gateway',
                contact: 'Im Fehlerfall wenden Sie sich bitte an',
                headline: 'Gateway',
                netcfg: {
                  cta: 'Bitte stellen Sie uns die Daten über das Netcfg-Formular zur Verfügung.',
                  intro: 'Um Ihre Maschine anbinden zu können, sind wir auf Ihre Angaben zur Netzwerkkonfiguration angewiesen.',
                  title: 'Netzwerkkonfiguration',
                  goToNetconfig: 'Netzwerkkonfiguration öffnen',
                },
                device: {
                  title: 'Gerätedetails',
                },
                enrollment: {
                  pic: 'Wenn das IoT Gateway so aussieht, führen Sie bitte folgende Schritte aus:',
                  line1: 'Laden Sie die Enrollment-Datei herunter, indem Sie auf die Schaltfläche am unteren Rand der Kachel klicken.',
                  line2: 'Der Download startet automatisch.',
                  line3: 'Legen Sie die .json-Datei im Stammverzeichnis auf einem USB-Stick ab. Stellen Sie sicher, dass die Datei benannt ist:',
                  line4: 'enrollment.json.',
                  line5: 'Stellen Sie sicher, dass sich die folgenden Dateien im Stammverzeichnis des USB-Sticks befinden:',
                  line6: 'Stecken Sie den USB-Stick in das entsprechende IoT-Gateway.',
                  line7: 'Das Einlernen startet automatisch, was durch eine blinkende grüne user LED signalisiert wird.',
                  line8: 'Nach erfolgreicher Anmeldung leuchtet die grüne LED, bis der USB-Stick entfernt wird.',
                  title: 'Enrollment',
                  button: 'Enrollment-datei',
                },
                deviceMigration: {
                  line1: 'Laden Sie die Datei „migrate-iot.zip“ herunter und extrahieren Sie deren Inhalt direkt auf einen leeren USB-Stick.',
                  line2: 'aws.json',
                  line3: 'Laden Sie auch die ',
                  line4: ' herunter und fügen diese auf den USB-Stick hinzu. Stellen Sie sicher, dass die Datei wie folgt benannt ist:',
                  line5: 'Stellen Sie sicher, dass sich die folgenden Dateien im Stammverzeichnis des USB-Sticks befinden:',
                  line6: 'install.json',
                  line7: 'aws-migrate-iot.tar.gz',
                  line8: 'aws-migrate-iot.tar.gz.sig',
                  line9: 'aws.json',
                  title: 'Plattform-Migration',
                  line10: 'Die Dateien wurden am 27.10.2023 geändert. Bitte laden Sie diese unbedingt erneut herunter und verwenden Sie nicht die alte Version.',
                  line11: 'Stecken Sie den USB-Stick in das entsprechende IoT-Gateway.',
                  line12: 'Die Software installiert sich automatisch, was durch eine blinkende gelbe LED signalisiert wird.',
                  line13: 'Nach erfolgreicher Migration leuchtet die grüne LED bis der USB-Stick entfernt wird.',
                  device: {
                    button: 'migrate-iot.zip',
                  },
                  generic: {
                    button: 'aws.json',
                  },
                },
              },
              info: {
                generate: 'Generieren',
                headline: 'Zeitreihen',
                noChartMessage: 'Um die gewünschten Daten anzuzeigen, wählen Sie eine oder mehrere Variablen aus und klicken Sie auf Generieren',
              },
              alarms: {
                headline: 'Alarme',
              },
              service: {
                headline: 'Service',
                update: {
                  title: 'Datei aktualisieren',
                },
                upload: {
                  title: 'Datei hochladen',
                  fields: {
                    file: 'Datei',
                    language: 'Dateisprache',
                    file_name: 'Dateiname',
                    list_of_languages: {
                      cz: 'Tschechisch',
                      de: 'Deutsch',
                      en: 'Englisch',
                      es: 'Spanisch',
                      fr: 'Französisch',
                      hu: 'Ungarisch',
                      it: 'Italienisch',
                      pl: 'Polnisch',
                      zh: 'Chinesisch',
                    },
                  },
                  buttons: {
                    send: 'Hochladen',
                    cancel: 'Abbrechen',
                  },
                  success: {
                    title: 'Erfolgreich',
                    button: 'Zurück zur Serviceübersicht',
                    message: 'Die Datei wurde erfolgreich hochgeladen',
                  },
                  error_messages: {
                    file_name: 'Bitte geben Sie einen Dateinamen an.',
                    file_not_valid: 'Bitte geben Sie eine Datei an / Bitte wählen Sie eine kleinere Datei aus.',
                    language_not_specified: 'Bitte geben Sie eine Dateisprache an.',
                  },
                },
                manuals: {
                  title: 'Handbücher',
                },
                version: {
                  title: 'Version dateien',
                },
                contracts: {
                  title: 'Verträge',
                },
                components: {
                  title: 'Komponenten Hard- und Software',
                },
                spare_parts: {
                  title: 'Ersatzteilliste',
                },
                consumeables: {
                  title: 'Betriebsstoffe',
                },
                Update: {
                  buttons: {
                    send: 'Aktualisieren',
                  },
                },
                software_edge: {
                  title: 'Software IoT Gateway',
                  values: {
                    box_id: 'Box-ID',
                    machine_connect: 'Machine Connect',
                    operating_system: 'Betriebssystem',
                  },
                },
                contact_service: {
                  line1: 'Brauchen Sie Hilfe mit Ihrer Maschine?',
                  line2: 'Kontaktieren Sie uns!',
                  title: 'Nachricht an SW-Service',
                  button: 'Service kontaktieren',
                  fields: {
                    name: 'Maschinennummer (SW)',
                    type: 'Anfragetyp',
                    email: 'E-Mail',
                    message: 'Nachricht',
                    phone_no: 'Telefonnummer',
                    last_name: 'Nachname',
                    first_name: 'Vorname',
                    contact_person: 'Ansprechpartner (SW) [optional]',
                    list_of_types: {
                      request: 'Allgemeine Anfrage',
                      service: 'Service',
                      retrofit: 'Retrofit',
                      expansion: 'Erweiterung',
                      inspection: 'Inspektion',
                    },
                    machine_identifier_customer: 'Maschinen-ID (Kunde) [optional]',
                  },
                  buttons: {
                    send: 'Anfrage senden',
                    cancel: 'Abbrechen',
                  },
                  success: {
                    title: 'Erfolgreich!',
                    button: 'Zurück zur Service-Übersicht',
                    message: 'Die Anfrage wurde erfolgreich an den SW-Service übermittelt.',
                  },
                  error_messages: {
                    email_not_valid: 'Bitte geben Sie eine gültige E-Mail-Adresse an.',
                    name_not_specified: 'Bitte geben Sie eine gültige SW-Maschinennummer an (mind. 7 Zeichen: A-Z, 0-9, - _).',
                    phone_not_specified: 'Bitte geben Sie eine gültige Telefonnummer an.',
                    message_not_specified: 'Bitte geben Sie eine Nachricht an.',
                    last_name_not_specified: 'Bitte geben Sie einen Nachnamen an.',
                    first_name_not_specified: 'Bitte geben Sie einen Vornamen an.',
                    family_name_not_specified: 'Bitte geben Sie einen Nachnamen an.',
                    contact_type_not_specified: 'Bitte geben Sie einen Anfragetyp an.',
                  },
                },
                software_machine: {
                  title: 'Software Maschine',
                  values: {
                    nc_version: 'NC-Version',
                    hmi_version: 'HMI-Version',
                    plc_version: 'PLC-Version',
                    compile_cycles: 'Compile-Zyklen',
                    operating_system: 'Betriebssystem',
                    pcu_base_version: 'PCU-Base-Version',
                    plc_toolbox_version: 'PLC-Toolbox',
                    plc_user_program_version: 'PLC-Benutzerprogramm',
                  },
                },
                changed: {
                  success: {
                    message: 'Der Name der Datei wurde erfolgreich geändert',
                  },
                },
                maintenance_assistance: {
                  intro: 'Der Handlungsassistent hilft Ihnen bei der Problemlösung.',
                  title: 'Handlungsassistent',
                  sub_title: 'Haben Sie momentan Schwierigkeiten mit einem Ihrer Assets?',
                  open_assistant: 'Handlungsassistent öffnen',
                  request_account: 'Account beantragen',
                },
              },
              spistat: {
                headline: 'Spindelstatistiken SpiStat',
              },
              onlineState: {
                title: 'IoT Gateway',
                labelOnline: 'an',
                labelOffline: 'aus',
              },
              swimlane: {
                headline: 'Verfügbarkeit Linie',
              },
              sendingState: {
                title: 'Sende-Status',
                aborted: 'abgebrochen',
                sending: 'sendet',
                undefined: 'nicht definiert',
                interrupted: 'unterbrochen',
              },
              alarms_map: {
                headline: 'Alarm Map',
              },
              collection: {
                headline: 'Kollektion',
                noChartMessage: 'Bitte wählen Sie eine Kollektion auf der linken Seite.',
              },
              performance: {
                kpi: {
                  sum: 'Summe',
                  header: 'Performance Kpi',
                  target: 'Soll',
                },
                alarms: {
                  title: 'Alarme',
                  category: [
                    'Nicht klassifiziert',
                    'Fehlermeldung',
                    'Betriebsmeldung',
                    'Statusmeldung',
                    'Bedienungsanweisung',
                  ],
                  icons: {
                    trend: 'Trend',
                    duration: 'Anstehdauer',
                    flatlist: 'Flache Liste',
                    frequency: 'Auftrittshäufigkeit',
                  },
                  tableHeadlines: {
                    time: 'Zeit',
                    trend: 'Trend',
                    duration: 'Anstehdauer',
                    leftTime: 'Alarm geht',
                    alarmCount: 'Anzahl',
                    alarmNumber: 'Alarm-Nr.',
                    description: 'Beschreibung',
                    alarmOccured: 'Alarm ist aufgetreten',
                    alarmClassification: 'Alarmklassifizierung',
                    alarmCountSecondRange: 'Anzahl T-1',
                  },
                  description: {
                    trend: {
                      up: 'ansteigend',
                      down: 'absteigend',
                      flat: 'gleichbleibend',
                    },
                  },
                },
                details: {
                  title: 'Details',
                  ncProgram: 'NC-Programm',
                  lastModified: 'Letzte Wertaufzeichnung',
                  machineState: 'Maschinenzustand',
                  numberAlarms: 'Alarme anstehend',
                  stopCondition: 'Haltebedingungen',
                  ncProgramState: 'NC-Programmstatus',
                  protectionLevel: 'Schutzstufe',
                  ncProgramStates: [
                    'abgebrochen',
                    'angehalten',
                    'läuft',
                    'wartend',
                    'unterbrochen',
                  ],
                  protectionLevels: [
                    'System',
                    'Hersteller',
                    'Service',
                    'Anwender',
                    'Schlüsselschalter Stellung 3',
                    'Schlüsselschalter Stellung 2',
                    'Schlüsselschalter Stellung 1',
                    'Schlüsselschalter Stellung 0',
                  ],
                },
                cycleTime: {
                  title: 'Zykluszeit',
                  chart: {
                    yAxis: {
                      title: 'Zykluszeit in s',
                    },
                  },
                },
                piececounter: {
                  ok: 'OK',
                  nok: 'NOK.',
                  sum: 'Summe',
                  title: 'Werkstückzähler',
                  titleDayCounter: 'Tageszähler',
                  titleShiftCounter: 'Schichtzähler',
                  titleTotalCounter: 'Gesamtzähler',
                  titleTotalCounterSmall: 'Gesamt',
                },
                availability: {
                  title: 'Verfügbarkeit',
                  kpi: {
                    title: 'Verfügbarkeit KPI',
                  },
                  legend: {
                    auto: 'AUTO',
                    JOG_with_error: 'JOG mit Fehler',
                    MDA_with_error: 'MDA mit Fehler',
                    JOG_without_error: 'JOG ohne Fehler',
                    MDA_without_error: 'MDA ohne Fehler',
                    not_defined_condition: 'Nicht definierter Zustand',
                    auto_no_program_active: 'AUTO Kein Programm aktiv',
                    auto_waiting_for_parts: 'AUTO Warte auf Teile',
                    auto_with_error_program_stopped: 'AUTO mit Fehler - Programm angehalten',
                    auto_waiting_for_parts_stopping_error: 'AUTO Warte auf Teile + Stoppfehler',
                  },
                  aggregated: {
                    title: 'Aggregierte Verfügbarkeit',
                  },
                  chart: {
                    yAxis: {
                      title: 'Verfügbarkeit',
                    },
                  },
                },
                inProduction: {
                  title: 'Status',
                },
                operationMode: {
                  title: 'Betriebsart',
                  chart: {
                    yAxis: {
                      title: 'Betriebsart',
                    },
                  },
                },
                runtimeMonitor: {
                  title: 'Laufzeitmonitor',
                  chart: {
                    yAxis: {
                      title: 'Programm',
                      labels: {
                        on: 'an',
                        off: 'aus',
                      },
                    },
                  },
                },
                spindleOverride: {
                  title: 'Spindel Override',
                },
                feedrateOverride: {
                  title: 'Vorschub Override',
                },
                uptimeDistribution: {
                  title: 'Uptime Distribution',
                },
              },
              administration: {
                title: 'Administration',
                headline: 'Administration',
                aws: {
                  title: 'AWS Links',
                },
                references: {
                  values: {
                    s3: 'S3',
                    iot: 'IoT Core',
                    ssm: 'Systems Manager',
                    greengrass: 'IoT Greengrass',
                  },
                },
              },
              condition: {
                bill: {
                  title: 'Stückliste',
                },
                component: {
                  title: 'Komponenten',
                },
              },
              maintenance: {
                headline: 'Wartung',
              },
              spindleData: {
                headline: 'Spindeldaten',
              },
              point8: {
                chart: {
                  xAxis: {
                    title: 'Zeit vor Alarm (s)',
                  },
                  yAxis: {
                    title: 'Zeit nach Alarm (s)',
                  },
                },
              },
              energymonitoring: {
                headline: 'Energie monitoring',
                chartTitles: {
                  air: 'Druckluftverbrauch',
                  current: 'Stromverbrauch',
                  consumption: 'Durchschnittlicher Verbrauch pro Stunde',
                },
              },
              conditionMonitoring: {
                headline: 'Zustandsüberwachung',
              },
            },
          },
          location: {
            fab_title: 'Neuen Standort hinzufügen',
            tabs: {
              list: 'Hallen',
              shopfloor: 'Shopfloor',
            },
            grid: {
              hallName: 'Halle',
            },
            success: {
              title: 'Erfolgreich!',
              button: 'Zurück zur Liste',
              message: 'Der Standort wurde erfolgreich angelegt.',
            },
            new_form_title: 'Neuer Standort',
            edit_form_title: 'Standort bearbeiten',
            new: {
              fields: {
                city: 'Stadt',
                name: 'Standortname',
                email: 'E-Mail [optional]',
                state: 'Bundesland [optional]',
                street: 'Anschrift',
                country: 'Land',
                website: 'Webseite [optional]',
                zipcode: 'Postleitzahl',
                language: 'Sprache [optional]',
                name_short: 'Standortname (Kurzform) [optional]',
                description: 'Beschreibung [optional]',
                description_internal: 'Beschreibung (intern) [optional]',
              },
              error_messages: {
                email: 'Bitte geben Sie eine gültige E-Mail Adresse an.',
                city_not_specified: 'Bitte geben Sie eine Stadt an.',
                name_not_specified: 'Bitte geben Sie einen Standortnamen an.',
                street_not_specified: 'Bitte geben Sie eine Anschrift an.',
                country_not_specified: 'Bitte geben Sie ein Land an.',
                zipcode_not_specified: 'Bitte geben Sie eine Postleitzahl an.',
              },
            },
            editSuccess: {
              title: 'Erfolgreich!',
              button: 'Zurück zur Liste',
              message: 'Der Standort wurde erfolgreich aktualisiert.',
            },
            header: {
              labels: {
                address: 'Anschrift',
                country: 'Land',
              },
            },
          },
          companies: {
            grid: {
              city: 'Ort',
              email: 'E-Mail',
              street: 'Anschrift',
              country: 'Land',
              website: 'Webseite',
              postalCode: 'Postleitzahl',
              companyName: 'Unternehmen',
            },
            tabs: {
              list: 'Unternehmen',
              worldmap: 'Weltkarte',
            },
            map: {
              description_not_available: 'Keine Beschreibung vorhanden',
            },
          },
          generic: {
            new: {
              buttons: {
                add: 'Hinzufügen',
                back: 'Zurück zur Liste',
                edit: 'Aktualisieren',
                save: 'Speichern',
                email: 'Bild senden',
                cancel: 'Abbrechen',
                addMultiple: 'Hinzufügen + Neu',
              },
            },
          },
          administration: {
            edit_form_title: 'Admin bearbeiten',
          },
        },
        settings: {
          tabs: {
            limits: 'Grenzen',
          },
          button: {
            import: 'Start Import',
            dismiss: 'Abbruch',
            download: 'Start Download',
          },
        },
        file: {
          new: {
            buttons: {
              delete: 'Datei löschen',
            },
          },
        },
        hall: {
          new: {
            buttons: {
              delete: 'Halle löschen',
            },
          },
        },
        line: {
          new: {
            buttons: {
              delete: 'Linie löschen',
            },
          },
        },
        runtimeStatus: {
          tileTitle: 'Status',
        },
        charts: {
          tooltipDateFormat: 'DD.MM.YYYY HH:mm:ss',
          exporting: {
            datetimeformat: 'DD.MM.YYYY HH:mm:ss',
          },
          globalSettings: {
            lang: {
              noData: 'Keine Daten vorhanden',
              loading: 'Lade Daten...',
              months: [
                'Januar',
                'Februar',
                'März',
                'April',
                'Mai',
                'Juni',
                'Juli',
                'August',
                'September',
                'Oktober',
                'November',
                'Dezember',
              ],
              resetZoom: 'Zoom zurücksetzen',
              weekdays: [
                'Sonntag',
                'Montag',
                'Dienstag',
                'Mittwoch',
                'Donnerstag',
                'Freitag',
                'Samstag',
              ],
              shortMonths: [
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'Mai',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Okt',
                'Nov',
                'Dez',
              ],
              shortWeekdays: [
                'So',
                'Mo',
                'Di',
                'Mi',
                'Do',
                'Fr',
                'Sa',
              ],
              increaseDateRange: 'wenigstens bis',
            },
          },
        },
        datepicker: {
          presets: {
            title: 'Voreinstellungen',
            rangeTo: 'Bis',
            selectDateRange: 'Bitte einen Zeitraum auswählen',
            predefined: {
              today: 'Heute',
              last7days: 'Letzte 7 Tage',
              yesterday: 'Gestern',
              last24Hours: 'Letzte 24 Stunden',
              last5Minutes: 'Letzte 5 Minuten',
            },
          },
        },
        i18n: {
          chooseLanguagePhrase: 'Sprache wählen',
        },
        company: {
          new: {
            buttons: {
              delete: 'Unternehmen löschen',
            },
          },
        },
        location: {
          new: {
            buttons: {
              delete: 'Standort löschen',
            },
          },
        },
        runtimeStatusList: {
          tileTitle: 'Status',
        },
        dashboard: {
          configure: {
            fab_title: 'Dashboard konfigurieren',
            hide: {
              title: 'verbergen',
            },
            show: {
              title: 'anzeigen',
            },
            delete: {
              title: 'löschen',
            },
            add: {
              fab_title: 'Eigene Kachel hinzufügen',
            },
            save: {
              fab_title: 'Konfiguration speichern',
            },
            move_left: {
              title: 'Zur vorherigen Position schieben',
            },
            move_right: {
              title: 'Zur nächsten Position schieben',
            },
            discard: {
              fab_title: 'Konfiguration verwerfen',
            },
            add_custom_card: {
              save: 'speichern',
              input: 'Kachelname angeben',
              title: 'Eigene Kachel hinzufügen',
              cancel: 'abbrechen',
              missingMachine: 'Sie müssen mindestens eine Maschine auswählen',
            },
          },
        },
        errors: {
          statusCodes: {
            e_0: {
              message: 'Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie, die Seite neu zu laden.',
            },
            e_1xx: {
              message: 'Informational Error',
            },
            e_2xx: {
              message: 'Erfolg',
            },
            e_3xx: {
              message: 'Weiterleitung',
            },
            e_400: {
              message: 'Die Anfrage war fehlerhaft und konnte nicht verarbeitet werden.',
            },
            e_401: {
              message: 'Sie sind nicht authentifiziert. Bitte loggen Sie sich erneut ein.',
            },
            e_403: {
              message: 'Sie haben keine ausreichende Berechtigung, um auf die angeforderte Resource zuzugreifen.',
            },
            e_404: {
              message: 'Die angeforderte Ressource ist nicht verfügbar.',
            },
            e_405: {
              message: 'Methode nicht erlaubt.',
            },
            e_409: {
              message: 'Die Ressource existiert bereits.',
            },
            e_418: {
              message: "I'm a Teapot! Yaay!",
            },
            e_4xx: {
              message: 'Client-Fehler',
            },
            e_500: {
              message: 'Ein interner Serverfehler ist aufgetreten.',
            },
            e_5xx: {
              message: 'Server-Fehler',
            },
            e_900: {
              message: 'Der Versuch ein Asset anzulegen ist fehlgeschlagen. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.',
            },
            e_901: {
              message: 'Die Aktion wurde abgebrochen. Es wurde kein neues Asset angelegt.',
            },
            e_902: {
              message: 'Die Aktion wurde abgebrochen. Es wurde keine Serviceanfrage abgeschickt.',
            },
            e_903: {
              message: 'Beim Versuch, die Serviceanfrage abzusenden, ist etwas schiefgelaufen. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.',
            },
            e_904: {
              message: "Asset '%replaceValue' wurde erfolgreich angelegt. Sie können jetzt weitere Assets hinzufügen.",
            },
            e_905: {
              message: 'Die Aktion wurde abgebrochen. Das Asset wurde nicht aktualisiert.',
            },
            e_906: {
              message: "Asset '%replaceValue' wurde erfolgreich verschoben.",
            },
            e_910: {
              message: 'Der Versuch einen Benutzer anzulegen ist fehlgeschlagen. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.',
            },
            e_911: {
              message: 'Die Aktion wurde abgebrochen. Es wurde kein neuer Benutzer angelegt.',
            },
            e_912: {
              message: 'Beim Versuch, ein Asset zu aktualisieren, ist fehlgeschlagen. Bitte versuchen Sie es erneut.',
            },
            e_920: {
              message: 'Die Aktion wurde abgebrochen. Es wurden keine Änderungen gespeichert.',
            },
            e_921: {
              message: 'Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es noch einmal.',
            },
            e_922: {
              message: 'Der Benutzername und/oder das Passwort ist nicht korrekt. Bitte versuchen Sie es noch einmal.',
            },
            e_923: {
              message: 'Bitte geben Sie einen Benutzernamen und ein Passwort an.',
            },
            e_924: {
              message: 'Ihre Sitzung ist aufgrund Inaktivität abgelaufen. Bitte melden Sie sich erneut an.',
            },
            e_925: {
              message: 'Um die SW Cloud Platform nutzen zu können, müssen Sie unsere Datenschutzerklärung akzeptieren.',
            },
            e_926: {
              message: 'Ihr Account ist temporär gesperrt. Bitte versuchen Sie es in einigen Minuten erneut.',
            },
            e_927: {
              message: 'Ihr Passwort wurde erfolgreich geändert.',
            },
            e_928: {
              message: 'Die Aktion wurde abgebrochen. Es wurde keine Datei hochgeladen.',
            },
            e_929: {
              message: 'Beim Versuch, eine Datei hochzuladen, ist etwas schiefgelaufen. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.',
            },
            e_930: {
              message: 'Beim Versuch, eine Datei zu löschen, ist etwas schiefgelaufen. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.',
            },
            e_10500: {
              message: 'Es scheint als wären Sie nicht mit dem Internet verbunden. Bitte stellen Sie sicher, dass Sie verbunden sind.',
            },
            e_10501: {
              message: 'Sie verwenden einen nicht unterstützten Browser. Bitte verwenden Sie einen der folgenden Browser: Google Chrome, Microsoft Edge, Apple Safari.',
            },
            e_409_asset: {
              message: 'Das Asset (SW-Maschinennummer %replaceValue) existiert bereits.',
            },
            e_410_asset: {
              message: 'Dieser Datei-Name %replaceValue existiert bereits',
            },
          },
        },
        list: {
          sweetalert: {
            maxAmountReached: {
              html: 'Es können nur %s Elemente gleichzeitig ausgewählt werden',
              title: 'Max. Anzahl erreicht',
            },
          },
        },
      },
      module: {
        assets: {
          machine: {
            alarms: {
              no_alarms_text: 'Keine alarme verfügbar',
            },
            nc_comp_file_cardTitle: 'Vergleichsdaten',
            nc_data_file_cardTitle: 'Zustandsdaten',
            nc_state_files: {
              no_files_text: 'Keine Dateien verfügbar.',
            },
            nc_comp_files: {
              column_title_trigger: 'Trigger',
              column_title_filename: 'Dateiname',
              column_title_creation_date: 'Erstellungsdatum',
              modules: {
                assets: {
                  nc_comp_download: 'Hertunterladen von Vergleichsdaten',
                },
              },
            },
            nc_data_files: {
              column_title_trigger: 'Trigger',
              column_title_filename: 'Dateiname',
              column_title_creation_date: 'Erstellungsdatum',
              modules: {
                assets: {
                  nc_data_download: 'Herunterladen von NC-Zustandsdaten',
                },
              },
            },
          },
        },
      },
    },
  },
  default: {
    translation: {
      generic: {
        logout: 'Logout',
        release: "On '%replaceValue1' from '%replaceValue2' to '%replaceValue3' maintenance work will be carried out on the platform. The platform will be partially unavailable during this period.",
        pageTitle: 'SW CloudPlatform',
        closeLabel: 'Close',
        acceptLabel: 'Accept',
        userProfile: 'User Profile',
        button: {
          clear: 'Remove Chart',
          timeline: 'Limit adjustment',
          separateCharts: 'Separate Charts',
        },
        declineLabel: 'Decline',
        backToDashboard: 'Back to Dashboard',
        footerLinks: {
          gtct: {
            url: 'https://sw-machines.com/en/gtc/',
            title: 'GTCT / standards',
          },
          contact: {
            url: 'mailto:lifedata@sw-machines.com',
            title: 'Contact',
          },
          imprint: {
            url: 'https://sw-machines.com/en/imprint/',
            title: 'Imprint',
          },
          privacy: {
            title: 'Privacy',
            header: 'Privacy Policy',
          },
          swHomepage: {
            url: 'https://www.sw-machines.com/en/',
            title: 'SW homepage',
          },
        },
        noInternetConnection: 'It seems that you are not connected to the internet. Please ensure you are connected.',
      },
      modules: {
        generic: {
          of: 'of',
          noData: 'No data',
          dateFormat: 'MM/DD/YYYY',
          downloaded: 'downloaded',
          noDuration: 'no duration available',
          timeFormat: 'hh:mm:ss A',
          alarmPending: 'alarm pending',
          buttons: {
            back: 'Back',
            save: 'Save',
            view: 'View',
            cancel: 'Cancel',
            submit: 'Submit',
            export: {
              limit: 'Download Limits',
              alarms: 'Download Alarms',
            },
            import: {
              limit: 'Start',
              alarms: 'Start',
            },
            selectAllCheckboxes: 'Select all checkboxes',
            unselectAllCheckboxes: 'Unselect all checkboxes',
          },
          notAvailable: 'Not available',
          notConnected: 'Not connected yet',
          download: {
            file: 'File Downloads',
          },
          dateTimeFormat: '%m/%d/%Y %I:%M:%S %p',
          timeFormats: {
            day: 'day',
            days: 'days',
            hour: 'hour',
            year: 'year',
            hours: 'hours',
            month: 'month',
            years: 'years',
            minute: 'minute',
            months: 'months',
            second: 'second',
            minutes: 'minutes',
            seconds: 'seconds',
          },
          notAvailableShort: 'n/a',
          timeFormatDuration: 'HH:mm:ss',
          timeFormatWithoutSeconds: 'hh:mm A',
          sweetalert: {
            buttons: {
              understood: 'Understood',
            },
          },
        },
        delete: {
          text: "You won't be able to revert this!",
        },
        error: {
          upload: 'Failed uploads',
          download: 'Failed downloads',
          message: {
            enrollment_code_409: 'Device is already enrolled in aws.',
            limit_change_failed: 'There was an error changing the limits. Please try again or contact lifedata@sw-machines.com',
          },
        },
        qs: {
          tabs: {
            list: 'time recordings',
          },
          list: {
            report: {
              headline: 'Select variables',
            },
          },
          grid: {
            columns: {
              datetime: 'Date',
            },
          },
        },
        frameTitle: {
          qs: 'Quality Assurance',
          users: 'User management',
          assets: 'Asset management',
          settings: 'Settings',
          dashboard: 'Dashboard',
          userProfile: 'User Profile',
        },
        kpi: {
          tileTitle: 'KPI',
        },
        sidebar: {
          users: 'User management',
          assets: 'Asset management',
          dashboard: 'Dashboard',
          userProfile: 'User Profile',
        },
        success: {
          upload: 'Successful uploads',
          downloads: 'Successful downloads',
          statusCodes: {
            s_0: {
              message: 'An unexpected error occurred. Please try to reload the page.',
            },
            s_1: {
              message: 'Your changes have been saved.',
            },
            s_930: {
              message: "Asset '%replaceValue' has been deleted successfully.",
            },
            s_931: {
              message: "Company '%replaceValue' has been deleted successfully.",
            },
            s_932: {
              message: "Line '%replaceValue' has been deleted successfully.",
            },
            s_933: {
              message: "Hall '%replaceValue' has been deleted successfully.",
            },
            s_934: {
              message: "Location '%replaceValue' has been deleted successfully.",
            },
            s_935: {
              message: "File '%replaceValue' has been deleted successfully.",
            },
          },
          message: {
            duplication_deleted: 'Duplicated Cards Deleted!',
            add_line_to_dashboard: 'Line added to Dashboard',
          },
        },
        limit: {
          tabs: {
            list: 'Limit',
            type: 'Select by type',
            alarms: 'Alarms',
            machine: 'Select by machine ID',
            listImport: 'Limit Import',
            listDownload: 'Limit Download',
          },
          text: {
            text: 'Limits are values you set for variables that you are interested in. If a variable is not within the defined limits, you will receive a notification. Limits are also displayed in charts generated in the Time Series tab.',
            title: 'Limits',
          },
          export: {
            text: 'You can always export the currently set limits for all variables that are present in Cloud Platform. You can use this list as a reference or use it as to import all limits in one ho in the section Imports Limits as CSV',
            title: 'Export Limits as CSV',
          },
          import: {
            text: 'You can import a previsously downloaded CSV file with information on limits and subscribers to update the currently saved values. You can later choose to which asset or machine types you want to apply the imported limits',
            title: 'Import Limits from CSV',
            upload: {
              title: '',
            },
            machines: {
              title: 'Select the machines or machine type you want to apply the limits to.',
            },
          },
          upload: {
            fields: {
              file: 'Select the CSV file you want to Import',
            },
          },
        },
        manual: {
          line: {
            one: 'Go to the desired line in the asset management.',
            two: 'In the line’s header you can find a context menu.',
            four: 'The line’s tile is added to the dashboard',
            three: 'Click on the context menu, then select “add to dashboard”.',
            header: 'Adding tiles',
          },
          individual: {
            one: 'Select the settings button on the left.',
            two: 'Select the “plus” icon.',
            four: 'Confirm with “save”. The individual tile is added to the dashboard.',
            three: 'Name your tile and add your desired machines.',
            header: 'Adding individual tiles',
          },
        },
        users: {
          fab_title: 'Add new user',
          new: {
            title: 'Create User',
            buttons: {
              add: 'Add',
              back: 'Back to list',
              save: 'Save',
              cancel: 'Cancel',
              delete: 'Delete User',
            },
            fields: {
              email: 'E-mail',
              roles: 'User Role',
              gender: 'Gender',
              created: 'Created',
              lastName: 'Last name',
              firstName: 'First name',
              breadcrumb: 'display Breadcrumbs',
              main_group: 'Main group',
              phoneNumber: 'Phone number [optional]',
              customer_org: 'Customer organization',
              last_modified: 'Last modified',
              email_validation: 'E-mail (validation)',
            },
            error_messages: {
              email_not_match: 'E-mail addresses do not match',
              email_not_valid: 'Please specify a valid e-mail address',
              roles_not_specified: 'Please select a main group',
              gender_not_specified: 'Please specify a gender',
              last_name_not_specified: 'Please specify a last name',
              first_name_not_specified: 'Please specify a first name',
              main_group_not_specified: 'Please select a main group',
              family_name_not_specified: 'Please specify a last name',
              customer_organization_not_specified: 'Please select a customer organization',
            },
          },
          update: {
            title: 'Update User',
          },
          success: {
            title: 'Success!',
            button: 'Back to list',
            message: 'The user was successfully created. An e-mail with an automatically generated password has been sent to the specified e-mail address.',
            message_for_edit: 'The user was successfully updated.',
            button_back_to_last_page: 'Back to last page',
          },
          delete: {
            success: 'User successfully deleted.',
            confirmation: {
              title: 'Are you sure you want to delete this user?',
              confirm: 'Yes, delete this user',
              dismiss: 'No, cancel',
            },
          },
          groups: {
            visitor: 'Visitor',
            customer: 'Customer',
          },
          list: {
            grid: {
              user: 'User',
              email: 'E-mail',
              company: 'Company',
              enabled: 'Status',
              lastName: 'Last name',
              sw_admin: 'SW Admin',
              firstName: 'First name',
              userRights: 'User rights',
              phoneNumber: 'Phone number',
            },
          },
          fields: {
            gender: {
              male: 'male',
              female: 'female',
              diverse: 'diverse',
            },
          },
        },
        alarms: {
          tabs: {
            list: 'Alarms',
            type: 'Select by type',
            machine: 'Select by machine ID',
            listImport: 'Alarms Import',
            listDownload: 'Alarms Download',
          },
          text: {
            text: 'Alarm notifications can be set for the alarm numbers you are interested in. If the set alarm occurs on a defined machine, you will receive a notification.',
            title: 'Alarm Notifications',
          },
          export: {
            text: 'You can export the currently set alarm notifications of all machines in the SW CloudPlatform at any time. You can use this list as a reference or use it to import all alarm notifications at once in the “Import alarms as CSV” section.',
            title: 'Exporting Alarms as CSV',
          },
          import: {
            text: 'You can import a previously downloaded CSV file with information on alarms and subscribers to update the currently saved values. You can later select which system or machine types you want to apply the imported alarm notifications to.',
            title: 'Import Alarms from CSV',
            upload: {
              title: '',
            },
            machines: {
              title: 'Select the machines or machine type you want to apply the alarms to.',
            },
          },
          upload: {
            fields: {
              file: 'Select the CSV file you want to Import',
            },
          },
        },
        search: {
          noResults: 'no results found',
          results: {
            of: 'of',
            lines: 'lines',
            machines: 'Machines',
          },
          callToAction: 'search for machines',
        },
        spistat: {
          tileTitle: 'SpiStat',
        },
        settings: {
          button: {
            ok: 'OK',
            import: 'Start Import',
            dismiss: 'cancel',
            download: 'Start Download',
          },
          tabs: {
            limits: 'Limits',
          },
        },
        worldmap: {
          tileTitle: 'World map',
        },
        shopfloor: {
          tileTitle: 'Shop floor',
          upload: {
            text1: 'Send an image of your shopfloor layout to lifedata@sw-machines.com (JPG, PNG, PDF).',
            text2: 'If your machines are registered and enrolled in the SW CloudPlatform, they will appear on the left and send real-time data.',
            text3: 'Then simply drag and drop the status on your plan at the correct machine.',
            mainText: 'In this view, you can set up your individual shopfloor layout and see your machines through different information layers. Just follow these steps.',
            tileTitle: 'Shopfloor upload',
          },
        },
        asset: {
          delete: {
            success: 'Asset successfully deleted.',
            confirmation: {
              title: "Are you sure you want to delete '%replaceValue'?",
              confirm: 'delete',
              dismiss: 'cancel',
              company: {
                text: 'If you delete the company, all belonging users will also be deleted.',
              },
            },
          },
          new: {
            buttons: {
              delete: 'Delete Asset',
            },
          },
          updateLimits: {
            confirmation: {
              intro: 'Do you want to apply the limits of this new machine type?',
              title: "You've changed the machine type",
              accept: 'Yes, apply new Limits',
              decline: 'No, keep current Limits',
              dismiss: 'Cancel',
              info_accept: 'To replace your current limits with the limits of the new machine type, choose Yes.',
              info_decline: 'To keep your current limits, choose No.',
              info_accept_warning: 'This will overwrite or delete all your current limits.',
            },
          },
        },
        assets: {
          line: {
            tabs: {
              kpi: 'KPIs',
              list: 'Machines',
              shopfloor: 'Shop floor',
            },
            fab_title: 'Add new line',
            grid: {
              hall: 'Hall',
              line: 'Line',
              type: 'Type',
              contractEnd: 'Contract end',
              cellPosition: 'Cell / position',
              operationStep: 'Processing step',
              machineIdentifierSw: 'Machine no. (SW)',
              machineIdentifierCustomer: 'Machine name',
            },
            success: {
              title: 'Success!',
              button: 'Back to list',
              message: 'The line was successfully created.',
            },
            new_form_title: 'Add line',
            edit_form_title: 'Edit line',
            new: {
              fields: {
                name: 'Line name',
                name_short: 'Line name (short name) [optional]',
                description: 'Description [optional]',
              },
              error_messages: {
                name_not_specified: 'Please specify a line name',
              },
            },
            editSuccess: {
              title: 'Success!',
              button: 'Back to list',
              message: 'The line was successfully updated.',
            },
          },
          hall: {
            fab_title: 'Add new hall',
            grid: {
              part: 'Part',
              lineName: 'Line',
              typeOfLoading: 'Type of loading',
              lineResponsible: 'Line manager',
              plannedQuantity: 'Planned quantity',
              numberOfMachines: '# Machines',
            },
            tabs: {
              list: 'Lines',
              shopfloor: 'Shop floor',
            },
            success: {
              title: 'Success!',
              button: 'Back to list',
              message: 'The hall was successfully created.',
            },
            new_form_title: 'Add hall',
            shopfloor: {
              edit: 'Edit Mode',
              configure: {
                save: {
                  fab_title: 'Save configuration',
                },
                discard: {
                  fab_title: 'Discard configuration',
                },
              },
            },
            edit_form_title: 'Edit hall',
            new: {
              fields: {
                name: 'Hall name',
                name_short: 'Hall name (short name) [optional]',
                description: 'Description [optional]',
                description_external: 'Description (external) [optional]',
                description_internal: 'Description (internal) [optional]',
              },
              error_messages: {
                name_not_specified: 'Please specify a hall name',
              },
            },
            editSuccess: {
              title: 'Success!',
              button: 'Back to list',
              message: 'The hall was successfully updated.',
            },
          },
          grid: {
            description: 'Description [optional]',
          },
          company: {
            fab_title: 'Add new company',
            grid: {
              city: 'City',
              email: 'E-mail',
              street: 'Street',
              country: 'Country',
              website: 'Website',
              postalCode: 'zip code',
              locationName: 'Location',
            },
            tabs: {
              list: 'Locations',
              worldmap: 'World map',
            },
            success: {
              title: 'Success!',
              button: 'Back to list',
              message: 'The company was successfully created.',
            },
            toggle_switch: 'Create a company location with the same data',
            new_form_title: 'Add company',
            edit_form_title: 'Edit company',
            new: {
              fields: {
                city: 'City',
                name: 'Company name',
                email: 'E-Mail [optional]',
                state: 'State [optional]',
                street: 'Street',
                country: 'Country',
                website: 'Website [optional]',
                zipcode: 'Zipcode',
                legal_form: 'Legal form [optional]',
                name_short: 'Company name (short name) [optional]',
                description: 'Description [optional]',
                company_logo: 'Company logo (URL) [optional]',
                customer_number: 'Customer Number',
                industry_sector: 'Industry sector',
                description_external: 'Description (external) [optional]',
                description_internal: 'Description [optional]',
              },
              error_messages: {
                url: 'Please specify a valid URL',
                email: 'Please enter a valid e-mail address',
                city_not_specified: 'Please specify a city',
                name_not_specified: 'Please specify a company name',
                street_not_specified: 'Please specify a street',
                country_not_specified: 'Please select a country of origin',
                zipcode_not_specified: 'Please specify a zipcode',
                customer_number_not_specified: 'Please enter a customer number',
                industry_sector_not_specified: 'Please select an industry sector',
              },
            },
            editSuccess: {
              title: 'Success!',
              button: 'Back to list',
              message: 'The company was successfully updated.',
            },
            header: {
              labels: {
                email: 'E-mail',
                address: 'Address',
                country: 'Country',
                website: 'Website',
              },
            },
            coordinateselement: {
              intro_text: 'The CloudPlatform displays the company on a map, using latitude and longitude.',
              intro_heading: 'Information regarding the location',
              location_name: 'Name of the location:',
              label_latitude: 'Latitude',
              label_longitude: 'Longitude',
              text_fallback_info: 'If not, the default coordinates will be used.',
              text_location_found: 'A location was found.',
              text_location_entered: 'The location was entered.',
              text_success_location: 'We have set the following information based on your input:',
              button_define_coordinates: 'Define coordinates yourself',
              text_no_coordinates_found: 'No coordinates were found for the data you entered.',
              text_coordinates_success_set: 'Coordinates successfully set.',
              button_set_custom_coordinates: 'Set custom coordinates',
              text_specify_coordinates_yourself: 'You can specify the coordinates yourself.',
            },
          },
          machine: {
            fab_title: 'Add new asset',
            tabs: {
              info: 'Time series',
              alarms: 'Alarms',
              gateway: 'Gateway',
              service: 'Service',
              spistat: 'SpiStat',
              swimlane: 'Swimlane',
              'axis-test': 'Axis tests',
              collection: 'Collections',
              maintenance: 'Maintenance',
              performance: 'Performance',
              spindledata: 'Spindle data',
              administration: 'Administration',
              energymonitoring: 'Energy monitoring',
            },
            move: {
              message: "You can move the asset '%replaceValue' to a new company, location, hall and/or line now.",
              select_hall_label: 'Select new hall',
              select_line_label: 'Select new line',
              select_company_label: 'Select new company',
              select_location_label: 'Select new location',
            },
            new: {
              fields: {
                s3: 'S3',
                name: 'Machine no. (SW)',
                iotcore: 'IoT Core',
                startup: 'Machine startup date [optional]',
                shipping: 'Machine Shipping date [optional]',
                controller: 'Controller',
                machine_type: 'Machine type',
                warranty_end: 'Machine warranty end [optional]',
                iotgreengrass: 'IoT Greengrass',
                systemsManager: 'Systems Manager',
                warranty_start: 'Machine warranty start [optional]',
                machine_swmp_api: 'Machine SWMP api end [optional]',
                machine_swsp_trace: 'Machine SWSP trace end [optional]',
                machine_swcp_portal: 'Machine SWCP portal end [optional]',
                start_of_production: 'Machine start of production [optional]',
                machine_swcp_support: 'Machine SWCP support end [optional]',
                machine_cell_position: 'Machine cell / position [optional]',
                machine_swsp_line_mon: 'Machine SWSP lineMon end [optional]',
                machine_identifier_customer: 'Machine ID (customer)',
              },
              error_messages: {
                name_not_specified: 'Please specify a valid SW machine no. (min. 7 chars: A-Z, 0-9, -)',
                controller_not_specified: 'Please select a controller',
                machine_type_not_specified: 'Please specify a machine type',
                machine_identifier_customer_not_specified: 'Please specify a customer machine ID',
              },
            },
            success: {
              title: 'Success!',
              button: 'Back to list',
              message: 'The asset was successfully created.',
            },
            new_form_title: 'New asset',
            edit_form_title: 'Edit asset',
            move_form_title: 'Move asset',
            editSuccess: {
              title: 'Success!',
              button: 'Back to list',
              message: 'The asset was successfully updated.',
            },
            header: {
              labels: {
                api: 'SWMP API',
                trace: 'SWSP Trace',
                portal: 'SWCP Portal',
                lineMon: 'SWSP LineMon',
                support: 'SWCP Support',
                shipping: 'Date of shipping',
                controller: 'Controller',
                contractEnd: 'Date of contract end',
                machineType: 'Machine type',
                startupDate: 'Date of startup',
                warrantyEnd: 'End of warranty',
                cellPosition: 'Machine cell / position',
                warrantyStart: 'Begin of warranty',
                customerNumber: 'Customer Number',
                enrollmentConfig: 'Enrollment-Configuration',
                startOfProduction: 'Start of production',
                machineIdentifierSw: 'Machine no. SW',
                enrollmentConfigDownload: 'Download',
                machineIdentifierCustomer: 'Customer machine name',
              },
            },
            add_line_to_dashboard: 'Add line to dashboard',
            elements: {
              gateway: {
                title: 'Gateway',
                contact: 'In case of an error, please contact',
                headline: 'Gateway',
                netcfg: {
                  cta: 'Please provide us the data via the Netcfg form.',
                  intro: 'In order to be able to connect your machine, we need information on your network configuration.',
                  title: 'Network Configuration',
                  goToNetconfig: 'Open Network Config',
                },
                device: {
                  title: 'Device details',
                },
                enrollment: {
                  pic: 'Please perform the following steps if the IoT Gateway looks like that:',
                  line1: 'Download the enrollment file by clicking on the button on the tile’s bottom.',
                  line2: 'Download will start automatically.',
                  line3: 'Put the .json file in the root directory on a USB stick. Make sure that the file is named',
                  line4: 'enrollment.json.',
                  line5: 'Make sure you have the following files in the root of the USB stick:',
                  line6: 'Insert the USB stick into the corresponding IoT Gateway.',
                  line7: 'Enrollment will start automatically, signalized by a slowly flashing green user LED.',
                  line8: 'After successful enrollment, the green LED is on until the USB stick is removed.',
                  title: 'Enrollment',
                  button: 'Enrollment file',
                },
                deviceMigration: {
                  line1: 'Download the migrate-iot.zip and extract its content directly to an empty USB stick.',
                  line2: 'aws.json',
                  line3: 'Download the',
                  line4: ' and also put it directly on the USB stick. Make sure that the file is named',
                  line5: 'Make sure you have the following files in the root of the USB stick:',
                  line6: 'install.json',
                  line7: 'aws-migrate-iot.tar.gz',
                  line8: 'aws-migrate-iot.tar.gz.sig',
                  line9: 'aws.json',
                  title: 'Platform Migration and enrollment',
                  line10: 'The, files have been modified as of 10/27/2023. Please be sure to download them again and do not use the old version.',
                  line11: 'Insert the USB stick into the corresponding IoT Gateway.',
                  line12: 'Software will run automatically, signalized by a flashing yellow LED.',
                  line13: 'After successful migration, the green LED is on until the USB stick is removed.',
                  device: {
                    button: 'migrate-iot.zip',
                  },
                  generic: {
                    button: 'aws.json',
                  },
                },
              },
              info: {
                generate: 'Generate',
                headline: 'Time series',
                noChartMessage: 'Please select one or more variables on the left and click on Generate to display your metrics',
              },
              alarms: {
                headline: 'Alarms',
              },
              service: {
                headline: 'Service',
                update: {
                  title: 'Update File',
                },
                upload: {
                  title: 'Upload File',
                  fields: {
                    file: 'File',
                    language: 'File language',
                    file_name: 'File name',
                    list_of_languages: {
                      cz: 'Czech',
                      de: 'German',
                      en: 'English',
                      es: 'Spanish',
                      fr: 'French',
                      hu: 'Hungarian',
                      it: 'Italian',
                      pl: 'Polish',
                      zh: 'Chinese',
                    },
                  },
                  buttons: {
                    send: 'Upload',
                    cancel: 'Cancel',
                  },
                  success: {
                    title: 'Success',
                    button: 'Back to service overview',
                    message: 'The file has been successfully uploaded.',
                  },
                  error_messages: {
                    file_name: 'Please specify a file name',
                    file_not_valid: 'Please specify a file / Please select a smaller file.',
                    language_not_specified: 'Please specify a file language.',
                  },
                },
                manuals: {
                  title: 'Manuals',
                },
                version: {
                  title: 'version files',
                },
                contracts: {
                  title: 'Contracts',
                },
                components: {
                  title: 'Hardware and Software Components',
                },
                spare_parts: {
                  title: 'Spareparts',
                },
                consumeables: {
                  title: 'Consumeables',
                },
                Update: {
                  buttons: {
                    send: 'Update',
                  },
                },
                software_edge: {
                  title: 'Software IoT-Gateway',
                  values: {
                    box_id: 'Box ID',
                    machine_connect: 'machine connect',
                    operating_system: 'operating system',
                  },
                },
                contact_service: {
                  line1: 'Need help with your machine?',
                  line2: 'Contact us!',
                  title: 'Message to SW Service',
                  button: 'Contact Service',
                  fields: {
                    name: 'Machine identifier (SW)',
                    type: 'Type',
                    email: 'E-Mail',
                    message: 'Message',
                    phone_no: 'Phone no.',
                    last_name: 'Last name',
                    first_name: 'First name',
                    contact_person: 'Contact person (SW) [optional]',
                    list_of_types: {
                      request: 'Request',
                      service: 'Service',
                      retrofit: 'Retrofit',
                      expansion: 'Expansion',
                      inspection: 'Inspection',
                    },
                    machine_identifier_customer: 'Machine identifier (customer) [optional]',
                  },
                  buttons: {
                    send: 'Send request',
                    cancel: 'Cancel',
                  },
                  success: {
                    title: 'Success',
                    button: 'Back to service overview',
                    message: 'The request has been successfully sent to SW Service.',
                  },
                  error_messages: {
                    email_not_valid: 'Please specify a valid e-mail address',
                    name_not_specified: 'Please specify a valid SW machine no. (min. 7 chars: A-Z, 0-9, - _)',
                    phone_not_specified: 'Please specify a phone number',
                    message_not_specified: 'Please enter a message',
                    last_name_not_specified: 'Please specify a last name',
                    first_name_not_specified: 'Please specify a first name',
                    family_name_not_specified: 'Please specify a last name',
                    contact_type_not_specified: 'Please specify a request type',
                  },
                },
                software_machine: {
                  title: 'Machine Software',
                  values: {
                    nc_version: 'NC version',
                    hmi_version: 'HMI version',
                    plc_version: 'PLC version',
                    compile_cycles: 'compile cycles',
                    operating_system: 'operating system',
                    pcu_base_version: 'PCU base version',
                    plc_toolbox_version: 'PLC toolbox version',
                    plc_user_program_version: 'PLC user program version',
                  },
                },
                changed: {
                  success: {
                    message: 'The name of the file has been successfully updated.',
                  },
                },
                maintenance_assistance: {
                  intro: "In the service assistant space you'll be guided through various troubleshooting processes.",
                  title: 'Service Assistant',
                  sub_title: 'Are you currently having a problem with one of your assets?',
                  open_assistant: 'Open Service Assistant',
                  request_account: 'Request Account',
                },
              },
              spistat: {
                headline: 'Spindle statistics SpiStat',
              },
              'axis-test': {
                kpiUnit: 'Unit',
                headline: 'Axis test',
                kpiValue: 'Value',
                kpiHeader: 'KPI',
                limitsLoad: 'Load from browser storage',
                limitsSave: 'Save to browser storage',
                selectAxis: 'Select axis',
                limitsExport: 'Export limits to clipboard',
                openAxistest: 'Open Axistest',
                axisTestPlural: 'Axistests',
                adjustments: {
                  add: 'Add local adjustment',
                  range: 'Range',
                  header: 'Local adjustment',
                  remove: 'Remove',
                  position: 'Position',
                  amplitude: 'Amplitude',
                  spreadMultiplier: 'Limit multiplier',
                },
                chart: {
                  Equability: {
                    x: 'Position',
                    y: 'Current',
                    up: 'positive direction',
                    down: 'negative direction',
                    limitsUp: 'limits positive direction',
                    limitsDown: 'limits negative direction',
                    sliderSlopeCenter: 'Slope origin',
                    sliderSlopeFactor: 'Slope factor',
                    sliderVerticalOffset: 'Y offset',
                    sliderVerticalSpread: 'Limit width',
                    sliderQuadraticCenter: 'Quadratic origin',
                    sliderQuadraticFactor: 'Quadratic factor',
                  },
                  Circularity: {
                    clockwise: 'clockwise',
                    limitsClockwise: 'limits clockwise',
                    counterClockwise: 'counter-clockwise',
                    sliderSineOffset: 'Wave offset',
                    sliderSineAmplitude: 'Wave amplitude',
                    sliderSineFrequency: 'Wave frequencz',
                    sliderVerticalOffset: 'Y offset',
                    sliderVerticalSpread: 'Limit width',
                    limitsCounterClockwise: 'limits counter-clockwise',
                  },
                },
                kpis: {
                  pulseVelocity: 'Velocity',
                  pulseMaxRampUp: 'Ramp ->',
                  pulseMaxRampDown: 'Ramp <-',
                  pulseMaxStiffness: 'Stiffness max',
                  pulseMinStiffness: 'Stiffness min',
                  pulseMeanStiffness: 'Stiffness Ø',
                  circularReversalRange: 'Circular hysteresis',
                  pulseMaxUpPathCurrent: 'Current max ->',
                  pulseMinUpPathCurrent: 'Current min ->',
                  pulseMeanUpPathCurrent: 'Current Ø ->',
                  pulseMaxDownPathCurrent: 'Current max <-',
                  pulseMinDownPathCurrent: 'Current min <-',
                  pulseMeanDownPathCurrent: 'Current Ø <-',
                  circularShapeDeviationClockwise: 'Circular deviation clockwise',
                  pulseStiffnessStandardDeviation: 'Stiffness σ',
                  pulseStandardDeviationUpPathCurrent: 'Current σ ->',
                  pulseStandardDeviationDownPathCurrent: 'Current σ <-',
                  circularShapeDeviationCounterClockwise: 'Circular deviation counter-clockwise',
                },
                axisTestTypes: {
                  CIRCLE: 'Circularity',
                  EQUABILITY: 'Equability',
                  CIRCULARITY: 'Circularity',
                },
                listbox: {
                  noAxisTestAvailable: 'No Axistest available',
                },
              },
              onlineState: {
                title: 'IoT Gateway',
                labelOnline: 'On',
                labelOffline: 'Off',
              },
              swimlane: {
                headline: 'Swimlane',
              },
              sendingState: {
                title: 'Sending status',
                aborted: 'aborted',
                sending: 'sending',
                undefined: 'undefined',
                interrupted: 'interrupted',
              },
              alarms_map: {
                headline: 'Alarm map',
              },
              collection: {
                headline: 'Collection',
                noChartMessage: 'Please choose any collection on the left.',
              },
              performance: {
                kpi: {
                  sum: 'Sum',
                  header: 'Performance Kpi',
                  target: 'Target',
                },
                alarms: {
                  title: 'Alarms',
                  category: [
                    'Not classified',
                    'Error message',
                    'Operation message',
                    'Status message',
                    'Operating Instruction',
                  ],
                  icons: {
                    trend: 'trend',
                    duration: 'duration',
                    flatlist: 'flat list',
                    frequency: 'frequency',
                  },
                  tableHeadlines: {
                    time: 'Time',
                    trend: 'Trend',
                    duration: 'Duration',
                    leftTime: 'Left time',
                    alarmCount: 'Number',
                    alarmNumber: 'Alarm no.',
                    description: 'Description',
                    alarmOccured: 'Alarm occured',
                    alarmClassification: 'Alarm classification',
                    alarmCountSecondRange: 'Number T-1',
                  },
                  description: {
                    trend: {
                      up: 'increasing',
                      down: 'decreasing',
                      flat: 'unchanged',
                    },
                  },
                },
                details: {
                  title: 'Details',
                  ncProgram: 'NC program',
                  lastModified: 'Last modified',
                  machineState: 'Machine state',
                  numberAlarms: 'Alarms pending',
                  stopCondition: 'Stop conditions',
                  ncProgramState: 'NC program state',
                  protectionLevel: 'Protection level',
                  ncProgramStates: [
                    'aborted',
                    'halted',
                    'running',
                    'waiting',
                    'interrupted',
                  ],
                  protectionLevels: [
                    'System',
                    'Manufacturer',
                    'Service',
                    'User',
                    'Key switch position 3',
                    'Key switch position 2',
                    'Key switch position 1',
                    'Key switch position 0',
                  ],
                },
                cycleTime: {
                  title: 'Cycle time',
                  chart: {
                    yAxis: {
                      title: 'Cycle time in s',
                    },
                  },
                },
                piececounter: {
                  ok: 'o.k.',
                  nok: 'n.o.k.',
                  sum: 'Sum',
                  title: 'Piece counter',
                  titleDayCounter: 'Day counter',
                  titleShiftCounter: 'Shift counter',
                  titleTotalCounter: 'Total counter',
                  titleTotalCounterSmall: 'Total',
                },
                availability: {
                  title: 'Availability',
                  kpi: {
                    title: 'Availability KPI',
                  },
                  legend: {
                    auto: 'AUTO',
                    JOG_with_error: 'JOG with error',
                    MDA_with_error: 'MDA with error',
                    JOG_without_error: 'JOG without error',
                    MDA_without_error: 'MDA without error',
                    not_defined_condition: 'not defined condition',
                    auto_no_program_active: 'AUTO no program active',
                    auto_waiting_for_parts: 'AUTO waiting for parts',
                    auto_with_error_program_stopped: 'AUTO with error - program stopped',
                    auto_waiting_for_parts_stopping_error: 'AUTO waiting for parts + stopping error',
                  },
                  aggregated: {
                    title: 'Aggregated Availability',
                  },
                  chart: {
                    yAxis: {
                      title: 'Availability',
                    },
                  },
                },
                inProduction: {
                  title: 'State',
                },
                operationMode: {
                  title: 'Operation mode',
                  chart: {
                    yAxis: {
                      title: 'Operation mode',
                    },
                  },
                },
                runtimeMonitor: {
                  title: 'Runtime monitor',
                  chart: {
                    yAxis: {
                      title: 'Program',
                      labels: {
                        on: 'on',
                        off: 'off',
                      },
                    },
                  },
                },
                spindleOverride: {
                  title: 'Spindle override',
                },
                feedrateOverride: {
                  title: 'Feed rate override',
                },
                uptimeDistribution: {
                  title: 'Uptime distribution',
                },
              },
              administration: {
                title: 'Administration',
                headline: 'Administration',
                aws: {
                  title: 'AWS Links',
                },
                references: {
                  values: {
                    s3: 'S3',
                    iot: 'IoT Core',
                    ssm: 'Systems Manager',
                    greengrass: 'IoT Greengrass',
                  },
                },
              },
              condition: {
                bill: {
                  title: 'Bill of Materials',
                },
                component: {
                  title: 'Components',
                },
              },
              spindleData: {
                headline: 'Spindle data',
              },
              point8: {
                chart: {
                  xAxis: {
                    title: 'Time before alarm (s)',
                  },
                  yAxis: {
                    title: 'Time after alarm (s)',
                  },
                },
              },
              energymonitoring: {
                headline: 'Energy monitoring',
                chartTitles: {
                  air: 'Compressed air consumption',
                  current: 'Current consumption',
                  consumption: 'Average Consumption per hour',
                },
              },
              conditionMonitoring: {
                headline: 'Condition Monitoring',
              },
            },
          },
          location: {
            fab_title: 'Add new location',
            tabs: {
              list: 'Halls',
              shopfloor: 'Shopfloor',
            },
            grid: {
              hallName: 'Hall',
            },
            success: {
              title: 'Success!',
              button: 'Back to list',
              message: 'The location was successfully created.',
            },
            new_form_title: 'Add location',
            edit_form_title: 'Edit location',
            new: {
              fields: {
                city: 'City',
                name: 'Location name',
                email: 'E-Mail [optional]',
                state: 'State [optional]',
                street: 'Street',
                country: 'Country',
                website: 'Website [optional]',
                zipcode: 'Zipcode',
                language: 'spoken language [optional]',
                name_short: 'Location name (short name) [optional]',
                description: 'Description [optional]',
                description_internal: 'Description (internal) [optional]',
              },
              error_messages: {
                email: 'Please enter a valid e-mail address',
                city_not_specified: 'Please specify a city',
                name_not_specified: 'Please specify a location name',
                street_not_specified: 'Please specify a street',
                country_not_specified: 'Please select a country of origin',
                zipcode_not_specified: 'Please specify a zipcode',
              },
            },
            editSuccess: {
              title: 'Success!',
              button: 'Back to list',
              message: 'The location was successfully updated.',
            },
            header: {
              labels: {
                address: 'Address',
                country: 'Country',
              },
            },
          },
          companies: {
            grid: {
              city: 'City',
              email: 'E-mail',
              street: 'Street',
              country: 'Country',
              website: 'Website',
              postalCode: 'Zip code',
              companyName: 'Company',
            },
            tabs: {
              list: 'Companies',
              worldmap: 'World map',
            },
            map: {
              description_not_available: 'No description available',
            },
          },
          generic: {
            new: {
              buttons: {
                add: 'Add',
                back: 'Back to list',
                edit: 'Update',
                save: 'Save',
                email: 'Send Image',
                cancel: 'Cancel',
                addMultiple: 'Add + new',
              },
            },
          },
          administration: {
            edit_form_title: 'Edit Administration',
          },
        },
        locales: {
          languages: {
            cz: 'Český',
            de: 'Deutsch',
            en: 'English',
            es: 'Español',
            fr: 'Français',
            hu: 'Magyar',
            it: 'Italiano',
            pl: 'Polski',
            zh: '中文',
          },
        },
        file: {
          new: {
            buttons: {
              delete: 'Delete File',
            },
          },
        },
        hall: {
          new: {
            buttons: {
              delete: 'Delete Hall',
            },
          },
        },
        line: {
          new: {
            buttons: {
              delete: 'Delete Line',
            },
          },
        },
        runtimeStatus: {
          tileTitle: 'state',
        },
        charts: {
          tooltipDateFormat: 'MM/DD/YYYY hh:mm:ss A',
          exporting: {
            datetimeformat: '%m/%d/%Y %I:%M:%S %p',
          },
          globalSettings: {
            lang: {
              noData: 'No data available',
              loading: 'Loading data...',
              months: [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
              ],
              resetZoom: 'Reset zoom',
              weekdays: [
                'Sunday',
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
              ],
              shortMonths: [
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'May',
                'June',
                'July',
                'Aug',
                'Sept',
                'Oct',
                'Nov',
                'Dec',
              ],
              shortWeekdays: [
                'Sun',
                'Mon',
                'Tue',
                'Wed',
                'Thu',
                'Fri',
                'Sat',
              ],
              increaseDateRange: 'at least until',
            },
          },
        },
        datepicker: {
          presets: {
            title: 'Presets',
            rangeTo: 'To',
            selectDateRange: 'Please select a date range',
            predefined: {
              today: 'Today',
              last7days: 'Last 7 days',
              yesterday: 'Yesterday',
              last24Hours: 'Last 24 hours',
              last5Minutes: 'Last 5 minutes',
            },
          },
        },
        i18n: {
          chooseLanguagePhrase: 'Select language',
        },
        company: {
          new: {
            buttons: {
              delete: 'Delete Company',
            },
          },
        },
        location: {
          new: {
            buttons: {
              delete: 'Delete Location',
            },
          },
        },
        runtimeStatusList: {
          tileTitle: 'state',
        },
        dashboard: {
          configure: {
            fab_title: 'Configure Dashboard',
            hide: {
              title: 'Hide',
            },
            show: {
              title: 'Show',
            },
            delete: {
              title: 'Delete',
            },
            add: {
              fab_title: 'Add custom tile',
            },
            save: {
              fab_title: 'Save configuration',
            },
            move_left: {
              title: 'Move to the previous position',
            },
            move_right: {
              title: 'Move to the next position',
            },
            discard: {
              fab_title: 'Discard configuration',
            },
            add_custom_card: {
              save: 'save',
              input: 'Enter tile name',
              title: 'Add custom tile',
              cancel: 'cancel',
              missingMachine: 'You need to select at least one machine',
            },
          },
        },
        errors: {
          statusCodes: {
            e_0: {
              message: 'An unexpected error occurred. Please try to reload the page.',
            },
            e_1xx: {
              message: 'Informational error',
            },
            e_2xx: {
              message: 'Success',
            },
            e_3xx: {
              message: 'Redirection',
            },
            e_400: {
              message: 'The request was malformed and could not be processed.',
            },
            e_401: {
              message: 'You are not authenticated. Please login again.',
            },
            e_403: {
              message: 'You do not have sufficient rights to access the requested resource.',
            },
            e_404: {
              message: 'The requested resource is not available.',
            },
            e_405: {
              message: 'Method Not Allowed.',
            },
            e_409: {
              message: 'The resource you are trying to add already exists.',
            },
            e_418: {
              message: "I'm a Teapot! Yaay!",
            },
            e_4xx: {
              message: 'Client error',
            },
            e_500: {
              message: 'An internal server error occurred.',
            },
            e_5xx: {
              message: 'Server error',
            },
            e_900: {
              message: 'Something went wrong while trying to add an asset. Please try again later.',
            },
            e_901: {
              message: 'The action was canceled. No new asset was created.',
            },
            e_902: {
              message: 'The action was canceled. No service request has been sent.',
            },
            e_903: {
              message: 'Something went wrong while trying to send the service request. Please try again later.',
            },
            e_904: {
              message: "Asset '%replaceValue' has been created successfully. You can now add another asset.",
            },
            e_905: {
              message: 'The action was canceled. The asset has not been updated.',
            },
            e_906: {
              message: "Asset '%replaceValue' has been moved successfully.",
            },
            e_910: {
              message: 'Something went wrong while trying to add a user. Please try again later.',
            },
            e_911: {
              message: 'You canceled the current action. No user has been created.',
            },
            e_912: {
              message: 'Something went wrong while trying to update an asset. Please try again later.',
            },
            e_920: {
              message: 'You canceled the current action. No changes saved.',
            },
            e_921: {
              message: 'An unspecified error occured. Please try again later.',
            },
            e_922: {
              message: 'Your username and/or password is incorrect. Please try again.',
            },
            e_923: {
              message: 'Please specify an e-mail and a password.',
            },
            e_924: {
              message: 'Your session expired due to inactivity. Please login again.',
            },
            e_925: {
              message: 'You need to accept our privacy policy in order to use the platform.',
            },
            e_926: {
              message: 'Your account is temporarily locked. Please try again later.',
            },
            e_927: {
              message: 'Your password change request has been recorded successfully. Please check your e-mail account for further instructions.',
            },
            e_928: {
              message: 'The action was canceled. No file has been uploaded.',
            },
            e_929: {
              message: 'An error occurred while attempting to upload a file. Please try again at a later time.',
            },
            e_930: {
              message: 'Something went wrong while trying to delete the file. Please try again later.',
            },
            e_10500: {
              message: 'It seems that you are not connected to the internet. Please ensure you are connected.',
            },
            e_10501: {
              message: 'You are using an unsupported Browser. The CloudPlatform needs one of the following browsers: Google Chrome, Microsoft Edge, Apple Safari.',
            },
            e_409_asset: {
              message: 'This asset (machine identifier %replaceValue) already exists',
            },
            e_410_asset: {
              message: 'This file %replaceValue already exists',
            },
          },
        },
        list: {
          sweetalert: {
            maxAmountReached: {
              html: 'Only %s items can be selected at a time',
              title: 'Max. amount reached',
            },
          },
        },
      },
      module: {
        assets: {
          machine: {
            alarms: {
              no_alarms_text: 'No alarms available',
            },
            nc_comp_file_cardTitle: 'NC Comparison Files',
            nc_data_file_cardTitle: 'NC State Files',
            nc_state_files: {
              no_files_text: 'No files available.',
            },
            nc_comp_files: {
              column_title_trigger: 'Trigger',
              column_title_filename: 'File name',
              column_title_creation_date: 'Creation Date',
              modules: {
                assets: {
                  nc_comparison_download: 'Download NC data',
                },
              },
            },
            nc_data_files: {
              column_title_trigger: 'Trigger',
              column_title_filename: 'File name',
              column_title_creation_date: 'Creation Date',
              modules: {
                assets: {
                  nc_data_download: 'Download NC data',
                },
              },
            },
          },
        },
      },
    },
  },
  en: {
    translation: {
      generic: {
        logout: 'Logout',
        release: "On '%replaceValue1' from '%replaceValue2' to '%replaceValue3' maintenance work will be carried out on the platform. The platform will be partially unavailable during this period.",
        pageTitle: 'SW CloudPlatform',
        closeLabel: 'Close',
        acceptLabel: 'Accept',
        userProfile: 'User Profile',
        button: {
          clear: 'Remove Chart',
          timeline: 'Limit adjustment',
          separateCharts: 'Separate Charts',
        },
        declineLabel: 'Decline',
        backToDashboard: 'Back to Dashboard',
        footerLinks: {
          gtct: {
            url: 'https://sw-machines.com/en/gtc/',
            title: 'GTCT / standards',
          },
          contact: {
            url: 'mailto:lifedata@sw-machines.com',
            title: 'Contact',
          },
          imprint: {
            url: 'https://sw-machines.com/en/imprint/',
            title: 'Imprint',
          },
          privacy: {
            title: 'Privacy',
            header: 'Privacy Policy',
          },
          swHomepage: {
            url: 'https://www.sw-machines.com/en/',
            title: 'SW homepage',
          },
        },
        noInternetConnection: 'It seems that you are not connected to the internet. Please ensure you are connected.',
      },
      modules: {
        generic: {
          of: 'of',
          noData: 'No data',
          dateFormat: 'MM/DD/YYYY',
          downloaded: 'downloaded',
          noDuration: 'no duration available',
          timeFormat: 'hh:mm:ss A',
          alarmPending: 'alarm pending',
          buttons: {
            back: 'Back',
            save: 'Save',
            view: 'View',
            cancel: 'Cancel',
            submit: 'Submit',
            export: {
              limit: 'Download Limits',
              alarms: 'Download Alarms',
            },
            import: {
              limit: 'Start',
              alarms: 'Start',
            },
            selectAllCheckboxes: 'Select all checkboxes',
            unselectAllCheckboxes: 'Unselect all checkboxes',
          },
          notAvailable: 'Not available',
          notConnected: 'Not connected yet',
          download: {
            file: 'File Downloads',
          },
          dateTimeFormat: '%m/%d/%Y %I:%M:%S %p',
          timeFormats: {
            day: 'day',
            days: 'days',
            hour: 'hour',
            year: 'year',
            hours: 'hours',
            month: 'month',
            years: 'years',
            minute: 'minute',
            months: 'months',
            second: 'second',
            minutes: 'minutes',
            seconds: 'seconds',
          },
          notAvailableShort: 'n/a',
          timeFormatDuration: 'HH:mm:ss',
          timeFormatWithoutSeconds: 'hh:mm A',
          sweetalert: {
            buttons: {
              understood: 'Understood',
            },
          },
        },
        delete: {
          text: "You won't be able to revert this!",
        },
        error: {
          upload: 'Failed uploads',
          download: 'Failed downloads',
          message: {
            enrollment_code_409: 'Device is already enrolled in aws.',
            limit_change_failed: 'There was an error changing the limits. Please try again or contact lifedata@sw-machines.com',
          },
        },
        qs: {
          tabs: {
            list: 'time recordings',
          },
          list: {
            report: {
              headline: 'Select variables',
            },
          },
          grid: {
            columns: {
              datetime: 'Date',
            },
          },
        },
        frameTitle: {
          qs: 'Quality Assurance',
          users: 'User management',
          assets: 'Asset management',
          settings: 'Settings',
          dashboard: 'Dashboard',
          userProfile: 'User Profile',
        },
        kpi: {
          tileTitle: 'KPI',
        },
        sidebar: {
          users: 'User management',
          assets: 'Asset management',
          dashboard: 'Dashboard',
          userProfile: 'User Profile',
        },
        success: {
          upload: 'Successful uploads',
          downloads: 'Successful downloads',
          statusCodes: {
            s_0: {
              message: 'An unexpected error occurred. Please try to reload the page.',
            },
            s_1: {
              message: 'Your changes have been saved.',
            },
            s_930: {
              message: "Asset '%replaceValue' has been deleted successfully.",
            },
            s_931: {
              message: "Company '%replaceValue' has been deleted successfully.",
            },
            s_932: {
              message: "Line '%replaceValue' has been deleted successfully.",
            },
            s_933: {
              message: "Hall '%replaceValue' has been deleted successfully.",
            },
            s_934: {
              message: "Location '%replaceValue' has been deleted successfully.",
            },
            s_935: {
              message: "File '%replaceValue' has been deleted successfully.",
            },
          },
          message: {
            duplication_deleted: 'Duplicated Cards Deleted!',
            add_line_to_dashboard: 'Line added to Dashboard',
          },
        },
        limit: {
          tabs: {
            list: 'Limit',
            type: 'Select by type',
            alarms: 'Alarms',
            machine: 'Select by machine ID',
            listImport: 'Limit Import',
            listDownload: 'Limit Download',
          },
          text: {
            text: 'Limits are values you set for variables that you are interested in. If a variable is not within the defined limits, you will receive a notification. Limits are also displayed in charts generated in the Time Series tab.',
            title: 'Limits',
          },
          export: {
            text: 'You can always export the currently set limits for all variables that are present in Cloud Platform. You can use this list as a reference or use it as to import all limits in one ho in the section Imports Limits as CSV',
            title: 'Export Limits as CSV',
          },
          import: {
            text: 'You can import a previsously downloaded CSV file with information on limits and subscribers to update the currently saved values. You can later choose to which asset or machine types you want to apply the imported limits',
            title: 'Import Limits from CSV',
            upload: {
              title: '',
            },
            machines: {
              title: 'Select the machines or machine type you want to apply the limits to.',
            },
          },
          upload: {
            fields: {
              file: 'Select the CSV file you want to Import',
            },
          },
        },
        manual: {
          line: {
            one: 'Go to the desired line in the asset management.',
            two: 'In the line’s header you can find a context menu.',
            four: 'The line’s tile is added to the dashboard',
            three: 'Click on the context menu, then select “add to dashboard”.',
            header: 'Adding tiles',
          },
          individual: {
            one: 'Select the settings button on the left.',
            two: 'Select the “plus” icon.',
            four: 'Confirm with “save”. The individual tile is added to the dashboard.',
            three: 'Name your tile and add your desired machines.',
            header: 'Adding individual tiles',
          },
        },
        users: {
          fab_title: 'Add new user',
          new: {
            title: 'Create User',
            buttons: {
              add: 'Add',
              back: 'Back to list',
              save: 'Save',
              cancel: 'Cancel',
              delete: 'Delete User',
            },
            fields: {
              email: 'E-mail',
              roles: 'User Role',
              gender: 'Gender',
              created: 'Created',
              lastName: 'Last name',
              firstName: 'First name',
              breadcrumb: 'display Breadcrumbs',
              main_group: 'Main group',
              phoneNumber: 'Phone number [optional]',
              customer_org: 'Customer organization',
              last_modified: 'Last modified',
              email_validation: 'E-mail (validation)',
            },
            error_messages: {
              email_not_match: 'E-mail addresses do not match',
              email_not_valid: 'Please specify a valid e-mail address',
              roles_not_specified: 'Please select a main group',
              gender_not_specified: 'Please specify a gender',
              last_name_not_specified: 'Please specify a last name',
              first_name_not_specified: 'Please specify a first name',
              main_group_not_specified: 'Please select a main group',
              family_name_not_specified: 'Please specify a last name',
              customer_organization_not_specified: 'Please select a customer organization',
            },
          },
          update: {
            title: 'Update User',
          },
          success: {
            title: 'Success!',
            button: 'Back to list',
            message: 'The user was successfully created. An e-mail with an automatically generated password has been sent to the specified e-mail address.',
            message_for_edit: 'The user was successfully updated.',
            button_back_to_last_page: 'Back to last page',
          },
          delete: {
            success: 'User successfully deleted.',
            confirmation: {
              title: 'Are you sure you want to delete this user?',
              confirm: 'Yes, delete this user',
              dismiss: 'No, cancel',
            },
          },
          groups: {
            visitor: 'Visitor',
            customer: 'Customer',
          },
          list: {
            grid: {
              user: 'User',
              email: 'E-mail',
              company: 'Company',
              enabled: 'Status',
              lastName: 'Last name',
              sw_admin: 'SW Admin',
              firstName: 'First name',
              userRights: 'User rights',
              phoneNumber: 'Phone number',
            },
          },
          fields: {
            gender: {
              male: 'male',
              female: 'female',
              diverse: 'diverse',
            },
          },
        },
        alarms: {
          tabs: {
            list: 'Alarms',
            type: 'Select by type',
            machine: 'Select by machine ID',
            listImport: 'Alarms Import',
            listDownload: 'Alarms Download',
          },
          text: {
            text: 'Alarm notifications can be set for the alarm numbers you are interested in. If the set alarm occurs on a defined machine, you will receive a notification.',
            title: 'Alarm Notifications',
          },
          export: {
            text: 'You can export the currently set alarm notifications of all machines in the SW CloudPlatform at any time. You can use this list as a reference or use it to import all alarm notifications at once in the “Import alarms as CSV” section.',
            title: 'Exporting Alarms as CSV',
          },
          import: {
            text: 'You can import a previously downloaded CSV file with information on alarms and subscribers to update the currently saved values. You can later select which system or machine types you want to apply the imported alarm notifications to.',
            title: 'Import Alarms from CSV',
            upload: {
              title: '',
            },
            machines: {
              title: 'Select the machines or machine type you want to apply the alarms to.',
            },
          },
          upload: {
            fields: {
              file: 'Select the CSV file you want to Import',
            },
          },
        },
        search: {
          noResults: 'no results found',
          results: {
            of: 'of',
            lines: 'lines',
            machines: 'Machines',
          },
          callToAction: 'search for machines',
        },
        spistat: {
          tileTitle: 'SpiStat',
        },
        settings: {
          button: {
            ok: 'OK',
            import: 'Start Import',
            dismiss: 'cancel',
            download: 'Start Download',
          },
          tabs: {
            limits: 'Limits',
          },
        },
        worldmap: {
          tileTitle: 'World map',
        },
        shopfloor: {
          tileTitle: 'Shop floor',
          upload: {
            text1: 'Send an image of your shopfloor layout to lifedata@sw-machines.com (JPG, PNG, PDF).',
            text2: 'If your machines are registered and enrolled in the SW CloudPlatform, they will appear on the left and send real-time data.',
            text3: 'Then simply drag and drop the status on your plan at the correct machine.',
            mainText: 'In this view, you can set up your individual shopfloor layout and see your machines through different information layers. Just follow these steps.',
            tileTitle: 'Shopfloor upload',
          },
        },
        asset: {
          delete: {
            success: 'Asset successfully deleted.',
            confirmation: {
              title: "Are you sure you want to delete '%replaceValue'?",
              confirm: 'delete',
              dismiss: 'cancel',
              company: {
                text: 'If you delete the company, all belonging users will also be deleted.',
              },
            },
          },
          new: {
            buttons: {
              delete: 'Delete Asset',
            },
          },
          updateLimits: {
            confirmation: {
              intro: 'Do you want to apply the limits of this new machine type?',
              title: "You've changed the machine type",
              accept: 'Yes, apply new Limits',
              decline: 'No, keep current Limits',
              dismiss: 'Cancel',
              info_accept: 'To replace your current limits with the limits of the new machine type, choose Yes.',
              info_decline: 'To keep your current limits, choose No.',
              info_accept_warning: 'This will overwrite or delete all your current limits.',
            },
          },
        },
        assets: {
          line: {
            tabs: {
              kpi: 'KPIs',
              list: 'Machines',
              shopfloor: 'Shop floor',
            },
            fab_title: 'Add new line',
            grid: {
              hall: 'Hall',
              line: 'Line',
              type: 'Type',
              contractEnd: 'Contract end',
              cellPosition: 'Cell / position',
              operationStep: 'Processing step',
              machineIdentifierSw: 'Machine no. (SW)',
              machineIdentifierCustomer: 'Machine name',
            },
            success: {
              title: 'Success!',
              button: 'Back to list',
              message: 'The line was successfully created.',
            },
            new_form_title: 'Add line',
            edit_form_title: 'Edit line',
            new: {
              fields: {
                name: 'Line name',
                name_short: 'Line name (short name) [optional]',
                description: 'Description [optional]',
              },
              error_messages: {
                name_not_specified: 'Please specify a line name',
              },
            },
            editSuccess: {
              title: 'Success!',
              button: 'Back to list',
              message: 'The line was successfully updated.',
            },
          },
          hall: {
            fab_title: 'Add new hall',
            grid: {
              part: 'Part',
              lineName: 'Line',
              typeOfLoading: 'Type of loading',
              lineResponsible: 'Line manager',
              plannedQuantity: 'Planned quantity',
              numberOfMachines: '# Machines',
            },
            tabs: {
              list: 'Lines',
              shopfloor: 'Shop floor',
            },
            success: {
              title: 'Success!',
              button: 'Back to list',
              message: 'The hall was successfully created.',
            },
            new_form_title: 'Add hall',
            shopfloor: {
              edit: 'Edit Mode',
              configure: {
                save: {
                  fab_title: 'Save configuration',
                },
                discard: {
                  fab_title: 'Discard configuration',
                },
              },
            },
            edit_form_title: 'Edit hall',
            new: {
              fields: {
                name: 'Hall name',
                name_short: 'Hall name (short name) [optional]',
                description: 'Description [optional]',
                description_external: 'Description (external) [optional]',
                description_internal: 'Description (internal) [optional]',
              },
              error_messages: {
                name_not_specified: 'Please specify a hall name',
              },
            },
            editSuccess: {
              title: 'Success!',
              button: 'Back to list',
              message: 'The hall was successfully updated.',
            },
          },
          grid: {
            description: 'Description [optional]',
          },
          company: {
            fab_title: 'Add new company',
            grid: {
              city: 'City',
              email: 'E-mail',
              street: 'Street',
              country: 'Country',
              website: 'Website',
              postalCode: 'zip code',
              locationName: 'Location',
            },
            tabs: {
              list: 'Locations',
              worldmap: 'World map',
            },
            success: {
              title: 'Success!',
              button: 'Back to list',
              message: 'The company was successfully created.',
            },
            toggle_switch: 'Create a company location with the same data',
            new_form_title: 'Add company',
            edit_form_title: 'Edit company',
            new: {
              fields: {
                city: 'City',
                name: 'Company name',
                email: 'E-Mail [optional]',
                state: 'State [optional]',
                street: 'Street',
                country: 'Country',
                website: 'Website [optional]',
                zipcode: 'Zipcode',
                legal_form: 'Legal form [optional]',
                name_short: 'Company name (short name) [optional]',
                description: 'Description [optional]',
                company_logo: 'Company logo (URL) [optional]',
                customer_number: 'Customer Number',
                industry_sector: 'Industry sector',
                description_external: 'Description (external) [optional]',
                description_internal: 'Description [optional]',
              },
              error_messages: {
                url: 'Please specify a valid URL',
                email: 'Please enter a valid e-mail address',
                city_not_specified: 'Please specify a city',
                name_not_specified: 'Please specify a company name',
                street_not_specified: 'Please specify a street',
                country_not_specified: 'Please select a country of origin',
                zipcode_not_specified: 'Please specify a zipcode',
                customer_number_not_specified: 'Please enter a customer number',
                industry_sector_not_specified: 'Please select an industry sector',
              },
            },
            editSuccess: {
              title: 'Success!',
              button: 'Back to list',
              message: 'The company was successfully updated.',
            },
            header: {
              labels: {
                email: 'E-mail',
                address: 'Address',
                country: 'Country',
                website: 'Website',
              },
            },
            coordinateselement: {
              intro_text: 'The CloudPlatform displays the company on a map, using latitude and longitude.',
              intro_heading: 'Information regarding the location',
              location_name: 'Name of the location:',
              label_latitude: 'Latitude',
              label_longitude: 'Longitude',
              text_fallback_info: 'If not, the default coordinates will be used.',
              text_location_found: 'A location was found.',
              text_location_entered: 'The location was entered.',
              text_success_location: 'We have set the following information based on your input:',
              button_define_coordinates: 'Define coordinates yourself',
              text_no_coordinates_found: 'No coordinates were found for the data you entered.',
              text_coordinates_success_set: 'Coordinates successfully set.',
              button_set_custom_coordinates: 'Set custom coordinates',
              text_specify_coordinates_yourself: 'You can specify the coordinates yourself.',
            },
          },
          machine: {
            fab_title: 'Add new asset',
            tabs: {
              info: 'Time series',
              alarms: 'Alarms',
              gateway: 'Gateway',
              service: 'Service',
              spistat: 'SpiStat',
              swimlane: 'Swimlane',
              'axis-test': 'Axis tests',
              collection: 'Collections',
              maintenance: 'Maintenance',
              performance: 'Performance',
              spindledata: 'Spindle data',
              administration: 'Administration',
              energymonitoring: 'Energy monitoring',
            },
            move: {
              message: "You can move the asset '%replaceValue' to a new company, location, hall and/or line now.",
              select_hall_label: 'Select new hall',
              select_line_label: 'Select new line',
              select_company_label: 'Select new company',
              select_location_label: 'Select new location',
            },
            new: {
              fields: {
                s3: 'S3',
                name: 'Machine no. (SW)',
                iotcore: 'IoT Core',
                startup: 'Machine startup date [optional]',
                shipping: 'Machine Shipping date [optional]',
                controller: 'Controller',
                machine_type: 'Machine type',
                warranty_end: 'Machine warranty end [optional]',
                iotgreengrass: 'IoT Greengrass',
                systemsManager: 'Systems Manager',
                warranty_start: 'Machine warranty start [optional]',
                machine_swmp_api: 'Machine SWMP api end [optional]',
                machine_swsp_trace: 'Machine SWSP trace end [optional]',
                machine_swcp_portal: 'Machine SWCP portal end [optional]',
                start_of_production: 'Machine start of production [optional]',
                machine_swcp_support: 'Machine SWCP support end [optional]',
                machine_cell_position: 'Machine cell / position [optional]',
                machine_swsp_line_mon: 'Machine SWSP lineMon end [optional]',
                machine_identifier_customer: 'Machine ID (customer)',
              },
              error_messages: {
                name_not_specified: 'Please specify a valid SW machine no. (min. 7 chars: A-Z, 0-9, -)',
                controller_not_specified: 'Please select a controller',
                machine_type_not_specified: 'Please specify a machine type',
                machine_identifier_customer_not_specified: 'Please specify a customer machine ID',
              },
            },
            success: {
              title: 'Success!',
              button: 'Back to list',
              message: 'The asset was successfully created.',
            },
            new_form_title: 'New asset',
            edit_form_title: 'Edit asset',
            move_form_title: 'Move asset',
            editSuccess: {
              title: 'Success!',
              button: 'Back to list',
              message: 'The asset was successfully updated.',
            },
            header: {
              labels: {
                api: 'SWMP API',
                trace: 'SWSP Trace',
                portal: 'SWCP Portal',
                lineMon: 'SWSP LineMon',
                support: 'SWCP Support',
                shipping: 'Date of shipping',
                controller: 'Controller',
                contractEnd: 'Date of contract end',
                machineType: 'Machine type',
                startupDate: 'Date of startup',
                warrantyEnd: 'End of warranty',
                cellPosition: 'Machine cell / position',
                warrantyStart: 'Begin of warranty',
                customerNumber: 'Customer Number',
                enrollmentConfig: 'Enrollment-Configuration',
                startOfProduction: 'Start of production',
                machineIdentifierSw: 'Machine no. SW',
                enrollmentConfigDownload: 'Download',
                machineIdentifierCustomer: 'Customer machine name',
              },
            },
            add_line_to_dashboard: 'Add line to dashboard',
            elements: {
              gateway: {
                title: 'Gateway',
                contact: 'In case of an error, please contact',
                headline: 'Gateway',
                netcfg: {
                  cta: 'Please provide us the data via the Netcfg form.',
                  intro: 'In order to be able to connect your machine, we need information on your network configuration.',
                  title: 'Network Configuration',
                  goToNetconfig: 'Open Network Config',
                },
                device: {
                  title: 'Device details',
                },
                enrollment: {
                  pic: 'Please perform the following steps if the IoT Gateway looks like that:',
                  line1: 'Download the enrollment file by clicking on the button on the tile’s bottom.',
                  line2: 'Download will start automatically.',
                  line3: 'Put the .json file in the root directory on a USB stick. Make sure that the file is named',
                  line4: 'enrollment.json.',
                  line5: 'Make sure you have the following files in the root of the USB stick:',
                  line6: 'Insert the USB stick into the corresponding IoT Gateway.',
                  line7: 'Enrollment will start automatically, signalized by a slowly flashing green user LED.',
                  line8: 'After successful enrollment, the green LED is on until the USB stick is removed.',
                  title: 'Enrollment',
                  button: 'Enrollment file',
                },
                deviceMigration: {
                  line1: 'Download the migrate-iot.zip and extract its content directly to an empty USB stick.',
                  line2: 'aws.json',
                  line3: 'Download the',
                  line4: ' and also put it directly on the USB stick. Make sure that the file is named',
                  line5: 'Make sure you have the following files in the root of the USB stick:',
                  line6: 'install.json',
                  line7: 'aws-migrate-iot.tar.gz',
                  line8: 'aws-migrate-iot.tar.gz.sig',
                  line9: 'aws.json',
                  title: 'Platform Migration and enrollment',
                  line10: 'The, files have been modified as of 10/27/2023. Please be sure to download them again and do not use the old version.',
                  line11: 'Insert the USB stick into the corresponding IoT Gateway.',
                  line12: 'Software will run automatically, signalized by a flashing yellow LED.',
                  line13: 'After successful migration, the green LED is on until the USB stick is removed.',
                  device: {
                    button: 'migrate-iot.zip',
                  },
                  generic: {
                    button: 'aws.json',
                  },
                },
              },
              info: {
                generate: 'Generate',
                headline: 'Time series',
                noChartMessage: 'Please select one or more variables on the left and click on Generate to display your metrics',
              },
              alarms: {
                headline: 'Alarms',
              },
              service: {
                headline: 'Service',
                update: {
                  title: 'Update File',
                },
                upload: {
                  title: 'Upload File',
                  fields: {
                    file: 'File',
                    language: 'File language',
                    file_name: 'File name',
                    list_of_languages: {
                      cz: 'Czech',
                      de: 'German',
                      en: 'English',
                      es: 'Spanish',
                      fr: 'French',
                      hu: 'Hungarian',
                      it: 'Italian',
                      pl: 'Polish',
                      zh: 'Chinese',
                    },
                  },
                  buttons: {
                    send: 'Upload',
                    cancel: 'Cancel',
                  },
                  success: {
                    title: 'Success',
                    button: 'Back to service overview',
                    message: 'The file has been successfully uploaded.',
                  },
                  error_messages: {
                    file_name: 'Please specify a file name',
                    file_not_valid: 'Please specify a file / Please select a smaller file.',
                    language_not_specified: 'Please specify a file language.',
                  },
                },
                manuals: {
                  title: 'Manuals',
                },
                version: {
                  title: 'version files',
                },
                contracts: {
                  title: 'Contracts',
                },
                components: {
                  title: 'Hardware and Software Components',
                },
                spare_parts: {
                  title: 'Spareparts',
                },
                consumeables: {
                  title: 'Consumeables',
                },
                Update: {
                  buttons: {
                    send: 'Update',
                  },
                },
                software_edge: {
                  title: 'Software IoT-Gateway',
                  values: {
                    box_id: 'Box ID',
                    machine_connect: 'machine connect',
                    operating_system: 'operating system',
                  },
                },
                contact_service: {
                  line1: 'Need help with your machine?',
                  line2: 'Contact us!',
                  title: 'Message to SW Service',
                  button: 'Contact Service',
                  fields: {
                    name: 'Machine identifier (SW)',
                    type: 'Type',
                    email: 'E-Mail',
                    message: 'Message',
                    phone_no: 'Phone no.',
                    last_name: 'Last name',
                    first_name: 'First name',
                    contact_person: 'Contact person (SW) [optional]',
                    list_of_types: {
                      request: 'Request',
                      service: 'Service',
                      retrofit: 'Retrofit',
                      expansion: 'Expansion',
                      inspection: 'Inspection',
                    },
                    machine_identifier_customer: 'Machine identifier (customer) [optional]',
                  },
                  buttons: {
                    send: 'Send request',
                    cancel: 'Cancel',
                  },
                  success: {
                    title: 'Success',
                    button: 'Back to service overview',
                    message: 'The request has been successfully sent to SW Service.',
                  },
                  error_messages: {
                    email_not_valid: 'Please specify a valid e-mail address',
                    name_not_specified: 'Please specify a valid SW machine no. (min. 7 chars: A-Z, 0-9, - _)',
                    phone_not_specified: 'Please specify a phone number',
                    message_not_specified: 'Please enter a message',
                    last_name_not_specified: 'Please specify a last name',
                    first_name_not_specified: 'Please specify a first name',
                    family_name_not_specified: 'Please specify a last name',
                    contact_type_not_specified: 'Please specify a request type',
                  },
                },
                software_machine: {
                  title: 'Machine Software',
                  values: {
                    nc_version: 'NC version',
                    hmi_version: 'HMI version',
                    plc_version: 'PLC version',
                    compile_cycles: 'compile cycles',
                    operating_system: 'operating system',
                    pcu_base_version: 'PCU base version',
                    plc_toolbox_version: 'PLC toolbox version',
                    plc_user_program_version: 'PLC user program version',
                  },
                },
                changed: {
                  success: {
                    message: 'The name of the file has been successfully updated.',
                  },
                },
                maintenance_assistance: {
                  intro: "In the service assistant space you'll be guided through various troubleshooting processes.",
                  title: 'Service Assistant',
                  sub_title: 'Are you currently having a problem with one of your assets?',
                  open_assistant: 'Open Service Assistant',
                  request_account: 'Request Account',
                },
              },
              spistat: {
                headline: 'Spindle statistics SpiStat',
              },
              'axis-test': {
                kpiUnit: 'Unit',
                headline: 'Axis test',
                kpiValue: 'Value',
                kpiHeader: 'KPI',
                limitsLoad: 'Load from browser storage',
                limitsSave: 'Save to browser storage',
                selectAxis: 'Select axis',
                limitsExport: 'Export limits to clipboard',
                openAxistest: 'Open Axistest',
                axisTestPlural: 'Axistests',
                adjustments: {
                  add: 'Add local adjustment',
                  range: 'Range',
                  header: 'Local adjustment',
                  remove: 'Remove',
                  position: 'Position',
                  amplitude: 'Amplitude',
                  spreadMultiplier: 'Limit multiplier',
                },
                chart: {
                  Equability: {
                    x: 'Position',
                    y: 'Current',
                    up: 'positive direction',
                    down: 'negative direction',
                    limitsUp: 'limits positive direction',
                    limitsDown: 'limits negative direction',
                    sliderSlopeCenter: 'Slope origin',
                    sliderSlopeFactor: 'Slope factor',
                    sliderVerticalOffset: 'Y offset',
                    sliderVerticalSpread: 'Limit width',
                    sliderQuadraticCenter: 'Quadratic origin',
                    sliderQuadraticFactor: 'Quadratic factor',
                  },
                  Circularity: {
                    clockwise: 'clockwise',
                    limitsClockwise: 'limits clockwise',
                    counterClockwise: 'counter-clockwise',
                    sliderSineOffset: 'Wave offset',
                    sliderSineAmplitude: 'Wave amplitude',
                    sliderSineFrequency: 'Wave frequencz',
                    sliderVerticalOffset: 'Y offset',
                    sliderVerticalSpread: 'Limit width',
                    limitsCounterClockwise: 'limits counter-clockwise',
                  },
                },
                kpis: {
                  pulseVelocity: 'Velocity',
                  pulseMaxRampUp: 'Ramp ->',
                  pulseMaxRampDown: 'Ramp <-',
                  pulseMaxStiffness: 'Stiffness max',
                  pulseMinStiffness: 'Stiffness min',
                  pulseMeanStiffness: 'Stiffness Ø',
                  circularReversalRange: 'Circular hysteresis',
                  pulseMaxUpPathCurrent: 'Current max ->',
                  pulseMinUpPathCurrent: 'Current min ->',
                  pulseMeanUpPathCurrent: 'Current Ø ->',
                  pulseMaxDownPathCurrent: 'Current max <-',
                  pulseMinDownPathCurrent: 'Current min <-',
                  pulseMeanDownPathCurrent: 'Current Ø <-',
                  circularShapeDeviationClockwise: 'Circular deviation clockwise',
                  pulseStiffnessStandardDeviation: 'Stiffness σ',
                  pulseStandardDeviationUpPathCurrent: 'Current σ ->',
                  pulseStandardDeviationDownPathCurrent: 'Current σ <-',
                  circularShapeDeviationCounterClockwise: 'Circular deviation counter-clockwise',
                },
                axisTestTypes: {
                  CIRCLE: 'Circularity',
                  EQUABILITY: 'Equability',
                  CIRCULARITY: 'Circularity',
                },
                listbox: {
                  noAxisTestAvailable: 'No Axistest available',
                },
              },
              onlineState: {
                title: 'IoT Gateway',
                labelOnline: 'On',
                labelOffline: 'Off',
              },
              swimlane: {
                headline: 'Swimlane',
              },
              sendingState: {
                title: 'Sending status',
                aborted: 'aborted',
                sending: 'sending',
                undefined: 'undefined',
                interrupted: 'interrupted',
              },
              alarms_map: {
                headline: 'Alarm map',
              },
              collection: {
                headline: 'Collection',
                noChartMessage: 'Please choose any collection on the left.',
              },
              performance: {
                kpi: {
                  sum: 'Sum',
                  header: 'Performance Kpi',
                  target: 'Target',
                },
                alarms: {
                  title: 'Alarms',
                  category: [
                    'Not classified',
                    'Error message',
                    'Operation message',
                    'Status message',
                    'Operating Instruction',
                  ],
                  icons: {
                    trend: 'trend',
                    duration: 'duration',
                    flatlist: 'flat list',
                    frequency: 'frequency',
                  },
                  tableHeadlines: {
                    time: 'Time',
                    trend: 'Trend',
                    duration: 'Duration',
                    leftTime: 'Left time',
                    alarmCount: 'Number',
                    alarmNumber: 'Alarm no.',
                    description: 'Description',
                    alarmOccured: 'Alarm occured',
                    alarmClassification: 'Alarm classification',
                    alarmCountSecondRange: 'Number T-1',
                  },
                  description: {
                    trend: {
                      up: 'increasing',
                      down: 'decreasing',
                      flat: 'unchanged',
                    },
                  },
                },
                details: {
                  title: 'Details',
                  ncProgram: 'NC program',
                  lastModified: 'Last modified',
                  machineState: 'Machine state',
                  numberAlarms: 'Alarms pending',
                  stopCondition: 'Stop conditions',
                  ncProgramState: 'NC program state',
                  protectionLevel: 'Protection level',
                  ncProgramStates: [
                    'aborted',
                    'halted',
                    'running',
                    'waiting',
                    'interrupted',
                  ],
                  protectionLevels: [
                    'System',
                    'Manufacturer',
                    'Service',
                    'User',
                    'Key switch position 3',
                    'Key switch position 2',
                    'Key switch position 1',
                    'Key switch position 0',
                  ],
                },
                cycleTime: {
                  title: 'Cycle time',
                  chart: {
                    yAxis: {
                      title: 'Cycle time in s',
                    },
                  },
                },
                piececounter: {
                  ok: 'o.k.',
                  nok: 'n.o.k.',
                  sum: 'Sum',
                  title: 'Piece counter',
                  titleDayCounter: 'Day counter',
                  titleShiftCounter: 'Shift counter',
                  titleTotalCounter: 'Total counter',
                  titleTotalCounterSmall: 'Total',
                },
                availability: {
                  title: 'Availability',
                  kpi: {
                    title: 'Availability KPI',
                  },
                  legend: {
                    auto: 'AUTO',
                    JOG_with_error: 'JOG with error',
                    MDA_with_error: 'MDA with error',
                    JOG_without_error: 'JOG without error',
                    MDA_without_error: 'MDA without error',
                    not_defined_condition: 'not defined condition',
                    auto_no_program_active: 'AUTO no program active',
                    auto_waiting_for_parts: 'AUTO waiting for parts',
                    auto_with_error_program_stopped: 'AUTO with error - program stopped',
                    auto_waiting_for_parts_stopping_error: 'AUTO waiting for parts + stopping error',
                  },
                  aggregated: {
                    title: 'Aggregated Availability',
                  },
                  chart: {
                    yAxis: {
                      title: 'Availability',
                    },
                  },
                },
                inProduction: {
                  title: 'State',
                },
                operationMode: {
                  title: 'Operation mode',
                  chart: {
                    yAxis: {
                      title: 'Operation mode',
                    },
                  },
                },
                runtimeMonitor: {
                  title: 'Runtime monitor',
                  chart: {
                    yAxis: {
                      title: 'Program',
                      labels: {
                        on: 'on',
                        off: 'off',
                      },
                    },
                  },
                },
                spindleOverride: {
                  title: 'Spindle override',
                },
                feedrateOverride: {
                  title: 'Feed rate override',
                },
                uptimeDistribution: {
                  title: 'Uptime distribution',
                },
              },
              administration: {
                title: 'Administration',
                headline: 'Administration',
                aws: {
                  title: 'AWS Links',
                },
                references: {
                  values: {
                    s3: 'S3',
                    iot: 'IoT Core',
                    ssm: 'Systems Manager',
                    greengrass: 'IoT Greengrass',
                  },
                },
              },
              condition: {
                bill: {
                  title: 'Bill of Materials',
                },
                component: {
                  title: 'Components',
                },
              },
              spindleData: {
                headline: 'Spindle data',
              },
              point8: {
                chart: {
                  xAxis: {
                    title: 'Time before alarm (s)',
                  },
                  yAxis: {
                    title: 'Time after alarm (s)',
                  },
                },
              },
              energymonitoring: {
                headline: 'Energy monitoring',
                chartTitles: {
                  air: 'Compressed air consumption',
                  current: 'Current consumption',
                  consumption: 'Average Consumption per hour',
                },
              },
              conditionMonitoring: {
                headline: 'Condition Monitoring',
              },
            },
          },
          location: {
            fab_title: 'Add new location',
            tabs: {
              list: 'Halls',
              shopfloor: 'Shopfloor',
            },
            grid: {
              hallName: 'Hall',
            },
            success: {
              title: 'Success!',
              button: 'Back to list',
              message: 'The location was successfully created.',
            },
            new_form_title: 'Add location',
            edit_form_title: 'Edit location',
            new: {
              fields: {
                city: 'City',
                name: 'Location name',
                email: 'E-Mail [optional]',
                state: 'State [optional]',
                street: 'Street',
                country: 'Country',
                website: 'Website [optional]',
                zipcode: 'Zipcode',
                language: 'spoken language [optional]',
                name_short: 'Location name (short name) [optional]',
                description: 'Description [optional]',
                description_internal: 'Description (internal) [optional]',
              },
              error_messages: {
                email: 'Please enter a valid e-mail address',
                city_not_specified: 'Please specify a city',
                name_not_specified: 'Please specify a location name',
                street_not_specified: 'Please specify a street',
                country_not_specified: 'Please select a country of origin',
                zipcode_not_specified: 'Please specify a zipcode',
              },
            },
            editSuccess: {
              title: 'Success!',
              button: 'Back to list',
              message: 'The location was successfully updated.',
            },
            header: {
              labels: {
                address: 'Address',
                country: 'Country',
              },
            },
          },
          companies: {
            grid: {
              city: 'City',
              email: 'E-mail',
              street: 'Street',
              country: 'Country',
              website: 'Website',
              postalCode: 'Zip code',
              companyName: 'Company',
            },
            tabs: {
              list: 'Companies',
              worldmap: 'World map',
            },
            map: {
              description_not_available: 'No description available',
            },
          },
          generic: {
            new: {
              buttons: {
                add: 'Add',
                back: 'Back to list',
                edit: 'Update',
                save: 'Save',
                email: 'Send Image',
                cancel: 'Cancel',
                addMultiple: 'Add + new',
              },
            },
          },
          administration: {
            edit_form_title: 'Edit Administration',
          },
        },
        locales: {
          languages: {
            cz: 'Český',
            de: 'Deutsch',
            en: 'English',
            es: 'Español',
            fr: 'Français',
            hu: 'Magyar',
            it: 'Italiano',
            pl: 'Polski',
            zh: '中文',
          },
        },
        file: {
          new: {
            buttons: {
              delete: 'Delete File',
            },
          },
        },
        hall: {
          new: {
            buttons: {
              delete: 'Delete Hall',
            },
          },
        },
        line: {
          new: {
            buttons: {
              delete: 'Delete Line',
            },
          },
        },
        runtimeStatus: {
          tileTitle: 'state',
        },
        charts: {
          tooltipDateFormat: 'MM/DD/YYYY hh:mm:ss A',
          exporting: {
            datetimeformat: '%m/%d/%Y %I:%M:%S %p',
          },
          globalSettings: {
            lang: {
              noData: 'No data available',
              loading: 'Loading data...',
              months: [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
              ],
              resetZoom: 'Reset zoom',
              weekdays: [
                'Sunday',
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
              ],
              shortMonths: [
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'May',
                'June',
                'July',
                'Aug',
                'Sept',
                'Oct',
                'Nov',
                'Dec',
              ],
              shortWeekdays: [
                'Sun',
                'Mon',
                'Tue',
                'Wed',
                'Thu',
                'Fri',
                'Sat',
              ],
              increaseDateRange: 'at least until',
            },
          },
        },
        datepicker: {
          presets: {
            title: 'Presets',
            rangeTo: 'To',
            selectDateRange: 'Please select a date range',
            predefined: {
              today: 'Today',
              last7days: 'Last 7 days',
              yesterday: 'Yesterday',
              last24Hours: 'Last 24 hours',
              last5Minutes: 'Last 5 minutes',
            },
          },
        },
        i18n: {
          chooseLanguagePhrase: 'Select language',
        },
        company: {
          new: {
            buttons: {
              delete: 'Delete Company',
            },
          },
        },
        location: {
          new: {
            buttons: {
              delete: 'Delete Location',
            },
          },
        },
        runtimeStatusList: {
          tileTitle: 'state',
        },
        dashboard: {
          configure: {
            fab_title: 'Configure Dashboard',
            hide: {
              title: 'Hide',
            },
            show: {
              title: 'Show',
            },
            delete: {
              title: 'Delete',
            },
            add: {
              fab_title: 'Add custom tile',
            },
            save: {
              fab_title: 'Save configuration',
            },
            move_left: {
              title: 'Move to the previous position',
            },
            move_right: {
              title: 'Move to the next position',
            },
            discard: {
              fab_title: 'Discard configuration',
            },
            add_custom_card: {
              save: 'save',
              input: 'Enter tile name',
              title: 'Add custom tile',
              cancel: 'cancel',
              missingMachine: 'You need to select at least one machine',
            },
          },
        },
        errors: {
          statusCodes: {
            e_0: {
              message: 'An unexpected error occurred. Please try to reload the page.',
            },
            e_1xx: {
              message: 'Informational error',
            },
            e_2xx: {
              message: 'Success',
            },
            e_3xx: {
              message: 'Redirection',
            },
            e_400: {
              message: 'The request was malformed and could not be processed.',
            },
            e_401: {
              message: 'You are not authenticated. Please login again.',
            },
            e_403: {
              message: 'You do not have sufficient rights to access the requested resource.',
            },
            e_404: {
              message: 'The requested resource is not available.',
            },
            e_405: {
              message: 'Method Not Allowed.',
            },
            e_409: {
              message: 'The resource you are trying to add already exists.',
            },
            e_418: {
              message: "I'm a Teapot! Yaay!",
            },
            e_4xx: {
              message: 'Client error',
            },
            e_500: {
              message: 'An internal server error occurred.',
            },
            e_5xx: {
              message: 'Server error',
            },
            e_900: {
              message: 'Something went wrong while trying to add an asset. Please try again later.',
            },
            e_901: {
              message: 'The action was canceled. No new asset was created.',
            },
            e_902: {
              message: 'The action was canceled. No service request has been sent.',
            },
            e_903: {
              message: 'Something went wrong while trying to send the service request. Please try again later.',
            },
            e_904: {
              message: "Asset '%replaceValue' has been created successfully. You can now add another asset.",
            },
            e_905: {
              message: 'The action was canceled. The asset has not been updated.',
            },
            e_906: {
              message: "Asset '%replaceValue' has been moved successfully.",
            },
            e_910: {
              message: 'Something went wrong while trying to add a user. Please try again later.',
            },
            e_911: {
              message: 'You canceled the current action. No user has been created.',
            },
            e_912: {
              message: 'Something went wrong while trying to update an asset. Please try again later.',
            },
            e_920: {
              message: 'You canceled the current action. No changes saved.',
            },
            e_921: {
              message: 'An unspecified error occured. Please try again later.',
            },
            e_922: {
              message: 'Your username and/or password is incorrect. Please try again.',
            },
            e_923: {
              message: 'Please specify an e-mail and a password.',
            },
            e_924: {
              message: 'Your session expired due to inactivity. Please login again.',
            },
            e_925: {
              message: 'You need to accept our privacy policy in order to use the platform.',
            },
            e_926: {
              message: 'Your account is temporarily locked. Please try again later.',
            },
            e_927: {
              message: 'Your password change request has been recorded successfully. Please check your e-mail account for further instructions.',
            },
            e_928: {
              message: 'The action was canceled. No file has been uploaded.',
            },
            e_929: {
              message: 'An error occurred while attempting to upload a file. Please try again at a later time.',
            },
            e_930: {
              message: 'Something went wrong while trying to delete the file. Please try again later.',
            },
            e_10500: {
              message: 'It seems that you are not connected to the internet. Please ensure you are connected.',
            },
            e_10501: {
              message: 'You are using an unsupported Browser. The CloudPlatform needs one of the following browsers: Google Chrome, Microsoft Edge, Apple Safari.',
            },
            e_409_asset: {
              message: 'This asset (machine identifier %replaceValue) already exists',
            },
            e_410_asset: {
              message: 'This file %replaceValue already exists',
            },
          },
        },
        list: {
          sweetalert: {
            maxAmountReached: {
              html: 'Only %s items can be selected at a time',
              title: 'Max. amount reached',
            },
          },
        },
      },
      module: {
        assets: {
          machine: {
            alarms: {
              no_alarms_text: 'No alarms available',
            },
            nc_comp_file_cardTitle: 'NC Comparison Files',
            nc_data_file_cardTitle: 'NC State Files',
            nc_state_files: {
              no_files_text: 'No files available.',
            },
            nc_comp_files: {
              column_title_trigger: 'Trigger',
              column_title_filename: 'File name',
              column_title_creation_date: 'Creation Date',
              modules: {
                assets: {
                  nc_comp_download: 'Download NC data',
                },
              },
            },
            nc_data_files: {
              column_title_trigger: 'Trigger',
              column_title_filename: 'File name',
              column_title_creation_date: 'Creation Date',
              modules: {
                assets: {
                  nc_data_download: 'Download NC data',
                },
              },
            },
          },
        },
      },
    },
  },
  es: {
    translation: {
      generic: {
        logout: 'Cerrar sesión',
        release: "El '%replaceValue1' desde'%replaceValue2' hasta '%replaceValue3' se realizarán trabajos de mantenimiento en la plataforma. Durante este periodo de tiempo la plataforma estará no disponible.",
        pageTitle: 'SW CloudPlatform',
        closeLabel: 'Cerrar',
        acceptLabel: 'Aceptar',
        userProfile: 'Perfil de usuario',
        declineLabel: 'Rechazar',
        backToDashboard: 'Volver al dashboard',
        footerLinks: {
          gtct: {
            url: 'https://sw-machines.com/es/condiciones-generales-normas/',
            title: 'Condiciones generales / Normas',
          },
          contact: {
            url: 'mailto:lifedata@sw-machines.com',
            title: 'Contacto',
          },
          imprint: {
            url: 'https://sw-machines.com/es/pie-de-imprenta/',
            title: 'Aviso legal',
          },
          privacy: {
            title: 'Privacidad',
            header: 'Política de protección de datos',
          },
          swHomepage: {
            url: 'https://www.sw-machines.com/',
            title: 'Página Web SW',
          },
        },
        noInternetConnection: 'Parece ser que no está conectado al Internet. Conéctese y vuélvalo a intentar.',
      },
      modules: {
        delete: {
          text: '¡Esta operación no se puede deshacer!',
        },
        error: {
          upload: 'Actualizaciones fallidas',
          message: {
            enrollment_code_409: 'El dispositivo ya está inscrito en AWS.',
          },
        },
        qs: {
          tabs: {
            list: 'Capturas de tiempo',
          },
          list: {
            report: {
              headline: 'Seleccionar variables',
            },
          },
          grid: {
            columns: {
              datetime: 'Fecha',
            },
          },
        },
        frameTitle: {
          qs: 'Garantía de calidad',
          users: 'Administración de usuario',
          assets: 'Administración de activos',
          settings: 'Ajustes',
          dashboard: 'Dashboard',
          userProfile: 'Perfil de usuario',
        },
        kpi: {
          tileTitle: 'KPI',
        },
        sidebar: {
          users: 'Administración de usuario',
          assets: 'Administración de activos',
          dashboard: 'Dashboard',
          userProfile: 'Perfil de usuario',
        },
        generic: {
          noData: 'Ningún dato',
          dateFormat: 'DD.MM.YYYY',
          noDuration: 'no hay duración disponible',
          timeFormat: 'HH:mm:ss',
          alarmPending: 'Alarmas activas',
          buttons: {
            back: 'Volver',
            save: 'Guardar',
            view: 'Mostrar',
            cancel: 'Cancelar',
            export: 'Límites de descarga',
            import: 'Inicio',
            submit: 'Aplicar',
            selectAllCheckboxes: 'Seleccionar todo',
            unselectAllCheckboxes: 'Cancelar selección',
          },
          notAvailable: 'No disponible',
          notConnected: 'Aun no está conectado',
          dateTimeFormat: 'DD.MM.YYYY HH:mm:ss',
          timeFormats: {
            day: 'Día',
            days: 'Días',
            hour: 'Hora',
            year: 'Año',
            hours: 'Horas',
            month: 'Mes',
            years: 'Años',
            minute: 'Minuto',
            months: 'Meses',
            second: 'Segundo',
            minutes: 'Minutos',
            seconds: 'Segundos',
          },
          notAvailableShort: 'n/a',
          timeFormatDuration: 'HH:mm:ss',
          timeFormatWithoutSeconds: 'HH:mm',
          sweetalert: {
            buttons: {
              understood: 'Entendido',
            },
          },
        },
        success: {
          upload: 'Actualizaciones exitosas',
          statusCodes: {
            s_0: {
              message: 'Se ha producido un error inesperado. Intente cargar la página de nuevo.',
            },
            s_1: {
              message: 'Las modificaciones se han guardado.',
            },
            s_930: {
              message: "El recurso '%replaceValue' se ha eliminado correctamente.",
            },
            s_931: {
              message: "La empresa '%replaceValue' se ha eliminado correctamente.",
            },
            s_932: {
              message: "La línea '%replaceValue' se ha eliminado correctamente.",
            },
            s_933: {
              message: "La nave '%replaceValue' se ha eliminado correctamente.",
            },
            s_934: {
              message: "La ubicación '%replaceValue' se ha eliminado correctamente.",
            },
            s_935: {
              message: "El archivo '%replaceValue' se ha borrado con éxito.",
            },
          },
          message: {
            duplication_deleted: '¡Se han borrado las tarjetas dobles!',
            add_line_to_dashboard: 'La línea ha sido agregada al dashboard',
          },
        },
        limit: {
          tabs: {
            list: 'Límites',
            type: 'Seleccione según tipo',
            machine: 'Seleccione según ID de máquina',
            listImport: 'Límites importación',
            listDownload: 'Límites descarga',
          },
          text: {
            text: 'Los valores límite son valores que puede prefijar para las variables que le interesan. Si una variable no se encuentra dentro de los valores límite definidos, recibirá una notificación. Los valores límite también se muestran en los diagramas que se generan en la pestaña «Series temporales».',
            title: 'Límites',
          },
          export: {
            text: 'Puede exportar los valores límite actualmente ajustados para todas las variables disponibles en la Plataforma en Nube en cualquier momento. Puede utilizar esta lista como referencia o utilizarla para importar todos los valores límite de una vez en la sección «Importar valores límite como CSV»',
            title: 'Exportar límites como CSV',
          },
          import: {
            text: 'Puede importar un archivo CSV previamente descargado con información sobre límites y abonados para actualizar los valores guardados actualmente. Más tarde puede seleccionar, a qué tipos de equipos o máquinas desea aplicar los valores límites',
            title: 'Importar valores limite desde CSV',
            machines: {
              title: 'Seleccione las máquinas o el tipo de máquina a los que desea aplicar los valores límites.',
            },
          },
          upload: {
            fields: {
              file: 'Seleccione el archivo CSV que desea importar',
            },
          },
        },
        manual: {
          line: {
            one: 'Vaya a la línea elegida en la administración de activos.',
            two: 'En la barra de encabezados encontrará un menú contextual.',
            four: 'El mosaico de la línea se agrega al dashboard.',
            three: 'Haga clic en el menú contextual y seleccione "Agregar al dashboard".',
            header: 'Agregar mosaicos de líneas',
          },
          individual: {
            one: 'Haga clic en el icono "engranaje" situado a la izquierda.',
            two: 'Seleccione el icono "positivo".',
            four: 'Confirme con "guardar". El mosaico individual se agrega al dashboard.',
            three: 'Asigne un nombre a su mosaico y agregue sus máquinas elegidas.',
            header: 'Agregar mosaicos individuales',
          },
        },
        users: {
          fab_title: 'Crear usuario nuevo',
          new: {
            title: 'Crear usuario',
            buttons: {
              add: 'Agregar',
              back: 'Volver a la lista',
              save: 'Guardar',
              cancel: 'Cancelar',
              delete: 'Borrar usuario',
            },
            fields: {
              email: 'E-Mail',
              roles: 'Grupo de usuarios',
              gender: 'Sexo',
              created: 'Creado',
              lastName: 'Apellido',
              firstName: 'Nombre de pila',
              breadcrumb: 'Mostrar navegación',
              main_group: 'Grupo de usuarios',
              phoneNumber: 'Número de teléfono [opcional]',
              customer_org: 'Organización de cliente',
              last_modified: 'Editado por',
              email_validation: 'E-Mail (validación)',
            },
            error_messages: {
              email_not_match: 'Las direcciones de E-mail no concuerdan.',
              email_not_valid: 'Introduzca una dirección de E-Mail válida.',
              roles_not_specified: 'Seleccione un grupo de usuarios.',
              gender_not_specified: 'Por favor Introduzca el sexo.',
              last_name_not_specified: 'Introduzca un apellido.',
              first_name_not_specified: 'Introduzca un nombre de pila.',
              main_group_not_specified: 'Seleccione un grupo de usuarios.',
              family_name_not_specified: 'Introduzca un apellido.',
              customer_organization_not_specified: 'Seleccione una organización de cliente.',
            },
          },
          update: {
            title: 'Actualizar usuario',
          },
          success: {
            title: '¡Con éxito!',
            button: 'Volver a la lista',
            message: 'El usuario se ha creado con éxito. Se ha enviado un E-mail con la contraseña generada automáticamente a la dirección de E-mail indicada.',
            message_for_edit: 'Se ha actualizado el usuario con éxito.',
            button_back_to_last_page: 'Volver a la última página',
          },
          delete: {
            success: 'Usuario borrado con éxito.',
            confirmation: {
              title: '¿Realmente desea borrar este usuario?',
              confirm: 'Sí, borrar este usuario',
              dismiss: 'No, cancelar',
            },
          },
          groups: {
            visitor: 'Visitante',
            customer: 'Cliente',
          },
          list: {
            grid: {
              user: 'Usuario',
              email: 'E-Mail',
              company: 'Empresa',
              enabled: 'Estado',
              lastName: 'Apellido',
              sw_admin: 'SW Admin',
              firstName: 'Nombre de pila',
              userRights: 'Estado de derechos',
              phoneNumber: 'Número de teléfono',
            },
          },
          fields: {
            gender: {
              male: 'masculino',
              female: 'femenino',
              diverse: 'diverso',
            },
          },
        },
        search: {
          noResults: 'No hay resultados',
          results: {
            of: 'de',
            lines: 'Líneas',
            machines: 'Máquinas',
          },
          callToAction: 'Buscar máquinas',
        },
        spistat: {
          tileTitle: 'SpiStat',
        },
        worldmap: {
          tileTitle: 'Mapa mundial',
        },
        shopfloor: {
          tileTitle: 'Shopfloor',
          upload: {
            text1: 'Envíe una imagen de la distribución de la shopfloor a lifedata@sw-machines.com (en formato JPG, PNG o PDF).',
            text2: 'Si sus máquinas están registradas e inscritas en SW CloudPlatform, se mostrarán a la izquierda y enviarán datos en tiempo real.',
            text3: 'Arrastre el estado del plan a la máquina correcta y suéltelo ahí.',
            mainText: 'Esta vista permite configurar la distribución de la shopfloor y ver las máquinas mediante distintas capas de información Solo tiene que seguir las instrucciones.',
            tileTitle: 'Carga de la shopfloor',
          },
        },
        asset: {
          delete: {
            success: 'El recurso se ha eliminado correctamente.',
            confirmation: {
              title: "¿Seguro que desea eliminar '%replaceValue'?",
              confirm: 'eliminar',
              dismiss: 'cancelar',
            },
          },
          new: {
            buttons: {
              delete: 'Eliminar recurso',
            },
          },
          updateLimits: {
            confirmation: {
              intro: '¿Desea aceptar los valores límite de este tipo de máquina nuevo?',
              title: 'Ha modificado el tipo de máquina',
              accept: 'Si, aceptar valores límites nuevos',
              decline: 'No, mantener los valores límites actuales',
              dismiss: 'Cancelar',
              info_accept: "Para sobrescribir los valores límite actuales con los valores límite del nuevo tipo de máquina, seleccione 'Sí'.",
              info_decline: "Para mantener los valores límite actuales, seleccione 'No'.",
              info_accept_warning: 'Todos los valores límites son sobrescritos o borrados.',
            },
          },
        },
        assets: {
          line: {
            tabs: {
              kpi: 'KPIs',
              list: 'Máquinas',
              shopfloor: 'Shopfloor',
            },
            fab_title: 'Agregar línea nueva',
            grid: {
              hall: 'Nave',
              line: 'Línea',
              type: 'Tipo',
              contractEnd: 'Fin de contrato [opcional]',
              cellPosition: 'Celda / posición [opcional]',
              operationStep: 'Paso del proceso',
              machineIdentifierSw: 'Nº de máquina (SW)',
              machineIdentifierCustomer: 'Nombre de máquina',
            },
            success: {
              title: '¡Con éxito!',
              button: 'Volver a la lista',
              message: 'La línea se ha creado con éxito.',
            },
            new_form_title: 'Línea nueva',
            edit_form_title: 'Editar línea',
            new: {
              fields: {
                name: 'Nombre de línea',
                name_short: 'Nombre de línea (abreviado) [opcional]',
                description: 'Descripción [opcional]',
              },
              error_messages: {
                name_not_specified: 'Introduzca un nombre de línea válido.',
              },
            },
            editSuccess: {
              title: '¡Con éxito!',
              button: 'Volver a la lista',
              message: 'La linea se ha actualizado con éxito.',
            },
          },
          hall: {
            fab_title: 'Agregar nave nueva',
            grid: {
              part: 'Tipo de piezas',
              lineName: 'Línea',
              typeOfLoading: 'Tipo de carga',
              lineResponsible: 'Responsable de líneas',
              plannedQuantity: 'Cantidad planificada',
              numberOfMachines: '# Máquinas',
            },
            tabs: {
              list: 'Líneas',
              shopfloor: 'Shopfloor',
            },
            success: {
              title: '¡Con éxito!',
              button: 'Volver a la lista',
              message: 'La nave se ha creado con éxito.',
            },
            new_form_title: 'Nave nueva',
            shopfloor: {
              edit: 'Modo de edición',
              configure: {
                save: {
                  fab_title: 'Guardar configuración',
                },
                discard: {
                  fab_title: 'Desechar configuración',
                },
              },
            },
            edit_form_title: 'Editar nave',
            new: {
              fields: {
                name: 'Nombre de nave',
                name_short: 'Nombre de nave (abreviado) [opcional]',
                description: 'Descripción [opcional]',
                description_external: 'Descripción (externo) [opcional]',
                description_internal: 'Descripción (interno) [opcional]',
              },
              error_messages: {
                name_not_specified: 'Introduzca un nombre de nave válido.',
              },
            },
            editSuccess: {
              title: '¡Con éxito!',
              button: 'Volver a la lista',
              message: 'La nave se ha actualizado con éxito.',
            },
          },
          grid: {
            description: 'Descripción [opcional]',
          },
          company: {
            fab_title: 'Agregar una empresa nueva',
            grid: {
              city: 'Localidad',
              email: 'E-Mail',
              street: 'Dirección',
              country: 'País',
              website: 'Página web',
              postalCode: 'Código postal',
              locationName: 'Ubicación',
            },
            tabs: {
              list: 'Ubicaciones',
              worldmap: 'Mapa mundial',
            },
            success: {
              title: '¡Con éxito!',
              button: 'Volver a la lista',
              message: 'Se ha creado la empresa con éxito.',
            },
            toggle_switch: 'Generar una ubicación con los mismos datos',
            new_form_title: 'Empresa nueva',
            edit_form_title: 'Editar empresa',
            new: {
              fields: {
                city: 'Ciudad',
                name: 'Nombre de la empresa',
                email: 'E-Mail [opcional]',
                state: 'Provincia [opcional]',
                street: 'Dirección',
                country: 'País',
                website: 'Página web [opcional]',
                zipcode: 'Código postal',
                legal_form: 'Forma jurídica [opcional]',
                name_short: 'Nombre de la empresa (corto) [opcional]',
                description: 'Descripción [opcional]',
                company_logo: 'Logotipo de la empresa (URL) [opcional]',
                customer_number: 'Número de cliente',
                industry_sector: 'Sector industrial',
                description_external: 'Descripción (externo) [opcional]',
                description_internal: 'Descripción (interno) [opcional]',
              },
              error_messages: {
                url: 'Introduzca una URL válida.',
                email: 'Introduzca una dirección de E-Mail válida.',
                city_not_specified: 'Introduzca una ciudad.',
                name_not_specified: 'Introduzca un nombre de empresa.',
                street_not_specified: 'Introduzca una dirección.',
                country_not_specified: 'Introduzca una País.',
                zipcode_not_specified: 'Introduzca un código postal.',
                customer_number_not_specified: 'Introduzca un número de cliente.',
                industry_sector_not_specified: 'Introduzca un sector industrial.',
              },
            },
            editSuccess: {
              title: '¡Con éxito!',
              button: 'Volver a la lista',
              message: 'Se ha actualizado la empresa con éxito.',
            },
            header: {
              labels: {
                email: 'E-Mail',
                address: 'Dirección',
                country: 'País',
                website: 'Página web',
              },
            },
            coordinateselement: {
              intro_text: 'La SW CloudPlatform muestra esta empresa en un mapa utilizando para ello la latitud y la longitud.',
              intro_heading: 'Informaciones sobre la ubicación',
              location_name: 'Nombre de la ubicación:',
              label_latitude: 'Latitud',
              label_longitude: 'Longitud',
              text_fallback_info: 'En caso contrario se usan las coordenadas estándar.',
              text_location_found: 'La ubicación se ha localizado.',
              text_location_entered: 'La ubicación se ha especificado.',
              text_success_location: 'Se establecen los datos siguiente:',
              button_define_coordinates: 'Introduzca las coordenadas usted mismo',
              text_no_coordinates_found: 'No se han encontrado coordenadas para los datos introducidos.',
              text_coordinates_success_set: 'Coordenadas establecidas.',
              button_set_custom_coordinates: 'Establecer coordenadas',
              text_specify_coordinates_yourself: 'Alternativamente puede introducir las coordenadas usted mismo.',
            },
          },
          machine: {
            fab_title: 'Crear recurso nuevo',
            tabs: {
              info: 'Series temporales',
              alarms: 'Alarmas',
              gateway: 'Gateway',
              service: 'Asistencia técnica',
              spistat: 'SpiStat',
              swimlane: 'Disponibilidad de línea',
              'axis-test': 'Pruebas de eje',
              collection: 'Colecciones',
              maintenance: 'Mantenimiento',
              performance: 'Rendimiento',
              spindledata: 'Datos de husillo',
              administration: 'Administración',
              energymonitoring: 'Monitorización de la energía',
            },
            move: {
              message: "El activo '%replaceValue' se puede mover a una empresa, ubicación, nave y/o línea nuevas.",
              select_hall_label: 'Seleccionar nave nueva',
              select_line_label: 'Seleccionar línea nueva',
              select_company_label: 'Seleccionar empresa nueva',
              select_location_label: 'Seleccionar ubicación nueva',
            },
            new: {
              fields: {
                s3: 'S3',
                name: 'Número de máquina (SW)',
                iotcore: 'IoT Core',
                startup: 'Suministro [opcional]',
                shipping: 'Expedición [opcional]',
                controller: 'Control',
                machine_type: 'Modelo de máquina',
                warranty_end: 'Fin de garantía [opcional]',
                iotgreengrass: 'IoT Greengrass',
                systemsManager: 'Administrador de sistemas',
                warranty_start: 'Inicio de la garantía [opcional]',
                machine_swmp_api: 'Máquinas SWMP fin de API [opcional]',
                machine_swsp_trace: 'Máquinas SWSP fin de trace [opcional]',
                machine_swcp_portal: 'Máquinas SWCP fin de portal [opcional]',
                start_of_production: 'Inicio de la producción [opcional]',
                machine_swcp_support: 'Máquinas SWCP fin de soporte [opcional]',
                machine_cell_position: 'Celda /posición máquinas',
                machine_swsp_line_mon: 'Máquinas SWSP fin LineMon [opcional]',
                machine_identifier_customer: 'ID máquina (cliente)',
              },
              error_messages: {
                name_not_specified: 'Introduzca un número de máquina SW válido (mín. 7 caracteres: A-Z, 0-9, -).',
                controller_not_specified: 'Seleccione un control de máquina.',
                machine_type_not_specified: 'Seleccione un tipo de máquina.',
                machine_identifier_customer_not_specified: 'Introduzca una ID de máquina.',
              },
            },
            success: {
              title: '¡Con éxito!',
              button: 'Volver a la lista',
              message: 'El recurso se ha creado con éxito.',
            },
            new_form_title: 'Agregar recurso',
            edit_form_title: 'Editar recurso',
            move_form_title: 'Mover activo',
            editSuccess: {
              title: '¡Con éxito!',
              button: 'Volver a la lista',
              message: 'El activo se ha actualizado con éxito.',
            },
            header: {
              labels: {
                api: 'SWMP API',
                trace: 'SWSP Trace',
                portal: 'SWCP Portal',
                lineMon: 'SWSP LineMon',
                support: 'SWCP Support',
                shipping: 'Fecha de expedición',
                controller: 'Control',
                contractEnd: 'Fin de contrato',
                machineType: 'Tipo de máquina',
                startupDate: 'Fecha de puesta en funcionamiento',
                warrantyEnd: 'Fin de la garantía',
                cellPosition: 'Celda / posición',
                warrantyStart: 'Inicio de la garantía',
                customerNumber: 'Número de cliente',
                enrollmentConfig: 'Configuración enrollment',
                startOfProduction: 'Inicio de la producción',
                machineIdentifierSw: 'Nº de máquina SW',
                enrollmentConfigDownload: 'Descargar',
                machineIdentifierCustomer: 'Nombre de máquina cliente',
              },
            },
            add_line_to_dashboard: 'Agregar línea al dashboard',
            elements: {
              'axis-test': {
                kpi: 'KPI',
                unit: 'Unidad',
                value: 'Valor',
                kpiUnit: 'Unidad',
                headline: 'Pruebas de eje',
                kpiValue: 'Valor',
                kpiHeader: 'KPI',
                chartTitle: 'Prueba de ejes',
                limitsLoad: 'Cargar desde la memoria del navegador',
                limitsSave: 'Guardar en la memoria del navegador',
                selectAxis: 'Seleccionar eje',
                limitsExport: 'Exportar valores límite a la memoria intermedia',
                openAxistest: 'Abrir prueba de eje',
                listbox: {
                  label: 'Tipo / Eje(s)',
                  noContentMessage: 'Seleccionar tipo de prueba de ejes',
                  noAxisTestAvailable: 'No hay ninguna prueba de ejes de este tipo disponible',
                },
                axisTestPlural: 'Pruebas de eje',
                noChartMessage: 'Seleccione una colección en el lado izquierdo.',
                adjustments: {
                  add: 'añadir adaptación local',
                  range: 'Alcance',
                  header: 'Adaptación local',
                  remove: 'Retirar',
                  position: 'Posición',
                  amplitude: 'Amplitud',
                  spreadMultiplier: 'Multiplicador de límites',
                },
                chart: {
                  Equability: {
                    x: 'Posición',
                    y: 'Corriente',
                    up: 'arriba',
                    down: 'abajo',
                    limitsUp: 'limita el sentido negativo',
                    limitsDown: 'limita el sentido negativo',
                    sliderSlopeCenter: 'Origen de pendiente',
                    sliderSlopeFactor: 'Factor de pendiente',
                    sliderVerticalOffset: 'Decalaje Y',
                    sliderVerticalSpread: 'Limitar anchura',
                    sliderQuadraticCenter: 'Origen cuadrado',
                    sliderQuadraticFactor: 'Factor cuadrado',
                  },
                  Circularity: {
                    clockwise: 'En sentido horario',
                    limitsClockwise: 'Límites en sentido horario',
                    counterClockwise: 'En sentido antihorario',
                    sliderSineOffset: 'Decalaje de ondas',
                    sliderSineAmplitude: 'Amplitud de ondas',
                    sliderSineFrequency: 'Frecuencia de ondas',
                    sliderVerticalOffset: 'Decalaje Y',
                    sliderVerticalSpread: 'Limitar anchura',
                    limitsCounterClockwise: 'Límites en sentido antihorario',
                  },
                },
                kpis: {
                  pulseVelocity: 'Velocidad',
                  pulseMaxRampUp: 'Rampa ->',
                  pulseMaxRampDown: 'Rampa <-',
                  pulseMaxStiffness: 'Rigidez máxima',
                  pulseMinStiffness: 'Rigidez mínima',
                  pulseMeanStiffness: 'Rigidez Ø',
                  circularReversalRange: 'Reversibilidad circular',
                  pulseMaxUpPathCurrent: 'Corriente máxima ->',
                  pulseMinUpPathCurrent: 'Corriente mínima ->',
                  pulseMeanUpPathCurrent: 'Corriente Ø ->',
                  pulseMaxDownPathCurrent: 'Corriente máxima <-',
                  pulseMinDownPathCurrent: 'Corriente mínima <-',
                  pulseMeanDownPathCurrent: 'Corriente Ø <-',
                  circularShapeDeviationClockwise: 'Error de circularidad a la derecha',
                  pulseStiffnessStandardDeviation: 'Rigidez σ',
                  pulseStandardDeviationUpPathCurrent: 'Corriente σ ->',
                  pulseStandardDeviationDownPathCurrent: 'Corriente σ <-',
                  circularShapeDeviationCounterClockwise: 'Error de circularidad a la izquierda',
                },
                axisLabels: {
                  CIRCLE: {
                    x: 'Posición',
                    y: 'Posición',
                  },
                  EQUABILITY: {
                    x: 'Posición',
                    y: 'Corriente',
                  },
                },
                axisTestTypes: {
                  CIRCLE: 'Prueba de circularidad',
                  EQUABILITY: 'Prueba de sincronicidad',
                  CIRCULARITY: 'Prueba de circularidad',
                },
              },
              gateway: {
                title: 'Gateway',
                contact: 'En caso de error, póngase en contacto con',
                headline: 'Gateway',
                netcfg: {
                  cta: 'Proporciónenos los datos a través del formulario Netcfg.',
                  intro: 'Para poder enlazar su máquina, dependemos de los datos de su configuración de red.',
                  title: 'Configuración de red',
                  goToNetconfig: 'Abrir configuración de red',
                },
                device: {
                  title: 'Detalles del equipo',
                },
                enrollment: {
                  line1: 'Descargue el archivo Enrollment haciendo clic con el botón en el borde inferior del mosaico.',
                  line2: 'La descarga se inicia automáticamente.',
                  line3: 'Coloque el archivo .json en el directorio raíz en una memoria USB. Asegúrese de que el archivo tenga un nombre:',
                  line4: 'enrollment.json.',
                  line5: 'Asegúrese de que los archivos siguientes se encuentren en el directorio raíz de la memoria USB:',
                  line6: 'Inserte la memoria USB en el IoT Gateway correspondiente.',
                  line8: 'Después de iniciar sesión con éxito, se ilumina el LED verde hasta que se retire la memoria USB.',
                  title: 'Enrollment',
                  button: 'Archivo enrollment',
                },
                deviceMigration: {
                  line1: 'Descargue el archivo "migrate-iot.zip" y extraiga su contenido directamente en una memoria USB vacía.',
                  line2: 'aws.json',
                  line3: 'Descargue también ',
                  line4: ' y lo agrega a una memoria USB. Asegúrese de que el archivo tenga el nombre siguiente:',
                  line5: 'Asegúrese de que los archivos siguientes se encuentren en el directorio raíz de la memoria USB:',
                  line6: 'install.json',
                  line7: 'migration.tar.gz',
                  line8: 'migration.tar.gz.sig',
                  line9: 'migration.json',
                  title: 'Migración de plataforma',
                  line10: 'Inserte la memoria USB en el IoT Gateway correspondiente.',
                  line11: 'El software se instala automáticamente, lo que se señaliza mediante un LED amarillo intermitente.',
                  line12: 'Después de una migración con éxito, se ilumina el LED verde hasta que se retire la memoria USB.',
                  line13: 'Después de una migración con éxito, se ilumina el LED verde hasta que se retire la memoria USB.',
                  device: {
                    button: 'migrate-iot.zip',
                  },
                  generic: {
                    button: 'aws.json',
                  },
                },
              },
              info: {
                generate: 'Generar',
                headline: 'Series temporales',
                noChartMessage: 'Para mostrar los datos elegidos, seleccione una o varias variantes y haga clic en generar',
              },
              alarms: {
                headline: 'Alarmas',
              },
              service: {
                headline: 'Asistencia técnica',
                update: {
                  title: 'Actualizar archivo',
                },
                upload: {
                  title: 'Cargar el archivo',
                  fields: {
                    file: 'Archivo',
                    language: 'Idioma del archivo',
                    file_name: 'Nombre de archivo',
                    list_of_languages: {
                      cz: 'Checo',
                      de: 'Alemán',
                      en: 'Inglés',
                      es: 'Español',
                      fr: 'Francés',
                      hu: 'Húngaro',
                      it: 'Italiano',
                      pl: 'Polaco',
                      zh: 'Chino',
                    },
                  },
                  buttons: {
                    send: 'Cargar',
                    cancel: 'Cancelar',
                  },
                  success: {
                    title: 'Correcto',
                    button: 'Volver a la visión general de servicio',
                    message: 'El archivo se ha cargado correctamente',
                  },
                  error_messages: {
                    file_name: 'Introduzca un nombre de archivo.',
                    file_not_valid: 'Indique un archivo/seleccione un archivo más pequeño.',
                    language_not_specified: 'Indique un idioma de archivo.',
                  },
                },
                manuals: {
                  title: 'Manuales',
                },
                version: {
                  title: 'archivos de versión',
                },
                contracts: {
                  title: 'Contratos',
                },
                components: {
                  title: 'Componentes Hard y Software',
                },
                spare_parts: {
                  title: 'Lista de piezas de repuesto',
                },
                consumeables: {
                  title: 'Materiales de producción',
                },
                Update: {
                  buttons: {
                    send: 'Actualizar',
                  },
                },
                software_edge: {
                  title: 'Software IoT Gateway',
                  values: {
                    box_id: 'ID Box',
                    machine_connect: 'Machine Connect',
                    operating_system: 'Sistema operativo',
                  },
                },
                contact_service: {
                  line1: '¿Necesita ayuda con una máquina?',
                  line2: '¡Contáctenos!',
                  title: 'Mensaje al servicio SW',
                  button: 'Contactar con el servicio',
                  fields: {
                    name: 'Número de máquina (SW)',
                    type: 'Tipo de petición',
                    email: 'E-Mail',
                    message: 'Mensaje',
                    phone_no: 'Número de teléfono',
                    last_name: 'Apellido',
                    first_name: 'Nombre de pila',
                    contact_person: 'Persona de contacto (SW) [opcional]',
                    list_of_types: {
                      request: 'Petición general',
                      service: 'Asistencia técnica',
                      retrofit: 'Retrofit',
                      expansion: 'Ampliación',
                      inspection: 'Inspección',
                    },
                    machine_identifier_customer: 'ID máquina (cliente) [opcional]',
                  },
                  buttons: {
                    send: 'Enviar petición',
                    cancel: 'Cancelar',
                  },
                  success: {
                    title: '¡Con éxito!',
                    button: 'Volver a la visión global del servicio',
                    message: 'La petición se ha transmitido al servicio SW con éxito.',
                  },
                  error_messages: {
                    email_not_valid: 'Introduzca una dirección de E-Mail válida.',
                    name_not_specified: 'Introduzca un número de máquina SW válido (mín. 7 caracteres: A-Z, 0-9, - _).',
                    phone_not_specified: 'Introduzca número de teléfono válido.',
                    message_not_specified: 'Introduzca un mensaje.',
                    last_name_not_specified: 'Introduzca un apellido.',
                    first_name_not_specified: 'Introduzca un nombre de pila.',
                    family_name_not_specified: 'Introduzca un apellido.',
                    contact_type_not_specified: 'Introduzca un tipo de petición.',
                  },
                },
                software_machine: {
                  title: 'Software máquina',
                  values: {
                    nc_version: 'Versión NC',
                    hmi_version: 'Versión HMI',
                    plc_version: 'Versión PLC',
                    compile_cycles: 'Ciclos compilar',
                    operating_system: 'Sistema operativo',
                    pcu_base_version: 'Versión base PCU',
                    plc_toolbox_version: 'Toolbox PLC',
                    plc_user_program_version: 'Programa usuario PLC',
                  },
                },
                changed: {
                  success: {
                    message: 'El nombre del archivo se ha modificado con éxito',
                  },
                },
                maintenance_assistance: {
                  intro: 'El asistente de acciones le ayuda en la solución de problemas.',
                  title: 'Asistente de acciones',
                  sub_title: '¿Tiene actualmente dificultades con uno de sus activos?',
                  open_assistant: 'Abrir el asistente de acciones',
                  request_account: 'Solicitar una cuenta',
                },
              },
              spistat: {
                headline: 'Estadísticas de husillo SpiStat',
              },
              onlineState: {
                title: 'IoT Gateway',
                labelOnline: 'Con',
                labelOffline: 'Desc',
              },
              swimlane: {
                headline: 'Disponibilidad de línea',
              },
              sendingState: {
                title: 'Estado de envío',
                aborted: 'cancelado',
                sending: 'enviando',
                interrupted: 'interrumpido',
              },
              alarms_map: {
                headline: 'Mapa de alarma',
              },
              collection: {
                headline: 'Colección',
                noChartMessage: 'Seleccione una colección en el lado izquierdo.',
              },
              performance: {
                kpi: {
                  sum: 'Suma',
                  header: 'Rendimiento Kpi',
                  target: 'Nominal',
                },
                alarms: {
                  title: 'Alarmas',
                  category: [
                    'Sin clasificar',
                    'Mensaje de error',
                    'Mensaje de servicio',
                    'Mensaje de estado',
                  ],
                  icons: {
                    trend: 'Tendencia',
                    duration: 'Duración de incidencia',
                    flatlist: 'Lista plana',
                    frequency: 'Frecuencia de incidencia',
                  },
                  tableHeadlines: {
                    time: 'Tiempo',
                    trend: 'Tendencia',
                    duration: 'Duración de incidencia',
                    leftTime: 'Alarma saliente',
                    alarmCount: 'Número',
                    alarmNumber: 'Nº de alarma',
                    description: 'Descripción',
                    alarmOccured: 'Se ha generado una alarma',
                    alarmClassification: 'Clasificación de alarma',
                    alarmCountSecondRange: 'Número T-1',
                  },
                  description: {
                    trend: {
                      up: 'ascendente',
                      down: 'descendente',
                      flat: 'estable',
                    },
                  },
                },
                details: {
                  title: 'Detalle',
                  ncProgram: 'Programas NC',
                  lastModified: 'Registro de valor último',
                  machineState: 'Estado de máquina',
                  numberAlarms: 'Alarmas activas',
                  stopCondition: 'Condiciones de parada',
                  ncProgramState: 'Estado de programa NC',
                  protectionLevel: 'Nivel de protección',
                  ncProgramStates: [
                    'cancelado',
                    'detenido',
                    'en marcha',
                    'esperando',
                    'interrumpido',
                  ],
                  protectionLevels: [
                    'Sistema',
                    'Fabricante',
                    'Asistencia técnica',
                    'Usuario',
                    'Interruptor con llave posición 3',
                    'Interruptor con llave posición 2',
                    'Interruptor con llave posición 1',
                    'Interruptor con llave posición 0',
                  ],
                },
                cycleTime: {
                  title: 'Tiempo de ciclo',
                  chart: {
                    yAxis: {
                      title: 'Tiempo de ciclo en s',
                    },
                  },
                },
                piececounter: {
                  ok: 'OK',
                  nok: 'No OK',
                  sum: 'Suma',
                  title: 'Contador de piezas',
                  titleDayCounter: 'Contador diario',
                  titleShiftCounter: 'Contador de turno',
                  titleTotalCounter: 'Contador total',
                  titleTotalCounterSmall: 'Total',
                },
                availability: {
                  title: 'Disponibilidad',
                  kpi: {
                    title: 'Disponibilidad KPI',
                  },
                  legend: {
                    auto: 'AUTO',
                    JOG_with_error: 'JOG con error',
                    MDA_with_error: 'MDA con error',
                    JOG_without_error: 'JOG sin error',
                    MDA_without_error: 'MDA sin error',
                    not_defined_condition: 'Estado no definido',
                    auto_no_program_active: 'AUTO no hay ningún programa activo',
                    auto_waiting_for_parts: 'AUTO esperando piezas',
                    auto_with_error_program_stopped: 'AUTO con error - programa detenido',
                    auto_waiting_for_parts_stopping_error: 'AUTO esperando piezas + error de parada',
                  },
                  aggregated: {
                    title: 'Disponibilidad agregada',
                  },
                  chart: {
                    yAxis: {
                      title: 'Disponibilidad',
                    },
                  },
                },
                inProduction: {
                  title: 'Estado',
                },
                operationMode: {
                  title: 'Estado de servicio',
                  chart: {
                    yAxis: {
                      title: 'Estado de servicio',
                    },
                  },
                },
                runtimeMonitor: {
                  title: 'Monitor de la duración',
                  chart: {
                    yAxis: {
                      title: 'Programa',
                      labels: {
                        on: 'con',
                        off: 'desc',
                      },
                    },
                  },
                },
                spindleOverride: {
                  title: 'Cancelación de husillo',
                },
                feedrateOverride: {
                  title: 'Cancelación de avance',
                },
                uptimeDistribution: {
                  title: 'Uptime Distribution',
                },
              },
              administration: {
                title: 'Administración',
                headline: 'Administración',
                aws: {
                  title: 'Enlaces de AWS',
                },
                references: {
                  values: {
                    s3: 'S3',
                    iot: 'IoT Core',
                    ssm: 'Administrador de sistemas',
                    greengrass: 'IoT Greengrass',
                  },
                },
              },
              condition: {
                bill: {
                  title: 'Lista de piezas',
                },
                component: {
                  title: 'Componentes',
                },
              },
              maintenance: {
                headline: 'Mantenimiento',
              },
              spindleData: {
                headline: 'Datos de husillo',
              },
              point8: {
                chart: {
                  xAxis: {
                    title: 'Tiempo previo alarma (s)',
                  },
                  yAxis: {
                    title: 'Tiempo después de alarma (s)',
                  },
                },
              },
              energymonitoring: {
                headline: 'Monitorización de energía',
                chartTitles: {
                  air: 'Consumo de aire comprimido',
                  current: 'Consumo de corriente',
                  consumption: 'Consumo medio por hora',
                },
              },
              conditionMonitoring: {
                headline: 'Vigilancia de estado',
              },
            },
          },
          location: {
            fab_title: 'Agregar ubicación nueva',
            tabs: {
              list: 'Naves',
              shopfloor: 'Shopfloor',
            },
            grid: {
              hallName: 'Nave',
            },
            success: {
              title: '¡Con éxito!',
              button: 'Volver a la lista',
              message: 'La ubicación se ha creado con éxito.',
            },
            new_form_title: 'Ubicación nueva',
            edit_form_title: 'Editar ubicación',
            new: {
              fields: {
                city: 'Ciudad',
                name: 'Nombre de sitio',
                email: 'E-Mail [opcional]',
                state: 'Provincia [opcional]',
                street: 'Dirección',
                country: 'País',
                website: 'Página web [opcional]',
                zipcode: 'Código postal',
                language: 'Idioma [opcional]',
                name_short: 'Nombre de sitio (abreviado) [opcional]',
                description: 'Descripción [opcional]',
                description_internal: 'Descripción (interno) [opcional]',
              },
              error_messages: {
                email: 'Introduzca una dirección de E-Mail válida.',
                city_not_specified: 'Introduzca una ciudad.',
                name_not_specified: 'Introduzca un nombre de sitio.',
                street_not_specified: 'Introduzca una dirección.',
                country_not_specified: 'Introduzca una País.',
                zipcode_not_specified: 'Introduzca un código postal.',
              },
            },
            editSuccess: {
              title: '¡Con éxito!',
              button: 'Volver a la lista',
              message: 'La ubicación se ha actualizado con éxito.',
            },
            header: {
              labels: {
                address: 'Dirección',
                country: 'País',
              },
            },
          },
          companies: {
            grid: {
              city: 'Localidad',
              email: 'E-Mail',
              street: 'Dirección',
              country: 'País',
              website: 'Página web',
              postalCode: 'Código postal',
              companyName: 'Empresa',
            },
            tabs: {
              list: 'Empresa',
              worldmap: 'Mapa mundial',
            },
            map: {
              description_not_available: 'No hay disponible ninguna descripción',
            },
          },
          generic: {
            new: {
              buttons: {
                add: 'Agregar',
                back: 'Volver a la lista',
                edit: 'Actualizar',
                save: 'Guardar',
                email: 'Enviar imagen',
                cancel: 'Cancelar',
                addMultiple: 'Añadir + Nuevo',
              },
            },
          },
          administration: {
            edit_form_title: 'Editar administración',
          },
        },
        settings: {
          tabs: {
            limits: 'Limites',
          },
          button: {
            import: 'Iniciar importación',
            dismiss: 'Cancelar',
            download: 'Iniciar descarga',
          },
        },
        file: {
          new: {
            buttons: {
              delete: 'Borrar archivo',
            },
          },
        },
        hall: {
          new: {
            buttons: {
              delete: 'Eliminar nave',
            },
          },
        },
        line: {
          new: {
            buttons: {
              delete: 'Eliminar línea',
            },
          },
        },
        runtimeStatus: {
          tileTitle: 'Estado',
        },
        charts: {
          tooltipDateFormat: 'DD.MM.YYYY HH:mm:ss',
          exporting: {
            datetimeformat: '%d.%m.%Y %H:%M:%S',
          },
          globalSettings: {
            lang: {
              noData: 'No hay datos disponibles',
              loading: 'Cargando datos...',
              months: [
                'Enero',
                'Febrero',
                'Marzo',
                'Abril',
                'Mayo',
                'Junio',
                'Julio',
                'Agosto',
                'Septiembre',
                'Octubre',
                'Noviembre',
                'Diciembre',
              ],
              resetZoom: 'Restablecer zoom',
              weekdays: [
                'Domingo',
                'Lunes',
                'Martes',
                'Miércoles',
                'Jueves',
                'Viernes',
                'Sábado',
              ],
              shortMonths: [
                'Ene',
                'Feb',
                'Mar',
                'Abr',
                'May',
                'Jun',
                'Jul',
                'Ago',
                'Sep',
                'Oct',
                'Nov',
                'Dic',
              ],
              shortWeekdays: [
                'Do',
                'Lu',
                'Ma',
                'Mi',
                'Ju',
                'Vi',
                'Sa',
              ],
              increaseDateRange: 'al menos hasta',
            },
          },
        },
        datepicker: {
          presets: {
            title: 'Preajustes',
            rangeTo: 'Hasta',
            selectDateRange: 'Seleccione un periodo de tiempo',
            predefined: {
              today: 'Hoy',
              last7days: 'Últimos 7 días',
              yesterday: 'Ayer',
              last24Hours: 'Últimas 24 horas',
              last5Minutes: 'Últimos 5 minutos',
            },
          },
        },
        i18n: {
          chooseLanguagePhrase: 'Seleccione idioma',
        },
        company: {
          new: {
            buttons: {
              delete: 'Eliminar empresa',
            },
          },
        },
        location: {
          new: {
            buttons: {
              delete: 'Eliminar ubicación',
            },
          },
        },
        runtimeStatusList: {
          tileTitle: 'Estado',
        },
        dashboard: {
          configure: {
            fab_title: 'Configurar Dashboard',
            hide: {
              title: 'desechar',
            },
            show: {
              title: 'mostrar',
            },
            delete: {
              title: 'borrar',
            },
            add: {
              fab_title: 'Agregar su propio cuadro',
            },
            save: {
              fab_title: 'Guardar configuración',
            },
            move_left: {
              title: 'Deslizar a la posición anterior',
            },
            move_right: {
              title: 'Deslizar a la posición siguiente',
            },
            discard: {
              fab_title: 'Desechar configuración',
            },
            add_custom_card: {
              save: 'guardar',
              input: 'Indicar nombre de cuadro',
              title: 'Agregar su propio cuadro',
              cancel: 'cancelar',
              missingMachine: 'Deberá seleccionar una máquina como mínimo',
            },
          },
        },
        errors: {
          statusCodes: {
            e_0: {
              message: 'Se ha producido un error inesperado. Intente cargar la página de nuevo.',
            },
            e_1xx: {
              message: 'Error informacional',
            },
            e_2xx: {
              message: 'Éxito',
            },
            e_3xx: {
              message: 'Reenvío',
            },
            e_400: {
              message: 'La petición ha sido errónea y no se ha podido procesar.',
            },
            e_401: {
              message: 'No está autenticado. Vuelva a iniciar sesión.',
            },
            e_403: {
              message: 'No dispone del permiso suficiente para acceder a los recursos solicitados.',
            },
            e_404: {
              message: 'No está disponible el recurso solicitado.',
            },
            e_405: {
              message: 'Método no permitido.',
            },
            e_409: {
              message: 'El recurso ya existe.',
            },
            e_418: {
              message: "I'm a Teapot! Yaay!",
            },
            e_4xx: {
              message: 'Error cliente',
            },
            e_500: {
              message: 'Se ha producido un error interno de servidor.',
            },
            e_5xx: {
              message: 'Error servidor',
            },
            e_900: {
              message: 'El intento de crear un recurso ha fallado. Vuelva a intentarlo más tarde.',
            },
            e_901: {
              message: 'La acción se ha interrumpido. No se ha creado un recurso nuevo.',
            },
            e_902: {
              message: 'La acción se ha interrumpido. No se ha enviado ninguna petición de asistencia.',
            },
            e_903: {
              message: 'Al intentar enviar la petición de asistencia se ha producido un fallo. Vuelva a intentarlo más tarde.',
            },
            e_904: {
              message: "El activo '%replaceValue' se ha creado con éxito. Ahora puede agregar mas activos.",
            },
            e_905: {
              message: 'La acción se ha interrumpido. El activo no se ha actualizado.',
            },
            e_906: {
              message: "El recurso '%replaceValue' se ha movido con éxito.",
            },
            e_910: {
              message: 'El intento de crear un usuario ha fallado. Vuelva a intentarlo más tarde.',
            },
            e_911: {
              message: 'La acción se ha interrumpido. No se ha creado ningún usuario nuevo.',
            },
            e_912: {
              message: 'Se ha producido un error al intentar actualizar un recurso. Inténtelo de nuevo.',
            },
            e_920: {
              message: 'La acción se ha interrumpido. No se ha guardado ninguna modificación.',
            },
            e_921: {
              message: 'Se ha producido un error inesperado. Inténtelo de nuevo.',
            },
            e_922: {
              message: 'El nombre de usuario y/o la contraseña no son correctos. Inténtelo de nuevo.',
            },
            e_923: {
              message: 'Introduzca un nombre de usuario y una contraseña.',
            },
            e_924: {
              message: 'La sesión ha expirado por falta de actividad. Vuelva a iniciar sesión.',
            },
            e_925: {
              message: 'Para poder utilizar SW Cloud Platform debe aceptar nuestra declaración de protección de datos.',
            },
            e_926: {
              message: 'Su cuenta se ha bloqueado de forma temporal. Inténtelo de nuevo dentro de unos minutos.',
            },
            e_927: {
              message: 'La contraseña se ha cambiado correctamente.',
            },
            e_928: {
              message: 'La acción se ha interrumpido. No se ha cargado ningún archivo.',
            },
            e_929: {
              message: 'Se ha producido un error al intentar cargar un archivo. Inténtelo de nuevo más tarde.',
            },
            e_930: {
              message: 'Se ha producido un error al intentar borrar un archivo. Vuelva a intentarlo más tarde.',
            },
            e_10500: {
              message: 'Parece que no tiene conexión a Internet. Compruebe que disponga de conexión.',
            },
            e_10501: {
              message: 'El navegador que utiliza no es compatible. Utilice uno de estos navegadores: Google Chrome, Microsoft Edge, Apple Safari.',
            },
            e_409_asset: {
              message: 'El Asset (SW número de máquina %replaceValue) ya existe.',
            },
            e_410_asset: {
              message: 'Este nombre de archivo %replaceValue ya existe',
            },
          },
        },
        list: {
          sweetalert: {
            maxAmountReached: {
              html: 'Solo se pueden seleccionar %s elementos al mismo tiempo',
              title: 'Numero máx. alcanzado',
            },
          },
        },
      },
      module: {
        assets: {
          machine: {
            alarms: {
              no_alarms_text: 'Alarmas no disponible',
            },
            nc_data_file_cardTitle: 'Archivos de estado CN',
            nc_state_files: {
              no_files_text: 'Archivos no disponible.',
            },
            nc_data_files: {
              column_title_trigger: 'Trigger',
              column_title_filename: 'Nombre de archivo',
              column_title_creation_date: 'Fecha de creación',
              modules: {
                assets: {
                  nc_data_download: 'Descarga de dados CN',
                },
              },
            },
          },
        },
      },
    },
  },
  fr: {
    translation: {
      generic: {
        logout: 'Se déconnecter',
        release: "Des opérations de maintenance seront réalisées surla plateforme le '%replaceValue1' de '%replaceValue2' à '%replaceValue3'. La plateforme ne sera pas disponible pendant cette période.",
        pageTitle: 'SW CloudPlatform',
        closeLabel: 'Fermer',
        acceptLabel: 'Accepter',
        userProfile: 'Profil d’utilisateur',
        declineLabel: 'Refuser',
        backToDashboard: 'Retour au dashboard',
        footerLinks: {
          gtct: {
            url: 'https://sw-machines.com/fr/conditions-generales-de-vente/',
            title: 'CONDITIONS GÉNÉRALES DE VENTE',
          },
          contact: {
            url: 'mailto:lifedata@sw-machines.com',
            title: 'Contact',
          },
          imprint: {
            url: 'https://sw-machines.com/fr/mentions-legales/',
            title: 'Mentions légales',
          },
          privacy: {
            title: 'Sphère privée',
            header: 'Dispositions relatives à la protection des données',
          },
          swHomepage: {
            url: 'https://www.sw-machines.com/',
            title: 'Page d’accueil SW',
          },
        },
        noInternetConnection: 'Il semblerait que vous n’êtes pas connecté à Internet. Veuillez vous connecter et effectuer une nouvelle tentative.',
      },
      modules: {
        delete: {
          text: 'Il sera impossible d’annuler cette action !',
        },
        error: {
          upload: 'Téléchargements montants échoués',
          message: {
            enrollment_code_409: 'L’appareil est déjà inscrit dans aws.',
          },
        },
        qs: {
          tabs: {
            list: 'Enregistrements horodatés',
          },
          list: {
            report: {
              headline: 'Sélectionner des variables',
            },
          },
          grid: {
            columns: {
              datetime: 'Date',
            },
          },
        },
        frameTitle: {
          qs: 'Assurance qualité',
          users: 'Gestion des utilisateurs',
          assets: 'Gestion des installations',
          settings: 'Paramètres',
          dashboard: 'Dashboard',
          userProfile: 'Profil d’utilisateur',
        },
        kpi: {
          tileTitle: 'IPC',
        },
        sidebar: {
          users: 'Gestion des utilisateurs',
          assets: 'Gestion des installations',
          dashboard: 'Dashboard',
          userProfile: 'Profil d’utilisateur',
        },
        generic: {
          noData: 'Pas de données',
          dateFormat: 'DD.MM.YYYY',
          noDuration: 'aucune durée disponible',
          timeFormat: 'HH:mm:ss',
          alarmPending: 'Alarmes en cours',
          buttons: {
            back: 'Retour',
            save: 'Enregistrer',
            view: 'Afficher',
            cancel: 'Annuler',
            export: 'Limites de téléchargement',
            import: 'Démarrer',
            submit: 'Reprendre',
            selectAllCheckboxes: 'Sélectionner tout',
            unselectAllCheckboxes: 'Lever la sélection',
          },
          notAvailable: 'Indisponible',
          notConnected: 'Pas encore connecté',
          dateTimeFormat: 'DD.MM.YYYY HH:mm:ss',
          timeFormats: {
            day: 'Jour',
            days: 'Jours',
            hour: 'Heure',
            year: 'Année',
            hours: 'Heures',
            month: 'Mois',
            years: 'Années',
            minute: 'Minute',
            months: 'Mois',
            second: 'Seconde',
            minutes: 'Minutes',
            seconds: 'Secondes',
          },
          notAvailableShort: 'n/a',
          timeFormatDuration: 'HH:mm:ss',
          timeFormatWithoutSeconds: 'HH:mm',
          sweetalert: {
            buttons: {
              understood: 'Compris',
            },
          },
        },
        success: {
          upload: 'Téléchargements montants réussis',
          statusCodes: {
            s_0: {
              message: "Une erreur inattendue s'est produite. Veuillez tenter de recharger la page.",
            },
            s_1: {
              message: 'Les modifications ont été enregistrées.',
            },
            s_930: {
              message: 'L’actif « %replaceValue » a été supprimé avec succès.',
            },
            s_931: {
              message: 'La société « %replaceValue » a été supprimée avec succès.',
            },
            s_932: {
              message: 'La ligne « %replaceValue » a été supprimée avec succès.',
            },
            s_933: {
              message: 'Le hall « %replaceValue » a été supprimé avec succès.',
            },
            s_934: {
              message: 'L’emplacement « %replaceValue » a été supprimé avec succès.',
            },
            s_935: {
              message: "Le fichier '%replaceValue' a été supprimé avec succès.",
            },
          },
          message: {
            duplication_deleted: 'Des cartes en doublon ont été supprimées !',
            add_line_to_dashboard: 'La ligne a été ajoutée au Dashboard',
          },
        },
        limit: {
          tabs: {
            list: 'Limites',
            type: 'Sélectionnez en fonction du type',
            machine: 'Sélectionnez l’ID de machine',
            listImport: 'Importer les limites',
            listDownload: 'Télécharger les limites',
          },
          text: {
            text: 'Les valeurs limites sont des valeurs que vous déterminez pour des variables qui vous intéressent. Si une variable ne repose pas au sein des valeurs limites définies, vous recevez une notification. Les valeurs limites sont affichées dans des diagrammes qui sont générées sur l’onglet « Séries temporelles ».',
            title: 'Limites',
          },
          export: {
            text: 'Vous pouvez exporter à tout moment les valeurs limites réglées actuellement pour toutes les variables existantes dans la plateforme Cloud. Vous pouvez utiliser cette liste comme référence ou pour importer toutes les valeurs limites en une seule fois au chapitre « Importer les valeurs limites comme CSV »',
            title: 'Exporter les limites comme CSV',
          },
          import: {
            text: 'Vous pouvez importer un fichier CSV téléchargé auparavant avec des informations relatives aux limites et abonnements afin d’actualiser les valeurs actuellement enregistrées. Vous pouvez choisir ultérieurement sur quels types d’installation ou de machine vous souhaitez appliquer les valeurs limites importées',
            title: 'Importer les valeurs limites de la CSV',
            machines: {
              title: 'Sélectionner les machines ou le type de machine sur lequel(lesquelles) vous souhaitez appliquer les valeurs limites.',
            },
          },
          upload: {
            fields: {
              file: 'Sélectionnez le fichier CSV que vous souhaitez importer',
            },
          },
        },
        manual: {
          line: {
            one: 'Aller à la ligne souhaitée dans la gestion des actifs.',
            two: "Vous trouverez un menu contextuel dans la ligne d'en-tête.",
            four: 'La vignette de la ligne est ajoutée au Dashboard.',
            three: 'Cliquer sur le menu contextuel et sélectionner « Ajouter au Dashboard ».',
            header: 'Ajout de vignettes de ligne',
          },
          individual: {
            one: 'Cliquer sur le symbole de « pignon » du côté gauche.',
            two: 'Sélectionnez le symbole « Plus ».',
            four: 'Confirmer avec « Enregistrer ». La vignette individuelle est ajoutée au Dashboard.',
            three: 'Nommer la vignette et ajouter la machine souhaitée.',
            header: 'Ajout de vignettes individuelles',
          },
        },
        users: {
          fab_title: 'Créer un nouvel utilisateur',
          new: {
            title: 'Créer un utilisateur',
            buttons: {
              add: 'Ajouter',
              back: 'Retour à la liste',
              save: 'Enregistrer',
              cancel: 'Annuler',
              delete: 'Supprimer un utilisateur',
            },
            fields: {
              email: 'Courriel',
              roles: "Groupe d'utilisateurs",
              gender: 'Genre',
              created: 'Créé',
              lastName: 'Nom',
              firstName: 'Prénom',
              breadcrumb: 'Afficher la navigation',
              main_group: 'Groupe d’utilisateurs',
              phoneNumber: 'Numéro de téléphone [en option]',
              customer_org: 'Organisation du client',
              last_modified: 'Dernière édition',
              email_validation: 'Courriel (validation)',
            },
            error_messages: {
              email_not_match: 'Les adresses de courriel ne coïncident pas.',
              email_not_valid: 'Veuillez saisir une adresse de courriel valide.',
              roles_not_specified: "Veuillez sélectionner un groupe d'utilisateurs.",
              gender_not_specified: 'Veuillez saisir un genre.',
              last_name_not_specified: 'Veuillez saisir un nom.',
              first_name_not_specified: 'Veuillez saisir un prénom.',
              main_group_not_specified: 'Veuillez sélectionner un groupe d’utilisateurs.',
              family_name_not_specified: 'Veuillez saisir un nom.',
              customer_organization_not_specified: 'Veuillez sélectionner une organisation de client.',
            },
          },
          update: {
            title: 'Actualier un utilisateur',
          },
          success: {
            title: 'Réussi!',
            button: 'Retour à la liste',
            message: "L’utilisateur a été créé avec succès. Un courriel avec un mot de passe généré automatiquement a été envoyé à l'adresse de courriel indiquée.",
            message_for_edit: 'L’utilisateur a été actualisé avec succès.',
            button_back_to_last_page: 'Retour à la dernière page',
          },
          delete: {
            success: "Suppression de l'utilisateur réussie.",
            confirmation: {
              title: 'Vraiment supprimer cet utilisateur ?',
              confirm: 'Oui, supprimer cet utilisateur',
              dismiss: 'Non, annuler',
            },
          },
          groups: {
            visitor: 'Visiteur',
            customer: 'Client',
          },
          list: {
            grid: {
              user: 'Utilisateur',
              email: 'Courriel',
              company: 'Société',
              enabled: 'Etat',
              lastName: 'Nom',
              sw_admin: 'SW admin',
              firstName: 'Prénom',
              userRights: 'Statut du droit',
              phoneNumber: 'Numéro de téléphone',
            },
          },
          fields: {
            gender: {
              male: 'Masculin',
              female: 'Féminin',
              diverse: 'Divers',
            },
          },
        },
        search: {
          noResults: 'Aucun résultat trouvé',
          results: {
            of: 'de',
            lines: 'Lignes',
            machines: 'Machines',
          },
          callToAction: 'Recherche de machines',
        },
        spistat: {
          tileTitle: 'SpiStat',
        },
        worldmap: {
          tileTitle: 'Carte du monde',
        },
        shopfloor: {
          tileTitle: 'Shopfloor',
          upload: {
            text1: 'Envoyer une photo de l’implantation de votre atelier à lifedata@sw-machines.com (JPG, PNG, PDF).',
            text2: 'Si vos machines sont enregistrées et inscrites dans la SW-CloudPlatform, elles apparaîtront à gauche et enverront des données en temps réel.',
            text3: 'Puis simplement glissez et déposez le statut sur votre plan sur la machine correcte.',
            mainText: 'Dans cette vue, vous pouvez définir votre implantation d’atelier et voir vos machines à travers différentes couches d’informations. Il suffit de suivre ces étapes.',
            tileTitle: 'Télécharger l’atelier',
          },
        },
        asset: {
          delete: {
            success: 'Actif supprimé avec succès.',
            confirmation: {
              title: 'Vraiment supprimer « %replaceValue »?',
              confirm: 'supprimer',
              dismiss: 'annuler',
            },
          },
          new: {
            buttons: {
              delete: 'Supprimer l’actif',
            },
          },
          updateLimits: {
            confirmation: {
              intro: 'Souhaitez-vous accepter les valeurs limites de ce nouveau type de machine ?',
              title: 'Vous avez modifié le type de machine',
              accept: 'Pio, accepter de nouvelles valeurs limites',
              decline: 'Non, respecter les valeurs limites actuelles',
              dismiss: 'Annuler',
              info_accept: 'Pour remplacer les valeurs limites actuelles par les valeurs limites du nouveau type de machine, choisissez « Oui ».',
              info_decline: 'Pour conserver les valeurs limites actuelles, choisissez « Non ».',
              info_accept_warning: 'Toutes les valeurs limites actuelles sont remplacées ou supprimées.',
            },
          },
        },
        assets: {
          line: {
            tabs: {
              kpi: 'IPC',
              list: 'Machines',
              shopfloor: 'Shopfloor',
            },
            fab_title: 'Ajouter une nouvelle ligne',
            grid: {
              hall: 'Halle',
              line: 'Ligne',
              type: 'Type',
              contractEnd: 'Fin du contrat [en option]',
              cellPosition: 'Ligne / position [en option]',
              operationStep: 'Adresse de traitement',
              machineIdentifierSw: 'N° machine (SW)',
              machineIdentifierCustomer: 'Nom de la machine',
            },
            success: {
              title: 'Réussi!',
              button: 'Retour à la liste',
              message: 'La ligne a été créée avec succès.',
            },
            new_form_title: 'Nouvelle ligne',
            edit_form_title: 'Éditer la ligne',
            new: {
              fields: {
                name: 'Nom de la ligne',
                name_short: 'Nom de la ligne (forme courte) [en option]',
                description: 'Description [en option]',
              },
              error_messages: {
                name_not_specified: 'Veuillez saisir un nom de ligne.',
              },
            },
            editSuccess: {
              title: 'Réussi!',
              button: 'Retour à la liste',
              message: 'La ligne a été actualisée avec succès.',
            },
          },
          hall: {
            fab_title: 'Ajouter une nouvelle halle',
            grid: {
              part: 'Type de pièces',
              lineName: 'Ligne',
              typeOfLoading: 'Type de chargement',
              lineResponsible: 'Responsable de ligne',
              plannedQuantity: 'Quantité prévue',
              numberOfMachines: '# machines',
            },
            tabs: {
              list: 'Lignes',
              shopfloor: 'Shopfloor',
            },
            success: {
              title: 'Réussi!',
              button: 'Retour à la liste',
              message: 'La halle a été créée avec succès.',
            },
            new_form_title: 'Nouvelle halle',
            shopfloor: {
              edit: 'Mode d’édition',
              configure: {
                save: {
                  fab_title: 'Enregistrer la configuration',
                },
                discard: {
                  fab_title: 'Rejeter la configuration',
                },
              },
            },
            edit_form_title: 'Éditer la halle',
            new: {
              fields: {
                name: 'Nom de la halle',
                name_short: 'Nom de la halle (forme courte) [en option]',
                description: 'Description [en option]',
                description_external: 'Description (externe) [en option]',
                description_internal: 'Description (interne) [en option]',
              },
              error_messages: {
                name_not_specified: 'Veuillez saisir un nom de halle.',
              },
            },
            editSuccess: {
              title: 'Réussi!',
              button: 'Retour à la liste',
              message: 'La halle a été actualisée avec succès.',
            },
          },
          grid: {
            description: 'Description [en option]',
          },
          company: {
            fab_title: 'Ajouter une nouvelle entreprise',
            grid: {
              city: 'Lieu',
              email: 'Courriel',
              street: 'Adresse',
              country: 'Pays',
              website: 'Site Internet',
              postalCode: 'Code postal',
              locationName: 'Site',
            },
            tabs: {
              list: 'Sites',
              worldmap: 'Carte du monde',
            },
            success: {
              title: 'Réussi!',
              button: 'Retour à la liste',
              message: "L'entreprise a été créée avec succès.",
            },
            toggle_switch: 'Créer un site avec les mêmes caractéristiques',
            new_form_title: 'Nouvelle entreprise',
            edit_form_title: 'Éditer l’entreprise',
            new: {
              fields: {
                city: 'Ville',
                name: 'Nom de l’entreprise',
                email: 'Courriel [en option]',
                state: 'Land [en option]',
                street: 'Adresse',
                country: 'Pays',
                website: 'Site Internet [en option]',
                zipcode: 'Code postal',
                legal_form: 'Forme juridique [en option]',
                name_short: "Nom de l'entreprise (court) [en option]",
                description: 'Description [en option]',
                company_logo: 'Logo de l’entreprise (URL) [en option]',
                customer_number: 'Numéro de client',
                industry_sector: 'Secteur industriel',
                description_external: 'Description (externe) [en option]',
                description_internal: 'Description (interne) [en option]',
              },
              error_messages: {
                url: 'Veuillez saisir une URL valide.',
                email: 'Veuillez saisir une adresse de courriel valide.',
                city_not_specified: 'Veuillez saisir une ville.',
                name_not_specified: 'Veuillez saisir un nom d’entreprise.',
                street_not_specified: 'Veuillez saisir une adresse.',
                country_not_specified: 'Veuillez saisir un pays.',
                zipcode_not_specified: 'Veuillez saisir un code postal.',
                customer_number_not_specified: 'Veuillez saisir un numéro de client.',
                industry_sector_not_specified: 'Veuillez saisir un secteur industriel.',
              },
            },
            editSuccess: {
              title: 'Réussi!',
              button: 'Retour à la liste',
              message: "L'entreprise a été actualisée avec succès.",
            },
            header: {
              labels: {
                email: 'Courriel',
                address: 'Adresse',
                country: 'Pays',
                website: 'Site Internet',
              },
            },
            coordinateselement: {
              intro_text: 'La SW CloudPlatform représente cette entreprise sur une carte et utilise à cet effet la longitude et la latitude.',
              intro_heading: 'Informations relatives au lieu',
              location_name: 'Nom du lieu :',
              label_latitude: 'Latitude',
              label_longitude: 'Longitude',
              text_fallback_info: "Si ce n'est pas le cas, les coordonnées par défaut sont utilisées.",
              text_location_found: 'Un lieu a été trouvé.',
              text_location_entered: 'Le lieu a été indiqué.',
              text_success_location: 'Les données suivantes ont été définies :',
              button_define_coordinates: 'Entrer soi-même les coordonnées',
              text_no_coordinates_found: "Aucune coordonnée relative aux données entrées n'a été trouvée.",
              text_coordinates_success_set: 'Coordonnées définies.',
              button_set_custom_coordinates: 'Définir les coordonnées',
              text_specify_coordinates_yourself: 'Les coordonnées peuvent être entrées soi-même en alternative.',
            },
          },
          machine: {
            fab_title: 'Créer un nouvel actif',
            tabs: {
              info: 'Séries temporelles',
              alarms: 'Alarmes',
              gateway: 'Gateway',
              service: 'Service',
              spistat: 'SpiStat',
              swimlane: 'Disponibilité ligne',
              'axis-test': 'Tests d’axe',
              collection: 'Collections',
              maintenance: 'Entretien',
              performance: 'Performance',
              spindledata: 'Données de broche',
              administration: 'Administration',
              energymonitoring: 'Surveillance de l’énergie',
            },
            move: {
              message: "L’actif '%replaceValue' peut être déplacé dans une nouvelle entreprise, un nouveau site, une nouvelle halle et/ou une nouvelle ligne.",
              select_hall_label: 'Sélectionner une nouvelle halle',
              select_line_label: 'Sélectionner une nouvelle ligne',
              select_company_label: 'Sélectionner une nouvelle entreprise',
              select_location_label: 'Sélectionner un nouveau site',
            },
            new: {
              fields: {
                s3: 'S3',
                name: 'Numéro de machine (SW)',
                iotcore: 'IdO Core',
                startup: 'Livraison [en option]',
                shipping: 'Expédition [en option]',
                controller: 'Commande',
                machine_type: 'Type de machine',
                warranty_end: 'Fin de la garantie [en option]',
                iotgreengrass: 'IdO Greengrass',
                systemsManager: 'Gestionnaire système',
                warranty_start: 'Début de la garantie [en option]',
                machine_swmp_api: 'Fin API SWCP machine [en option]',
                machine_swsp_trace: 'Fin traçage SWCP machine [en option]',
                machine_swcp_portal: 'Fin portail SWCP machine [en option]',
                start_of_production: 'Début de la production [en option]',
                machine_swcp_support: 'Fin support SWCP machine [en option]',
                machine_cell_position: 'Cellule machine / position',
                machine_swsp_line_mon: 'Fin LineMon SWCP machine [en option]',
                machine_identifier_customer: 'ID machine (client)',
              },
              error_messages: {
                name_not_specified: 'Veuillez saisir un numéro machine SW valide (min. 7 caractères: A-Z, 0-9, -).',
                controller_not_specified: 'Veuillez sélectionner une commande.',
                machine_type_not_specified: 'Veuillez sélectionner un type de machine.',
                machine_identifier_customer_not_specified: 'Veuillez saisir une ID de machine valide.',
              },
            },
            success: {
              title: 'Réussi!',
              button: 'Retour à la liste',
              message: "L'actif a été créé avec succès.",
            },
            new_form_title: 'Ajouter un actif',
            edit_form_title: 'Éditer l’actif',
            move_form_title: 'Déplacer un actif',
            editSuccess: {
              title: 'Réussi!',
              button: 'Retour à la liste',
              message: "L'actif a été actualisé avec succès.",
            },
            header: {
              labels: {
                api: 'SWMP API',
                trace: 'SWSP Trace',
                portal: 'SWCP Portal',
                lineMon: 'SWSP LineMon',
                support: 'SWCP Support',
                shipping: "Date d'expédition",
                controller: 'Commande',
                contractEnd: 'Fin du contrat',
                machineType: 'Type de machine',
                startupDate: 'Date de mise en service',
                warrantyEnd: 'Fin de la garantie',
                cellPosition: 'Cellule / position',
                warrantyStart: 'Début de la garantie',
                customerNumber: 'Numéro de client',
                enrollmentConfig: 'Configuration Enrollment',
                startOfProduction: 'Début de la production',
                machineIdentifierSw: 'N° machine SW',
                enrollmentConfigDownload: 'Télécharger',
                machineIdentifierCustomer: 'Nom de la machine client',
              },
            },
            add_line_to_dashboard: 'Ajouter une ligne vers le dashboard',
            elements: {
              'axis-test': {
                kpi: 'IPC',
                unit: 'Unité',
                value: 'Valeur',
                kpiUnit: 'Unité',
                headline: 'Tests d’axe',
                kpiValue: 'Valeur',
                kpiHeader: 'IPC',
                chartTitle: "Test d'axe",
                limitsLoad: 'Charger depuis la mémoire du navigateur',
                limitsSave: 'Enregistrer dans la mémoire du navigateur',
                selectAxis: 'Sélectionner l’axe',
                limitsExport: 'Exporter les valeurs limites dans le presse-papier',
                openAxistest: 'Ouvrir test d’axe',
                listbox: {
                  label: 'Type / axe(s)',
                  noContentMessage: 'Sélectionner le type de test d’axe',
                  noAxisTestAvailable: 'Aucun test d’axe de ce type disponible',
                },
                axisTestPlural: 'Tests d’axe',
                noChartMessage: 'Veuillez sélectionner une collection du côté gauche.',
                adjustments: {
                  add: 'Ajouter une adaptation locale',
                  range: 'Portée',
                  header: 'Adaptation locale',
                  remove: 'Enlever',
                  position: 'Position',
                  amplitude: 'Amplitude',
                  spreadMultiplier: 'Multiplicateur de limites',
                },
                chart: {
                  Equability: {
                    x: 'Position',
                    y: 'Courant',
                    up: 'vers le haut',
                    down: 'vers le bas',
                    limitsUp: 'limite la direction positive',
                    limitsDown: 'limite la direction négative',
                    sliderSlopeCenter: 'Origine de la pente',
                    sliderSlopeFactor: 'Facteur de pente',
                    sliderVerticalOffset: 'Décalage Y',
                    sliderVerticalSpread: 'Limiter la largeur',
                    sliderQuadraticCenter: 'Origine carrée',
                    sliderQuadraticFactor: 'Facteur carré',
                  },
                  Circularity: {
                    clockwise: 'Sens horaire',
                    limitsClockwise: 'Limites dans le sens horaire',
                    counterClockwise: 'Sens antihoraire',
                    sliderSineOffset: 'Décalage d’onde',
                    sliderSineAmplitude: 'Amplitude d’onde',
                    sliderSineFrequency: 'Fréquence d’onde',
                    sliderVerticalOffset: 'Décalage Y',
                    sliderVerticalSpread: 'Limiter la largeur',
                    limitsCounterClockwise: 'Limites dans le sens antihoraire',
                  },
                },
                kpis: {
                  pulseVelocity: 'Vitesse',
                  pulseMaxRampUp: 'Rampe ->',
                  pulseMaxRampDown: 'Rampe <-',
                  pulseMaxStiffness: 'Rigidité maximale',
                  pulseMinStiffness: 'Rigidité minimale',
                  pulseMeanStiffness: 'Rigidité Ø',
                  circularReversalRange: 'Jeu à l’inversion circulaire',
                  pulseMaxUpPathCurrent: 'Courant maximum ->',
                  pulseMinUpPathCurrent: 'Courant minimum ->',
                  pulseMeanUpPathCurrent: 'Courant Ø ->',
                  pulseMaxDownPathCurrent: 'Courant maximum <-',
                  pulseMinDownPathCurrent: 'Courant minimum <-',
                  pulseMeanDownPathCurrent: 'Courant Ø <-',
                  circularShapeDeviationClockwise: 'Déviation de forme circulaire vers la droite',
                  pulseStiffnessStandardDeviation: 'Rigidité σ',
                  pulseStandardDeviationUpPathCurrent: 'Courant σ ->',
                  pulseStandardDeviationDownPathCurrent: 'Courant σ <-',
                  circularShapeDeviationCounterClockwise: 'Déviation de forme circulaire vers la gauche',
                },
                axisLabels: {
                  CIRCLE: {
                    x: 'Position',
                    y: 'Position',
                  },
                  EQUABILITY: {
                    x: 'Position',
                    y: 'Courant',
                  },
                },
                axisTestTypes: {
                  CIRCLE: 'Test de circularité',
                  EQUABILITY: 'Test de synchronisation',
                  CIRCULARITY: 'Test de circularité',
                },
              },
              gateway: {
                title: 'Gateway',
                contact: "En cas d'erreur, veuillez vous adresser à",
                headline: 'Gateway',
                netcfg: {
                  cta: 'Veuillez mettre les données à notre disposition au moyen du formulaire Netcfg.',
                  intro: 'Pour pouvoir intégrer votre machine, nous dépendons de vos indications relatives à la configuration du réseau.',
                  title: 'Configuration du réseau',
                  goToNetconfig: 'Ouvrir la configuration du réseau',
                },
                device: {
                  title: "Détails de l'appareil",
                },
                enrollment: {
                  line1: 'Télécharger le fichier Enrollment en cliquant sur le bouton au bord inférieur de la vignette.',
                  line2: 'Le téléchargement démarre automatiquement.',
                  line3: "Insérer le fichier .json dans le répertoire racine sur la clé USB. S'assurer que le fichier est nommé :",
                  line4: 'enrollment.json.',
                  line5: "S'assurer que les fichiers suivants se trouvent dans le répertoire racine de la clé USB :",
                  line6: "Enficher la clé USB dans l'IoT-Gateway correspondante.",
                  line8: "Une fois la connexion effectuée, la diode verte est allumée jusqu'à ce que la clé USB soit retirée.",
                  title: 'Enrollment',
                  button: 'Fichier Enrollment',
                },
                deviceMigration: {
                  line1: 'Télécharger le fichier « migrate-iot.zip » et extrayez son contenu directement sur une clé USB vide.',
                  line2: 'aws.json',
                  line3: 'Télécharger également le ',
                  line4: " et l'ajouter sur la clé USB. S'assurer que le fichier est nommé de la manière suivante :",
                  line5: "S'assurer que les fichiers suivants se trouvent dans le répertoire racine de la clé USB :",
                  line6: 'install.json',
                  line7: 'migration.tar.gz',
                  line8: 'migration.tar.gz.sig',
                  line9: 'migration.json',
                  title: 'Migration de la plateforme',
                  line10: "Enficher la clé USB dans l'IoT-Gateway correspondante.",
                  line11: "Le logiciel s'installe automatiquement, ce qui est signalé par une diode jaune clignotante.",
                  line12: "Une fois la migration terminée, la diode verte est allumée jusqu'à ce que la clé USB soit retirée.",
                  line13: "Une fois la migration terminée, la diode verte est allumée jusqu'à ce que la clé USB soit retirée.",
                  device: {
                    button: 'migrate-iot.zip',
                  },
                  generic: {
                    button: 'aws.json',
                  },
                },
              },
              info: {
                generate: 'Générer',
                headline: 'Séries temporelles',
                noChartMessage: 'Pour afficher les données souhaitées, sélectionnez une ou plusieurs variables et cliquez sur Générer',
              },
              alarms: {
                headline: 'Alarmes',
              },
              service: {
                headline: 'Service',
                update: {
                  title: 'Actualiser un fichier',
                },
                upload: {
                  title: 'Télécharger le fichier',
                  fields: {
                    file: 'Fichier',
                    language: 'Langue du fichier',
                    file_name: 'Nom de fichier',
                    list_of_languages: {
                      cz: 'Tchèque',
                      de: 'Allemand',
                      en: 'Anglais',
                      es: 'Espagnol',
                      fr: 'Français',
                      hu: 'Hongrois',
                      it: 'Italien',
                      pl: 'Polonais',
                      zh: 'Chinois',
                    },
                  },
                  buttons: {
                    send: 'Télécharger',
                    cancel: 'Annuler',
                  },
                  success: {
                    title: 'Réussi',
                    button: 'Retour à la vue d’ensemble de service',
                    message: 'Le fichier a été téléchargé avec succès',
                  },
                  error_messages: {
                    file_name: 'Veuillez saisir un nom de fichier.',
                    file_not_valid: 'Veuillez indiquer un fichier / veuillez sélectionner un fichier plus petit.',
                    language_not_specified: 'Veuillez indiquer une langue de fichier.',
                  },
                },
                manuals: {
                  title: 'Manuels',
                },
                version: {
                  title: 'Fichiers de version',
                },
                contracts: {
                  title: 'Contrats',
                },
                components: {
                  title: 'Composants matériels et logiciels',
                },
                spare_parts: {
                  title: 'Nomenclatures de pièces de rechange',
                },
                consumeables: {
                  title: 'Fluides de service',
                },
                Update: {
                  buttons: {
                    send: 'Actualiser',
                  },
                },
                software_edge: {
                  title: 'Software IoT Gateway',
                  values: {
                    box_id: 'Box-ID',
                    machine_connect: 'Machine Connect',
                    operating_system: 'Système d’exploitation',
                  },
                },
                contact_service: {
                  line1: 'Avez-vous besoin d’aide pour votre machine?',
                  line2: 'Contactez-nous!',
                  title: 'Message au SAV SW',
                  button: 'Contacter le SAV',
                  fields: {
                    name: 'Numéro de machine (SW)',
                    type: 'Type de demande',
                    email: 'Courriel',
                    message: 'Message',
                    phone_no: 'Numéro de téléphone',
                    last_name: 'Nom',
                    first_name: 'Prénom',
                    contact_person: 'Interlocuteur (SW) [en option]',
                    list_of_types: {
                      request: 'Demande générale',
                      service: 'Service',
                      retrofit: 'Réaménagement',
                      expansion: 'Extension',
                      inspection: 'Inspection',
                    },
                    machine_identifier_customer: 'ID machine (client) [en option]',
                  },
                  buttons: {
                    send: 'Envoyer la demande',
                    cancel: 'Annuler',
                  },
                  success: {
                    title: 'Réussi!',
                    button: 'Retour à la vue d’ensemble du SAV',
                    message: 'La demande a été transmise avec succès au SAV SW.',
                  },
                  error_messages: {
                    email_not_valid: 'Veuillez saisir une adresse de courriel valide.',
                    name_not_specified: 'Veuillez saisir un numéro machine SW valide (min. 7 caractères: A-Z, 0-9, - _).',
                    phone_not_specified: 'Veuillez saisir un numéro de téléphone valide.',
                    message_not_specified: 'Veuillez saisir un message.',
                    last_name_not_specified: 'Veuillez saisir un nom.',
                    first_name_not_specified: 'Veuillez saisir un prénom.',
                    family_name_not_specified: 'Veuillez saisir un nom.',
                    contact_type_not_specified: 'Veuillez saisir un type de demande.',
                  },
                },
                software_machine: {
                  title: 'Logiciel machine',
                  values: {
                    nc_version: 'Version CN',
                    hmi_version: 'Version HMI',
                    plc_version: 'Version PLC',
                    compile_cycles: 'Cycles de compilation',
                    operating_system: 'Système d’exploitation',
                    pcu_base_version: 'Version de base PCU',
                    plc_toolbox_version: 'Toolbox PLC',
                    plc_user_program_version: 'Programme d’utilisateur PLC',
                  },
                },
                changed: {
                  success: {
                    message: 'Le nom du fichier a été modifié avec succès',
                  },
                },
                maintenance_assistance: {
                  intro: "L'assistant de traitement vous aide pour la résolution du problème.",
                  title: 'Assistant de traitement',
                  sub_title: 'Avez-vous des difficultés actuellement avec un de vos actifs ?',
                  open_assistant: "Ouvrir l'assistant de traitement",
                  request_account: 'Demander un compte',
                },
              },
              spistat: {
                headline: 'Statistiques de broche SpiStat',
              },
              onlineState: {
                title: 'IoT Gateway',
                labelOnline: 'Marche',
                labelOffline: 'Arrêt',
              },
              swimlane: {
                headline: 'Disponibilité ligne',
              },
              sendingState: {
                title: "État de l'envoi",
                aborted: 'interrompu',
                sending: 'envoie',
                interrupted: 'interrompu',
              },
              alarms_map: {
                headline: "Carte d'alarme",
              },
              collection: {
                headline: 'Collection',
                noChartMessage: 'Veuillez sélectionner une collection du côté gauche.',
              },
              performance: {
                kpi: {
                  sum: 'Somme',
                  header: 'Performance KPI',
                  target: 'Théorique',
                },
                alarms: {
                  title: 'Alarmes',
                  category: [
                    'Non classifié',
                    'Message d’erreur',
                    'Message de service',
                    'Message d’état',
                  ],
                  icons: {
                    trend: 'Tendance',
                    duration: "Durée d'activation",
                    flatlist: 'Liste plate',
                    frequency: 'Fréquence d’apparition',
                  },
                  tableHeadlines: {
                    time: 'Temps',
                    trend: 'Tendance',
                    duration: "Durée d'activation",
                    leftTime: "L’alarme s'arrête",
                    alarmCount: 'Nombre',
                    alarmNumber: 'N° d’alarme',
                    description: 'Description',
                    alarmOccured: 'L’alarme est survenue',
                    alarmClassification: 'Classification d’alarme',
                    alarmCountSecondRange: 'Nombre T-1',
                  },
                  description: {
                    trend: {
                      up: 'augmentant',
                      down: 'descendant',
                      flat: 'constant',
                    },
                  },
                },
                details: {
                  title: 'Détails',
                  ncProgram: 'Programme CN',
                  lastModified: 'Dernier enregistrement de valeur',
                  machineState: 'État machine',
                  numberAlarms: 'Alarmes en cors',
                  stopCondition: 'Conditions d’arrêt',
                  ncProgramState: 'État de programme CN',
                  protectionLevel: 'Niveau de protection',
                  ncProgramStates: [
                    'interrompu',
                    'arrêté',
                    'tourne',
                    'en attente',
                    'interrompu',
                  ],
                  protectionLevels: [
                    'Système',
                    'Fabricant',
                    'Service',
                    'Utilisateur',
                    'Commutateur à clé position 3',
                    'Commutateur à clé position 2',
                    'Commutateur à clé position 1',
                    'Commutateur à clé position 0',
                  ],
                },
                cycleTime: {
                  title: 'Durée de cycle',
                  chart: {
                    yAxis: {
                      title: 'Durée du cycle en s',
                    },
                  },
                },
                piececounter: {
                  ok: 'OK',
                  nok: 'Pas OK',
                  sum: 'Somme',
                  title: 'Compteur d’unités',
                  titleDayCounter: 'Compteur journalier',
                  titleShiftCounter: 'Compteur d’équipe',
                  titleTotalCounter: 'Compteur total',
                  titleTotalCounterSmall: 'Total',
                },
                availability: {
                  title: 'Disponibilité',
                  kpi: {
                    title: 'IPC disponibilité',
                  },
                  legend: {
                    auto: 'AUTO',
                    JOG_with_error: 'JOG avec erreur',
                    MDA_with_error: 'MDA avec erreur',
                    JOG_without_error: 'JOG sans erreur',
                    MDA_without_error: 'MDA sans erreur',
                    not_defined_condition: 'État non défini',
                    auto_no_program_active: 'AUTO Pas de programme actif',
                    auto_waiting_for_parts: 'AUTO Attente de pièces',
                    auto_with_error_program_stopped: 'AUTO avec erreur - Programme arrêté',
                    auto_waiting_for_parts_stopping_error: 'AUTO Attente de pièces + erreur d’arrêt',
                  },
                  aggregated: {
                    title: 'Disponibilité agrégée',
                  },
                  chart: {
                    yAxis: {
                      title: 'Disponibilité',
                    },
                  },
                },
                inProduction: {
                  title: 'Etat',
                },
                operationMode: {
                  title: 'État de service',
                  chart: {
                    yAxis: {
                      title: 'État de service',
                    },
                  },
                },
                runtimeMonitor: {
                  title: 'Moniteur de durée de fonctionnement',
                  chart: {
                    yAxis: {
                      title: 'Programme',
                      labels: {
                        on: 'marche',
                        off: 'arrêt',
                      },
                    },
                  },
                },
                spindleOverride: {
                  title: 'Override broche',
                },
                feedrateOverride: {
                  title: 'Override avance',
                },
                uptimeDistribution: {
                  title: 'Uptime Distribution',
                },
              },
              administration: {
                title: 'Administration',
                headline: 'Administration',
                aws: {
                  title: 'Liens AWS',
                },
                references: {
                  values: {
                    s3: 'S3',
                    iot: 'IdO Core',
                    ssm: 'Gestionnaire système',
                    greengrass: 'IdO Greengrass',
                  },
                },
              },
              condition: {
                bill: {
                  title: 'Nomenclature de pièces',
                },
                component: {
                  title: 'Composants',
                },
              },
              maintenance: {
                headline: 'Entretien',
              },
              spindleData: {
                headline: 'Données de broche',
              },
              point8: {
                chart: {
                  xAxis: {
                    title: 'Durée avant alarme(s)',
                  },
                  yAxis: {
                    title: 'Durée après alarme(s)',
                  },
                },
              },
              energymonitoring: {
                headline: 'Surveillance d’énergie',
                chartTitles: {
                  air: 'Consommation d’air comprimé',
                  current: 'Consommation électrique',
                  consumption: 'Consommation horaire moyenne',
                },
              },
              conditionMonitoring: {
                headline: 'Surveillance de l’état',
              },
            },
          },
          location: {
            fab_title: 'Ajouter un nouveau site',
            tabs: {
              list: 'Halles',
              shopfloor: 'Shopfloor',
            },
            grid: {
              hallName: 'Halle',
            },
            success: {
              title: 'Réussi!',
              button: 'Retour à la liste',
              message: 'Le site a été créé avec succès.',
            },
            new_form_title: 'Nouveau site',
            edit_form_title: 'Éditer le site',
            new: {
              fields: {
                city: 'Ville',
                name: 'Nom du site',
                email: 'Courriel [en option]',
                state: 'Land [en option]',
                street: 'Adresse',
                country: 'Pays',
                website: 'Site Internet [en option]',
                zipcode: 'Code postal',
                language: 'Langue [en option]',
                name_short: 'Nom du site (forme courte) [en option]',
                description: 'Description [en option]',
                description_internal: 'Description (interne) [en option]',
              },
              error_messages: {
                email: 'Veuillez saisir une adresse de courriel valide.',
                city_not_specified: 'Veuillez saisir une ville.',
                name_not_specified: 'Veuillez saisir un nom de site.',
                street_not_specified: 'Veuillez saisir une adresse.',
                country_not_specified: 'Veuillez saisir un pays.',
                zipcode_not_specified: 'Veuillez saisir un code postal.',
              },
            },
            editSuccess: {
              title: 'Réussi!',
              button: 'Retour à la liste',
              message: 'Le site a été actualisé avec succès.',
            },
            header: {
              labels: {
                address: 'Adresse',
                country: 'Pays',
              },
            },
          },
          companies: {
            grid: {
              city: 'Lieu',
              email: 'Courriel',
              street: 'Adresse',
              country: 'Pays',
              website: 'Site Internet',
              postalCode: 'Code postal',
              companyName: 'Entreprise',
            },
            tabs: {
              list: 'Entreprise',
              worldmap: 'Carte du monde',
            },
            map: {
              description_not_available: 'Aucune description disponible',
            },
          },
          generic: {
            new: {
              buttons: {
                add: 'Ajouter',
                back: 'Retour à la liste',
                edit: 'Actualiser',
                save: 'Enregistrer',
                email: 'Envoyer l’image',
                cancel: 'Annuler',
                addMultiple: 'Ajouter + nouveau',
              },
            },
          },
          administration: {
            edit_form_title: 'Éditer l’administration',
          },
        },
        settings: {
          tabs: {
            limits: 'Limites',
          },
          button: {
            import: 'Démarrer l’importation',
            dismiss: 'Annuler',
            download: 'Démarrer le téléchargement',
          },
        },
        file: {
          new: {
            buttons: {
              delete: 'Supprimer un fichier',
            },
          },
        },
        hall: {
          new: {
            buttons: {
              delete: 'Supprimer le hall',
            },
          },
        },
        line: {
          new: {
            buttons: {
              delete: 'Supprimer la ligne',
            },
          },
        },
        runtimeStatus: {
          tileTitle: 'Etat',
        },
        charts: {
          tooltipDateFormat: 'DD.MM.YYYY HH:mm:ss',
          exporting: {
            datetimeformat: '%d.%m.%Y %H:%M:%S',
          },
          globalSettings: {
            lang: {
              noData: 'Aucune donnée présente',
              loading: 'Chargement des données...',
              months: [
                'Janvier',
                'Février',
                'Mars',
                'Avril',
                'Mai',
                'Juin',
                'Juillet',
                'Août',
                'Septembre',
                'Octobre',
                'Novembre',
                'Décembre',
              ],
              resetZoom: 'Réinitialiser le zoom',
              weekdays: [
                'Dimanche',
                'Lundi',
                'Mardi',
                'Mercredi',
                'Jeudi',
                'Vendredi',
                'Samedi',
              ],
              shortMonths: [
                'Jan',
                'Fév',
                'Mar',
                'Avr',
                'Mai',
                'Juin',
                'Jui',
                'Août',
                'Sep',
                'Oct',
                'Nov',
                'Déc',
              ],
              shortWeekdays: [
                'Di',
                'Lu',
                'Ma',
                'Me',
                'Je',
                'Ve',
                'Sa',
              ],
              increaseDateRange: 'au moins jusqu’à',
            },
          },
        },
        datepicker: {
          presets: {
            title: 'Préréglages',
            rangeTo: 'Jusqu’à',
            selectDateRange: 'Veuillez sélectionner une période',
            predefined: {
              today: 'Aujourd’hui',
              last7days: '7 derniers jours',
              yesterday: 'Hier',
              last24Hours: '24 dernières heures',
              last5Minutes: '5 dernières minutes',
            },
          },
        },
        i18n: {
          chooseLanguagePhrase: 'Sélectionner la langue',
        },
        company: {
          new: {
            buttons: {
              delete: 'Supprimer la société',
            },
          },
        },
        location: {
          new: {
            buttons: {
              delete: 'Supprimer l’emplacement',
            },
          },
        },
        runtimeStatusList: {
          tileTitle: 'Etat',
        },
        dashboard: {
          configure: {
            fab_title: 'Configurer le tableau de bord',
            hide: {
              title: 'masquer',
            },
            show: {
              title: 'afficher',
            },
            delete: {
              title: 'supprimer',
            },
            add: {
              fab_title: 'Ajouter sa propre vignette',
            },
            save: {
              fab_title: 'Enregistrer la configuration',
            },
            move_left: {
              title: 'Ramener à la position précédente',
            },
            move_right: {
              title: 'Amener à la position suivante',
            },
            discard: {
              fab_title: 'Rejeter la configuration',
            },
            add_custom_card: {
              save: 'enregistrer',
              input: 'Indiquer le nom de vignette',
              title: 'Ajouter sa propre vignette',
              cancel: 'annuler',
              missingMachine: 'Vous devez sélectionner au moins une machine',
            },
          },
        },
        errors: {
          statusCodes: {
            e_0: {
              message: "Une erreur inattendue s'est produite. Veuillez tenter de recharger la page.",
            },
            e_1xx: {
              message: 'Informational Error',
            },
            e_2xx: {
              message: 'Succès',
            },
            e_3xx: {
              message: 'Transfert',
            },
            e_400: {
              message: 'La demande était incorrecte et n’a pas pu être traitée.',
            },
            e_401: {
              message: 'Vous n’êtes pas authentifié. Veuillez vous connecter de nouveau.',
            },
            e_403: {
              message: "Vous n'avez pas une autorisation suffisante pour accéder à la ressource requise.",
            },
            e_404: {
              message: "La ressource demandée n'est pas disponible.",
            },
            e_405: {
              message: 'Méthode pas autorisée.',
            },
            e_409: {
              message: 'La ressource existe déjà.',
            },
            e_418: {
              message: "I'm a Teapot! Yaay!",
            },
            e_4xx: {
              message: 'Erreur client',
            },
            e_500: {
              message: "Une erreur de serveur interne s'est produite.",
            },
            e_5xx: {
              message: 'Erreur serveur',
            },
            e_900: {
              message: 'La tentative de créer un actif a échoué. Veuillez effectuer une nouvelle tentative ultérieurement.',
            },
            e_901: {
              message: "L'action a été interrompue. Aucun nouvel actif n’a été créé.",
            },
            e_902: {
              message: "L'action a été interrompue. Aucune demande de service n’a été envoyée.",
            },
            e_903: {
              message: 'Un problème est survenu lors de la tentative d’envoyer une demande de service. Veuillez effectuer une nouvelle tentative ultérieurement.',
            },
            e_904: {
              message: "L’actif '%replaceValue' a été créé avec succès. Vous pouvez maintenant ajouter d’autres actifs.",
            },
            e_905: {
              message: "L'action a été interrompue. L’actif n’a pas été actualisé.",
            },
            e_906: {
              message: "L’actif '%replaceValue' a été déplacé avec succès.",
            },
            e_910: {
              message: 'La tentative de créer un utilisateur a échoué. Veuillez effectuer une nouvelle tentative ultérieurement.',
            },
            e_911: {
              message: "L'action a été interrompue. Aucun nouvel utilisateur n’a été créé.",
            },
            e_912: {
              message: 'La tentative d’actualiser un actif a échoué. Veuillez faire une nouvelle tentative.',
            },
            e_920: {
              message: "L'action a été interrompue. Aucune modification n’a été enregistré.",
            },
            e_921: {
              message: "Une erreur inattendue s'est produite. Veuillez faire une tentative encore une fois.",
            },
            e_922: {
              message: "Le nom d’utilisateur et/ou le mot de passe n'est pas correct. Veuillez faire une tentative encore une fois.",
            },
            e_923: {
              message: 'Veuillez saisir un nom d’utilisateur et un mot de passe.',
            },
            e_924: {
              message: 'Votre session est terminée pour inactivité. Veuillez vous connecter de nouveau.',
            },
            e_925: {
              message: 'Vous devez accepter notre déclaration de protection des données pour pouvoir utiliser la SW Claud Platform.',
            },
            e_926: {
              message: 'Votre compte est temporairement verrouillé. Veuillez effectuer une nouvelle tentative dans quelques minutes.',
            },
            e_927: {
              message: 'Votre mot de passe a été modifié avec succès.',
            },
            e_928: {
              message: "L'action a été interrompue. Aucun fichier n’a été téléchargé.",
            },
            e_929: {
              message: 'Un problème est survenu lors de la tentative de télécharger un fichier. Veuillez effectuer une nouvelle tentative ultérieurement.',
            },
            e_930: {
              message: 'Un problème est survenu lors de la tentative de supprimer un fichier. Veuillez effectuer une nouvelle tentative ultérieurement.',
            },
            e_10500: {
              message: 'Il semble que vous n’êtes pas connecté à Internet. Veuillez assurer que vous êtes connecté.',
            },
            e_10501: {
              message: "Vous utilisez un navigateur qui n'est pas pris en charge. Veuillez utiliser un des navigateurs suivants: Google Chrome, Microsoft Edge, Apple Safari.",
            },
            e_409_asset: {
              message: 'L’actif (numéro de machine SW %replaceValue) existe déjà.',
            },
            e_410_asset: {
              message: 'Ce nom de fichier %replaceValue existe déjà',
            },
          },
        },
        list: {
          sweetalert: {
            maxAmountReached: {
              html: 'Il est possible de sélectionner seulement %s éléments simultanément',
              title: 'Nombre max. atteint',
            },
          },
        },
      },
      module: {
        assets: {
          machine: {
            alarms: {
              no_alarms_text: 'Aucune alarme disponible',
            },
            nc_data_file_cardTitle: 'NC State Files',
            nc_state_files: {
              no_files_text: 'Aucun fichier disponible.',
            },
            nc_data_files: {
              column_title_trigger: 'Déclencheur',
              column_title_filename: 'Nom de fichier',
              column_title_creation_date: 'Date de création',
              modules: {
                assets: {
                  nc_data_download: 'Téléchargement de données CN',
                },
              },
            },
          },
        },
      },
    },
  },
  hu: {
    translation: {
      generic: {
        logout: 'Kijelentkezés',
        release: "Ennél a berendezésnél:'%replaceValue1' ettől a dátumtól: '%replaceValue2' eddig a dátumig '%replaceValue3' karbantartási munkákat végeznek a platformon. Ebben az időszakban platform részben nem lesz elérhető.",
        pageTitle: 'SW CloudPlatform',
        closeLabel: 'Becsukás',
        acceptLabel: 'Elfogadás',
        userProfile: 'Felhasználói profil',
        declineLabel: 'Elutasítás',
        backToDashboard: 'Vissza a dashboardra',
        footerLinks: {
          gtct: {
            url: 'https://sw-machines.com/agb/',
            title: 'GTCT / szabványok',
          },
          contact: {
            url: 'mailto:lifedata@sw-machines.com',
            title: 'Kapcsolat',
          },
          imprint: {
            url: 'https://sw-machines.com/impressum/',
            title: 'Impresszum',
          },
          privacy: {
            title: 'Privátszféra',
            header: 'Adatvédelmi előírások',
          },
          swHomepage: {
            url: 'https://www.sw-machines.com/',
            title: 'SW honlap',
          },
        },
        noInternetConnection: 'Úgy tűnik, nincs csatlakozva az internetre. Csatlakozzon az internetre és próbálja meg újra.',
      },
      modules: {
        delete: {
          text: 'Ovo nećete moći povratiti!',
        },
        error: {
          upload: 'Sikertelen feltöltések',
          message: {
            enrollment_code_409: 'Uređaj je već upisan u AWS.',
          },
        },
        qs: {
          tabs: {
            list: 'Időfelvételek',
          },
          list: {
            report: {
              headline: 'Változók kiválasztása',
            },
          },
          grid: {
            columns: {
              datetime: 'Dátum',
            },
          },
        },
        frameTitle: {
          qs: 'Minőségbiztosítás',
          users: 'Felhasználók kezelése',
          assets: 'Megmunkáló eszköz kezelése',
          settings: 'Beállítások',
          dashboard: 'Dashboard',
          userProfile: 'Felhasználói profil',
        },
        kpi: {
          tileTitle: 'KPI',
        },
        sidebar: {
          users: 'Felhasználók kezelése',
          assets: 'Megmunkáló eszköz kezelése',
          dashboard: 'Dashboard',
          userProfile: 'Felhasználói profil',
        },
        generic: {
          noData: 'Nincsenek adatok',
          dateFormat: 'DD.MM.YYYY',
          noDuration: 'nem áll rendelkezésre időtartam',
          timeFormat: 'HH:mm:ss',
          alarmPending: 'Beérkezett riasztás',
          buttons: {
            back: 'Vissza',
            save: 'Mentés',
            view: 'Kijelzés',
            cancel: 'Elvetés',
            export: 'Határértékek letöltése',
            import: 'Start',
            submit: 'Átvétel',
            selectAllCheckboxes: 'Mindet kiválaszt',
            unselectAllCheckboxes: 'Kiválasztás visszavonása',
          },
          notAvailable: 'Nem áll rendelkezésre',
          notConnected: 'Még nincs csatlakoztatva',
          dateTimeFormat: 'DD.MM.YYYY HH:mm:ss',
          timeFormats: {
            day: 'Nap',
            days: 'Napok',
            hour: 'Óra',
            year: 'Év',
            hours: 'Órák',
            month: 'Hónap',
            years: 'Évek',
            minute: 'Perc',
            months: 'Hónapok',
            second: 'Másodperc',
            minutes: 'Percek',
            seconds: 'Másodpercek',
          },
          notAvailableShort: 'nincs adat',
          timeFormatDuration: 'HH:mm:ss',
          timeFormatWithoutSeconds: 'HH:mm',
          sweetalert: {
            buttons: {
              understood: 'Megértettem',
            },
          },
        },
        success: {
          upload: 'Sikeres feltöltések',
          statusCodes: {
            s_0: {
              message: 'Egy váratlan hiba lépett fel. Próbálja meg az oldalt újra betölteni.',
            },
            s_1: {
              message: 'A módosításokat elmenti.',
            },
            s_930: {
              message: "Sredstvo '%replaceValue' uspješno je izbrisano.",
            },
            s_931: {
              message: "Tvrtka '%replaceValue' uspješno je izbrisana.",
            },
            s_932: {
              message: "Linija '%replaceValue' uspješno je izbrisana.",
            },
            s_933: {
              message: "Dvorana '%replaceValue' uspješno je izbrisana.",
            },
            s_934: {
              message: "Lokacija '%replaceValue' uspješno je izbrisana.",
            },
            s_935: {
              message: "A '%replaceValue' fájlt sikeresen törölte.",
            },
          },
          message: {
            duplication_deleted: 'A kétszer előforduló kártyákat kitörli!',
            add_line_to_dashboard: 'A gyártósort hozzáadása a dashboardhoz',
          },
        },
        limit: {
          tabs: {
            list: 'Határértékek',
            type: 'Válassza ki a típus szerint',
            machine: 'Válassza ki a gép ID szerint',
            listImport: 'Határértékek importálása',
            listDownload: 'Határértékek letöltése',
          },
          text: {
            text: 'A határértékek azok az értékek, amelyeket azok számára a változók számára határoz meg, amelyek érdeklik. Ha egy változó nincs a meghatározott határértékek között, egy értesítést kap. A határértékeket a diagramokban is kijelzi, amelyeket az „Idősorok” fülön generál.',
            title: 'Határértékek',
          },
          export: {
            text: 'Az aktuálisan beállított határértékeket bármikor exportálni tudja a Cloud Platformba található változó számára. Ezt a listát referenciaként használhatja, vagy arra használhatja, hogy az összes határértéket egyszerre a „Határértékek importálása CSV-ként” szakaszból importálja',
            title: 'Exportálási határok CSV-formátumban',
          },
          import: {
            text: 'Annak érdekében, hogy az aktuálisan elmentett értékeket aktualizálja, a korábban letöltött CSV-fájlokat importálni tudja a határértékekre és előfizetőkre vonatkozó információkkal együtt. Később kiválaszthatja, hogy mely berendezés- vagy géptípusra szeretné alkalmazni az importált határértékeket',
            title: 'Határértékek importálása a CSV-ből',
            machines: {
              title: 'Válassza ki azt a gépet, vagy géptípust, amelyre alkalmazni szeretné a határértékeket.',
            },
          },
          upload: {
            fields: {
              file: 'Válassza ki azt a CSV-fájlt, amelyet importálni szeretne',
            },
          },
        },
        manual: {
          line: {
            one: 'A megmunkáló eszköz kezelőben menjen a kívánt gyártósorra.',
            two: 'A címsorban egy helyi menü található.',
            four: 'A gyártósor csempéjét hozzáadja a dashboardhoz.',
            three: 'Kattintson a helyi menüre és válassza ki a „Hozzáadás a dashboardhoz”.',
            header: 'A gyártósor-csempe hozzáadása',
          },
          individual: {
            one: 'Bal oldalon kattintson a „fogaskerék”-szimbólumra.',
            two: 'Válassza ki a „plusz”-szimbólumot.',
            four: 'Nyugtázza a „Mentés” gombbal. Az egyes csempéket hozzáadja a dashboardhoz.',
            three: 'Adjon nevet a csempének és csatolja a kívánt géphez.',
            header: 'Az egyes csempék hozzáadása',
          },
        },
        users: {
          fab_title: 'Új felhasználó létrehozása',
          new: {
            title: 'Felhasználó létrehozása',
            buttons: {
              add: 'Hozzáadás',
              back: 'Vissza a listához',
              save: 'Mentés',
              cancel: 'Elvetés',
              delete: 'Felhasználó törlése',
            },
            fields: {
              email: 'E-mail',
              roles: 'Felhasználói csoport',
              gender: 'Nem',
              created: 'Létrehozva',
              lastName: 'Családnév',
              firstName: 'Utónév',
              breadcrumb: 'A navigáció mutatása',
              main_group: 'Main group',
              phoneNumber: 'Telefonszám [opcionális]',
              customer_org: 'Ügyfélszervezet',
              last_modified: 'Utoljára szerkesztve',
              email_validation: 'E-mail (ellenőrzés)',
            },
            error_messages: {
              email_not_match: 'Az e-mail címek nem egyeznek.',
              email_not_valid: 'Kérem, adjon meg egy érvényes e-mail-címet.',
              roles_not_specified: 'Kérem, válasszon ki egy felhasználói csoportot.',
              gender_not_specified: 'Kérem, adja meg a nemét.',
              last_name_not_specified: 'Kérem, adja meg a nevét.',
              first_name_not_specified: 'Kérem, adja meg az utónevét.',
              main_group_not_specified: 'Kérem, válasszon ki egy felhasználói csoportot.',
              family_name_not_specified: 'Please specify a last name',
              customer_organization_not_specified: 'Kérem, válasszon ki egy ügyfélszervezetet.',
            },
          },
          update: {
            title: 'Felhasználó frissítése',
          },
          success: {
            title: 'Sikeres!',
            button: 'Vissza a listához',
            message: 'A felhasználót sikeresen létrehozta. A megadott e-mail címre egy automatikusan generált jelszót küldött e-mailben.',
            message_for_edit: 'A felhasználót sikeresen frissítette.',
            button_back_to_last_page: 'Vissza az utolsó oldalra',
          },
          delete: {
            success: 'A felhasználót sikeresen kitörölte.',
            confirmation: {
              title: 'Ezt a felhasználót tényleg ki szeretné törölni?',
              confirm: 'Igen, törölje ki ezt a felhasználót',
              dismiss: 'Nem, elvetés',
            },
          },
          groups: {
            visitor: 'Látogató',
            customer: 'Ügyfél',
          },
          list: {
            grid: {
              user: 'Felhasználó',
              email: 'E-mail',
              company: 'Cég',
              enabled: 'Státusz',
              lastName: 'Családnév',
              sw_admin: 'SW admin',
              firstName: 'Utónév',
              userRights: 'Jogi státusz',
              phoneNumber: 'Telefonszám',
            },
          },
          fields: {
            gender: {
              male: 'férfi',
              female: 'nő',
              diverse: 'divers',
            },
          },
        },
        search: {
          noResults: 'Nem talált eredményt',
          results: {
            of: 'innen:',
            lines: 'Gyártósorok',
            machines: 'Gépek',
          },
          callToAction: 'Gépek keresése',
        },
        spistat: {
          tileTitle: 'SpiStat',
        },
        worldmap: {
          tileTitle: 'Világtérkép',
        },
        shopfloor: {
          tileTitle: 'Shopfloor',
          upload: {
            text1: 'Pošaljite sliku svojeg shopfloor-a na lifedata@sw-machines.com (JPG, PNG, PDF).',
            text2: 'Ako su vaši strojevi registrirani i upisani u platformi SW CloudPlatform, pojavit će se s lijeve strane i slati podatke u stvarnom vremenu.',
            text3: 'U tom slučaju samo povucite i ispustite status iz svog plana na pravi stroj.',
            mainText: 'U ovom prikazu možete postaviti svoj pojedinačni raspored aktivnosti u proizvodnji (shopfloor) i vidjeti svoje strojeve kroz različite slojeve informacija. Samo slijedite ove korake.',
            tileTitle: 'Prijenos shopfloor-a',
          },
        },
        asset: {
          delete: {
            success: 'Sredstvo je uspješno izbrisano.',
            confirmation: {
              title: "Jeste li sigurni da želite izbrisati '%replaceValue'?",
              confirm: 'izbriši',
              dismiss: 'otkaži',
            },
          },
          new: {
            buttons: {
              delete: 'Izbriši sredstvo',
            },
          },
          updateLimits: {
            confirmation: {
              intro: 'Át szeretné venni ennek az új géptípusnak a határértékeit?',
              title: 'Megváltoztatta a géptípust',
              accept: 'Igen, vegye át az új határértékeket',
              decline: 'Nem, tartsa meg az aktuális határértékeket',
              dismiss: 'Elvetés',
              info_accept: 'Ahhoz, hogy az aktuális határértékeket felülírja az új géptípus határértékeivel, válassza az „Igen”-t.',
              info_decline: 'Az aktuális határértékek megtartásához válassza a „Nem”-et.',
              info_accept_warning: 'Minden aktuális határértékek felülír vagy kitöröl.',
            },
          },
        },
        assets: {
          line: {
            tabs: {
              kpi: 'KPI-k',
              list: 'Gépek',
              shopfloor: 'Shopfloor',
            },
            fab_title: 'Új gyártósor hozzáadása',
            grid: {
              hall: 'Csarnok',
              line: 'Gyártósor',
              type: 'Típus',
              contractEnd: 'A szerződés vége [opcionális]',
              cellPosition: 'Cella / pozíció [opcionális]',
              operationStep: 'Megmunkálási lépés',
              machineIdentifierSw: 'Gépszám (SW)',
              machineIdentifierCustomer: 'Gépnév',
            },
            success: {
              title: 'Sikeres!',
              button: 'Vissza a listához',
              message: 'A gyártósort sikeresen létrehozta.',
            },
            new_form_title: 'Új gyártósor',
            edit_form_title: 'A gyártósor szerkesztése',
            new: {
              fields: {
                name: 'Gyártósor neve',
                name_short: 'Gyártósor neve (rövid változat) [opcionális]',
                description: 'Leírás [opcionális]',
              },
              error_messages: {
                name_not_specified: 'Kérem, adja meg a gyártósor nevét.',
              },
            },
            editSuccess: {
              title: 'Sikeres!',
              button: 'Vissza a listához',
              message: 'A gyártósort sikeresen frissítette.',
            },
          },
          hall: {
            fab_title: 'Új csarnok hozzáadása',
            grid: {
              part: 'Alkatrésztípus',
              lineName: 'Gyártósor',
              typeOfLoading: 'Berakástípus',
              lineResponsible: 'Gyártósor-felelős',
              plannedQuantity: 'Tervezett mennyiség',
              numberOfMachines: '# Gépek',
            },
            tabs: {
              list: 'Gyártósorok',
              shopfloor: 'Shopfloor',
            },
            success: {
              title: 'Sikeres!',
              button: 'Vissza a listához',
              message: 'A csarnokot sikeresen létrehozta.',
            },
            new_form_title: 'Új csarnok',
            shopfloor: {
              edit: 'Szerkesztőmód',
              configure: {
                save: {
                  fab_title: 'A konfiguráció mentése',
                },
                discard: {
                  fab_title: 'A konfiguráció elvetése',
                },
              },
            },
            edit_form_title: 'A csarnok szerkesztése',
            new: {
              fields: {
                name: 'Csarnoknév',
                name_short: 'Csarnoknév (rövid változat) [opcionális]',
                description: 'Leírás [opcionális]',
                description_external: 'Leírás (külső) [opcionális]',
                description_internal: 'Leírás (belső) [opcionális]',
              },
              error_messages: {
                name_not_specified: 'Kérem, adjon meg egy csarnoknevet.',
              },
            },
            editSuccess: {
              title: 'Sikeres!',
              button: 'Vissza a listához',
              message: 'A csarnokot sikeresen frissítette.',
            },
          },
          grid: {
            description: 'Leírás [opcionális]',
          },
          company: {
            fab_title: 'Új vállalat hozzáadása',
            grid: {
              city: 'Hely',
              email: 'E-mail',
              street: 'Cím',
              country: 'Ország',
              website: 'Honlap',
              postalCode: 'Postai irányítószám',
              locationName: 'Telephely',
            },
            tabs: {
              list: 'Telephelyek',
              worldmap: 'Világtérkép',
            },
            success: {
              title: 'Sikeres!',
              button: 'Vissza a listához',
              message: 'A vállalatot sikeresen létrehozta.',
            },
            toggle_switch: 'Egy telephely létrehozása ugyanazokkal az adatokkal',
            new_form_title: 'Új vállalat',
            edit_form_title: 'A vállalat szerkesztése',
            new: {
              fields: {
                city: 'Város',
                name: 'A vállalat neve',
                email: 'E-mail [opcionális]',
                state: 'Szövetségi tartomány [opcionális]',
                street: 'Cím',
                country: 'Ország',
                website: 'Honlap [opcionális]',
                zipcode: 'Postai irányítószám',
                legal_form: 'Jogi forma [opcionális]',
                name_short: 'A vállalat neve (röviden) [opcionális]',
                description: 'Leírás [opcionális]',
                company_logo: 'Vállalat emblémája (URL) [opcionális]',
                customer_number: 'Ügyfélszám',
                industry_sector: 'Iparág',
                description_external: 'Leírás (külső) [opcionális]',
                description_internal: 'Leírás (belső) [opcionális]',
              },
              error_messages: {
                url: 'Kérem, adjon meg egy érvényes URL-et.',
                email: 'Kérem, adjon meg egy érvényes e-mail-címet.',
                city_not_specified: 'Kérem, adja meg a várost.',
                name_not_specified: 'Kérem, adja meg a cégnevet.',
                street_not_specified: 'Kérem, adja meg a postai címét.',
                country_not_specified: 'Kérem, adja meg az országot.',
                zipcode_not_specified: 'Kérem, adja meg a postai irányítószámot.',
                customer_number_not_specified: 'Kérem, adja meg a ügyfélszámot.',
                industry_sector_not_specified: 'Kérem, adjon meg egy iparágat.',
              },
            },
            editSuccess: {
              title: 'Sikeres!',
              button: 'Vissza a listához',
              message: 'A vállalatot sikeresen aktualizálta.',
            },
            header: {
              labels: {
                email: 'E-mail',
                address: 'Cím',
                country: 'Ország',
                website: 'Honlap',
              },
            },
            coordinateselement: {
              intro_text: 'Az SW CloudPlatform egy térképen ábrázolja ezt a vállalatot és ehhez hosszúsági és szélességi fokokat használ.',
              intro_heading: 'Helyre vonatkozó információk',
              location_name: 'A helység neve:',
              label_latitude: 'Szélességi fok',
              label_longitude: 'Hosszúsági fok',
              text_fallback_info: 'Ha nem, standard koordinátákat fog használni.',
              text_location_found: 'Megtalálta helyet.',
              text_location_entered: 'A helyet megadta.',
              text_success_location: 'A következő adatokat állították be:',
              button_define_coordinates: 'A koordináták kézi megadása',
              text_no_coordinates_found: 'A megadott adatokhoz nem talált koordinátákat.',
              text_coordinates_success_set: 'A koordináták be vannak állítva.',
              button_set_custom_coordinates: 'A koordináták beállítása',
              text_specify_coordinates_yourself: 'Alternatív megoldásként beírhatja kézzel a koordinátákat.',
            },
          },
          machine: {
            fab_title: 'Új megmunkáló eszköz létrehozása',
            tabs: {
              info: 'Idősor',
              alarms: 'Riasztás',
              gateway: 'Gateway',
              service: 'Szerviz',
              spistat: 'SpiStat',
              swimlane: 'Gyártósor elérhetőség',
              'axis-test': 'Tengelyteszt',
              collection: 'Gyűjtemények',
              maintenance: 'Karbantartás',
              performance: 'Teljesítmény',
              spindledata: 'Orsóadatok',
              administration: 'Administracija',
              energymonitoring: 'Energiamonitoring',
            },
            move: {
              message: "A '%replaceValue' megmunkáló eszközt egy új vállalathoz, telephelyre, csarnokba és/vagy gyártósorba lehet költöztetni.",
              select_hall_label: 'Új csarnok kiválasztása',
              select_line_label: 'Új gyártósor kiválasztása',
              select_company_label: 'Új vállalat kiválasztása',
              select_location_label: 'Új telephely kiválasztása',
            },
            new: {
              fields: {
                s3: 'S3',
                name: 'Gépszám (SW)',
                iotcore: 'IoT Core',
                startup: 'Szállítás [opcionális]',
                shipping: 'Elküldés [opcionális]',
                controller: 'Vezérlés',
                machine_type: 'Gép típus',
                warranty_end: 'Garancia vége [opcionális]',
                iotgreengrass: 'IoT Greengrass',
                systemsManager: 'Upravitelj sustava',
                warranty_start: 'Garancia kezdete [opcionális]',
                machine_swmp_api: 'Gép SWCP API vége [opció]',
                machine_swsp_trace: 'Gép SWCP Trace vége [opció]',
                machine_swcp_portal: 'Gép SWCP Portal vége [opció]',
                start_of_production: 'A gyártás kezdete [opcionális]',
                machine_swcp_support: 'Gép SWCP Support vége [opció]',
                machine_cell_position: 'Gép cella / pozíció',
                machine_swsp_line_mon: 'Gép SWCP LineMon vége [opció]',
                machine_identifier_customer: 'Gép ID (ügyfél)',
              },
              error_messages: {
                name_not_specified: 'Kérem adjon meg egy érvényes SW-gépszámot (min, 7 karakter: A-Z, 0-9, -).',
                controller_not_specified: 'Kérem, válasszon ki egy vezérlést.',
                machine_type_not_specified: 'Kérem, válasszon ki egy géptípust.',
                machine_identifier_customer_not_specified: 'Kérem, adjon meg egy gép ID-t.',
              },
            },
            success: {
              title: 'Sikeres!',
              button: 'Vissza a listához',
              message: 'A megmunkáló eszközt sikeresen létrehozta.',
            },
            new_form_title: 'Megmunkáló eszköz hozzáadása',
            edit_form_title: 'A megmunkáló eszköz szerkesztése',
            move_form_title: 'A megmunkáló eszköz eltolása',
            editSuccess: {
              title: 'Sikeres!',
              button: 'Vissza a listához',
              message: 'A megmunkáló eszközt sikeresen aktualizálta.',
            },
            header: {
              labels: {
                api: 'SWMP API',
                trace: 'SWSP Trace',
                portal: 'SWCP Portal',
                lineMon: 'SWSP LineMon',
                support: 'SWCP Support',
                shipping: 'Elküldés dátuma',
                controller: 'Vezérlés',
                contractEnd: 'A megrendelés vége',
                machineType: 'Gép típus',
                startupDate: 'Az üzembevétel dátuma',
                warrantyEnd: 'A szavatosság vége',
                cellPosition: 'Cella / pozíció',
                warrantyStart: 'A szavatosság kezdete',
                customerNumber: 'Ügyfélszám',
                enrollmentConfig: 'Enrollment-konfiguráció',
                startOfProduction: 'A gyártás kezdete',
                machineIdentifierSw: 'Gépszám SW',
                enrollmentConfigDownload: 'Letöltés',
                machineIdentifierCustomer: 'Az ügyfél gépszáma',
              },
            },
            add_line_to_dashboard: 'Gyártósor hozzáadása a dashboardhoz',
            elements: {
              'axis-test': {
                kpi: 'KPI',
                unit: 'Egység',
                value: 'Érték',
                kpiUnit: 'Egység',
                headline: 'Tengelyteszt',
                kpiValue: 'Érték',
                kpiHeader: 'KPI',
                chartTitle: 'Tengelyteszt',
                limitsLoad: 'Betöltés a böngésző tárából',
                limitsSave: 'A böngésző tárába elmenteni',
                selectAxis: 'Tengely kiválasztása',
                limitsExport: 'A határértékek ezportálása a vágólapba',
                openAxistest: 'Tengelyteszt megnyitása',
                listbox: {
                  label: 'Típus / tengely(ek)',
                  noContentMessage: 'Tengelytípus kiválasztása',
                  noAxisTestAvailable: 'Ilyen típusú tengelyteszt nem áll rendelkezésre',
                },
                axisTestPlural: 'Tengelyteszt',
                noChartMessage: 'Baloldalt válasszon ki egy tengelytesztet.',
                adjustments: {
                  add: 'helyi kiigazítás hozzáadása',
                  range: 'Hatósugár',
                  header: 'Helyi kiigazítás',
                  remove: 'Eltávolítás',
                  position: 'Pozíció',
                  amplitude: 'Amplitúdó',
                  spreadMultiplier: 'Határérték-multiplikátor',
                },
                chart: {
                  Equability: {
                    x: 'Pozíció',
                    y: 'Áram',
                    up: 'fel',
                    down: 'le',
                    limitsUp: 'a pozitív irányt határolja',
                    limitsDown: 'a negatív irányt határolja',
                    sliderSlopeCenter: 'Növekedési eredet',
                    sliderSlopeFactor: 'Meredekségi tényező',
                    sliderVerticalOffset: 'Y-eltolás',
                    sliderVerticalSpread: 'Szélesség határolása',
                    sliderQuadraticCenter: 'Négyzetes eredet',
                    sliderQuadraticFactor: 'Négyzetes tényező',
                  },
                  Circularity: {
                    clockwise: 'Óramutató járásával megegyező irány',
                    limitsClockwise: 'Határ az óramutató járásával megegyező irányban',
                    counterClockwise: 'Óramutató járásával ellentétes irány',
                    sliderSineOffset: 'Hullámeltolás',
                    sliderSineAmplitude: 'Hullám-amplitúdó',
                    sliderSineFrequency: 'Hullámfrekvencia',
                    sliderVerticalOffset: 'Y-eltolás',
                    sliderVerticalSpread: 'Szélesség határolása',
                    limitsCounterClockwise: 'Határ az óramutató járásával ellentétes irányban',
                  },
                },
                kpis: {
                  pulseVelocity: 'Sebesség',
                  pulseMaxRampUp: 'Rámpa ->',
                  pulseMaxRampDown: 'Rámpa <-',
                  pulseMaxStiffness: 'Maximális merevség',
                  pulseMinStiffness: 'Minimális merevség',
                  pulseMeanStiffness: 'Merevség Ø',
                  circularReversalRange: 'Körkörös játék',
                  pulseMaxUpPathCurrent: 'Maximális áram ->',
                  pulseMinUpPathCurrent: 'Minimális áram ->',
                  pulseMeanUpPathCurrent: 'Áram Ø ->',
                  pulseMaxDownPathCurrent: 'Maximális áram <-',
                  pulseMinDownPathCurrent: 'Minimális áram <-',
                  pulseMeanDownPathCurrent: 'Áram Ø <-',
                  circularShapeDeviationClockwise: 'Körformától való eltérés jobbra',
                  pulseStiffnessStandardDeviation: 'Merevség σ',
                  pulseStandardDeviationUpPathCurrent: 'Áram σ ->',
                  pulseStandardDeviationDownPathCurrent: 'Áram σ <-',
                  circularShapeDeviationCounterClockwise: 'Körformától való eltérés balra',
                },
                axisLabels: {
                  CIRCLE: {
                    x: 'Pozíció',
                    y: 'Pozíció',
                  },
                  EQUABILITY: {
                    x: 'Pozíció',
                    y: 'Áram',
                  },
                },
                axisTestTypes: {
                  CIRCLE: 'Köralak-teszt',
                  EQUABILITY: 'Egyenes futás teszt',
                  CIRCULARITY: 'Köralak-teszt',
                },
              },
              gateway: {
                title: 'Gateway',
                contact: 'Hiba esetén forduljon',
                headline: 'Gateway',
                netcfg: {
                  cta: 'Kérjük, hogy a Netcfg-űrlap segítségével bocsássa a rendelkezésünkre az adatokat.',
                  intro: 'Ahhoz, hogy a gépet be lehessen csatlakoztatni, az ön hálózati konfigurációjának adataira vagyunk utalva.',
                  title: 'Hálózati konfiguráció',
                  goToNetconfig: 'Nyissa meg a hálózati konfigurációt',
                },
                device: {
                  title: 'Készülékrészletek',
                },
                enrollment: {
                  line1: 'A csempe alsó peremén levő gombra kattintva töltse le a Enrollment-fájlt.',
                  line2: 'A letöltés automatikusan elindul.',
                  line3: 'A .json-fájlt mentse egy USB pendrive gyökérkönyvtárába. Ellenőrizze le, hogy a fájl a következő módon van-e elnevezve:',
                  line4: 'enrollment.json.',
                  line5: 'Ellenőrizze le, hogy a következő fájlok az USB pendrive gyökérkönyvtárában vannak-e:',
                  line6: 'Csatlakoztassa az USB pendrive-ot a megfelelő IoT-gateway-hoz.',
                  line8: 'A sikeres bejelentkezés után a zöld LED addig világít, míg az USB pendrive-ot el nem távolítják.',
                  title: 'Enrollment',
                  button: 'Enrollment-fájl',
                },
                deviceMigration: {
                  line1: 'Töltse le a „migrate-iot.zip“ fájlt és csomagolja ki egy üres USB pendrive-ra.',
                  line2: 'aws.json',
                  line3: 'Töltse le még a következőt is: ',
                  line4: ' majd adja az USB-pendrive-hoz. Ellenőrizze le, hogy a fájl a következő módon van-e elnevezve:',
                  line5: 'Ellenőrizze le, hogy a következő fájlok az USB pendrive gyökérkönyvtárában vannak-e:',
                  line6: 'install.json',
                  line7: 'migration.tar.gz',
                  line8: 'migration.tar.gz.sig',
                  line9: 'migration.json',
                  title: 'Platform-migráció',
                  line10: 'Csatlakoztassa az USB pendrive-ot a megfelelő IoT-gateway-hoz.',
                  line11: 'A szoft telepítése automatikusan megtörténik, ezt egy villogó sárga LED jelzi.',
                  line12: 'A sikeres migráció után a zöld LED addig világít, míg az USB-pendrive-ot el nem távolítják.',
                  line13: 'A sikeres migráció után a zöld LED addig világít, míg az USB-pendrive-ot el nem távolítják.',
                  device: {
                    button: 'migrate-iot.zip',
                  },
                  generic: {
                    button: 'aws.json',
                  },
                },
              },
              info: {
                generate: 'Generálás',
                headline: 'Idősor',
                noChartMessage: 'A kívánt adatok kijelzéséhez válasszon ki egy vagy több változót és kattintson a generálásra',
              },
              alarms: {
                headline: 'Riasztás',
              },
              service: {
                headline: 'Szerviz',
                update: {
                  title: 'Fájl frissítése',
                },
                upload: {
                  title: 'Fájl feltöltése',
                  fields: {
                    file: 'Fájl',
                    language: 'Fájl nyelve',
                    file_name: 'Fájlnév',
                    list_of_languages: {
                      cz: 'Cseh',
                      de: 'Német',
                      en: 'Angol',
                      es: 'Spanyol',
                      fr: 'Francia',
                      hu: 'Magyar',
                      it: 'Olasz',
                      pl: 'Lengyel',
                      zh: 'Kínai',
                    },
                  },
                  buttons: {
                    send: 'Feltöltés',
                    cancel: 'Elvetés',
                  },
                  success: {
                    title: 'Sikeres',
                    button: 'Vissza a szerviz-áttekintéshez',
                    message: 'A fájlot sikeresen feltöltötte',
                  },
                  error_messages: {
                    file_name: 'Kérem, adjon meg egy fájlnevet.',
                    file_not_valid: 'Kérem adjon meg egy fájlot / válasszon ki egy kisebb fájlot.',
                    language_not_specified: 'Kérem, adja meg a fájl nyelvét.',
                  },
                },
                manuals: {
                  title: 'Kézikönyvek',
                },
                version: {
                  title: 'datoteke verzije',
                },
                contracts: {
                  title: 'Szerződések',
                },
                components: {
                  title: 'Hard- és szoftver alkotóelemek',
                },
                spare_parts: {
                  title: 'Alkatrészlista',
                },
                consumeables: {
                  title: 'Üzemanyagok',
                },
                Update: {
                  buttons: {
                    send: 'Frissítés',
                  },
                },
                software_edge: {
                  title: 'IoT Gateway szoftver',
                  values: {
                    box_id: 'Box-ID',
                    machine_connect: 'Machine Connect',
                    operating_system: 'Operációs rendszer',
                  },
                },
                contact_service: {
                  line1: 'Segítségre van szüksége a géppel kapcsolatban?',
                  line2: 'Vegye fel velünk a kapcsolatot!',
                  title: 'Üzenet az SW-szerviznek',
                  button: 'Kapcsolatfelvétel a szervizzel',
                  fields: {
                    name: 'Gépszám (SW)',
                    type: 'Kéréstípus',
                    email: 'E-mail',
                    message: 'Üzenet',
                    phone_no: 'Telefonszám',
                    last_name: 'Családnév',
                    first_name: 'Utónév',
                    contact_person: 'Kapcsolattartó (SW) [opcionális]',
                    list_of_types: {
                      request: 'Általános kérés',
                      service: 'Szerviz',
                      retrofit: 'Utólagos felszerelés',
                      expansion: 'Bővítés',
                      inspection: 'Inspekció',
                    },
                    machine_identifier_customer: 'Gép-ID (ügyfél) [opció]',
                  },
                  buttons: {
                    send: 'Kérés küldése',
                    cancel: 'Elvetés',
                  },
                  success: {
                    title: 'Sikeres!',
                    button: 'Vissza a szerviz-áttekintéshez',
                    message: 'A kérést sikeresen elküldte az SW-szerviznek.',
                  },
                  error_messages: {
                    email_not_valid: 'Kérem, adjon meg egy érvényes e-mail-címet.',
                    name_not_specified: 'Kérem adjon meg egy érvényes SW-gépszámot (min. 7 karakter: A-Z, 0-9, - _).',
                    phone_not_specified: 'Kérem, adjon meg egy érvényes telefonszámot.',
                    message_not_specified: 'Kérem, adjon meg egy hírt.',
                    last_name_not_specified: 'Kérem, adja meg a családnevét.',
                    first_name_not_specified: 'Kérem, adja meg az utónevét.',
                    family_name_not_specified: 'Kérem, adja meg a családnevét.',
                    contact_type_not_specified: 'Kérem, adja meg a kérés típusát.',
                  },
                },
                software_machine: {
                  title: 'Gép szoftver',
                  values: {
                    nc_version: 'NC-verzió',
                    hmi_version: 'HMI-verzió',
                    plc_version: 'PLC-verzió',
                    compile_cycles: 'Compile-ciklusok',
                    operating_system: 'Operációs rendszer',
                    pcu_base_version: 'PCU-Base-verzió',
                    plc_toolbox_version: 'PLC-Toolbox',
                    plc_user_program_version: 'PLC-felhasználói program',
                  },
                },
                changed: {
                  success: {
                    message: 'A fájl nevét sikeren megváltoztatta',
                  },
                },
                maintenance_assistance: {
                  intro: 'A kezelési asszisztens segít a problémamegoldásban.',
                  title: 'Kezelési asszisztens',
                  sub_title: 'Nehézségei vannak valamelyik megmunkáló eszközzel?',
                  open_assistant: 'Nyissa meg a kezelési asszisztenst',
                  request_account: 'Kérje a kontóját',
                },
              },
              spistat: {
                headline: 'SpiStat orsóstatisztika',
              },
              onlineState: {
                title: 'IoT Gateway',
                labelOnline: 'be',
                labelOffline: 'ki',
              },
              swimlane: {
                headline: 'Gyártósor elérhetőség',
              },
              sendingState: {
                title: 'Küldés státusza',
                aborted: 'elvetve',
                sending: 'küld',
                interrupted: 'megszakítva',
              },
              alarms_map: {
                headline: 'Riasztási térkép',
              },
              collection: {
                headline: 'Gyűjtemény',
                noChartMessage: 'A bal oldalon válasszon ki egy kollekciót.',
              },
              performance: {
                kpi: {
                  sum: 'Összeg',
                  header: 'Elérhetőség Kpi',
                  target: 'Névleges',
                },
                alarms: {
                  title: 'Riasztás',
                  category: [
                    'Nincs osztályozva',
                    'Hibajelentés',
                    'Üzemi jelentés',
                    'Állapotjelentés',
                  ],
                  icons: {
                    trend: 'Trend',
                    duration: 'Fennállás időtartama',
                    flatlist: 'Egyszerű lista',
                    frequency: 'Előfordulási gyakoriság',
                  },
                  tableHeadlines: {
                    time: 'Idő',
                    trend: 'Trend',
                    duration: 'Fennállás időtartama',
                    leftTime: 'A riasztás működik',
                    alarmCount: 'Darabszám',
                    alarmNumber: 'Riasztás-sz.',
                    description: 'Leírás',
                    alarmOccured: 'Riasztás lépett fel',
                    alarmClassification: 'Riasztások osztályozása',
                    alarmCountSecondRange: 'Darabszám T-1',
                  },
                  description: {
                    trend: {
                      up: 'növekvő',
                      down: 'csökkenő',
                      flat: 'egyenletes',
                    },
                  },
                },
                details: {
                  title: 'Részletek',
                  ncProgram: 'NC-program',
                  lastModified: 'Utolsó értékfeljegyzés',
                  machineState: 'Gép állapota',
                  numberAlarms: 'Függőben lévő riasztás',
                  stopCondition: 'Leállási feltételek',
                  ncProgramState: 'NC-program státusza',
                  protectionLevel: 'Védelmi fokozat',
                  ncProgramStates: [
                    'elvetve',
                    'leállítva',
                    'fut',
                    'várakozik',
                    'megszakítva',
                  ],
                  protectionLevels: [
                    'Rendszer',
                    'Gyártó',
                    'Szerviz',
                    'Felhasználó',
                    'Kulcsos kapcsoló 3. állás',
                    'Kulcsos kapcsoló 2. állás',
                    'Kulcsos kapcsoló 1. állás',
                    'Kulcsos kapcsoló 0. állás',
                  ],
                },
                cycleTime: {
                  title: 'Ciklusidő',
                  chart: {
                    yAxis: {
                      title: 'Ciklusidő másodpercben',
                    },
                  },
                },
                piececounter: {
                  ok: 'OK',
                  nok: 'NOK.',
                  sum: 'Összeg',
                  title: 'Munkadarab-számláló',
                  titleDayCounter: 'Napi mennyiség számláló',
                  titleShiftCounter: 'Mennyiség számláló egy műszakban',
                  titleTotalCounter: 'Összmennyiség számláló',
                  titleTotalCounterSmall: 'Összesen',
                },
                availability: {
                  title: 'Elérhetőség',
                  kpi: {
                    title: 'Elérhetőség KPI',
                  },
                  legend: {
                    auto: 'AUTO',
                    JOG_with_error: 'JOG hibával',
                    MDA_with_error: 'MDA hibával',
                    JOG_without_error: 'JOG hiba nélkül',
                    MDA_without_error: 'MDA hiba nélkül',
                    not_defined_condition: 'Nem értelmezett állapot',
                    auto_no_program_active: 'AUTO nincs aktív program',
                    auto_waiting_for_parts: 'AUTO alkatrészre várakozás',
                    auto_with_error_program_stopped: 'AUTO hibával - program leállítva',
                    auto_waiting_for_parts_stopping_error: 'AUTO alkatrészre várakozás + stophiba',
                  },
                  aggregated: {
                    title: 'Aggregált elérhetőség',
                  },
                  chart: {
                    yAxis: {
                      title: 'Elérhetőség',
                    },
                  },
                },
                inProduction: {
                  title: 'Státusz',
                },
                operationMode: {
                  title: 'Üzemmód',
                  chart: {
                    yAxis: {
                      title: 'Üzemmód',
                    },
                  },
                },
                runtimeMonitor: {
                  title: 'Működési idő monitor',
                  chart: {
                    yAxis: {
                      title: 'Program',
                      labels: {
                        on: 'be',
                        off: 'ki',
                      },
                    },
                  },
                },
                spindleOverride: {
                  title: 'Orsó override',
                },
                feedrateOverride: {
                  title: 'Előtolás override',
                },
                uptimeDistribution: {
                  title: 'Uptime Distribution',
                },
              },
              administration: {
                title: 'Administracija',
                headline: 'Administracija',
                aws: {
                  title: 'AWS veze',
                },
                references: {
                  values: {
                    s3: 'S3',
                    iot: 'IoT Core',
                    ssm: 'Upravitelj sustava',
                    greengrass: 'IoT Greengrass',
                  },
                },
              },
              condition: {
                bill: {
                  title: 'Darabjegyzék',
                },
                component: {
                  title: 'Alkotóelemek',
                },
              },
              maintenance: {
                headline: 'Karbantartás',
              },
              spindleData: {
                headline: 'Orsóadatok',
              },
              point8: {
                chart: {
                  xAxis: {
                    title: 'Riasztás előtti idő (s)',
                  },
                  yAxis: {
                    title: 'Riasztás utáni idő (s)',
                  },
                },
              },
              energymonitoring: {
                headline: 'Energiamonitoring',
                chartTitles: {
                  air: 'Sűrítettlevegő-fogyasztás',
                  current: 'Áramfogyasztás',
                  consumption: 'Prosječna potrošnja po satu',
                },
              },
              conditionMonitoring: {
                headline: 'Állapotfelügyelet',
              },
            },
          },
          location: {
            fab_title: 'Új telephely hozzáadása',
            tabs: {
              list: 'Csarnokok',
              shopfloor: 'Shopfloor',
            },
            grid: {
              hallName: 'Csarnok',
            },
            success: {
              title: 'Sikeres!',
              button: 'Vissza a listához',
              message: 'A telephelyt sikeresen létrehozta.',
            },
            new_form_title: 'Új telephely',
            edit_form_title: 'A telephely szerkesztése',
            new: {
              fields: {
                city: 'Város',
                name: 'Telephely neve',
                email: 'E-mail [opcionális]',
                state: 'Szövetségi tartomány [opcionális]',
                street: 'Cím',
                country: 'Ország',
                website: 'Honlap [opcionális]',
                zipcode: 'Postai irányítószám',
                language: 'Nyelv [opcionális]',
                name_short: 'Telephely neve (rövid változat) [opcionális]',
                description: 'Leírás [opcionális]',
                description_internal: 'Leírás (belső) [opcionális]',
              },
              error_messages: {
                email: 'Kérem, adjon meg egy érvényes e-mail-címet.',
                city_not_specified: 'Kérem, adja meg a várost.',
                name_not_specified: 'Kérem, adja meg a telephely nevét.',
                street_not_specified: 'Kérem, adja meg a postai címét.',
                country_not_specified: 'Kérem, adja meg az országot.',
                zipcode_not_specified: 'Kérem, adja meg a postai irányítószámot.',
              },
            },
            editSuccess: {
              title: 'Sikeres!',
              button: 'Vissza a listához',
              message: 'A telephelyt sikeresen frissítette.',
            },
            header: {
              labels: {
                address: 'Cím',
                country: 'Ország',
              },
            },
          },
          companies: {
            grid: {
              city: 'Hely',
              email: 'E-mail',
              street: 'Cím',
              country: 'Ország',
              website: 'Honlap',
              postalCode: 'Postai irányítószám',
              companyName: 'Vállalat',
            },
            tabs: {
              list: 'Vállalat',
              worldmap: 'Világtérkép',
            },
            map: {
              description_not_available: 'Nincs leírás',
            },
          },
          generic: {
            new: {
              buttons: {
                add: 'Hozzáadás',
                back: 'Vissza a listához',
                edit: 'Frissítés',
                save: 'Mentés',
                email: 'Pošalji sliku',
                cancel: 'Elvetés',
                addMultiple: 'Hozzáadás + új',
              },
            },
          },
          administration: {
            edit_form_title: 'Uredi administraciju',
          },
        },
        settings: {
          tabs: {
            limits: 'Határok',
          },
          button: {
            import: 'Import indítása',
            dismiss: 'Megszakítás',
            download: 'Letöltés indítása',
          },
        },
        file: {
          new: {
            buttons: {
              delete: 'Fájl törlése',
            },
          },
        },
        hall: {
          new: {
            buttons: {
              delete: 'Izbriši dvoranu',
            },
          },
        },
        line: {
          new: {
            buttons: {
              delete: 'Izbriši liniju',
            },
          },
        },
        runtimeStatus: {
          tileTitle: 'Státusz',
        },
        charts: {
          tooltipDateFormat: 'DD.MM.YYYY HH:mm:ss',
          exporting: {
            datetimeformat: '%d.%m.%Y %H:%M:%S',
          },
          globalSettings: {
            lang: {
              noData: 'Nincsenek adatok',
              loading: 'Adatok betöltése...',
              months: [
                'Január',
                'Február',
                'Március',
                'Április',
                'Május',
                'Június',
                'Július',
                'Augusztus',
                'Szeptember',
                'Október',
                'November',
                'December',
              ],
              resetZoom: 'Reset zoom',
              weekdays: [
                'Vasárnap',
                'Hétfő',
                'Kedd',
                'Szerda',
                'Csütörtök',
                'Péntek',
                'Szombat',
              ],
              shortMonths: [
                'Jan',
                'Febr',
                'Márc',
                'Ápr',
                'Máj',
                'Jún',
                'Júl',
                'Aug',
                'Szept',
                'Okt',
                'Nov',
                'Dec',
              ],
              shortWeekdays: [
                'V',
                'H',
                'K',
                'Sz',
                'Cs',
                'P',
                'Szo',
              ],
              increaseDateRange: 'minimum eddig:',
            },
          },
        },
        datepicker: {
          presets: {
            title: 'Előzetes beállítások',
            rangeTo: 'Eddig',
            selectDateRange: 'Válasszon ki egy időszakot',
            predefined: {
              today: 'Ma',
              last7days: 'Az utolsó 7 nap',
              yesterday: 'Tegnap',
              last24Hours: 'Az utolsó 24 óra',
              last5Minutes: 'Az utolsó 5 perc',
            },
          },
        },
        i18n: {
          chooseLanguagePhrase: 'Nyelv kiválasztása',
        },
        company: {
          new: {
            buttons: {
              delete: 'Izbriši tvrtku',
            },
          },
        },
        location: {
          new: {
            buttons: {
              delete: 'Izbriši lokaciju',
            },
          },
        },
        runtimeStatusList: {
          tileTitle: 'Státusz',
        },
        dashboard: {
          configure: {
            fab_title: 'Dashboard konfigurálása',
            hide: {
              title: 'elrejtés',
            },
            show: {
              title: 'kijelzés',
            },
            delete: {
              title: 'törlés',
            },
            add: {
              fab_title: 'Saját csempe hozzáadása',
            },
            save: {
              fab_title: 'A konfiguráció mentése',
            },
            move_left: {
              title: 'Az előző pozícióba tolás',
            },
            move_right: {
              title: 'A következő pozícióba tolás',
            },
            discard: {
              fab_title: 'A konfiguráció elvetése',
            },
            add_custom_card: {
              save: 'mentés',
              input: 'Adja meg a csempe nevét',
              title: 'Saját csempe hozzáadása',
              cancel: 'elvetés',
              missingMachine: 'Legalább egy gépet ki kell választania',
            },
          },
        },
        errors: {
          statusCodes: {
            e_0: {
              message: 'Egy váratlan hiba lépett fel. Próbálja meg az oldalt újra betölteni.',
            },
            e_1xx: {
              message: 'Informational Error',
            },
            e_2xx: {
              message: 'Siker',
            },
            e_3xx: {
              message: 'Továbbítás',
            },
            e_400: {
              message: 'A kérés hibás volt és nem sikerült feldolgozni.',
            },
            e_401: {
              message: 'Nincs bejelentkezve. Kérem, jelentkezzen újból be.',
            },
            e_403: {
              message: 'Nincs elegendő jogosultsága a kért erőforráshoz való hozzáféréshez.',
            },
            e_404: {
              message: 'A kért erőforrás nem elérhető.',
            },
            e_405: {
              message: 'A módszer nem megengedett.',
            },
            e_409: {
              message: 'Az erőforrás már létezik.',
            },
            e_418: {
              message: "I'm a Teapot! Yaay!",
            },
            e_4xx: {
              message: 'Client-hiba',
            },
            e_500: {
              message: 'Egy belső szerverhiba lépett fel.',
            },
            e_5xx: {
              message: 'Szerver-hiba',
            },
            e_900: {
              message: 'A megmunkáló eszköz létrehozása nem sikerült. Próbálja meg újra egy későbbi időpontban.',
            },
            e_901: {
              message: 'A műveletet megszakította. Nem lett új megmunkáló eszköz létrehozva.',
            },
            e_902: {
              message: 'A műveletet megszakította. A szervizkérés elküldése nem történt meg.',
            },
            e_903: {
              message: 'A szervizkérés elküldésekor valami félresikerült. Próbálja meg újra egy későbbi időpontban.',
            },
            e_904: {
              message: "A '%replaceValue' megmunkáló eszközt sikeresen létrehozta. Most további megmunkáló eszközöket tud hozzáadni.",
            },
            e_905: {
              message: 'A műveletet megszakította. A megmunkáló eszközt nem aktualizálta.',
            },
            e_906: {
              message: "A '%replaceValue' megmunkáló eszközt sikeresen áthelyezte.",
            },
            e_910: {
              message: 'Az új felhasználó létrehozása félresikerült. Próbálja meg újra egy későbbi időpontban.',
            },
            e_911: {
              message: 'A műveletet megszakította. Nem lett új felhasználó létrehozva.',
            },
            e_912: {
              message: 'A megmunkáló eszköz frissítése nem sikerült. Próbálja meg újra.',
            },
            e_920: {
              message: 'A műveletet megszakította. Nem voltak módosítások elmentve.',
            },
            e_921: {
              message: 'Egy váratlan hiba lépett fel. Próbálja meg még egyszer.',
            },
            e_922: {
              message: 'A felhasználónév és/vagy jelszó nem helyes. Próbálja meg még egyszer.',
            },
            e_923: {
              message: 'Kérem, adjon meg egy felhasználónevet és egy jelszót.',
            },
            e_924: {
              message: 'A munkamenet az inaktivitás miatt lejárt. Kérem, jelentkezzen be újból.',
            },
            e_925: {
              message: 'Ahhoz, hogy az SW Cloud Platformot használni tudja, el kell fogadnia az adatvédelmi nyilatkozatunkat.',
            },
            e_926: {
              message: 'A fiókja ideiglenesen le van tiltva. Egy pár perc múlva próbálja meg újból.',
            },
            e_927: {
              message: 'A jelszavát sikeresen megváltoztatta.',
            },
            e_928: {
              message: 'A műveletet megszakította. Nem töltött fel egyetlen fájlt sem.',
            },
            e_929: {
              message: 'Egy fájl feltöltésekor valami félresikerült. Próbálja meg újra egy későbbi időpontban...',
            },
            e_930: {
              message: 'Egy fájl törlési próbálkozásánál valami félresikerült. Próbálja meg újra egy későbbi időpontban.',
            },
            e_10500: {
              message: 'Úgy tűnik, nincs az internethez csatlakozva. Ellenőrizze le a csatlakozását.',
            },
            e_10501: {
              message: 'Egy nem támogatott böngészőt használ. Használja a következő böngészők egyikét: Google Chrome, Microsoft Edge, Apple Safari.',
            },
            e_409_asset: {
              message: 'A megmunkáló eszköz (SW-gépszám %replaceValue) már létezik.',
            },
            e_410_asset: {
              message: 'Ez a fájlnév %replaceValue már létezik',
            },
          },
        },
        list: {
          sweetalert: {
            maxAmountReached: {
              html: 'Egyidejűleg csak %s elemet lehet kiválasztani',
              title: 'Elérte a max. számot',
            },
          },
        },
      },
      module: {
        assets: {
          machine: {
            alarms: {
              no_alarms_text: 'Riasztás nem áll rendelkezésre',
            },
            nc_data_file_cardTitle: 'NC State Files',
            nc_state_files: {
              no_files_text: 'Nem állnak fájlok a rendelkezésre.',
            },
            nc_data_files: {
              column_title_trigger: 'Trigger',
              column_title_filename: 'Fájlnév',
              column_title_creation_date: 'A készítés dátuma',
              modules: {
                assets: {
                  nc_data_download: 'Az NC-adatok letöltése',
                },
              },
            },
          },
        },
      },
    },
  },
  it: {
    translation: {
      generic: {
        logout: 'Logout',
        release: "Il '%replaceValue1' dalle'%replaceValue2' alle '%replaceValue3' verranno eseguiti lavori di manutenzione sulla piattaforma. In quest’arco di tempo la piattaforma sarà parzialmente non disponibile.",
        pageTitle: 'SW CloudPlatform',
        closeLabel: 'Chiudere',
        acceptLabel: 'Accetta',
        userProfile: 'Profilo utente',
        declineLabel: 'Rifiuta',
        backToDashboard: 'Torna alla dashboard',
        footerLinks: {
          gtct: {
            url: 'https://sw-machines.com/it/condizioni-generali-di-contratto-norme/',
            title: 'Condizioni generali di vendita / Standard',
          },
          contact: {
            url: 'mailto:lifedata@sw-machines.com',
            title: 'Contatti',
          },
          imprint: {
            url: 'https://sw-machines.com/it/impronta/',
            title: 'impronta',
          },
          privacy: {
            title: 'Area privata',
            header: 'Norme sulla protezione dei dati',
          },
          swHomepage: {
            url: 'https://www.sw-machines.com/',
            title: 'SW Homepage',
          },
        },
        noInternetConnection: 'Non è presente una connessione a internet. Si prega di stabilire una connessione e di riprovare.',
      },
      modules: {
        delete: {
          text: 'L’azione sarà irreversibile!',
        },
        error: {
          upload: 'Caricamenti falliti',
          message: {
            enrollment_code_409: 'Il dispositivo è già registrato in aws.',
          },
        },
        qs: {
          tabs: {
            list: 'Foto temporizzata',
          },
          list: {
            report: {
              headline: 'Selezionare le variabili',
            },
          },
          grid: {
            columns: {
              datetime: 'Data',
            },
          },
        },
        frameTitle: {
          qs: 'Assicurazione qualità',
          users: 'Gestione utenti',
          assets: 'Gestione impianti',
          settings: 'Impostazioni',
          dashboard: 'Dashboard',
          userProfile: 'Profilo utente',
        },
        kpi: {
          tileTitle: 'KPI',
        },
        sidebar: {
          users: 'Gestione utenti',
          assets: 'Gestione impianti',
          dashboard: 'Dashboard',
          userProfile: 'Profilo utente',
        },
        generic: {
          noData: 'Nessun dato',
          dateFormat: 'DD.MM.YYYY',
          noDuration: 'nessuna durata disponibile',
          timeFormat: 'HH:mm:ss',
          alarmPending: 'Allarme presente',
          buttons: {
            back: 'Indietro',
            save: 'Salva',
            view: 'Visualizzare',
            cancel: 'Interrompi',
            export: 'Limiti per il download',
            import: 'Avvio',
            submit: 'Adotta',
            selectAllCheckboxes: 'Seleziona tutto',
            unselectAllCheckboxes: 'Annulla la selezione',
          },
          notAvailable: 'Non disponibile',
          notConnected: 'Non ancora connesso',
          dateTimeFormat: 'DD.MM.YYYY HH:mm:ss',
          timeFormats: {
            day: 'Giorno',
            days: 'Giorni',
            hour: 'Ora',
            year: 'Anno',
            hours: 'Ore',
            month: 'Mese',
            years: 'Anni',
            minute: 'Minuto',
            months: 'Mesi',
            second: 'Secondo',
            minutes: 'Minuti',
            seconds: 'Secondi',
          },
          notAvailableShort: 'n/a',
          timeFormatDuration: 'HH:mm:ss',
          timeFormatWithoutSeconds: 'HH:mm',
          sweetalert: {
            buttons: {
              understood: 'Compreso',
            },
          },
        },
        success: {
          upload: 'Caricamenti riusciti',
          statusCodes: {
            s_0: {
              message: 'Si è verificato un errore inatteso. Provare a ricaricare la pagina.',
            },
            s_1: {
              message: 'Le modifiche sono state salvate.',
            },
            s_930: {
              message: "L’asset '%replaceValue’ è stato cancellato con successo.",
            },
            s_931: {
              message: "L’azienda '%replaceValue’ è stata cancellata con successo.",
            },
            s_932: {
              message: "La linea '%replaceValue’ è stata cancellata con successo.",
            },
            s_933: {
              message: "La sala '%replaceValue’ è stata cancellata con successo.",
            },
            s_934: {
              message: "La location '%replaceValue’ è stata cancellata con successo.",
            },
            s_935: {
              message: "Il file '%replaceValue' è stato cancellato correttamente.",
            },
          },
          message: {
            duplication_deleted: 'Le cartine doppie sono state cancellate!',
            add_line_to_dashboard: 'La linea è stata aggiunta alla dashboard',
          },
        },
        limit: {
          tabs: {
            list: 'Limiti',
            type: 'Selezionare in base al tipo',
            machine: 'Selezionare in base all’ID della macchina',
            listImport: 'Importazione dei limiti',
            listDownload: 'Download dei limiti',
          },
          text: {
            text: 'I valori limite sono valori che l’utente stabilisce per le variabili di interesse rilevante. Se una variabile non rientra nel range stabilito tramite i valori limite appositamente definiti, l’utente riceverà un apposito messaggio. I valori limite vengono anche visualizzati in forma di diagrammi, che vengono generati nella scheda di registro “serie storiche”.',
            title: 'Limiti',
          },
          export: {
            text: 'I valori limite impostati e vigenti al momento attuale possono essere esportati in qualsiasi momento per tutte le variabili presenti nella piattaforma cloud. Questa lista può essere utilizzata come riferimento oppure può essere utilizzata per importate in una sola volta tutti i valori limite nella sezione “importare valori limite in formato CSV”',
            title: 'Limiti di esportazione come CSV',
          },
          import: {
            text: 'È possibile importare un file CSV precedentemente scaricato contenente le informazioni relative ai limiti e agli abbonati, al fine di aggiornare i valori attualmente salvati. Sarà possibile selezionare successivamente su quali tipi di impianto o quali tipi di macchina si desidera applicare i valori limite importati',
            title: 'Importare valori limite da CSV',
            machines: {
              title: 'Selezionare le macchine oppure il tipo di macchina sulle quali si desidera applicare i valori limite.',
            },
          },
          upload: {
            fields: {
              file: 'Selezionare il file CSV che si desidera importare',
            },
          },
        },
        manual: {
          line: {
            one: 'Passare alla linea desiderata nella gestione asset.',
            two: 'Nella riga dei titoli si trova un menu contestuale.',
            four: 'Il riquadro della linea viene aggiunto alla dashboard.',
            three: 'Cliccare sul menu contestuale e selezionare "Aggiungi alla dashboard".',
            header: 'Aggiungere riquadri della linea',
          },
          individual: {
            one: 'Cliccare sul simbolo della "rotella dentata" posto sul lato sinistro.',
            two: 'Selezionare il segno "più".',
            four: 'Confermare con "Salva". Il singolo riquadro viene aggiunto alla dashboard.',
            three: 'Denominare il riquadro e aggiungerlo alle macchine desiderate.',
            header: 'Aggiungere riquadri individuali',
          },
        },
        users: {
          fab_title: 'Creare un nuovo utente',
          new: {
            title: 'Crea utente',
            buttons: {
              add: 'Aggiungi',
              back: 'Torna alla lista',
              save: 'Salva',
              cancel: 'Interrompi',
              delete: 'Cancella utente',
            },
            fields: {
              email: 'E-mail',
              roles: 'Gruppo di utenti',
              gender: 'Genere',
              created: 'Creato',
              lastName: 'Cognome',
              firstName: 'Nome',
              breadcrumb: 'Mostra navigazione',
              main_group: 'Gruppo utenti',
              phoneNumber: 'Numero di telefono [optional]',
              customer_org: 'Organizzazione cliente',
              last_modified: 'Ultimo elaborato',
              email_validation: 'E-Mail (conferma)',
            },
            error_messages: {
              email_not_match: 'Gli indirizzi e-mail non corrispondono.',
              email_not_valid: 'Si prega di immettere un indirizzo e-mail valido.',
              roles_not_specified: 'Si prega di selezionare un gruppo utenti.',
              gender_not_specified: 'Prego immettere un genere.',
              last_name_not_specified: 'Si prega di immettere il cognome.',
              first_name_not_specified: 'Si prega di immettere il nome.',
              main_group_not_specified: 'Si prega di selezionare un gruppo utenti.',
              family_name_not_specified: 'Si prega di immettere il cognome.',
              customer_organization_not_specified: "Si prega di selezionare un'organizzazione cliente.",
            },
          },
          update: {
            title: 'Aggiorna utente',
          },
          success: {
            title: 'Completato con successo!',
            button: 'Torna alla lista',
            message: "L'utente è stato creato con successo. Abbiamo inviato all'indirizzo di posta elettronica indicato una e-mail con una password generata automaticamente.",
            message_for_edit: "L'utente è stato aggiornato con successo.",
            button_back_to_last_page: "Torna all'ultima pagina",
          },
          delete: {
            success: 'Cancellazione utente riuscita.',
            confirmation: {
              title: 'Si desidera davvero cancellare questo utente?',
              confirm: 'Si, cancella questo utente',
              dismiss: 'No, interrompi',
            },
          },
          groups: {
            visitor: 'Visitatore',
            customer: 'Cliente',
          },
          list: {
            grid: {
              user: 'Utente',
              email: 'E-mail',
              company: 'Azienda',
              enabled: 'Stato',
              lastName: 'Cognome',
              sw_admin: 'Amministratore SW',
              firstName: 'Nome',
              userRights: 'Stato diritti',
              phoneNumber: 'Numero di telefono',
            },
          },
          fields: {
            gender: {
              male: 'maschio',
              female: 'femmina',
              diverse: 'altro',
            },
          },
        },
        search: {
          noResults: 'Nessun risultato trovato',
          results: {
            of: 'da',
            lines: 'Linee',
            machines: 'Macchine',
          },
          callToAction: 'Ricerca macchine',
        },
        spistat: {
          tileTitle: 'SpiStat',
        },
        worldmap: {
          tileTitle: 'Cartina mondiale',
        },
        shopfloor: {
          tileTitle: 'Shopfloor',
          upload: {
            text1: 'Inviare un’immagine del vostro layout di shopfloor a lifedata@sw-machines.com (JPG, PNG, PDF).',
            text2: 'Se le vostre macchine sono registrate e inserite nella SW CloudPlatform, appariranno sulla sinistra e invieranno dati in tempo reale.',
            text3: 'A questo punto basta trascinare e rilasciare lo stato sulla vostra pianta presso la macchina corretta.',
            mainText: 'In questa vista è possibile impostare i vostri layout di shopfloor personalizzati e vedere le vostre macchine in diversi livelli di informazioni. Basta seguire questi passi.',
            tileTitle: 'Caricamento shopfloor',
          },
        },
        asset: {
          delete: {
            success: 'Asset cancellato con successo.',
            confirmation: {
              title: "Si desidera davvero cancellare '%replaceValue'?",
              confirm: 'cancella',
              dismiss: 'annulla',
            },
          },
          new: {
            buttons: {
              delete: 'Cancella asset',
            },
          },
          updateLimits: {
            confirmation: {
              intro: 'Si desidera adottare i valori limite propri di questo nuovo tipo di macchina?',
              title: 'È stata effettuata una modifica del tipo di macchina',
              accept: 'Si, adottare nuovi valori limite',
              decline: 'No, mantenere i valori limite attuali',
              dismiss: 'Interrompi',
              info_accept: 'Per sovrascrivere i valori limite attuali con i valori limite propri del nuovo tipo di macchina, selezionare ‘Si’.',
              info_decline: 'Per mantenere i valori limite attuali, selezionare ‘No’.',
              info_accept_warning: 'I valori limite attuali vengono sovrascritti oppure cancellati.',
            },
          },
        },
        assets: {
          line: {
            tabs: {
              kpi: 'KPIs',
              list: 'Macchine',
              shopfloor: 'Shopfloor',
            },
            fab_title: 'Aggiungere nuova linea',
            grid: {
              hall: 'Sala',
              line: 'Linea',
              type: 'Tipo',
              contractEnd: 'Fine contratto [optional]',
              cellPosition: 'Cella / Posizione [optional]',
              operationStep: 'Fase di lavorazione',
              machineIdentifierSw: 'Numero macchina (SW)',
              machineIdentifierCustomer: 'Nome macchina',
            },
            success: {
              title: 'Completato con successo!',
              button: 'Torna alla lista',
              message: 'La linea è stata creata con successo.',
            },
            new_form_title: 'Nuova linea',
            edit_form_title: 'Elabora linea',
            new: {
              fields: {
                name: 'Nome linea',
                name_short: 'Nome linea (in breve) [optional]',
                description: 'Descrizione [optional]',
              },
              error_messages: {
                name_not_specified: 'Si prega di immettere il nome della linea.',
              },
            },
            editSuccess: {
              title: 'Completato con successo!',
              button: 'Torna alla lista',
              message: 'La linea è stata aggiornata con successo.',
            },
          },
          hall: {
            fab_title: 'Aggiungere nuova sala',
            grid: {
              part: 'Tipo pezzi',
              lineName: 'Linea',
              typeOfLoading: 'Tipo di trattamento',
              lineResponsible: 'Responsabile della linea',
              plannedQuantity: 'Quantità programmata',
              numberOfMachines: '# Macchine',
            },
            tabs: {
              list: 'Linee',
              shopfloor: 'Shopfloor',
            },
            success: {
              title: 'Completato con successo!',
              button: 'Torna alla lista',
              message: 'La sala è stata creata con successo.',
            },
            new_form_title: 'Nuova sala',
            shopfloor: {
              edit: 'Modalità di lavorazione',
              configure: {
                save: {
                  fab_title: 'Salvare la configurazione',
                },
                discard: {
                  fab_title: 'Rigettare la configurazione',
                },
              },
            },
            edit_form_title: 'Elabora sala',
            new: {
              fields: {
                name: 'Nome sala',
                name_short: 'Nome della sala (in breve) [optional]',
                description: 'Descrizione [optional]',
                description_external: 'Descrizione (esterna) [optional]',
                description_internal: 'Descrizione (interna) [optional]',
              },
              error_messages: {
                name_not_specified: 'Si prega di immettere il nome della sala.',
              },
            },
            editSuccess: {
              title: 'Completato con successo!',
              button: 'Torna alla lista',
              message: 'La sala è stata aggiornata con successo.',
            },
          },
          grid: {
            description: 'Descrizione [optional]',
          },
          company: {
            fab_title: 'Aggiungere nuova azienda',
            grid: {
              city: 'Luogo',
              email: 'E-mail',
              street: 'Indirizzo',
              country: 'Paese',
              website: 'Sito internet',
              postalCode: 'CAP',
              locationName: 'Sede',
            },
            tabs: {
              list: 'Sedi',
              worldmap: 'Cartina mondiale',
            },
            success: {
              title: 'Completato con successo!',
              button: 'Torna alla lista',
              message: "L'azienda è stata creata con successo.",
            },
            toggle_switch: 'Creare una sede con gli stessi dati',
            new_form_title: 'Nuova azienda',
            edit_form_title: 'Elabora azienda',
            new: {
              fields: {
                city: 'Città',
                name: "Nome dell'azienda",
                email: 'E-mail [optional]',
                state: 'Stato federale [optional]',
                street: 'Indirizzo',
                country: 'Paese',
                website: 'Sito internet [optional]',
                zipcode: 'CAP',
                legal_form: 'Forma giuridica [optional]',
                name_short: "Nome dell'azienda (in breve) [optional]",
                description: 'Descrizione [optional]',
                company_logo: "Logo dell'azienda (URL) [optional]",
                customer_number: 'Numero cliente',
                industry_sector: 'Settore industriale',
                description_external: 'Descrizione (esterna) [optional]',
                description_internal: 'Descrizione (interna) [optional]',
              },
              error_messages: {
                url: 'Si prega di immettere un URL valido.',
                email: 'Si prega di immettere un indirizzo e-mail valido.',
                city_not_specified: 'Si prega di immettere la città.',
                name_not_specified: "Si prega di immettere il nome dell'azienda.",
                street_not_specified: "Si prega di immettere l'indirizzo.",
                country_not_specified: 'Si prega di immettere il paese.',
                zipcode_not_specified: 'Si prega di immettere il CAP.',
                customer_number_not_specified: 'Si prega di immettere un numero cliente.',
                industry_sector_not_specified: 'Si prega di immettere un settore industriale.',
              },
            },
            editSuccess: {
              title: 'Completato con successo!',
              button: 'Torna alla lista',
              message: "L'azienda è stata aggiornata con successo.",
            },
            header: {
              labels: {
                email: 'E-mail',
                address: 'Indirizzo',
                country: 'Paese',
                website: 'Sito internet',
              },
            },
            coordinateselement: {
              intro_text: 'La SW CloudPlatform rappresenta l’azienda in oggetto su una cartina; a tal fine utilizza i gradi di longitudine e latitudine.',
              intro_heading: 'Informazioni sul luogo',
              location_name: 'Nome del luogo:',
              label_latitude: 'Latitudine',
              label_longitude: 'Longitudine',
              text_fallback_info: 'In caso contrario, vengono utilizzate le coordinate standard.',
              text_location_found: 'È stato trovato un luogo.',
              text_location_entered: 'Il luogo è stato indicato.',
              text_success_location: 'I seguenti dati sono stati impostati:',
              button_define_coordinates: 'Immettere autonomamente le coordinate',
              text_no_coordinates_found: 'Non è stata trovata alcuna coordinata corrispondente ai dati immessi.',
              text_coordinates_success_set: 'Le coordinate sono impostate.',
              button_set_custom_coordinates: 'Impostare le coordinate',
              text_specify_coordinates_yourself: 'In alternativa è possibile immettere le coordinate autonomamente.',
            },
          },
          machine: {
            fab_title: 'Creare un nuovo asset',
            tabs: {
              info: 'Serie storiche',
              alarms: 'Allarme',
              gateway: 'Gateway',
              service: 'Servizio',
              spistat: 'SpiStat',
              swimlane: 'Disponibilità linea',
              'axis-test': 'Test assi',
              collection: 'Collezioni',
              maintenance: 'Manutenzione',
              performance: 'Performance',
              spindledata: 'Dati mandrino',
              administration: 'Amministrazione',
              energymonitoring: 'Monitoraggio energia',
            },
            move: {
              message: "L'asset '%replaceValue' può essere spostato in una nuova azienda, sede, sala e/o linea.",
              select_hall_label: 'Selezionare una nuova sala',
              select_line_label: 'Selezionare una nuova linea',
              select_company_label: 'Selezionare nuova azienda',
              select_location_label: 'Selezionare una nuova sede',
            },
            new: {
              fields: {
                s3: 'S3',
                name: 'Numero macchina (SW)',
                iotcore: 'IoT Core',
                startup: 'Fornitura [optional]',
                shipping: 'Spedizione [optional]',
                controller: 'Unità di comando',
                machine_type: 'Tipo di macchina',
                warranty_end: 'Termine della garanzia [optional]',
                iotgreengrass: 'IoT Greengrass',
                systemsManager: 'Systems Manager',
                warranty_start: 'Inizio della garanzia [optional]',
                machine_swmp_api: 'Macchine SWMP API fine [optional]',
                machine_swsp_trace: 'Macchine SWSP Trace fine [optional]',
                machine_swcp_portal: 'Macchine SWCP Portal fine [optional]',
                start_of_production: 'Inizio della produzione [optional]',
                machine_swcp_support: 'Macchine SWCP Support fine [optional]',
                machine_cell_position: 'Cella macchina / Posizione',
                machine_swsp_line_mon: 'Macchine SWSP LineMon fine [optional]',
                machine_identifier_customer: 'ID macchine (cliente)',
              },
              error_messages: {
                name_not_specified: 'Si prega si immettere un numero macchina SW valido (minimo 7 caratteri: A-Z, 0-9, -).',
                controller_not_specified: "Si prega di selezionare un'unità di comando.",
                machine_type_not_specified: 'Si prega di selezionare un tipo di macchina.',
                machine_identifier_customer_not_specified: "Si prega di immettere l'ID macchina.",
              },
            },
            success: {
              title: 'Completato con successo!',
              button: 'Torna alla lista',
              message: "L'asset è stato creato con successo.",
            },
            new_form_title: 'Aggiungi asset',
            edit_form_title: 'Elabora asset',
            move_form_title: "Spostare l'asset",
            editSuccess: {
              title: 'Completato con successo!',
              button: 'Torna alla lista',
              message: "L'asset è stato aggiornato con successo.",
            },
            header: {
              labels: {
                api: 'SWMP API',
                trace: 'SWSP Trace',
                portal: 'SWCP Portal',
                lineMon: 'SWSP LineMon',
                support: 'SWCP Support',
                shipping: 'Data di spedizione',
                controller: 'Unità di comando',
                contractEnd: 'Fine contratto',
                machineType: 'Tipo di macchina',
                startupDate: 'Data messa in funzione',
                warrantyEnd: 'Fine garanzia',
                cellPosition: 'Cella / Posizione',
                warrantyStart: 'Inizio garanzia',
                customerNumber: 'Numero cliente',
                enrollmentConfig: 'Configurazione della registrazione',
                startOfProduction: 'Inizio della produzione',
                machineIdentifierSw: 'Numero macchina SW',
                enrollmentConfigDownload: 'Scarica',
                machineIdentifierCustomer: 'Nome macchina cliente',
              },
            },
            add_line_to_dashboard: 'Aggiungi linea alla dashboard',
            elements: {
              'axis-test': {
                kpi: 'KPI',
                unit: 'Unità',
                value: 'Valore',
                kpiUnit: 'Unità',
                headline: 'Test assi',
                kpiValue: 'Valore',
                kpiHeader: 'KPI',
                chartTitle: 'Test asse',
                limitsLoad: 'Caricare da memoria browser',
                limitsSave: 'Salvare nella memoria browser',
                selectAxis: 'Selezionare asse',
                limitsExport: 'Esportare i valori limite nella memoria temporanea',
                openAxistest: 'Aprire test assi',
                listbox: {
                  label: 'Tipo / Asse(i)',
                  noContentMessage: 'Selezionare tipo di test assi',
                  noAxisTestAvailable: 'Nessun test assi di questo tipo disponibile',
                },
                axisTestPlural: 'Test assi',
                noChartMessage: 'Si prega di selezionare una collezione dal lato sinistro.',
                adjustments: {
                  add: 'aggiungere adattamento locale',
                  range: 'Portata',
                  header: 'Adattamento locale',
                  remove: 'Rimuovi',
                  position: 'Posizione',
                  amplitude: 'Ampiezza',
                  spreadMultiplier: 'Moltiplicatore limiti',
                },
                chart: {
                  Equability: {
                    x: 'Posizione',
                    y: 'Corrente',
                    up: 'su',
                    down: 'giù',
                    limitsUp: 'limita la direzione positiva',
                    limitsDown: 'limita la direzione negativa',
                    sliderSlopeCenter: 'Origine incremento',
                    sliderSlopeFactor: 'Fattore di incremento',
                    sliderVerticalOffset: 'Traslazione Y',
                    sliderVerticalSpread: 'Limitare ampiezza',
                    sliderQuadraticCenter: 'Origine quadratica',
                    sliderQuadraticFactor: 'Fattore quadratico',
                  },
                  Circularity: {
                    clockwise: 'Senso orario',
                    limitsClockwise: 'Limiti in senso orario',
                    counterClockwise: 'Senso antiorario',
                    sliderSineOffset: 'Sfasamento onda',
                    sliderSineAmplitude: 'Ampiezza onda',
                    sliderSineFrequency: 'Frequenza onda',
                    sliderVerticalOffset: 'Traslazione Y',
                    sliderVerticalSpread: 'Limitare ampiezza',
                    limitsCounterClockwise: 'Limiti in senso antiorario',
                  },
                },
                kpis: {
                  pulseVelocity: 'Velocità',
                  pulseMaxRampUp: 'Rampa ->',
                  pulseMaxRampDown: 'Rampa <-',
                  pulseMaxStiffness: 'Rigidità massima',
                  pulseMinStiffness: 'Rigidità minima',
                  pulseMeanStiffness: 'Rigidità Ø',
                  circularReversalRange: 'Errore di inversione circolare',
                  pulseMaxUpPathCurrent: 'Corrente massima ->',
                  pulseMinUpPathCurrent: 'Corrente minima ->',
                  pulseMeanUpPathCurrent: 'Corrente Ø ->',
                  pulseMaxDownPathCurrent: 'Corrente massima <-',
                  pulseMinDownPathCurrent: 'Corrente minima <-',
                  pulseMeanDownPathCurrent: 'Corrente Ø <-',
                  circularShapeDeviationClockwise: 'Scostamento dalla circolarità rotazione destra',
                  pulseStiffnessStandardDeviation: 'Rigidità σ',
                  pulseStandardDeviationUpPathCurrent: 'Corrente σ ->',
                  pulseStandardDeviationDownPathCurrent: 'Corrente σ <-',
                  circularShapeDeviationCounterClockwise: 'Scostamento dalla circolarità rotazione sinistra',
                },
                axisLabels: {
                  CIRCLE: {
                    x: 'Posizione',
                    y: 'Posizione',
                  },
                  EQUABILITY: {
                    x: 'Posizione',
                    y: 'Corrente',
                  },
                },
                axisTestTypes: {
                  CIRCLE: 'Test di circolarità',
                  EQUABILITY: 'Test di sincronismo',
                  CIRCULARITY: 'Test di circolarità',
                },
              },
              gateway: {
                title: 'Gateway',
                contact: 'In caso di errore prego rivolgersi a',
                headline: 'Gateway',
                netcfg: {
                  cta: 'Vi preghiamo di metterci a disposizione i dati relativi al modulo Netcfg.',
                  intro: 'Per potere procedere alla connessione della Vostra macchina, dobbiamo basarci sulle Vostre indicazioni relative alla configurazione di rete.',
                  title: 'Configurazione di rete',
                  goToNetconfig: 'Aprire la configurazione di rete',
                },
                device: {
                  title: 'Dettagli dispositivo',
                },
                enrollment: {
                  line1: 'Scaricare il file di registrazione cliccando sul pulsante posto sul margine inferiore del riquadro.',
                  line2: 'Il download inizia automaticamente.',
                  line3: 'Salvare il file .json nella cartella principale di una chiavetta USB. Assicurarsi che il file sia denominato:',
                  line4: 'enrollment.json.',
                  line5: 'Assicurarsi che i seguenti file si trovino nella cartella principale della chiavetta USB:',
                  line6: 'Collegare la chiavetta USB alla corrispondete porta IoT Gateway.',
                  line8: 'Una volta effettuato con successo il login, il LED verde si accende fino a che la chiavetta USB viene rimossa.',
                  title: 'Registrazione',
                  button: 'File di registrazione',
                },
                deviceMigration: {
                  line1: 'Scaricare il file "migrate-iot.zip" ed estrarre il contenuto direttamente su una chiavetta USB vuota.',
                  line2: 'aws.json',
                  line3: 'Scaricare anche la ',
                  line4: ' e aggiungerla alla chiavetta USB. Assicurarsi che il file sia denominato come segue:',
                  line5: 'Assicurarsi che i seguenti file si trovino nella cartella principale della chiavetta USB:',
                  line6: 'install.json',
                  line7: 'migration.tar.gz',
                  line8: 'migration.tar.gz.sig',
                  line9: 'migration.json',
                  title: 'Migrazione di piattaforma',
                  line10: 'Collegare la chiavetta USB alla corrispondete porta IoT Gateway.',
                  line11: 'Il software si installa automaticamente; la procedura di installazione in corso viene segnalata da un LED giallo lampeggiante.',
                  line12: 'Una volta effettuata con successo la migrazione, il LED verde si accende fino a che la chiavetta USB viene rimossa.',
                  line13: 'Una volta effettuata con successo la migrazione, il LED verde si accende fino a che la chiavetta USB viene rimossa.',
                  device: {
                    button: 'migrate-iot.zip',
                  },
                  generic: {
                    button: 'aws.json',
                  },
                },
              },
              info: {
                generate: 'Generare',
                headline: 'Serie storiche',
                noChartMessage: 'Per visualizzare i dati desiderati, selezionare una o più variabili e cliccare su "generare"',
              },
              alarms: {
                headline: 'Allarme',
              },
              service: {
                headline: 'Servizio',
                update: {
                  title: 'Aggiornare file',
                },
                upload: {
                  title: 'Caricare il file',
                  fields: {
                    file: 'File',
                    language: 'Lingua del file',
                    file_name: 'Nome file',
                    list_of_languages: {
                      cz: 'Ceco',
                      de: 'Tedesco',
                      en: 'Inglese',
                      es: 'Spagnolo',
                      fr: 'Francese',
                      hu: 'Ungherese',
                      it: 'Italiano',
                      pl: 'Polacco',
                      zh: 'Cinese',
                    },
                  },
                  buttons: {
                    send: 'Carica',
                    cancel: 'Interrompi',
                  },
                  success: {
                    title: 'Completato con successo',
                    button: 'Torna al riepilogo dei servizi',
                    message: 'Il file è stato caricato con successo',
                  },
                  error_messages: {
                    file_name: 'Prego immettere un nome di file.',
                    file_not_valid: 'Si prega di indicare un file / Si prega di selezionare un file di dimensioni minori.',
                    language_not_specified: 'Si prega di immettere una lingua del file.',
                  },
                },
                manuals: {
                  title: 'Manuali',
                },
                version: {
                  title: 'versione file',
                },
                contracts: {
                  title: 'Contratti',
                },
                components: {
                  title: 'Componenti hardware e software',
                },
                spare_parts: {
                  title: 'Elenco pezzi di ricambio',
                },
                consumeables: {
                  title: 'Fluidi operativi',
                },
                Update: {
                  buttons: {
                    send: 'Aggiorna',
                  },
                },
                software_edge: {
                  title: 'Software IoT Gateway',
                  values: {
                    box_id: 'ID box',
                    machine_connect: 'Machine Connect',
                    operating_system: 'Sistema operativo',
                  },
                },
                contact_service: {
                  line1: 'Avete bisogno di aiuto con la Vostra macchina?',
                  line2: 'Mettetevi in contatto con noi!',
                  title: 'Messaggio a servizio assistenza SW',
                  button: 'Contattare servizio di assistenza clienti',
                  fields: {
                    name: 'Numero macchina (SW)',
                    type: 'Tipo di richiesta',
                    email: 'E-mail',
                    message: 'Messaggio',
                    phone_no: 'Numero di telefono',
                    last_name: 'Cognome',
                    first_name: 'Nome',
                    contact_person: 'Persona di riferimento (SW) [optional]',
                    list_of_types: {
                      request: 'Richiesta generica',
                      service: 'Servizio',
                      retrofit: 'Retrofit',
                      expansion: 'Ampliamento',
                      inspection: 'Ispezione',
                    },
                    machine_identifier_customer: 'ID macchina (cliente) [optional]',
                  },
                  buttons: {
                    send: 'Invia richiesta',
                    cancel: 'Interrompi',
                  },
                  success: {
                    title: 'Completato con successo!',
                    button: 'Torna al riepilogo dei servizi',
                    message: 'La richiesta è stata trasmessa con successo al servizio di assistenza SW.',
                  },
                  error_messages: {
                    email_not_valid: 'Si prega di immettere un indirizzo e-mail valido.',
                    name_not_specified: 'Si prega si immettere un numero macchina SW valido (minimo 7 caratteri: A-Z, 0-9, - _).',
                    phone_not_specified: 'Si prega di immettere un numero di telefono valido.',
                    message_not_specified: 'Si prega di immettere un messaggio.',
                    last_name_not_specified: 'Si prega di immettere il cognome.',
                    first_name_not_specified: 'Si prega di immettere il nome.',
                    family_name_not_specified: 'Si prega di immettere il cognome.',
                    contact_type_not_specified: 'Si prega di indicare il tipo di richiesta.',
                  },
                },
                software_machine: {
                  title: 'Software macchina',
                  values: {
                    nc_version: 'Versione NC',
                    hmi_version: 'Versione HMI',
                    plc_version: 'Versione PLC',
                    compile_cycles: 'Cicli Compile',
                    operating_system: 'Sistema operativo',
                    pcu_base_version: 'Versione PCU-Base',
                    plc_toolbox_version: 'Toolbox PLC',
                    plc_user_program_version: 'Programma utente PLC',
                  },
                },
                changed: {
                  success: {
                    message: 'Il nome del file è stato modificato correttamente',
                  },
                },
                maintenance_assistance: {
                  intro: 'L’assistente operativo potrà aiutarvi nella risoluzione dei problemi.',
                  title: 'Assistente operativo',
                  sub_title: 'Siete momentaneamente in difficoltà con uno dei Vostri asset?',
                  open_assistant: 'Aprire l’assistente operativo',
                  request_account: 'Richiedere un account',
                },
              },
              spistat: {
                headline: 'Statistiche mandrino SpiStat',
              },
              onlineState: {
                title: 'IoT Gateway',
                labelOnline: 'On',
                labelOffline: 'Off',
              },
              swimlane: {
                headline: 'Disponibilità linea',
              },
              sendingState: {
                title: 'Stato invio',
                aborted: 'annullato',
                sending: 'inviato',
                interrupted: 'interrotto',
              },
              alarms_map: {
                headline: 'Mappa degli allarmi',
              },
              collection: {
                headline: 'Collezione',
                noChartMessage: 'Si prega di selezionare una collezione dal lato sinistro.',
              },
              performance: {
                kpi: {
                  sum: 'Somma',
                  header: 'Performance Kpi',
                  target: 'Nominale',
                },
                alarms: {
                  title: 'Allarme',
                  category: [
                    'Non classificato',
                    'Messaggio di errore',
                    'Messaggio di funzionamento',
                    'Messaggio di stato',
                  ],
                  icons: {
                    trend: 'Tendenza',
                    duration: 'Durata della presenza',
                    flatlist: 'Elenco semplice',
                    frequency: 'Frequenza di comparsa',
                  },
                  tableHeadlines: {
                    time: 'Tempo',
                    trend: 'Tendenza',
                    duration: 'Durata della presenza',
                    leftTime: 'Allarme in corso',
                    alarmCount: 'Numero',
                    alarmNumber: 'N. allarme',
                    description: 'Descrizione',
                    alarmOccured: 'Si è verificato un allarme',
                    alarmClassification: 'Classificazione allarme',
                    alarmCountSecondRange: 'Numero T-1',
                  },
                  description: {
                    trend: {
                      up: 'Crescente',
                      down: 'Discendente',
                      flat: 'Invariato',
                    },
                  },
                },
                details: {
                  title: 'Dettagli',
                  ncProgram: 'Programma NC',
                  lastModified: 'Ultima registrazione di valori',
                  machineState: 'Stato della macchina',
                  numberAlarms: 'Allarme presente',
                  stopCondition: 'Condizioni di arresto',
                  ncProgramState: 'Stato programma NC',
                  protectionLevel: 'Livello di protezione',
                  ncProgramStates: [
                    'annullato',
                    'sospeso',
                    'in corso',
                    'in attesa',
                    'interrotto',
                  ],
                  protectionLevels: [
                    'Sistema',
                    'Produttore',
                    'Servizio',
                    'Utilizzatore',
                    'Interruttore a chiave posizione 3',
                    'Interruttore a chiave posizione 2',
                    'Interruttore a chiave posizione 1',
                    'Interruttore a chiave posizione 0',
                  ],
                },
                cycleTime: {
                  title: 'Tempo di ciclo',
                  chart: {
                    yAxis: {
                      title: 'Tempo di ciclo in s',
                    },
                  },
                },
                piececounter: {
                  ok: 'OK',
                  nok: 'Non OK',
                  sum: 'Somma',
                  title: 'Contatore pezzi',
                  titleDayCounter: 'Contatore giorni',
                  titleShiftCounter: 'Contatore turni',
                  titleTotalCounter: 'Contatore complessivo',
                  titleTotalCounterSmall: 'Complessivo',
                },
                availability: {
                  title: 'Disponibilità',
                  kpi: {
                    title: 'Disponibilità KPI',
                  },
                  legend: {
                    auto: 'AUTO',
                    JOG_with_error: 'JOG con errore',
                    MDA_with_error: 'MDA con errore',
                    JOG_without_error: 'JOG senza errore',
                    MDA_without_error: 'MDA senza errore',
                    not_defined_condition: 'Stato non definito',
                    auto_no_program_active: 'AUTO Nessun programma attivo',
                    auto_waiting_for_parts: 'AUTO Attesa dei pezzi',
                    auto_with_error_program_stopped: 'AUTO con errore - programma arrestato',
                    auto_waiting_for_parts_stopping_error: 'AUTO Attesa dei pezzi + errore di arresto',
                  },
                  aggregated: {
                    title: 'Disponibilità aggregata',
                  },
                  chart: {
                    yAxis: {
                      title: 'Disponibilità',
                    },
                  },
                },
                inProduction: {
                  title: 'Stato',
                },
                operationMode: {
                  title: 'Stato di funzionamento',
                  chart: {
                    yAxis: {
                      title: 'Stato di funzionamento',
                    },
                  },
                },
                runtimeMonitor: {
                  title: 'Monitor tempo di funzionamento',
                  chart: {
                    yAxis: {
                      title: 'Programma',
                      labels: {
                        on: 'on',
                        off: 'off',
                      },
                    },
                  },
                },
                spindleOverride: {
                  title: 'Override mandrino',
                },
                feedrateOverride: {
                  title: 'Override avanzamento',
                },
                uptimeDistribution: {
                  title: 'Uptime Distribution',
                },
              },
              administration: {
                title: 'Amministrazione',
                headline: 'Amministrazione',
                aws: {
                  title: 'Link AWS',
                },
                references: {
                  values: {
                    s3: 'S3',
                    iot: 'IoT Core',
                    ssm: 'Systems Manager',
                    greengrass: 'IoT Greengrass',
                  },
                },
              },
              condition: {
                bill: {
                  title: 'Lista componenti',
                },
                component: {
                  title: 'Componenti',
                },
              },
              maintenance: {
                headline: 'Manutenzione',
              },
              spindleData: {
                headline: 'Dati mandrino',
              },
              point8: {
                chart: {
                  xAxis: {
                    title: 'Tempo prima di allarme (s)',
                  },
                  yAxis: {
                    title: 'Tempo dopo allarme (s)',
                  },
                },
              },
              energymonitoring: {
                headline: 'Monitoraggio energia',
                chartTitles: {
                  air: 'Consumo di aria compressa',
                  current: 'Consumo di corrente',
                  consumption: 'Consumo medio all’ora',
                },
              },
              conditionMonitoring: {
                headline: 'Monitoraggio dello stato',
              },
            },
          },
          location: {
            fab_title: 'Aggiungere nuova sede',
            tabs: {
              list: 'Sale',
              shopfloor: 'Shopfloor',
            },
            grid: {
              hallName: 'Sala',
            },
            success: {
              title: 'Completato con successo!',
              button: 'Torna alla lista',
              message: 'La sede è stata creata con successo.',
            },
            new_form_title: 'Nuova sede',
            edit_form_title: 'Elabora sede',
            new: {
              fields: {
                city: 'Città',
                name: 'Nome della sede',
                email: 'E-mail [optional]',
                state: 'Stato federale [optional]',
                street: 'Indirizzo',
                country: 'Paese',
                website: 'Sito internet [optional]',
                zipcode: 'CAP',
                language: 'Lingua [optional]',
                name_short: 'Nome della sede (in breve) [optional]',
                description: 'Descrizione [optional]',
                description_internal: 'Descrizione (interna) [optional]',
              },
              error_messages: {
                email: 'Si prega di immettere un indirizzo e-mail valido.',
                city_not_specified: 'Si prega di immettere la città.',
                name_not_specified: 'Si prega di immettere il nome della sede.',
                street_not_specified: "Si prega di immettere l'indirizzo.",
                country_not_specified: 'Si prega di immettere il paese.',
                zipcode_not_specified: 'Si prega di immettere il CAP.',
              },
            },
            editSuccess: {
              title: 'Completato con successo!',
              button: 'Torna alla lista',
              message: 'La sede è stata aggiornata con successo.',
            },
            header: {
              labels: {
                address: 'Indirizzo',
                country: 'Paese',
              },
            },
          },
          companies: {
            grid: {
              city: 'Luogo',
              email: 'E-mail',
              street: 'Indirizzo',
              country: 'Paese',
              website: 'Sito internet',
              postalCode: 'CAP',
              companyName: 'Azienda',
            },
            tabs: {
              list: 'Azienda',
              worldmap: 'Cartina mondiale',
            },
            map: {
              description_not_available: 'Nessuna descrizione presente',
            },
          },
          generic: {
            new: {
              buttons: {
                add: 'Aggiungi',
                back: 'Torna alla lista',
                edit: 'Aggiorna',
                save: 'Salva',
                email: 'Invia immagine',
                cancel: 'Interrompi',
                addMultiple: 'Aggiungi + nuovo',
              },
            },
          },
          administration: {
            edit_form_title: 'Edita amministrazione',
          },
        },
        settings: {
          tabs: {
            limits: 'Limiti',
          },
          button: {
            import: 'Avvio importazione',
            dismiss: 'Interruzione',
            download: 'Avvio download',
          },
        },
        file: {
          new: {
            buttons: {
              delete: 'Cancellare file',
            },
          },
        },
        hall: {
          new: {
            buttons: {
              delete: 'Cancella sala',
            },
          },
        },
        line: {
          new: {
            buttons: {
              delete: 'Cancella linea',
            },
          },
        },
        runtimeStatus: {
          tileTitle: 'Stato',
        },
        charts: {
          tooltipDateFormat: 'DD.MM.YYYY HH:mm:ss',
          exporting: {
            datetimeformat: '%d.%m.%Y %H:%M:%S',
          },
          globalSettings: {
            lang: {
              noData: 'Nessun dato presente',
              loading: 'Caricamento dati...',
              months: [
                'Gennaio',
                'Febbraio',
                'Marzo',
                'Aprile',
                'Maggio',
                'Giugno',
                'Luglio',
                'Agosto',
                'Settembre',
                'Ottobre',
                'Novembre',
                'Dicembre',
              ],
              resetZoom: 'Resettare zoom',
              weekdays: [
                'Domenica',
                'Lunedì',
                'Martedì',
                'Mercoledì',
                'Giovedì',
                'Venerdì',
                'Sabato',
              ],
              shortMonths: [
                'Gen',
                'Feb',
                'Mar',
                'Apr',
                'Mag',
                'Giu',
                'Lug',
                'Ago',
                'Set',
                'Ott',
                'Nov',
                'Dic',
              ],
              shortWeekdays: [
                'Dom',
                'Lun',
                'Mar',
                'Mer',
                'Gio',
                'Ven',
                'Sab',
              ],
              increaseDateRange: 'almeno fino a',
            },
          },
        },
        datepicker: {
          presets: {
            title: 'Impostazioni di default',
            rangeTo: 'Fino a',
            selectDateRange: 'Prego selezionare un intervallo di tempo',
            predefined: {
              today: 'Oggi',
              last7days: 'Ultimi 7 giorni',
              yesterday: 'Ieri',
              last24Hours: 'Ultime 24 ore',
              last5Minutes: 'Ultimi 5 minuti',
            },
          },
        },
        i18n: {
          chooseLanguagePhrase: 'Selezionare la lingua',
        },
        company: {
          new: {
            buttons: {
              delete: 'Cancella azienda',
            },
          },
        },
        location: {
          new: {
            buttons: {
              delete: 'Cancella posizione',
            },
          },
        },
        runtimeStatusList: {
          tileTitle: 'Stato',
        },
        dashboard: {
          configure: {
            fab_title: 'Configurare dashboard',
            hide: {
              title: 'Nascondere',
            },
            show: {
              title: 'Visualizzare',
            },
            delete: {
              title: 'cancella',
            },
            add: {
              fab_title: 'Aggiungere dei propri pannelli',
            },
            save: {
              fab_title: 'Salvare la configurazione',
            },
            move_left: {
              title: 'Spostare nella posizione precedente',
            },
            move_right: {
              title: 'Spostare alla posizione successiva',
            },
            discard: {
              fab_title: 'Rigettare la configurazione',
            },
            add_custom_card: {
              save: 'salva',
              input: 'Immettere il nome del pannello',
              title: 'Aggiungere dei propri pannelli',
              cancel: 'interrompi',
              missingMachine: "E' necessario selezionare almeno una macchina",
            },
          },
        },
        errors: {
          statusCodes: {
            e_0: {
              message: 'Si è verificato un errore inatteso. Provare a ricaricare la pagina.',
            },
            e_1xx: {
              message: 'Informational Error',
            },
            e_2xx: {
              message: 'Successo',
            },
            e_3xx: {
              message: 'Inoltro',
            },
            e_400: {
              message: 'La richiesta risulta errata; non è stato possibile elaborarla.',
            },
            e_401: {
              message: "L'utente non è autenticato. Si prega di effettuare nuovamente il login.",
            },
            e_403: {
              message: 'Non si dispone di autorizzazioni sufficienti per accedere alla risorsa richiesta.',
            },
            e_404: {
              message: 'La risorsa richiesta non è disponibile.',
            },
            e_405: {
              message: 'Metodo non consentito.',
            },
            e_409: {
              message: 'La risorsa è già esistente.',
            },
            e_418: {
              message: "I'm a Teapot! Yaay!",
            },
            e_4xx: {
              message: 'Errore client',
            },
            e_500: {
              message: 'Si è verificato un errore server interno.',
            },
            e_5xx: {
              message: 'Errore server',
            },
            e_900: {
              message: 'Il tentativo di creare un asset è fallito. Si prega di riprovare più tardi.',
            },
            e_901: {
              message: "L'azione è stata interrotta. Non è stato creato un nuovo asset.",
            },
            e_902: {
              message: "L'azione è stata interrotta. Non è stata inviata alcuna richiesta di servizio.",
            },
            e_903: {
              message: 'Nel tentativo di inviare la richiesta di servizio si è verificato un problema. Si prega di riprovare più tardi.',
            },
            e_904: {
              message: "L'asset '%replaceValue' è stato creato con successo. Ora è possibile aggiungere altri asset.",
            },
            e_905: {
              message: "L'azione è stata interrotta. L'asset non è stato aggiornato.",
            },
            e_906: {
              message: "L'asset '%replaceValue' è stato spostato con successo.",
            },
            e_910: {
              message: 'Il tentativo di creare un utente è fallito. Si prega di riprovare più tardi.',
            },
            e_911: {
              message: "L'azione è stata interrotta. Non è stato creato alcun nuovo utente.",
            },
            e_912: {
              message: 'Il tentativo di aggiornare un asset è fallito. Si prega di riprovare.',
            },
            e_920: {
              message: "L'azione è stata interrotta. Le modifiche non sono state salvate.",
            },
            e_921: {
              message: 'Si è verificato un errore inatteso. Si prega di riprovare.',
            },
            e_922: {
              message: 'Il nome utente e/o la password non sono corretti. Si prega di riprovare.',
            },
            e_923: {
              message: 'Si prega di immettere un nome utente e una password.',
            },
            e_924: {
              message: 'La sessione è scaduta a causa di inattività prolungata. Si prega di effettuare nuovamente il login.',
            },
            e_925: {
              message: 'Per potere utilizzare la SW Cloud Platform è necessario accettare la nostra dichiarazione sulla protezione dei dati.',
            },
            e_926: {
              message: 'Il Vostro account è temporaneamente bloccato. Si prega di riprovare tra qualche minuto.',
            },
            e_927: {
              message: 'La Vostra password è stata modificata con successo.',
            },
            e_928: {
              message: "L'azione è stata interrotta. Non è stato caricato alcun file.",
            },
            e_929: {
              message: 'Nel tentativo di caricare un file si è verificato un problema. Si prega di riprovare più tardi.',
            },
            e_930: {
              message: 'Nel tentativo di cancellare un file si è verificato un problema. Si prega di riprovare più tardi.',
            },
            e_10500: {
              message: 'Sembra non sia presente alcuna connessione a internet. Assicurarsi che sia presente una connessione.',
            },
            e_10501: {
              message: 'State utilizzando un browser non supportato. Si prega di utilizzare uno dei seguenti browser: Google Chrome, Microsoft Edge, Apple Safari.',
            },
            e_409_asset: {
              message: "L'asset (numero macchina SW %replaceValue) è già esistente.",
            },
            e_410_asset: {
              message: 'Questo nome file %replaceValue è già esistente',
            },
          },
        },
        list: {
          sweetalert: {
            maxAmountReached: {
              html: 'Possono essere selezionati solo %s elementi contemporaneamente',
              title: 'Numero massimo raggiunto',
            },
          },
        },
      },
      module: {
        assets: {
          machine: {
            alarms: {
              no_alarms_text: 'Nessun allarme disponibile',
            },
            nc_data_file_cardTitle: 'NC State Files',
            nc_state_files: {
              no_files_text: 'Nessun file disponibile.',
            },
            nc_data_files: {
              column_title_trigger: 'Trigger',
              column_title_filename: 'Nome file',
              column_title_creation_date: 'Data di creazione',
              modules: {
                assets: {
                  nc_data_download: 'Download dei dati NC',
                },
              },
            },
          },
        },
      },
    },
  },
  pl: {
    translation: {
      generic: {
        logout: 'Wyloguj się',
        release: "W dniu '%replaceValue1' od godziny '%replaceValue2' do '%replaceValue3' prowadzone będą prace serwisowe. W tym czasie platforma będzie częściowo niedostępna.",
        pageTitle: 'SW CloudPlatform',
        closeLabel: 'Zamknąć',
        acceptLabel: 'Zaakceptuj',
        userProfile: 'Profil użytkownika',
        declineLabel: 'Odrzuć',
        backToDashboard: 'Powrót do dashboard',
        footerLinks: {
          gtct: {
            url: 'https://sw-machines.com/pl/gtc-normy/',
            title: 'GTCT / standardy',
          },
          contact: {
            url: 'mailto:lifedata@sw-machines.com',
            title: 'Kontakt',
          },
          imprint: {
            url: 'https://sw-machines.com/pl/imprint/',
            title: 'Nota prawna',
          },
          privacy: {
            title: 'Sfera prywatna',
            header: 'Przepisy o ochronie danych osobowych',
          },
          swHomepage: {
            url: 'https://www.sw-machines.com/',
            title: 'Strona główna SW',
          },
        },
        noInternetConnection: 'Wygląda na to, że nie masz połączenia z Internetem. Proszę połączyć się i spróbować ponownie.',
      },
      modules: {
        delete: {
          text: 'Tej czynności nie można cofnąć!',
        },
        error: {
          upload: 'Nieudane przesyłanie danych',
          message: {
            enrollment_code_409: 'Urządzenie jest już zapisane w aws.',
          },
        },
        qs: {
          tabs: {
            list: 'Rejestracja czasu',
          },
          list: {
            report: {
              headline: 'Wybierz zmienne',
            },
          },
          grid: {
            columns: {
              datetime: 'Data',
            },
          },
        },
        frameTitle: {
          qs: 'Zapewnianie jakości',
          users: 'Zarządzanie użytkownikami',
          assets: 'Zarządzanie instalacjami',
          settings: 'Ustawienia',
          dashboard: 'Dashboard',
          userProfile: 'Profil użytkownika',
        },
        kpi: {
          tileTitle: 'KPI',
        },
        sidebar: {
          users: 'Zarządzanie użytkownikami',
          assets: 'Zarządzanie instalacjami',
          dashboard: 'Dashboard',
          userProfile: 'Profil użytkownika',
        },
        generic: {
          noData: 'Brak danych',
          dateFormat: 'DD.MM.YYYY',
          noDuration: 'brak czasu trwania',
          timeFormat: 'HH:mm:ss',
          alarmPending: 'Alarm obecny',
          buttons: {
            back: 'Wstecz',
            save: 'Zapisz',
            view: 'Wyświetl',
            cancel: 'Anuluj',
            export: 'Limity pobierania',
            import: 'Start',
            submit: 'Zastosuj',
            selectAllCheckboxes: 'Wybierz wszystko',
            unselectAllCheckboxes: 'Usuń wybór',
          },
          notAvailable: 'Brak dostępności',
          notConnected: 'Jeszcze nie połączono',
          dateTimeFormat: 'DD.MM.YYYY HH:mm:ss',
          timeFormats: {
            day: 'dzień',
            days: 'dni',
            hour: 'godzina',
            year: 'rok',
            hours: 'godz.',
            month: 'miesiąc',
            years: 'lat(a)',
            minute: 'minuta',
            months: 'mies.',
            second: 'sekunda',
            minutes: 'min',
            seconds: 'sek.',
          },
          notAvailableShort: 'nie dotyczy',
          timeFormatDuration: 'HH:mm:ss',
          timeFormatWithoutSeconds: 'HH:mm',
          sweetalert: {
            buttons: {
              understood: 'Rozumiem',
            },
          },
        },
        success: {
          upload: 'Udane przesyłanie danych',
          statusCodes: {
            s_0: {
              message: 'Wystąpił nieoczekiwany błąd. Spróbuj na nowo wczytać stronę.',
            },
            s_1: {
              message: 'Zmiany zostały zapisane.',
            },
            s_930: {
              message: "Zasób '%replaceValue' został usunięty prawidłowo.",
            },
            s_931: {
              message: "Firma '%replaceValue' została usunięta prawidłowo.",
            },
            s_932: {
              message: "Linia '%replaceValue' została usunięta prawidłowo.",
            },
            s_933: {
              message: "Hala '%replaceValue' została usunięta prawidłowo.",
            },
            s_934: {
              message: "Lokalizacja '%replaceValue' została usunięta prawidłowo.",
            },
            s_935: {
              message: "Plik '%replaceValue' został usunięty.",
            },
          },
          message: {
            duplication_deleted: 'Podwójne mapy zostały usunięte!',
            add_line_to_dashboard: 'Linia została dodana do pulpitu',
          },
        },
        limit: {
          tabs: {
            list: 'Limity',
            type: 'Wybierz na podstawie typu',
            machine: 'Wybierz na podstawie ID urządzenia',
            listImport: 'Limity importowania',
            listDownload: 'Limity pobierania',
          },
          text: {
            text: 'Wartości graniczne to wartości definiowane dla zmiennych w sferze zainteresowania. Jeżeli dana zmienna nie mieści się w zdefiniowanych wartościach granicznych, otrzymasz powiadomienie. Wartości graniczne są widoczne również na wykresach generowanych w zakładce „Szeregi czasowe”.',
            title: 'Limity',
          },
          export: {
            text: 'W każdej chwili można wyeksportować aktualnie ustawione wartości graniczne dla wszystkich zmiennych na platformie Cloud Platform. Niniejszej listy można użyć jako referencji albo do zaimportowania jednocześnie wszystkich wartości granicznych w sekcji „Importuj wartości graniczne jako CSV”',
            title: 'Eksporty limity jako CSV',
          },
          import: {
            text: 'Można zaimportować pobrany wcześniej plik CSV z informacjami dotyczącymi limitów i subskrybentów, aby zaktualizować aktualnie zapisane wartości. Później możesz wybrać, do którego typu urządzeń lub maszyn chcesz zastosować zaimportowane wartości graniczne',
            title: 'Importowanie wartości granicznych z pliku CSV',
            machines: {
              title: 'Wybierz maszyny lub typ maszyny, do których chcesz zastosować wartości graniczne.',
            },
          },
          upload: {
            fields: {
              file: 'Wybierz plik CSV, który chcesz zaimportować',
            },
          },
        },
        manual: {
          line: {
            one: 'Przejdź do wybranej linii w zarządzaniu aktywami.',
            two: 'W wierszu nagłówka znajduje się menu kontekstowe.',
            four: 'Kafelek linii zostanie dodany do pulpitu.',
            three: 'Kliknij menu kontekstowe i wybierz „Dodaj do pulpitu”.',
            header: 'Dodawanie kafelków linii',
          },
          individual: {
            one: 'Kliknij symbol „Koło zębate” po lewej stronie.',
            two: 'Wybierz symbol „Plus”.',
            four: 'Potwierdź naciskając „Zapisz”. Pojedynczy kafelek zostanie dodany do pulpitu.',
            three: 'Nazwij swój kafelek i dodaj wybrane przez siebie maszyny.',
            header: 'Dodawanie indywidualnych kafelków',
          },
        },
        users: {
          fab_title: 'Utwórz nowego użytkownika',
          new: {
            title: 'Utwórz użytkownika',
            buttons: {
              add: 'Dodaj',
              back: 'Powrót do listy',
              save: 'Zapisz',
              cancel: 'Anuluj',
              delete: 'Usuń użytkownika',
            },
            fields: {
              email: 'E-mail',
              roles: 'Grupa użytkowników',
              gender: 'Płeć',
              created: 'Utworzył(a)',
              lastName: 'Nazwisko',
              firstName: 'Imię',
              breadcrumb: 'Pokaż nawigację',
              main_group: 'Grupa użytkowników',
              phoneNumber: 'Numer telefonu [opcjonalnie]',
              customer_org: 'Organizacja klienta',
              last_modified: 'Ostatnia edycja',
              email_validation: 'E-mail (walidacja)',
            },
            error_messages: {
              email_not_match: 'Adresy e-mail nie są zgodne.',
              email_not_valid: 'Proszę podać ważny adres e-mail.',
              roles_not_specified: 'Wybierz grupę użytkowników.',
              gender_not_specified: 'Wprowadź płeć.',
              last_name_not_specified: 'Wprowadź imię i nazwisko.',
              first_name_not_specified: 'Proszę podać imię.',
              main_group_not_specified: 'Wybierz grupę użytkowników.',
              family_name_not_specified: 'Proszę podać nazwisko.',
              customer_organization_not_specified: 'Proszę wybrać organizację klienta.',
            },
          },
          update: {
            title: 'Zaktualizuj użytkownika',
          },
          success: {
            title: 'Udało się!',
            button: 'Powrót do listy',
            message: 'Utworzenie użytkownika przebiegło pomyślnie. Wiadomość e-mail z automatycznie wygenerowanym hasłem została wysłana na podany adres e-mail.',
            message_for_edit: 'Aktualizacja użytkownika przebiegła pomyślnie.',
            button_back_to_last_page: 'Powrót do ostatniej strony',
          },
          delete: {
            success: 'Użytkownik usunięty pomyślnie.',
            confirmation: {
              title: 'Czy naprawdę chcesz usunąć tego użytkownika?',
              confirm: 'Tak, usuń tego użytkownika',
              dismiss: 'Nie, anuluj',
            },
          },
          groups: {
            visitor: 'Gość',
            customer: 'Klient',
          },
          list: {
            grid: {
              user: 'Użytkownik',
              email: 'E-mail',
              company: 'Firma',
              enabled: 'Status',
              lastName: 'Nazwisko',
              sw_admin: 'Administrator SW',
              firstName: 'Imię',
              userRights: 'Status praw',
              phoneNumber: 'Numer telefonu',
            },
          },
          fields: {
            gender: {
              male: 'męska',
              female: 'żeńska',
              diverse: 'odmienna',
            },
          },
        },
        search: {
          noResults: 'Nie znaleziono żadnych wyników',
          results: {
            of: 'z',
            lines: 'Linie',
            machines: 'Maszyny',
          },
          callToAction: 'Wyszukaj maszyny',
        },
        spistat: {
          tileTitle: 'SpiStat',
        },
        worldmap: {
          tileTitle: 'Mapa świata',
        },
        shopfloor: {
          tileTitle: 'Przestrzeń produkcyjna',
          upload: {
            text1: 'Wyślij obraz układu hali produkcyjnej na adres lifedata@sw-machines.com (JPG, PNG, PDF).',
            text2: 'Jeżeli Twoje maszyny są zarejestrowane i zapisane w SW CloudPlatform, wyświetlą się po lewej stronie i zaczną wysyłać dane w czasie rzeczywistym.',
            text3: 'Następnie wystarczy przeciągnąć i upuścić status na swój plan przy odpowiedniej maszynie.',
            mainText: 'W tym widoku można skonfigurować własny układ hali produkcyjnej i przeglądać maszyny za pośrednictwem poszczególnych warstw informacyjnych. Wystarczy wykonać te czynności.',
            tileTitle: 'Przesyłanie hali produkcyjnej',
          },
        },
        asset: {
          delete: {
            success: 'Zasób usunięty prawidłowo.',
            confirmation: {
              title: "Czy na pewno chcesz usunąć '%replaceValue'?",
              confirm: 'usuń',
              dismiss: 'anuluj',
            },
          },
          new: {
            buttons: {
              delete: 'Usuń zasób',
            },
          },
          updateLimits: {
            confirmation: {
              intro: 'Czy chcesz zatwierdzić wartości graniczne nowego typu maszyny?',
              title: 'Typ maszyny został zmieniony',
              accept: 'Tak, przejmij nowe wartości graniczne',
              decline: 'Nie, zachowaj aktualne wartości graniczne',
              dismiss: 'Anuluj',
              info_accept: 'Aby zastąpić aktualne wartości graniczne wartościami granicznymi nowego typu maszyny, wybierz „Tak”.',
              info_decline: 'Aby zachować aktualne wartości graniczne, wybierz „Nie”.',
              info_accept_warning: 'Wszystkie aktualne wartości graniczne zostaną zastąpione lub usunięte.',
            },
          },
        },
        assets: {
          line: {
            tabs: {
              kpi: 'KPI',
              list: 'Maszyny',
              shopfloor: 'Przestrzeń produkcyjna',
            },
            fab_title: 'Dodaj nową linię',
            grid: {
              hall: 'Hala',
              line: 'Linia',
              type: 'Typ',
              contractEnd: 'Koniec umowy [opcja]',
              cellPosition: 'Komórka / pozycja [opcja]',
              operationStep: 'Etap obróbki',
              machineIdentifierSw: 'Nr maszyny (SW)',
              machineIdentifierCustomer: 'Nazwa maszyny',
            },
            success: {
              title: 'Udało się!',
              button: 'Powrót do listy',
              message: 'Utworzenie linii przebiegło pomyślnie.',
            },
            new_form_title: 'Nowa linia',
            edit_form_title: 'Edytuj linię',
            new: {
              fields: {
                name: 'Nazwa linii',
                name_short: 'Nazwa linii (forma skrócona) [opcjonalnie]',
                description: 'Opis [opcjonalnie]',
              },
              error_messages: {
                name_not_specified: 'Proszę podać nazwę linii.',
              },
            },
            editSuccess: {
              title: 'Udało się!',
              button: 'Powrót do listy',
              message: 'Aktualizacja linii przebiegła pomyślnie.',
            },
          },
          hall: {
            fab_title: 'Dodaj nową halę',
            grid: {
              part: 'Typ części',
              lineName: 'Linia',
              typeOfLoading: 'Typ załadunku',
              lineResponsible: 'Osoba odpowiedzialna za linię',
              plannedQuantity: 'Planowana ilość',
              numberOfMachines: '# Maszyny',
            },
            tabs: {
              list: 'Linie',
              shopfloor: 'Przestrzeń produkcyjna',
            },
            success: {
              title: 'Udało się!',
              button: 'Powrót do listy',
              message: 'Utworzenie hali przebiegło pomyślnie.',
            },
            new_form_title: 'Nowa hala',
            shopfloor: {
              edit: 'Tryb edycji',
              configure: {
                save: {
                  fab_title: 'Zapisz konfigurację',
                },
                discard: {
                  fab_title: 'Anuluj konfigurację',
                },
              },
            },
            edit_form_title: 'Edytuj halę',
            new: {
              fields: {
                name: 'Nazwa hali',
                name_short: 'Nazwa hali (forma skrócona) [opcjonalnie]',
                description: 'Opis [opcjonalnie]',
                description_external: 'Opis (zewnętrzny) [opcjonalnie]',
                description_internal: 'Opis (wewnętrzny) [opcjonalnie]',
              },
              error_messages: {
                name_not_specified: 'Proszę podać nazwę hali.',
              },
            },
            editSuccess: {
              title: 'Udało się!',
              button: 'Powrót do listy',
              message: 'Aktualizacja hali przebiegła pomyślnie.',
            },
          },
          grid: {
            description: 'Opis [opcjonalnie]',
          },
          company: {
            fab_title: 'Dodać nowe przedsiębiorstwo',
            grid: {
              city: 'Miejscowość',
              email: 'E-mail',
              street: 'Adres',
              country: 'Kraj',
              website: 'Strona internetowa',
              postalCode: 'Kod pocztowy',
              locationName: 'Lokalizacja',
            },
            tabs: {
              list: 'Lokalizacje',
              worldmap: 'Mapa świata',
            },
            success: {
              title: 'Udało się!',
              button: 'Powrót do listy',
              message: 'Utworzenie przedsiębiorstwa przebiegło pomyślnie.',
            },
            toggle_switch: 'Utwórz lokalizację z tymi samymi danymi',
            new_form_title: 'Nowe przedsiębiorstwo',
            edit_form_title: 'Edytuj przedsiębiorstwo',
            new: {
              fields: {
                city: 'Miasto',
                name: 'Nazwa przedsiębiorstwa',
                email: 'E-mail [opcjonalnie]',
                state: 'Kraj związkowy [opcjonalnie]',
                street: 'Adres',
                country: 'Kraj',
                website: 'Strona internetowa [opcjonalnie]',
                zipcode: 'Kod pocztowy',
                legal_form: 'Forma prawna [opcjonalnie]',
                name_short: 'Nazwa przedsiębiorstwa (skrócona) [opcjonalnie]',
                description: 'Opis [opcjonalnie]',
                company_logo: 'Logo przedsiębiorstwa (URL) [opcjonalnie]',
                customer_number: 'Numer klienta',
                industry_sector: 'Sektor przemysłu',
                description_external: 'Opis (zewnętrzny) [opcjonalnie]',
                description_internal: 'Opis (wewnętrzny) [opcjonalnie]',
              },
              error_messages: {
                url: 'Proszę podać ważny adres URL.',
                email: 'Proszę podać ważny adres e-mail.',
                city_not_specified: 'Proszę podać miasto.',
                name_not_specified: 'Proszę podać nazwę przedsiębiorstwa.',
                street_not_specified: 'Proszę podać adres.',
                country_not_specified: 'Proszę podać kraj.',
                zipcode_not_specified: 'Proszę podać kod pocztowy.',
                customer_number_not_specified: 'Proszę podać numer klienta.',
                industry_sector_not_specified: 'Proszę podać sektor przemysłu.',
              },
            },
            editSuccess: {
              title: 'Udało się!',
              button: 'Powrót do listy',
              message: 'Aktualizacja przedsiębiorstwa przebiegła pomyślnie.',
            },
            header: {
              labels: {
                email: 'E-mail',
                address: 'Adres',
                country: 'Kraj',
                website: 'Strona internetowa',
              },
            },
            coordinateselement: {
              intro_text: 'Platforma SW CloudPlatform pokazuje to przedsiębiorstwo na mapie i stosuje do tego celu stopnie długości i szerokości geograficznej.',
              intro_heading: 'Informacje o miejscowości',
              location_name: 'Nazwa miejscowości:',
              label_latitude: 'Stopień szerokości geograficznej',
              label_longitude: 'Stopień długości geograficznej',
              text_fallback_info: 'Jeśli nie, stosowane są współrzędne standardowe.',
              text_location_found: 'Znaleziono miejscowość.',
              text_location_entered: 'Podano miejscowość.',
              text_success_location: 'Ustawiono następujące dane:',
              button_define_coordinates: 'Wprowadź współrzędne samodzielnie',
              text_no_coordinates_found: 'Nie znaleziono współrzędnych dla wprowadzonych danych.',
              text_coordinates_success_set: 'Współrzędne ustawione.',
              button_set_custom_coordinates: 'Ustaw współrzędne',
              text_specify_coordinates_yourself: 'Alternatywnie można wprowadzić współrzędne samodzielnie.',
            },
          },
          machine: {
            fab_title: 'Utwórz nowe aktywa',
            tabs: {
              info: 'Szeregi czasowe',
              alarms: 'Alarmy',
              gateway: 'Bramka',
              service: 'Serwis',
              spistat: 'SpiStat',
              swimlane: 'Dostępność linii',
              'axis-test': 'Testy osi',
              collection: 'Kolekcje',
              maintenance: 'Konserwacja',
              performance: 'Wydajność',
              spindledata: 'Dane wrzeciona',
              administration: 'Administracja',
              energymonitoring: 'Monitoring energetyczny',
            },
            move: {
              message: "Zasób '%replaceValue' może zostać przesunięty do nowego przedsiębiorstwa, nowej lokalizacji, hali i/lub linii.",
              select_hall_label: 'Wybierz nową halę',
              select_line_label: 'Wybierz nową linię',
              select_company_label: 'Wybierz nowe przedsiębiorstwo',
              select_location_label: 'Wybierz nową lokalizację',
            },
            new: {
              fields: {
                s3: 'S3',
                name: 'Numer maszyny (SW)',
                iotcore: 'IoT Core',
                startup: 'Dostawa [opcjonalnie]',
                shipping: 'Wysyłka [opcjonalnie]',
                controller: 'Sterownik',
                machine_type: 'Typ maszyny',
                warranty_end: 'Koniec gwarancji [opcjonalnie]',
                iotgreengrass: 'IoT Greengrass',
                systemsManager: 'Menedżer systemów',
                warranty_start: 'Rozpoczęcie gwarancji [opcjonalnie]',
                machine_swmp_api: 'SWMP API maszyny, koniec [opcjonalnie]',
                machine_swsp_trace: 'SWSP Trace maszyny, koniec [opcjonalnie]',
                machine_swcp_portal: 'SWCP Portal maszyny, koniec [opcjonalnie]',
                start_of_production: 'Rozpoczęcie produkcji [opcjonalnie]',
                machine_swcp_support: 'SWCP Support maszyny, koniec [opcjonalnie]',
                machine_cell_position: 'Komórka / pozycja maszyny',
                machine_swsp_line_mon: 'SWSP LineMon maszyny, koniec [opcjonalnie]',
                machine_identifier_customer: 'ID maszyny (klient)',
              },
              error_messages: {
                name_not_specified: 'Proszę podać ważny numer maszyny SW (co najmniej 7 znaków: A-Z, 0-9, -).',
                controller_not_specified: 'Proszę wybrać sterownik.',
                machine_type_not_specified: 'Proszę wybrać typ maszyny.',
                machine_identifier_customer_not_specified: 'Proszę podać ID maszyny.',
              },
            },
            success: {
              title: 'Udało się!',
              button: 'Powrót do listy',
              message: 'Utworzenie aktywów przebiegło pomyślnie.',
            },
            new_form_title: 'Dodaj aktywa',
            edit_form_title: 'Edytuj aktywa',
            move_form_title: 'Przesuń zasób',
            editSuccess: {
              title: 'Udało się!',
              button: 'Powrót do listy',
              message: 'Aktualizacja aktywów przebiegła pomyślnie.',
            },
            header: {
              labels: {
                api: 'SWMP API',
                trace: 'SWSP Trace',
                portal: 'SWCP Portal',
                lineMon: 'SWSP LineMon',
                support: 'SWCP Support',
                shipping: 'Data wysyłki',
                controller: 'Sterownik',
                contractEnd: 'Koniec umowy',
                machineType: 'Typ maszyny',
                startupDate: 'Data uruchomienia',
                warrantyEnd: 'Koniec rękojmi',
                cellPosition: 'Komórka/pozycja',
                warrantyStart: 'Początek rękojmi',
                customerNumber: 'Numer klienta',
                enrollmentConfig: 'Konfiguracja rekrutacji',
                startOfProduction: 'Rozpoczęcie produkcji',
                machineIdentifierSw: 'Nr maszyny SW',
                enrollmentConfigDownload: 'Pobierz',
                machineIdentifierCustomer: 'Nazwa maszyny klienta',
              },
            },
            add_line_to_dashboard: 'Dodaj linię do pulpitu',
            elements: {
              'axis-test': {
                kpi: 'KPI',
                unit: 'Jednostka',
                value: 'Wartość',
                kpiUnit: 'Jednostka',
                headline: 'Testy osi',
                kpiValue: 'Wartość',
                kpiHeader: 'KPI',
                chartTitle: 'Test osi',
                limitsLoad: 'Wczytaj z pamięci przeglądarki',
                limitsSave: 'Zapisz w pamięci przeglądarki',
                selectAxis: 'Wybierz oś',
                limitsExport: 'Eksportuj wartości graniczne do schowka',
                openAxistest: 'Otwórz test osi',
                listbox: {
                  label: 'Typ / oś(osie)',
                  noContentMessage: 'Wybierz typ badania osi',
                  noAxisTestAvailable: 'Niedostępne badanie osi tego typu',
                },
                axisTestPlural: 'Testy osi',
                noChartMessage: 'Proszę wybrać kolekcję po lewej stronie.',
                adjustments: {
                  add: 'Dodaj lokalne dostosowanie',
                  range: 'Zasięg',
                  header: 'Lokalne dostosowanie',
                  remove: 'Usuń',
                  position: 'Pozycja',
                  amplitude: 'Amplituda',
                  spreadMultiplier: 'Mnożnik limitów',
                },
                chart: {
                  Equability: {
                    x: 'Pozycja',
                    y: 'Prąd',
                    up: 'w górę',
                    down: 'w dół',
                    limitsUp: 'ogranicza w kierunku dodatnim',
                    limitsDown: 'ogranicza w kierunku ujemnym',
                    sliderSlopeCenter: 'Początek skoku',
                    sliderSlopeFactor: 'Współczynnik skoku',
                    sliderVerticalOffset: 'Przesunięcie Y',
                    sliderVerticalSpread: 'Ogranicz szerokość',
                    sliderQuadraticCenter: 'Początek kwadratu',
                    sliderQuadraticFactor: 'Współczynnik kwadratu',
                  },
                  Circularity: {
                    clockwise: 'Zgodnie z ruchem wskazówek zegara',
                    limitsClockwise: 'Granice w kierunku zgodnym z ruchem wskazówek zegara',
                    counterClockwise: 'Przeciwnie do ruchu wskazówek zegara',
                    sliderSineOffset: 'Przesunięcie fali',
                    sliderSineAmplitude: 'Amplituda fali',
                    sliderSineFrequency: 'Częstotliwość fali',
                    sliderVerticalOffset: 'Przesunięcie Y',
                    sliderVerticalSpread: 'Ogranicz szerokość',
                    limitsCounterClockwise: 'Granice w kierunku odwrotnym do ruchu wskazówek zegara',
                  },
                },
                kpis: {
                  pulseVelocity: 'Prędkość',
                  pulseMaxRampUp: 'Rampa ->',
                  pulseMaxRampDown: 'Rampa <-',
                  pulseMaxStiffness: 'Sztywność maksymalna',
                  pulseMinStiffness: 'Sztywność minimalna',
                  pulseMeanStiffness: 'Sztywność Ø',
                  circularReversalRange: 'Histereza okręgu',
                  pulseMaxUpPathCurrent: 'Prąd maksymalny ->',
                  pulseMinUpPathCurrent: 'Prąd minimalny ->',
                  pulseMeanUpPathCurrent: 'Prąd Ø ->',
                  pulseMaxDownPathCurrent: 'Prąd maksymalny <-',
                  pulseMinDownPathCurrent: 'Prąd minimalny <-',
                  pulseMeanDownPathCurrent: 'Prąd Ø <-',
                  circularShapeDeviationClockwise: 'Odstępstwo od okręgu w prawo',
                  pulseStiffnessStandardDeviation: 'Sztywność σ',
                  pulseStandardDeviationUpPathCurrent: 'Prąd σ ->',
                  pulseStandardDeviationDownPathCurrent: 'Prąd σ <-',
                  circularShapeDeviationCounterClockwise: 'Odstępstwo od okręgu w lewo',
                },
                axisLabels: {
                  CIRCLE: {
                    x: 'Pozycja',
                    y: 'Pozycja',
                  },
                  EQUABILITY: {
                    x: 'Pozycja',
                    y: 'Prąd',
                  },
                },
                axisTestTypes: {
                  CIRCLE: 'Badanie okrągłości',
                  EQUABILITY: 'Badanie współbieżności',
                  CIRCULARITY: 'Badanie okrągłości',
                },
              },
              gateway: {
                title: 'Bramka',
                contact: 'W razie błędu zwróć się do',
                headline: 'Bramka',
                netcfg: {
                  cta: 'Prosimy udostępnić nam dane przy użyciu formularza Netcfg.',
                  intro: 'W celu podłączenia maszyny potrzebujemy informacji dotyczących konfiguracji sieci.',
                  title: 'Konfiguracja sieci',
                  goToNetconfig: 'Otwieranie konfiguratora sieci',
                },
                device: {
                  title: 'Informacje szczegółowe o urządzeniu',
                },
                enrollment: {
                  line1: 'Pobierz plik Enrollment, klikając przycisk na dolnej krawędzi kafelka.',
                  line2: 'Pobieranie uruchamia się automatycznie.',
                  line3: 'Zapisz plik .json w katalogu głównym na pamięci USB. Upewnij się, że plik ma nazwę:',
                  line4: 'enrollment.json.',
                  line5: 'Upewnij się, że w katalogu głównym na pamięci USB znajdują się następujące pliki:',
                  line6: 'Włóż pamięć USB do odpowiedniej bramki IoT.',
                  line8: 'Po zakończonej pomyślnie rejestracji zielona dioda LED świeci do momentu wyjęcia pamięci USB.',
                  title: 'Enrollment',
                  button: 'Plik Enrollment',
                },
                deviceMigration: {
                  line1: 'Pobierz plik „migrate-iot.zip” i wypakuj jego zawartość bezpośrednio na pustą pamięć USB.',
                  line2: 'aws.json',
                  line3: 'Pobierz również ',
                  line4: ' i dodaj na pamięć USB. Upewnij się, że plik ma następującą nazwę:',
                  line5: 'Upewnij się, że w katalogu głównym na pamięci USB znajdują się następujące pliki:',
                  line6: 'install.json',
                  line7: 'migration.tar.gz',
                  line8: 'migration.tar.gz.sig',
                  line9: 'migration.json',
                  title: 'Migracja platformy',
                  line10: 'Włóż pamięć USB do odpowiedniej bramki IoT.',
                  line11: 'Oprogramowanie instaluje się automatycznie, co jest sygnalizowane przez migającą żółtą diodę LED.',
                  line12: 'Po zakończonej pomyślnie migracji zielona dioda LED świeci do momentu wyjęcia pamięci USB.',
                  line13: 'Po zakończonej pomyślnie migracji zielona dioda LED świeci do momentu wyjęcia pamięci USB.',
                  device: {
                    button: 'migrate-iot.zip',
                  },
                  generic: {
                    button: 'aws.json',
                  },
                },
              },
              info: {
                generate: 'Generuj',
                headline: 'Szeregi czasowe',
                noChartMessage: 'Aby wyświetlić potrzebne dane, wybierz jedną lub większą liczbę zmiennych i kliknij Generuj',
              },
              alarms: {
                headline: 'Alarmy',
              },
              service: {
                headline: 'Serwis',
                update: {
                  title: 'Zaktualizuj plik',
                },
                upload: {
                  title: 'Wczytaj plik',
                  fields: {
                    file: 'Plik',
                    language: 'Język pliku',
                    file_name: 'Nazwa pliku',
                    list_of_languages: {
                      cz: 'Czeski',
                      de: 'Niemiecki',
                      en: 'Angielski',
                      es: 'Hiszpański',
                      fr: 'Francuski',
                      hu: 'Węgierski',
                      it: 'Włoski',
                      pl: 'Polski',
                      zh: 'Chiński',
                    },
                  },
                  buttons: {
                    send: 'Wczytaj',
                    cancel: 'Anuluj',
                  },
                  success: {
                    title: 'Powodzenie',
                    button: 'Wstecz do zestawienia serwisowego',
                    message: 'Plik został wczytany pomyślnie',
                  },
                  error_messages: {
                    file_name: 'Wprowadź nazwę pliku.',
                    file_not_valid: 'Podaj plik / Wybierz mniejszy plik.',
                    language_not_specified: 'Podaj język pliku.',
                  },
                },
                manuals: {
                  title: 'Podręczniki',
                },
                version: {
                  title: 'pliki wersji',
                },
                contracts: {
                  title: 'Umowy',
                },
                components: {
                  title: 'Komponenty osprzętu i oprogramowania',
                },
                spare_parts: {
                  title: 'Lista części zamiennych',
                },
                consumeables: {
                  title: 'Materiały eksploatacyjne',
                },
                Update: {
                  buttons: {
                    send: 'Aktualizuj',
                  },
                },
                software_edge: {
                  title: 'Bramka oprogramowania IoT',
                  values: {
                    box_id: 'Box-ID',
                    machine_connect: 'Machine Connect',
                    operating_system: 'System operacyjny',
                  },
                },
                contact_service: {
                  line1: 'Czy potrzebujesz pomocy w odniesieniu do Twojej maszyny?',
                  line2: 'Skontaktuj się z nami!',
                  title: 'Wiadomość do serwisu SW',
                  button: 'Skontaktować się z serwisem',
                  fields: {
                    name: 'Numer maszyny (SW)',
                    type: 'Typ zapytania',
                    email: 'E-mail',
                    message: 'Wiadomość',
                    phone_no: 'Numer telefonu',
                    last_name: 'Nazwisko',
                    first_name: 'Imię',
                    contact_person: 'Konsultant (SW) [opcjonalnie]',
                    list_of_types: {
                      request: 'Zapytanie ogólne',
                      service: 'Serwis',
                      retrofit: 'Doposażenie',
                      expansion: 'Rozszerzenie',
                      inspection: 'Inspekcja',
                    },
                    machine_identifier_customer: 'ID maszyny (klient) [opcjonalnie]',
                  },
                  buttons: {
                    send: 'Wyślij zapytanie',
                    cancel: 'Anuluj',
                  },
                  success: {
                    title: 'Udało się!',
                    button: 'Powrót do przeglądu serwisu',
                    message: 'Zapytanie zostało pomyślnie przesłane serwisowi SW.',
                  },
                  error_messages: {
                    email_not_valid: 'Proszę podać ważny adres e-mail.',
                    name_not_specified: 'Proszę podać ważny numer maszyny SW (co najmniej 7 znaków: A-Z, 0-9, - _).',
                    phone_not_specified: 'Proszę podać ważny numer telefonu.',
                    message_not_specified: 'Proszę podać wiadomość.',
                    last_name_not_specified: 'Proszę podać nazwisko.',
                    first_name_not_specified: 'Proszę podać imię.',
                    family_name_not_specified: 'Proszę podać nazwisko.',
                    contact_type_not_specified: 'Proszę podać typ zapytania.',
                  },
                },
                software_machine: {
                  title: 'Oprogramowanie maszyny',
                  values: {
                    nc_version: 'Wersja NC',
                    hmi_version: 'Wersja HMI',
                    plc_version: 'Wersja PLC',
                    compile_cycles: 'Cykle kompilacji',
                    operating_system: 'System operacyjny',
                    pcu_base_version: 'Wersja podstawowa PCU',
                    plc_toolbox_version: 'Zestaw narzędzi PLC',
                    plc_user_program_version: 'Program użytkowy PLC',
                  },
                },
                changed: {
                  success: {
                    message: 'Nazwa pliku została pomyślnie zmieniona',
                  },
                },
                maintenance_assistance: {
                  intro: 'Asystent aktywności jest pomocny w rozwiązywaniu problemu.',
                  title: 'Asystent aktywności',
                  sub_title: 'Czy masz obecnie trudności ze swoimi aktywami?',
                  open_assistant: 'Otwórz asystenta aktywności',
                  request_account: 'Złóż wniosek o konto',
                },
              },
              spistat: {
                headline: 'Statystyki wrzeciona SpiStat',
              },
              onlineState: {
                title: 'IoT Gateway',
                labelOnline: 'Wł.',
                labelOffline: 'Wył.',
              },
              swimlane: {
                headline: 'Dostępność linii',
              },
              sendingState: {
                title: 'Status przesyłania',
                aborted: 'anulowano',
                sending: 'wysyła',
                interrupted: 'przerwano',
              },
              alarms_map: {
                headline: 'Mapa alarmów',
              },
              collection: {
                headline: 'Kolekcja',
                noChartMessage: 'Proszę wybrać kolekcję po lewej stronie.',
              },
              performance: {
                kpi: {
                  sum: 'Suma',
                  header: 'Wydajność KPI',
                  target: 'Wartość zadana',
                },
                alarms: {
                  title: 'Alarmy',
                  category: [
                    'Niesklasyfikowane',
                    'Komunikat o błędzie',
                    'Komunikat o działaniu',
                    'Komunikat o stanie',
                  ],
                  icons: {
                    trend: 'Trend',
                    duration: 'Czas oczekiwania',
                    flatlist: 'Lista niezhierarchizowana',
                    frequency: 'Częstość występowania',
                  },
                  tableHeadlines: {
                    time: 'Czas',
                    trend: 'Trend',
                    duration: 'Czas oczekiwania',
                    leftTime: 'Alarm przemija',
                    alarmCount: 'Liczba',
                    alarmNumber: 'Nr alarmu',
                    description: 'Opis',
                    alarmOccured: 'Włączył się alarm',
                    alarmClassification: 'Klasyfikacja alarmów',
                    alarmCountSecondRange: 'Liczba T-1',
                  },
                  description: {
                    trend: {
                      up: 'rosnąca',
                      down: 'malejąca',
                      flat: 'stała',
                    },
                  },
                },
                details: {
                  title: 'Szczegóły',
                  ncProgram: 'Program NC',
                  lastModified: 'Ostatnia rejestracja wartości',
                  machineState: 'Stan maszyny',
                  numberAlarms: 'Alarmy w stanie oczekiwania',
                  stopCondition: 'Warunki zatrzymania',
                  ncProgramState: 'Status programu NC',
                  protectionLevel: 'Poziom ochrony',
                  ncProgramStates: [
                    'anulowano',
                    'zatrzymano',
                    'trwa',
                    'w stanie oczekiwania',
                    'przerwano',
                  ],
                  protectionLevels: [
                    'System',
                    'Producent',
                    'Serwis',
                    'Użytkownik',
                    'Przełącznik kluczykowy w położeniu 3',
                    'Przełącznik kluczykowy w położeniu 2',
                    'Przełącznik kluczykowy w położeniu 1',
                    'Przełącznik kluczykowy w położeniu 0',
                  ],
                },
                cycleTime: {
                  title: 'Czas cyklu',
                  chart: {
                    yAxis: {
                      title: 'Czas cyklu w s',
                    },
                  },
                },
                piececounter: {
                  ok: 'OK',
                  nok: 'nie OK',
                  sum: 'Suma',
                  title: 'Licznik sztuk',
                  titleDayCounter: 'Licznik dzienny',
                  titleShiftCounter: 'Licznik zmianowy',
                  titleTotalCounter: 'Licznik łączny',
                  titleTotalCounterSmall: 'Łącznie',
                },
                availability: {
                  title: 'Dostępność',
                  kpi: {
                    title: 'Dostępność KPI',
                  },
                  legend: {
                    auto: 'AUTO',
                    JOG_with_error: 'JOG z błędem',
                    MDA_with_error: 'MDA z błędem',
                    JOG_without_error: 'JOG bez błędu',
                    MDA_without_error: 'MDA bez błędu',
                    not_defined_condition: 'Niezdefiniowany stan',
                    auto_no_program_active: 'AUTO Brak aktywnego programu',
                    auto_waiting_for_parts: 'AUTO Czekam na części',
                    auto_with_error_program_stopped: 'AUTO z błędem – program zatrzymany',
                    auto_waiting_for_parts_stopping_error: 'AUTO Czekam na części + błąd zatrzymania',
                  },
                  aggregated: {
                    title: 'Zagregowana dostępność',
                  },
                  chart: {
                    yAxis: {
                      title: 'Dostępność',
                    },
                  },
                },
                inProduction: {
                  title: 'Status',
                },
                operationMode: {
                  title: 'Stan pracy',
                  chart: {
                    yAxis: {
                      title: 'Stan pracy',
                    },
                  },
                },
                runtimeMonitor: {
                  title: 'Monitor okresu eksploatacji',
                  chart: {
                    yAxis: {
                      title: 'Program',
                      labels: {
                        on: 'wł.',
                        off: 'wył.',
                      },
                    },
                  },
                },
                spindleOverride: {
                  title: 'Korekcja wrzeciona',
                },
                feedrateOverride: {
                  title: 'Korekcja posuwu',
                },
                uptimeDistribution: {
                  title: 'Uptime Distribution',
                },
              },
              administration: {
                title: 'Administracja',
                headline: 'Administracja',
                aws: {
                  title: 'Łącza AWS',
                },
                references: {
                  values: {
                    s3: 'S3',
                    iot: 'IoT Core',
                    ssm: 'Menedżer systemów',
                    greengrass: 'IoT Greengrass',
                  },
                },
              },
              condition: {
                bill: {
                  title: 'Lista części',
                },
                component: {
                  title: 'Komponenty',
                },
              },
              maintenance: {
                headline: 'Konserwacja',
              },
              spindleData: {
                headline: 'Dane wrzeciona',
              },
              point8: {
                chart: {
                  xAxis: {
                    title: 'Czas przed alarmem (s)',
                  },
                  yAxis: {
                    title: 'Czas po alarmie (s)',
                  },
                },
              },
              energymonitoring: {
                headline: 'Monitoring energii',
                chartTitles: {
                  air: 'Zużycie sprężonego powietrza',
                  current: 'Zużycie prądu',
                  consumption: 'Średni pobór na godzinę',
                },
              },
              conditionMonitoring: {
                headline: 'Monitorowanie stanu',
              },
            },
          },
          location: {
            fab_title: 'Dodaj nową lokalizację',
            tabs: {
              list: 'Hale',
              shopfloor: 'Przestrzeń produkcyjna',
            },
            grid: {
              hallName: 'Hala',
            },
            success: {
              title: 'Udało się!',
              button: 'Powrót do listy',
              message: 'Utworzenie lokalizacji przebiegło pomyślnie.',
            },
            new_form_title: 'Nowa lokalizacja',
            edit_form_title: 'Edytuj lokalizację',
            new: {
              fields: {
                city: 'Miasto',
                name: 'Nazwa lokalizacji',
                email: 'E-mail [opcjonalnie]',
                state: 'Kraj związkowy [opcjonalnie]',
                street: 'Adres',
                country: 'Kraj',
                website: 'Strona internetowa [opcjonalnie]',
                zipcode: 'Kod pocztowy',
                language: 'Język [opcjonalnie]',
                name_short: 'Nazwa lokalizacji (forma skrócona) [opcjonalnie]',
                description: 'Opis [opcjonalnie]',
                description_internal: 'Opis (wewnętrzny) [opcjonalnie]',
              },
              error_messages: {
                email: 'Proszę podać ważny adres e-mail.',
                city_not_specified: 'Proszę podać miasto.',
                name_not_specified: 'Proszę podać nazwę lokalizacji.',
                street_not_specified: 'Proszę podać adres.',
                country_not_specified: 'Proszę podać kraj.',
                zipcode_not_specified: 'Proszę podać kod pocztowy.',
              },
            },
            editSuccess: {
              title: 'Udało się!',
              button: 'Powrót do listy',
              message: 'Aktualizacja lokalizacji przebiegła pomyślnie.',
            },
            header: {
              labels: {
                address: 'Adres',
                country: 'Kraj',
              },
            },
          },
          companies: {
            grid: {
              city: 'Miejscowość',
              email: 'E-mail',
              street: 'Adres',
              country: 'Kraj',
              website: 'Strona internetowa',
              postalCode: 'Kod pocztowy',
              companyName: 'Przedsiębiorstwo',
            },
            tabs: {
              list: 'Przedsiębiorstwo',
              worldmap: 'Mapa świata',
            },
            map: {
              description_not_available: 'Brak dostępnego opisu',
            },
          },
          generic: {
            new: {
              buttons: {
                add: 'Dodaj',
                back: 'Powrót do listy',
                edit: 'Aktualizuj',
                save: 'Zapisz',
                email: 'Wyślij obraz',
                cancel: 'Anuluj',
                addMultiple: 'Dodaj + nowe',
              },
            },
          },
          administration: {
            edit_form_title: 'Edytuj administrację',
          },
        },
        settings: {
          tabs: {
            limits: 'Granice',
          },
          button: {
            import: 'Rozpocznij import',
            dismiss: 'Anuluj',
            download: 'Rozpocznij pobieranie',
          },
        },
        file: {
          new: {
            buttons: {
              delete: 'Usuń plik',
            },
          },
        },
        hall: {
          new: {
            buttons: {
              delete: 'Usuń halę',
            },
          },
        },
        line: {
          new: {
            buttons: {
              delete: 'Usuń linię',
            },
          },
        },
        runtimeStatus: {
          tileTitle: 'Status',
        },
        charts: {
          tooltipDateFormat: 'DD.MM.YYYY HH:mm:ss',
          exporting: {
            datetimeformat: '%d.%m.%Y %H:%M:%S',
          },
          globalSettings: {
            lang: {
              noData: 'Brak danych',
              loading: 'Wczytaj dane...',
              months: [
                'Styczeń',
                'Luty',
                'Marzec',
                'Kwiecień',
                'Maj',
                'Czerwiec',
                'Lipiec',
                'Sierpień',
                'Wrzesień',
                'Październik',
                'Listopad',
                'Grudzień',
              ],
              resetZoom: 'Zresetuj zoom',
              weekdays: [
                'Niedziela',
                'Poniedziałek',
                'Wtorek',
                'Środa',
                'Czwartek',
                'Piątek',
                'Sobota',
              ],
              shortMonths: [
                'STY',
                'LUT',
                'MAR',
                'KWI',
                'MAJ',
                'CZE',
                'LIP',
                'SIE',
                'WRZ',
                'PAŹ',
                'LIS',
                'GRU',
              ],
              shortWeekdays: [
                'Niedz.',
                'Pon.',
                'Wt.',
                'Śr.',
                'Czw.',
                'Pt.',
                'Sob.',
              ],
              increaseDateRange: 'co najmniej do',
            },
          },
        },
        datepicker: {
          presets: {
            title: 'Ustawienia domyślne',
            rangeTo: 'Do',
            selectDateRange: 'Wybierz przedział czasu',
            predefined: {
              today: 'Dzisiaj',
              last7days: 'Ostatnie 7 dni',
              yesterday: 'Wczoraj',
              last24Hours: 'Ostatnie 24 godz.',
              last5Minutes: 'Ostatnie 5 min.',
            },
          },
        },
        i18n: {
          chooseLanguagePhrase: 'Wybrać język',
        },
        company: {
          new: {
            buttons: {
              delete: 'Usuń firmę',
            },
          },
        },
        location: {
          new: {
            buttons: {
              delete: 'Usuń lokalizację',
            },
          },
        },
        runtimeStatusList: {
          tileTitle: 'Status',
        },
        dashboard: {
          configure: {
            fab_title: 'Konfiguruj pulpit',
            hide: {
              title: 'Ukryj',
            },
            show: {
              title: 'Wyświetl',
            },
            delete: {
              title: 'usuń',
            },
            add: {
              fab_title: 'Dodaj własny kafelek',
            },
            save: {
              fab_title: 'Zapisz konfigurację',
            },
            move_left: {
              title: 'Przesuń do poprzedniej pozycji',
            },
            move_right: {
              title: 'Przesuń do następnej pozycji',
            },
            discard: {
              fab_title: 'Anuluj konfigurację',
            },
            add_custom_card: {
              save: 'zapisz',
              input: 'Podaj nazwę kafelka',
              title: 'Dodaj własny kafelek',
              cancel: 'anuluj',
              missingMachine: 'Należy wybrać co najmniej jedną maszynę',
            },
          },
        },
        errors: {
          statusCodes: {
            e_0: {
              message: 'Wystąpił nieoczekiwany błąd. Spróbuj na nowo wczytać stronę.',
            },
            e_1xx: {
              message: 'Błąd informacyjny',
            },
            e_2xx: {
              message: 'Sukces',
            },
            e_3xx: {
              message: 'Przekierowanie',
            },
            e_400: {
              message: 'Zapytanie było błędne i nie udało się go przetworzyć.',
            },
            e_401: {
              message: 'Nie przeprowadzono uwierzytelnienia. Zaloguj się ponownie.',
            },
            e_403: {
              message: 'Nie masz uprawnień umożliwiających otwarcie żądanego zasobu.',
            },
            e_404: {
              message: 'Żądany zasób nie są dostępny.',
            },
            e_405: {
              message: 'Metoda nie jest dozwolona.',
            },
            e_409: {
              message: 'Zasób już istnieje.',
            },
            e_418: {
              message: "I'm a Teapot! Yaay!",
            },
            e_4xx: {
              message: 'Błąd klienta',
            },
            e_500: {
              message: 'Wystąpił błąd wewnętrzny serwera.',
            },
            e_5xx: {
              message: 'Błąd serwera',
            },
            e_900: {
              message: 'Próba utworzenia aktywów nie powiodła się. Spróbuj ponownie później.',
            },
            e_901: {
              message: 'Operacja została anulowana. Nie utworzono nowych aktywów.',
            },
            e_902: {
              message: 'Operacja została anulowana. Nie wysłano zapytania serwisowego.',
            },
            e_903: {
              message: 'Podczas próby wysłania zapytania serwisowego pojawił się problem. Spróbuj ponownie później.',
            },
            e_904: {
              message: "Aktywa '%replaceValue' utworzone pomyślnie. Możesz teraz dodać dalsze aktywa.",
            },
            e_905: {
              message: 'Operacja została anulowana. Aktywa nie zostały zaktualizowane.',
            },
            e_906: {
              message: "Zasób '%replaceValue' został przesunięty pomyślnie.",
            },
            e_910: {
              message: 'Próba utworzenia użytkownika nie powiodła się. Spróbuj ponownie później.',
            },
            e_911: {
              message: 'Operacja została anulowana. Nie utworzono nowego użytkownika.',
            },
            e_912: {
              message: 'Próba aktualizacji zasobu nie powiodła się. Spróbuj ponownie.',
            },
            e_920: {
              message: 'Operacja została anulowana. Nie zapisano zmian.',
            },
            e_921: {
              message: 'Wystąpił nieoczekiwany błąd. Spróbuj ponownie.',
            },
            e_922: {
              message: 'Nazwa użytkownika i/lub hasło nie jest poprawne. Spróbuj ponownie.',
            },
            e_923: {
              message: 'Podaj nazwę użytkownika i hasło.',
            },
            e_924: {
              message: 'Twoja sesja wygasła na skutek nieaktywności. Zaloguj się ponownie.',
            },
            e_925: {
              message: 'Korzystanie z platformy SW Cloud Platform wymaga zaakceptowania naszej polityki prywatności.',
            },
            e_926: {
              message: 'Twoje konto jest tymczasowo zablokowane. Spróbuj ponownie za kilka minut.',
            },
            e_927: {
              message: 'Twoje hasło zostało zmienione pomyślnie.',
            },
            e_928: {
              message: 'Operacja została anulowana. Nie wczytano żadnego pliku.',
            },
            e_929: {
              message: 'Podczas próby wczytania pliku wystąpił problem. Spróbuj ponownie później.',
            },
            e_930: {
              message: 'Próba usunięcia pliku nie powiodła się. Spróbuj ponownie później.',
            },
            e_10500: {
              message: 'Wygląda na to, że nie masz połączenia z Internetem. Upewnij się, że masz połączenie.',
            },
            e_10501: {
              message: 'Używasz nieobsługiwanej przeglądarki internetowej. Użyj jednej z poniższych przeglądarek: Google Chrome, Microsoft Edge, Apple Safari.',
            },
            e_409_asset: {
              message: 'Zasób (numer maszyny SW %replaceValue) już istnieje.',
            },
            e_410_asset: {
              message: 'Istnieje już plik o nazwie %replaceValue',
            },
          },
        },
        list: {
          sweetalert: {
            maxAmountReached: {
              html: 'Równocześnie można wybrać tylko %s elementy(-ów)',
              title: 'Maks. liczba osiągnięta',
            },
          },
        },
      },
      module: {
        assets: {
          machine: {
            alarms: {
              no_alarms_text: 'Brak alarmów',
            },
            nc_data_file_cardTitle: 'Pliki NC State',
            nc_state_files: {
              no_files_text: 'Żadnych dostępnych plików.',
            },
            nc_data_files: {
              column_title_trigger: 'Trigger',
              column_title_filename: 'Nazwa pliku',
              column_title_creation_date: 'Data utworzenia',
              modules: {
                assets: {
                  nc_data_download: 'Pobieranie danych NC',
                },
              },
            },
          },
        },
      },
    },
  },
  zh: {
    translation: {
      generic: {
        logout: '退出',
        release: "正在对 '%replaceValue1' 从'%replaceValue2' 到 '%replaceValue3' 执行平台维护。在此时间段内，该平台将部分不可用。",
        pageTitle: 'SW 云平台',
        closeLabel: '关闭',
        acceptLabel: '接受',
        userProfile: '用户数据',
        declineLabel: '拒绝',
        backToDashboard: '返回主菜单',
        footerLinks: {
          gtct: {
            url: 'https://sw-machines.com/en/gtc/',
            title: 'GTCT / 标准',
          },
          contact: {
            url: 'mailto:lifedata@sw-machines.com',
            title: '联系方式',
          },
          imprint: {
            url: 'https://sw-machines.com/en/imprint/',
            title: '版本说明',
          },
          privacy: {
            title: '隐私',
            header: '数据保护条例',
          },
          swHomepage: {
            url: 'https://www.sw-machines.com/',
            title: 'SW 网站',
          },
        },
        noInternetConnection: '您似乎没有连接到互联网。请您连网并重新尝试。',
      },
      modules: {
        delete: {
          text: '您将无法恢复此状态!',
        },
        error: {
          upload: '上传失败',
          message: {
            enrollment_code_409: '设备已在 aws 中登记。',
          },
        },
        qs: {
          tabs: {
            list: '时间记录',
          },
          list: {
            report: {
              headline: '选择变量',
            },
          },
          grid: {
            columns: {
              datetime: '日期',
            },
          },
        },
        frameTitle: {
          qs: '质量保证',
          users: '用户管理',
          assets: '设备管理',
          settings: '多个设置',
          dashboard: '主菜单',
          userProfile: '用户数据',
        },
        kpi: {
          tileTitle: 'KPI',
        },
        sidebar: {
          users: '用户管理',
          assets: '设备管理',
          dashboard: '主菜单',
          userProfile: '用户数据',
        },
        generic: {
          noData: '无数据',
          dateFormat: 'YYYY-MM-DD',
          noDuration: '没有可用的持续时间',
          timeFormat: 'HH:mm:ss',
          alarmPending: '警报待定',
          buttons: {
            back: '返回',
            save: '保存',
            view: '显示',
            cancel: '中断',
            export: '下载极限',
            import: '开始',
            submit: '接收',
            selectAllCheckboxes: '全选',
            unselectAllCheckboxes: '取消选择',
          },
          notAvailable: '不可用',
          notConnected: '还没连接',
          dateTimeFormat: 'YYYY-MM-DD HH:mm:ss',
          timeFormats: {
            day: '天',
            days: '天(复数)',
            hour: '小时',
            year: '年',
            hours: '小时(复数)',
            month: '月',
            years: '年(复数)',
            minute: '分',
            months: '月(复数)',
            second: '秒',
            minutes: '分(复数)',
            seconds: '秒(复数)',
          },
          notAvailableShort: 'n/a',
          timeFormatDuration: 'HH:mm:ss',
          timeFormatWithoutSeconds: 'HH:mm',
          sweetalert: {
            buttons: {
              understood: '明白',
            },
          },
        },
        success: {
          upload: '上传成功',
          statusCodes: {
            s_0: {
              message: '出现了意外的错误。请您重试加载此网页。',
            },
            s_1: {
              message: '修改已经保存。',
            },
            s_930: {
              message: "资产 '%replaceValue' 已成功删除。",
            },
            s_931: {
              message: "公司 '%replaceValue' 已成功删除。",
            },
            s_932: {
              message: "生产线 '%replaceValue' 已成功删除。",
            },
            s_933: {
              message: "车间 '%replaceValue' 已成功删除。",
            },
            s_934: {
              message: "位置 '%replaceValue' 已成功删除。",
            },
            s_935: {
              message: "文件 '%replaceValue' 已成功删除。",
            },
          },
          message: {
            duplication_deleted: '重复的地图已被删除!',
            add_line_to_dashboard: '生产线已添加到主菜单中',
          },
        },
        limit: {
          tabs: {
            list: '极限值',
            type: '请您按型号选择',
            machine: '请您按机器 ID 选择',
            listImport: '导入极限值',
            listDownload: '下载极限值',
          },
          text: {
            text: '极限值是您要为您感兴趣的变量设定的值。如果一个变量不在定义的极限值内，您将收到通知。极限值还显示在“时间顺序”选项卡中生成的图表中。',
            title: '极限值',
          },
          export: {
            text: '您可以随时导出 Cloud Platform 中用于所有变量的当前所设置的极限值。您可以使用此列表作为参考，或使用它在“将极限值作为 CSV 导入”段落中一次性导入所有极限值',
            title: '将极限值导出为 CSV',
          },
          import: {
            text: '您可以导入之前下载的包含极限值和订户信息的 CSV 文件来更新当前保存的值。您可以以后再选择要将导入的极限值应用到哪些型号的设备或机器上',
            title: '从 CSV 中导入极限值',
            machines: {
              title: '请您选择要应用极限值的机器或机器型号。',
            },
          },
          upload: {
            fields: {
              file: '选择您要导入的 CSV 文件',
            },
          },
        },
        manual: {
          line: {
            one: '请您转到资产管理中的所需生产线。',
            two: '在标题行中，您会找到一个上下文菜单。',
            four: '该生产线的图案将被添加到主菜单中。',
            three: '请您单击上下文菜单，并选择“添加到主菜单”。',
            header: '添加生产线图案',
          },
          individual: {
            one: '请您单击左侧的“齿轮”符号。',
            two: '请您选择“加号”符号。',
            four: '请您用“保存”确认。单个图案添加到主菜单中。',
            three: '请您命名您的图案，并将其添加到您想要的机器中。',
            header: '添加个性化图案',
          },
        },
        users: {
          fab_title: '创建新用户',
          new: {
            title: '创建用户',
            buttons: {
              add: '添加',
              back: '返回列表',
              save: '保存',
              cancel: '中断',
              delete: '删除用户',
            },
            fields: {
              email: '电子邮件',
              roles: '用户组',
              gender: '性别',
              created: '创建',
              lastName: '姓',
              firstName: '名',
              breadcrumb: '显示导航',
              main_group: '用户组',
              phoneNumber: '电话号 [选项]',
              customer_org: '客户组织',
              last_modified: '最后编辑',
              email_validation: '电邮 (验证)',
            },
            error_messages: {
              email_not_match: '电子邮件地址不一致。',
              email_not_valid: '请输入有效的电子邮件地址。',
              roles_not_specified: '请您选择一个用户组。',
              gender_not_specified: '请您输入性别。',
              last_name_not_specified: '请输入姓。',
              first_name_not_specified: '请输入名。',
              main_group_not_specified: '请选择一个用户组。',
              family_name_not_specified: '请输入姓。',
              customer_organization_not_specified: '请选择一个客户组织。',
            },
          },
          update: {
            title: '更新用户',
          },
          success: {
            title: '成功！',
            button: '返回列表',
            message: '该用户已成功创建。系统将自动生成密码并发电子邮件至您所提供的邮箱地址。',
            message_for_edit: '该用户已成功更新。',
            button_back_to_last_page: '返回到上一页',
          },
          delete: {
            success: '用户已成功删除。',
            confirmation: {
              title: '您真的要删除该用户吗?',
              confirm: '是，删除该用户',
              dismiss: '否，中断',
            },
          },
          groups: {
            visitor: '访问者',
            customer: '客户',
          },
          list: {
            grid: {
              user: '用户',
              email: '电邮',
              company: '公司',
              enabled: '状态',
              lastName: '姓',
              sw_admin: 'SW 管理员',
              firstName: '名',
              userRights: '权限状态',
              phoneNumber: '电话',
            },
          },
          fields: {
            gender: {
              male: '男性',
              female: '女性',
              diverse: '各种各样的',
            },
          },
        },
        search: {
          noResults: '没有找到结果',
          results: {
            of: '由',
            lines: '生产线',
            machines: '机器',
          },
          callToAction: '搜索机器',
        },
        spistat: {
          tileTitle: 'SpiStat',
        },
        worldmap: {
          tileTitle: '世界地图',
        },
        shopfloor: {
          tileTitle: '生产车间',
          upload: {
            text1: '将生产车间布局图像发送至 lifedata@sw-machines.com (JPG, PNG, PDF)。',
            text2: '如果您的机器已在 SW CloudPlatform 中注册和登记，它们将显示在左侧并发送实时数据。',
            text3: '然后只需将您的设计状态拖拽到正确的机器上即可。',
            mainText: '在此视图中，您可以设置专属的生产车间布局，并通过不同的信息层查看您的机器。只需按照以下步骤操作即可。',
            tileTitle: '上传生产车间',
          },
        },
        asset: {
          delete: {
            success: '资产已成功删除。',
            confirmation: {
              title: "您确定要删除 '%replaceValue’吗?",
              confirm: '删除',
              dismiss: '取消',
            },
          },
          new: {
            buttons: {
              delete: '删除资产',
            },
          },
          updateLimits: {
            confirmation: {
              intro: '您想接受这种新机器型号的极限值吗?',
              title: '您已更改了机器型号',
              accept: '是，接受新的极限值',
              decline: '否，保持当前的极限值',
              dismiss: '取消',
              info_accept: '要使用新机器型号的极限值覆盖写当前的极限值，请选择“是”。',
              info_decline: '要保留当前的极限值，请选择“否”。',
              info_accept_warning: '所有当前的极限值均被覆盖写或删除。',
            },
          },
        },
        assets: {
          line: {
            tabs: {
              kpi: 'KPIs',
              list: '机器',
              shopfloor: '生产现场',
            },
            fab_title: '添加新生产线',
            grid: {
              hall: '车间',
              line: '生产线',
              type: '型号',
              contractEnd: '合同到期 [选项]',
              cellPosition: '加工单元/位置 [选项]',
              operationStep: '加工步骤',
              machineIdentifierSw: '机器号(SW)',
              machineIdentifierCustomer: '机器名称',
            },
            success: {
              title: '成功！',
              button: '返回列表',
              message: '该生产线已成功创建。',
            },
            new_form_title: '新生产线',
            edit_form_title: '编辑流水线',
            new: {
              fields: {
                name: '生产线名称',
                name_short: '生产线名称 (缩写) [选项]',
                description: '说明 [选项]',
              },
              error_messages: {
                name_not_specified: '请输入生产线名称。',
              },
            },
            editSuccess: {
              title: '成功！',
              button: '返回列表',
              message: '该流水线已成功更新。',
            },
          },
          hall: {
            fab_title: '添加新车间',
            grid: {
              part: '零件型号',
              lineName: '生产线',
              typeOfLoading: '上下料形式',
              lineResponsible: '生产线负责人',
              plannedQuantity: '计划的产量',
              numberOfMachines: '# 机器',
            },
            tabs: {
              list: '生产线',
              shopfloor: '生产车间',
            },
            success: {
              title: '成功！',
              button: '返回列表',
              message: '该车间已成功创建。',
            },
            new_form_title: '新车间',
            shopfloor: {
              edit: '编辑模式',
              configure: {
                save: {
                  fab_title: '保存配置',
                },
                discard: {
                  fab_title: '放弃配置',
                },
              },
            },
            edit_form_title: '编辑车间',
            new: {
              fields: {
                name: '车间名称',
                name_short: '车间名称 (缩写) [选项]',
                description: '描述 [选项]',
                description_external: '说明 (外部) [选项]',
                description_internal: '说明 (内部) [选项]',
              },
              error_messages: {
                name_not_specified: '请输入车间名称。',
              },
            },
            editSuccess: {
              title: '成功！',
              button: '返回列表',
              message: '该车间已成功更新。',
            },
          },
          grid: {
            description: '描述 [选项]',
          },
          company: {
            fab_title: '添加新公司',
            grid: {
              city: '城市',
              email: '电子邮件',
              street: '街道',
              country: '国家',
              website: '网站',
              postalCode: '邮编',
              locationName: '基地名称',
            },
            tabs: {
              list: '清单',
              worldmap: '世界地图',
            },
            success: {
              title: '成功！',
              button: '返回列表',
              message: '该公司已成功创建。',
            },
            toggle_switch: '创建一个具有相同数据的生产基地',
            new_form_title: '新公司',
            edit_form_title: '编辑公司',
            new: {
              fields: {
                city: '城市',
                name: '公司名称',
                email: '电邮 [选项]',
                state: '州/省 [选项]',
                street: '街道',
                country: '国家',
                website: '网站 [选项]',
                zipcode: '邮编',
                legal_form: '公司类别[选项]',
                name_short: '公司名称(缩写) [选项]',
                description: '描述 [选项]',
                company_logo: '公司商标 (URL) [选项]',
                customer_number: '客户号',
                industry_sector: '工业领域',
                description_external: '说明 (外部) [选项]',
                description_internal: '说明 (内部) [选项]',
              },
              error_messages: {
                url: '请您输入一个有效的 URL。',
                email: '请输入有效的电邮地址。',
                city_not_specified: '请输入城市。',
                name_not_specified: '请输入公司名称。',
                street_not_specified: '请输入地址。',
                country_not_specified: '请输入国家。',
                zipcode_not_specified: '请输入邮编。',
                customer_number_not_specified: '请您输入一个客户号。',
                industry_sector_not_specified: '请输入工业领域。',
              },
            },
            editSuccess: {
              title: '成功！',
              button: '返回列表',
              message: '该公司已成功更新。',
            },
            header: {
              labels: {
                email: '电子邮件',
                address: '地址',
                country: '国家',
                website: '网站',
              },
            },
            coordinateselement: {
              intro_text: 'SW CloudPlatform 在地图上使用经度和纬度显示该公司。',
              intro_heading: '有关地点的信息',
              location_name: '地名:',
              label_latitude: '纬度',
              label_longitude: '经度',
              text_fallback_info: '如果未设定，则使用标准坐标。',
              text_location_found: '已找到一个地点。',
              text_location_entered: '地点已给出。',
              text_success_location: '设置了以下数据:',
              button_define_coordinates: '自己输入坐标',
              text_no_coordinates_found: '没有找到相应于所输入数据的坐标。',
              text_coordinates_success_set: '坐标已设定。',
              button_set_custom_coordinates: '设定坐标',
              text_specify_coordinates_yourself: '或者，您可以自己输入坐标。',
            },
          },
          machine: {
            fab_title: '创建新资产',
            tabs: {
              info: '时间顺序',
              alarms: '警报',
              gateway: '网关',
              service: '客户服务',
              spistat: 'SpiStat',
              swimlane: '生产线可用性',
              'axis-test': '轴测试',
              collection: '选择',
              maintenance: '维护',
              performance: '性能',
              spindledata: '主轴数据',
              administration: '管理',
              energymonitoring: '能耗监测',
            },
            move: {
              message: "可以将资产 '%replaceValue' 移动到新的公司、生产基地、车间和/或流水线中。",
              select_hall_label: '请选择新的新车间',
              select_line_label: '请选择新的流水线',
              select_company_label: '请选择新的公司',
              select_location_label: '请选择新的生产基地',
            },
            new: {
              fields: {
                s3: 'S3',
                name: '机器号 (SW)',
                iotcore: 'IoT Core',
                startup: '交付 [选项]',
                shipping: '发送 [选项]',
                controller: '控制器',
                machine_type: '机器型号',
                warranty_end: '保修结束 [选项]',
                iotgreengrass: 'IoT Greengrass',
                systemsManager: '系统管理员',
                warranty_start: '保修开始 [选项]',
                machine_swmp_api: '机器 SWMP API 结束 [选项]',
                machine_swsp_trace: '机器 SWSP Trace 结束 [选项]',
                machine_swcp_portal: '机器 SWCP Portal 结束 [选项]',
                start_of_production: '生产开始 [选项]',
                machine_swcp_support: '机器 SWCP Support 结束 [选项]',
                machine_cell_position: '加工单元/位置',
                machine_swsp_line_mon: '机器 SWSP LineMon 结束 [选项]',
                machine_identifier_customer: '机器确认号 (客户)',
              },
              error_messages: {
                name_not_specified: '请输入有效的 SW 机器号（至少 7 个字符: A-Z, 0-9, -)。',
                controller_not_specified: '请选择一个控制器。',
                machine_type_not_specified: '请选择一个机器型号。',
                machine_identifier_customer_not_specified: '请输入机器确认号。',
              },
            },
            success: {
              title: '成功！',
              button: '返回列表',
              message: '该资产已成功创建。',
            },
            new_form_title: '添加资产',
            edit_form_title: '编辑资产',
            move_form_title: '移动资产',
            editSuccess: {
              title: '成功！',
              button: '返回列表',
              message: '该资产已成功更新。',
            },
            header: {
              labels: {
                api: 'SWMP API',
                trace: 'SWSP Trace',
                portal: 'SWCP Portal',
                lineMon: 'SWSP LineMon',
                support: 'SWCP Support',
                shipping: '发货日期',
                controller: '数控系统',
                contractEnd: '合同结束',
                machineType: '机型',
                startupDate: '调试日期',
                warrantyEnd: '保修结束日',
                cellPosition: '单元/位置',
                warrantyStart: '保修起始日',
                customerNumber: '客户号',
                enrollmentConfig: '注册配置',
                startOfProduction: '生产开始',
                machineIdentifierSw: '机器号SW',
                enrollmentConfigDownload: '下载',
                machineIdentifierCustomer: '客户机器名称',
              },
            },
            add_line_to_dashboard: '给主菜单添加生产线',
            elements: {
              'axis-test': {
                kpi: 'KPI',
                unit: '单元',
                value: '值',
                kpiUnit: '单元',
                headline: '轴测试',
                kpiValue: '值',
                kpiHeader: 'KPI',
                chartTitle: '轴测试',
                limitsLoad: '从浏览器存储器中加载',
                limitsSave: '保存到浏览器存储器中',
                selectAxis: '选择轴',
                limitsExport: '将极限值导出到剪贴板',
                openAxistest: '打开轴测试',
                listbox: {
                  label: '类型 / 轴(复数)',
                  noContentMessage: '选择轴检验类型',
                  noAxisTestAvailable: '没有这种类型的轴检验可用',
                },
                axisTestPlural: '轴测试',
                noChartMessage: '请在左侧选择一个汇集。',
                adjustments: {
                  add: '添加本地调整',
                  range: '范围',
                  header: '本地调整',
                  remove: '去掉',
                  position: '位置',
                  amplitude: '振幅',
                  spreadMultiplier: '极限值乘数',
                },
                chart: {
                  Equability: {
                    x: '位置',
                    y: '电流',
                    up: '上',
                    down: '下',
                    limitsUp: '限制正方向',
                    limitsDown: '限制负方向',
                    sliderSlopeCenter: '坡度原点',
                    sliderSlopeFactor: '坡度系数',
                    sliderVerticalOffset: 'Y 轴偏移',
                    sliderVerticalSpread: '限制宽度',
                    sliderQuadraticCenter: '方形原点',
                    sliderQuadraticFactor: '方形系数',
                  },
                  Circularity: {
                    clockwise: '顺时针',
                    limitsClockwise: '顺时针限制',
                    counterClockwise: '逆时针',
                    sliderSineOffset: '波偏移',
                    sliderSineAmplitude: '波幅',
                    sliderSineFrequency: '波频率',
                    sliderVerticalOffset: 'Y 轴偏移',
                    sliderVerticalSpread: '限制宽度',
                    limitsCounterClockwise: '逆时针限制',
                  },
                },
                kpis: {
                  pulseVelocity: '速度',
                  pulseMaxRampUp: '斜坡 ->',
                  pulseMaxRampDown: '斜坡 <-',
                  pulseMaxStiffness: '最大刚度',
                  pulseMinStiffness: '最小刚度',
                  pulseMeanStiffness: '刚度 Ø',
                  circularReversalRange: '圆形反装夹',
                  pulseMaxUpPathCurrent: '最大电流 ->',
                  pulseMinUpPathCurrent: '最小电流 ->',
                  pulseMeanUpPathCurrent: '电流 Ø ->',
                  pulseMaxDownPathCurrent: '最大电流 <-',
                  pulseMinDownPathCurrent: '最小电流 <-',
                  pulseMeanDownPathCurrent: '电流 Ø <-',
                  circularShapeDeviationClockwise: '右转圆形偏差',
                  pulseStiffnessStandardDeviation: '刚度 σ',
                  pulseStandardDeviationUpPathCurrent: '电流 σ ->',
                  pulseStandardDeviationDownPathCurrent: '电流 σ <-',
                  circularShapeDeviationCounterClockwise: '左转圆形偏差',
                },
                axisLabels: {
                  CIRCLE: {
                    x: '位置',
                    y: '位置',
                  },
                  EQUABILITY: {
                    x: '位置',
                    y: '电流',
                  },
                },
                axisTestTypes: {
                  CIRCLE: '圆检验',
                  EQUABILITY: '同步检验',
                  CIRCULARITY: '圆检验',
                },
              },
              gateway: {
                title: '网关',
                contact: '如有错误请使用',
                headline: '网关',
                netcfg: {
                  cta: '请您通过 Netcfg 表格向我们提供数据供使用。',
                  intro: '为了能够连接您的机器，我们需要您的网络配置信息。',
                  title: '网络配置',
                  goToNetconfig: '打开网络配置',
                },
                device: {
                  title: '设备详情',
                },
                enrollment: {
                  line1: '请您单击图案下边缘的按钮，以下载注册文件。',
                  line2: '下载自动开始。',
                  line3: '请您将 .json 文件放在 U 盘的根文件夹中。请您确保文件已命名为:',
                  line4: 'enrollment.json.',
                  line5: '请您确保以下文件位于 U 盘的根文件夹中:',
                  line6: '请您将 U 盘插入相应的物联网网关。',
                  line8: '注册成功后，绿色 LED 灯会亮起，直到 U 盘被拔出。',
                  title: '注册',
                  button: '注册文件',
                },
                deviceMigration: {
                  line1: '请您下载“migrate-iot.zip”文件，并将其内容直接解压缩到空白 U 盘上。',
                  line2: 'aws.json',
                  line3: '请您也下载它 ',
                  line4: ' 并将其添加到 U 盘上。请您确保文件命名如下:',
                  line5: '请您确保以下文件位于 U 盘的根文件夹中:',
                  line6: 'install.json',
                  line7: 'migration.tar.gz',
                  line8: 'migration.tar.gz.sig',
                  line9: 'migration.json',
                  title: '平台融合',
                  line10: '请您将 U 盘插入相应的物联网网关。',
                  line11: '该软件会自动安装，由闪烁的黄色 LED 指示。',
                  line12: '成功融合后，绿色 LED 灯会亮起，直到 U 盘被拔出。',
                  line13: '成功融合后，绿色 LED 灯会亮起，直到 U 盘被拔出。',
                  device: {
                    button: 'migrate-iot.zip',
                  },
                  generic: {
                    button: 'aws.json',
                  },
                },
              },
              info: {
                generate: '生成',
                headline: '时间序列管理',
                noChartMessage: '请选择一个或多个参数选项，然后单击“生成”',
              },
              alarms: {
                headline: '警报',
              },
              service: {
                headline: '客户服务',
                update: {
                  title: '更新文件',
                },
                upload: {
                  title: '上传文件',
                  fields: {
                    file: '文件',
                    language: '文件语言',
                    file_name: '文件名',
                    list_of_languages: {
                      cz: '捷克语',
                      de: '德语',
                      en: '英语',
                      es: '西班牙语',
                      fr: '法语',
                      hu: '匈牙利',
                      it: '意大利语',
                      pl: '波兰语',
                      zh: '中文',
                    },
                  },
                  buttons: {
                    send: '上传',
                    cancel: '中断',
                  },
                  success: {
                    title: '成功',
                    button: '返回服务总览',
                    message: '该文件已成功上传',
                  },
                  error_messages: {
                    file_name: '请您输入一个文件名。',
                    file_not_valid: '请输入一个文件/请选择一个较小的文件。',
                    language_not_specified: '请输入文件语言。',
                  },
                },
                manuals: {
                  title: '使用手册',
                },
                version: {
                  title: '文件版本',
                },
                contracts: {
                  title: '合同',
                },
                components: {
                  title: '硬件部件和软件部件',
                },
                spare_parts: {
                  title: '备件清单',
                },
                consumeables: {
                  title: '工作耗材',
                },
                Update: {
                  buttons: {
                    send: '更新',
                  },
                },
                software_edge: {
                  title: '物联网网关软件',
                  values: {
                    box_id: 'Box-ID',
                    machine_connect: '机器连接',
                    operating_system: '操作系统',
                  },
                },
                contact_service: {
                  line1: '您需要机器上的帮助吗?',
                  line2: '请联系我们!',
                  title: '给 SW 客户服务的信息',
                  button: '联系客户服务',
                  fields: {
                    name: '机器号 (SW)',
                    type: '请求类型',
                    email: '电子邮件',
                    message: '信息',
                    phone_no: '电话',
                    last_name: '姓',
                    first_name: '名',
                    contact_person: '联系人 (SW) [选项]',
                    list_of_types: {
                      request: '一般性请求',
                      service: '客户服务',
                      retrofit: '翻新',
                      expansion: '扩展',
                      inspection: '检查',
                    },
                    machine_identifier_customer: '机器确认号 (客户) [选项]',
                  },
                  buttons: {
                    send: '发送请求',
                    cancel: '中断',
                  },
                  success: {
                    title: '成功！',
                    button: '返回客户服务页面',
                    message: '请求已成功发送至SW客户服务。',
                  },
                  error_messages: {
                    email_not_valid: '请输入有效的电子邮件地址。',
                    name_not_specified: '请输入有效的 SW 机器号（至少 7 个字符: A-Z, 0-9, - _)。',
                    phone_not_specified: '请输入有效的电话号码。',
                    message_not_specified: '请输入信息。',
                    last_name_not_specified: '请输入姓。',
                    first_name_not_specified: '请输入名。',
                    family_name_not_specified: '请输入姓。',
                    contact_type_not_specified: '请输入服务请求类型。',
                  },
                },
                software_machine: {
                  title: '机器软件',
                  values: {
                    nc_version: 'NC 版本',
                    hmi_version: 'HMI 版本',
                    plc_version: 'PLC 版本',
                    compile_cycles: '编译周期',
                    operating_system: '操作系统',
                    pcu_base_version: 'PCU 基本版',
                    plc_toolbox_version: 'PLC 工具箱',
                    plc_user_program_version: 'PLC 用户程序',
                  },
                },
                changed: {
                  success: {
                    message: '文件名已成功修改',
                  },
                },
                maintenance_assistance: {
                  intro: '操作助手帮你解决问题。',
                  title: '操作助手',
                  sub_title: '您目前是否对您的一项资产有困难?',
                  open_assistant: '打开操作助手',
                  request_account: '申请账户',
                },
              },
              spistat: {
                headline: '主轴统计 SpiStat',
              },
              onlineState: {
                title: '物联网连接',
                labelOnline: '开',
                labelOffline: '关',
              },
              swimlane: {
                headline: '生产线可用性',
              },
              sendingState: {
                title: '发送状态',
                aborted: '中断了',
                sending: '发送',
                interrupted: '中断了',
              },
              alarms_map: {
                headline: '屏幕警报',
              },
              collection: {
                headline: '时间 ',
                noChartMessage: '请在左侧选择一个参数选项。',
              },
              performance: {
                kpi: {
                  sum: '合计',
                  header: 'Kpi 性能',
                  target: '给定',
                },
                alarms: {
                  title: '警报',
                  category: [
                    '未分类',
                    '故障报告',
                    '工作报告',
                    '状态报告',
                  ],
                  icons: {
                    trend: '趋势',
                    duration: '时长',
                    flatlist: '平面列表',
                    frequency: '出现的频率',
                  },
                  tableHeadlines: {
                    time: '时间',
                    trend: '趋势',
                    duration: '时长',
                    leftTime: '警报走了',
                    alarmCount: '数量',
                    alarmNumber: '警报号',
                    description: '描述',
                    alarmOccured: '出现了警报',
                    alarmClassification: '警报分类',
                    alarmCountSecondRange: '数量 T-1',
                  },
                  description: {
                    trend: {
                      up: '上升的',
                      down: '下降的',
                      flat: '不变的',
                    },
                  },
                },
                details: {
                  title: '细节',
                  ncProgram: 'NC 程序',
                  lastModified: '上一次修改',
                  machineState: '机器状态',
                  numberAlarms: '警报待定',
                  stopCondition: '中止条件',
                  ncProgramState: 'NC 程序状态',
                  protectionLevel: '保护级别',
                  ncProgramStates: [
                    '中断了',
                    '中止了',
                    '运行中',
                    '等待中',
                    '中断了',
                  ],
                  protectionLevels: [
                    '系统',
                    '制造商',
                    '客户服务',
                    '用户',
                    '钥匙开关 位置 3',
                    '钥匙开关 位置 2',
                    '钥匙开关 位置 1',
                    '钥匙开关 位置 0',
                  ],
                },
                cycleTime: {
                  title: '节拍',
                  chart: {
                    yAxis: {
                      title: '循环时间，单位：秒',
                    },
                  },
                },
                piececounter: {
                  ok: '正常',
                  nok: '不正常',
                  sum: '合计',
                  title: '件计数器',
                  titleDayCounter: '日计数器',
                  titleShiftCounter: '工班计数器',
                  titleTotalCounter: '总计数器',
                  titleTotalCounterSmall: '总体',
                },
                availability: {
                  title: '可用性',
                  kpi: {
                    title: 'KPI 可用性',
                  },
                  legend: {
                    auto: 'AUTO',
                    JOG_with_error: 'JOG 带错误',
                    MDA_with_error: 'MDA 带错误',
                    JOG_without_error: 'JOG 无错误',
                    MDA_without_error: 'MDA 无错误',
                    not_defined_condition: '未定义的状态',
                    auto_no_program_active: 'AUTO 没有程序被激活',
                    auto_waiting_for_parts: 'AUTO 等待零件',
                    auto_with_error_program_stopped: 'AUTO 带错误 - 程序停止',
                    auto_waiting_for_parts_stopping_error: 'AUTO 等待零件 + 停止错误',
                  },
                  aggregated: {
                    title: '汇聚的可用性',
                  },
                  chart: {
                    yAxis: {
                      title: '运行时间监视器',
                    },
                  },
                },
                inProduction: {
                  title: '状态',
                },
                operationMode: {
                  title: '工作模式',
                  chart: {
                    yAxis: {
                      title: '工作状态',
                    },
                  },
                },
                runtimeMonitor: {
                  title: '运行时间监视器',
                  chart: {
                    yAxis: {
                      title: '程序',
                      labels: {
                        on: '开',
                        off: '关',
                      },
                    },
                  },
                },
                spindleOverride: {
                  title: '主轴承载',
                },
                feedrateOverride: {
                  title: '进给承载',
                },
                uptimeDistribution: {
                  title: '正常运行时间分配',
                },
              },
              administration: {
                title: '管理',
                headline: '管理',
                aws: {
                  title: 'AWS 链接',
                },
                references: {
                  values: {
                    s3: 'S3',
                    iot: 'IoT Core',
                    ssm: '系统管理员',
                    greengrass: 'IoT Greengrass',
                  },
                },
              },
              condition: {
                bill: {
                  title: '零件清单',
                },
                component: {
                  title: '部件',
                },
              },
              maintenance: {
                headline: '维护',
              },
              spindleData: {
                headline: '主轴数据',
              },
              point8: {
                chart: {
                  xAxis: {
                    title: '警报前时间(s)',
                  },
                  yAxis: {
                    title: '警报后时间(s)',
                  },
                },
              },
              energymonitoring: {
                headline: '能耗监测',
                chartTitles: {
                  air: '耗气量',
                  current: '耗电量',
                  consumption: '每小时平均消耗量',
                },
              },
              conditionMonitoring: {
                headline: '状态监控',
              },
            },
          },
          location: {
            fab_title: '添加新生产基地',
            tabs: {
              list: '车间',
              shopfloor: '生产车间',
            },
            grid: {
              hallName: '车间',
            },
            success: {
              title: '成功！',
              button: '返回列表',
              message: '该生产基地已成功创建。',
            },
            new_form_title: '新生产基地',
            edit_form_title: '编辑生产基地',
            new: {
              fields: {
                city: '城市',
                name: '生产基地名称',
                email: '电子邮件 [选项]',
                state: '州/省 [选项]',
                street: '地址',
                country: '国家',
                website: '网站 [选项]',
                zipcode: '邮编',
                language: '语言 [选项]',
                name_short: '生产基地名称 (缩写) [选项]',
                description: '说明 [选项]',
                description_internal: '说明 (内部) [选项]',
              },
              error_messages: {
                email: '请输入有效的电邮地址。',
                city_not_specified: '请输入城市。',
                name_not_specified: '请输入生产基地名称。',
                street_not_specified: '请输入地址。',
                country_not_specified: '请输入国家。',
                zipcode_not_specified: '请输入邮编。',
              },
            },
            editSuccess: {
              title: '成功！',
              button: '返回列表',
              message: '该生产基地已成功更新。',
            },
            header: {
              labels: {
                address: '地址',
                country: '国家',
              },
            },
          },
          companies: {
            grid: {
              city: '城市',
              email: '电子邮件',
              street: '街道',
              country: '国家',
              website: '网站',
              postalCode: '邮编',
              companyName: '公司名称',
            },
            tabs: {
              list: '清单',
              worldmap: '世界地图',
            },
            map: {
              description_not_available: '没有描述',
            },
          },
          generic: {
            new: {
              buttons: {
                add: '添加',
                back: '返回列表',
                edit: '更新',
                save: '保存',
                email: '发送图片',
                cancel: '取消',
                addMultiple: '添加 + 新建',
              },
            },
          },
          administration: {
            edit_form_title: '编辑管理',
          },
        },
        settings: {
          tabs: {
            limits: '限制',
          },
          button: {
            import: '开始导入',
            dismiss: '中断',
            download: '开始下载',
          },
        },
        file: {
          new: {
            buttons: {
              delete: '删除文件',
            },
          },
        },
        hall: {
          new: {
            buttons: {
              delete: '删除车间',
            },
          },
        },
        line: {
          new: {
            buttons: {
              delete: '删除生产线',
            },
          },
        },
        runtimeStatus: {
          tileTitle: '状态',
        },
        charts: {
          tooltipDateFormat: 'YYYY-MM-DD HH:mm:ss',
          exporting: {
            datetimeformat: '%m/%d/%Y %H:%M:%S',
          },
          globalSettings: {
            lang: {
              noData: '无数据',
              loading: '正在加载数据...',
              months: [
                '1月',
                '2月',
                '3月',
                '4月',
                '5月',
                '6月',
                '7月',
                '8月',
                '9月',
                '10月',
                '11月',
                '12月',
              ],
              resetZoom: '缩放复原',
              weekdays: [
                '周日',
                '周一',
                '周二',
                '周三',
                '周四',
                '周五',
                '周六',
              ],
              shortMonths: [
                '1月',
                '2月',
                '3月',
                '4月',
                '5月',
                '6月',
                '7月',
                '8月',
                '9月',
                '10月',
                '11月',
                '12月',
              ],
              shortWeekdays: [
                '周日',
                '周一',
                '周二',
                '周三',
                '周四',
                '周五',
                '周六',
              ],
              increaseDateRange: '至少至',
            },
          },
        },
        datepicker: {
          presets: {
            title: '预设',
            rangeTo: '至',
            selectDateRange: '请选择时间段',
            predefined: {
              today: '今天',
              last7days: '前7 天',
              yesterday: '昨天',
              last24Hours: '前24 小时',
              last5Minutes: '前 5 分钟',
            },
          },
        },
        i18n: {
          chooseLanguagePhrase: '语言选择',
        },
        company: {
          new: {
            buttons: {
              delete: '删除公司',
            },
          },
        },
        location: {
          new: {
            buttons: {
              delete: '删除位置',
            },
          },
        },
        runtimeStatusList: {
          tileTitle: '状态',
        },
        dashboard: {
          configure: {
            fab_title: '配置主菜单',
            hide: {
              title: '隐藏',
            },
            show: {
              title: '显示',
            },
            delete: {
              title: '删除',
            },
            add: {
              fab_title: '添加自己的图块',
            },
            save: {
              fab_title: '保存配置',
            },
            move_left: {
              title: '移到上一个位置',
            },
            move_right: {
              title: '移到下一个位置',
            },
            discard: {
              fab_title: '放弃配置',
            },
            add_custom_card: {
              save: '保存',
              input: '给出图块名',
              title: '添加自己的图块',
              cancel: '中断',
              missingMachine: '您必须至少选择一台机器',
            },
          },
        },
        errors: {
          statusCodes: {
            e_0: {
              message: '出现了意外的错误。请您重试加载此网页。',
            },
            e_1xx: {
              message: '信息错误',
            },
            e_2xx: {
              message: '成功',
            },
            e_3xx: {
              message: '转发',
            },
            e_400: {
              message: '请求有错误，无法处理。',
            },
            e_401: {
              message: '您未通过身份验证。请您重新登录。',
            },
            e_403: {
              message: '您没有有效的权限来访问要求的资源。',
            },
            e_404: {
              message: '要求的资源不存在。',
            },
            e_405: {
              message: '方法不允许。',
            },
            e_409: {
              message: '您正在添加的资源已存在。',
            },
            e_418: {
              message: "I'm a Teapot! Yaay!",
            },
            e_4xx: {
              message: '客户端错误',
            },
            e_500: {
              message: '内部服务器错误。',
            },
            e_5xx: {
              message: '服务器错误',
            },
            e_900: {
              message: '创建资产的尝试失败了。请稍后再试。',
            },
            e_901: {
              message: '该操作被取消了。没有创建新资产。',
            },
            e_902: {
              message: '该操作被取消了。没有发送任何服务请求。',
            },
            e_903: {
              message: '尝试发送服务请求时出错了。请稍后再试。',
            },
            e_904: {
              message: "资产'%replaceValue'已经成功创建。您现在可以继续添加资产。",
            },
            e_905: {
              message: '该操作被取消了。该资产还没有更新。',
            },
            e_906: {
              message: "资产 '%replaceValue' 已经成功创建。",
            },
            e_910: {
              message: '创建用户的尝试失败了。请稍后再试。',
            },
            e_911: {
              message: '您取消了当前操作。没有创建新用户。',
            },
            e_912: {
              message: '更新一个资产的尝试失败了。请重新尝试。',
            },
            e_920: {
              message: '该操作被取消了。还没有保存修改。',
            },
            e_921: {
              message: '出现了意外的错误。请再试一次。',
            },
            e_922: {
              message: '用户名和/或密码不正确。请再试一次。',
            },
            e_923: {
              message: '请输入用户名和密码。',
            },
            e_924: {
              message: '由于不活动，您的访问已过期。请您重新登录。',
            },
            e_925: {
              message: '为了能够使用 SW 云平台，您必须接受我们的隐私声明。',
            },
            e_926: {
              message: '您的帐户已被暂停。请在几分钟后再试。',
            },
            e_927: {
              message: '您的密码已成功更改。',
            },
            e_928: {
              message: '该操作被取消了。没有上传文件。',
            },
            e_929: {
              message: '尝试上传文件失败了。请稍后再试。',
            },
            e_930: {
              message: '尝试删除文件时出现问题。请稍后再试。',
            },
            e_10500: {
              message: '好像您没有连接到互联网。请确保您已连接。',
            },
            e_10501: {
              message: '您使用的是不支持的浏览器。请使用以下浏览器之一：Google Chrome、Microsoft Edge、Apple Safari。',
            },
            e_409_asset: {
              message: '资产 (SW 机器号 %replaceValue) 已存在。',
            },
            e_410_asset: {
              message: '该文件名 %replaceValue 已存在',
            },
          },
        },
        list: {
          sweetalert: {
            maxAmountReached: {
              html: '只能同时选择 %s 个元素',
              title: '达到了最大数量',
            },
          },
        },
      },
      module: {
        assets: {
          machine: {
            alarms: {
              no_alarms_text: '没有可用的警报',
            },
            nc_data_file_cardTitle: 'NC 状态文件',
            nc_state_files: {
              no_files_text: '没有可用的文件。',
            },
            nc_data_files: {
              column_title_trigger: '触发器',
              column_title_filename: '文件名',
              column_title_creation_date: '创建日期',
              modules: {
                assets: {
                  nc_data_download: '下载 NC 数据',
                },
              },
            },
          },
        },
      },
    },
  },
};